import React, { useState, useCallback, useEffect } from "react";
import { Tabs } from "antd";
//Components
import Wrapper from "Layout/Wrapper";
import styles from "./Home.module.scss";
import CsaCards from "Common/CsaCards";
import Tournaments from "pages/Tournaments";
import Progress from "pages/ProgressTournaments/Progress";
import Schedule from "pages/ScheduleTournaments/Schedule";
import Statistics from "pages/Statistics";
import PlayerInformation from "pages/PlayerInformation/PlayerInformation";
import TeamInformation from "pages/TeamInformation/TeamInformation";
import { useDispatch, useSelector } from "react-redux";
import { setPoolId } from "redux/slice/commonSlice";
import { setTournamentTab } from "redux/slice/commonSlice";
import { setIsBack } from "redux/slice/commonSlice";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

const { TabPane } = Tabs;
function Home() {
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [tabpanestate, settabpanestate] = useState("");
  const [statsTabState, setStatsTabState] = useState("players");
  const [memberName, setmeberName] = useState("players");
  const [mId, setmId] = useState();
  const [banner, setBanner] = useState();
  const [tName, setTName] = useState();
  const [scoreId, setScoreId] = useState();
  const [id, setId] = useState();
  const [skip, setSkip] = useState(false);
  const [mData, setmData] = useState();
  const [isBack, setIsBackk] = useState(false);
  const [status, setStatus] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("tournaments")) {
      setActiveKey("2");
    }
    if (path.includes("stats")) {
      setActiveKey("4");
    }
    switch (path) {
      case "/home":
        navigate("score");
        break;
      case "/home" || "/home/score":
        setActiveKey("1");
        break;
    }
  }, [location.pathname]);

  const onKeyChange = (key) => {
    if (key == "2") {
      navigate("tournaments");
    }
    if (key == "1") {
      navigate("score");
    }
    if (key == "4") {
      navigate("stats");
    }
    // if (location?.pathname !== "/home") {
    //   navigate(-1);
    // }
    dispatch(setIsBack(false));
    setActiveKey(key);

    settabpanestate("");
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPoolId(null));
    dispatch(
      setTournamentTab({
        tab: "1",
      })
    );
  }, []);
  const onchangecomponent = useCallback(
    ({
      page,
      mId,
      banner,
      tournamentName,
      scId,
      key,
      skip,
      mData,
      statsTab,
      memberName,
      isBack,
    }) => {
      setActiveKey(key);
      settabpanestate(page);
      setmId(mId);
      setBanner(banner);
      setTName(tournamentName);
      setScoreId(scId);
      setSkip(skip);
      setmData(mData);
      setStatsTabState(statsTab);
      setmeberName(memberName);
      setIsBackk(isBack);
      dispatch(setIsBack(isBack || false));
    },
    [settabpanestate]
  );

  const handleNavigate = useCallback(
    ({ key, tId }) => {
      setActiveKey(key);
      setId(tId);
    },
    [setActiveKey]
  );
  const props = {
    styles,
    info,
    setInfo,
    isLocal,
    setLocal,
    setActiveKey,
    status,
    setStatus,
    id,
    setId,
    skip,
    tName,
    banner,
    mId,
    scoreId,
    onchangecomponent,
    handleNavigate,
    statsTabState,
  };
  //
  console.count("home");
  return (
    <Wrapper>
      <div className={styles.HomeWrapper}>
        <div className={styles.contents}>
          <>
            <div>
              <Tabs
                type="card"
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={onKeyChange}
              >
                <TabPane tab="Live Matches" key="1">
                  <Outlet context={{ propsData: props }} />
                </TabPane>

                <TabPane tab="Tournaments / Leagues" key="2">
                  <Outlet context={{ propsData: props }} />
                </TabPane>
                <TabPane tab="Statistics" key="4">
                  <Outlet context={{ propsData: props }} />
                </TabPane>
              </Tabs>
            </div>
          </>
        </div>
      </div>
    </Wrapper>
  );
}

export default Home;
