import { fixSpecialChar, getShortenedName } from "utils/helpers";
import React from "react";
import styles from "./TournamentsScoreCard.module.scss";

export function generateWicketName(item, minLength, caps) {
  const bowlerName = getShortenedName(
    caps
      ? fixSpecialChar(item.bowler.name)?.toUpperCase()
      : fixSpecialChar(item.bowler.name),
    minLength
  );
  const fielder1Name = getShortenedName(
    caps
      ? fixSpecialChar(item.fielder1.name)?.toUpperCase()
      : fixSpecialChar(item.fielder1.name),
    minLength
  );
  const fielder2Name = getShortenedName(
    caps
      ? fixSpecialChar(item?.fielder2?.name)?.toUpperCase()
      : fixSpecialChar(item?.fielder2?.name),
    minLength
  );

  const bIsSub = item?.bowler?.isSub;
  const f1IsSub = item?.fielder1?.isSub;
  const f2IsSub = item?.fielder2?.isSub;

  function isSub(s) {
    //
    return s ? "(Sub)" : "";
  }
  let playerName =
    (item?.wicketType === "lbw" &&
      item.bowler.name !== "" &&
      `(lbw) ${bowlerName} ${isSub(bIsSub)} `) ||
    (item?.wicketType === "hit wicket" &&
      `hit wicket (b) ${bowlerName} ${isSub(bIsSub)}`) ||
    (item?.wicketType === "bowled" && `(b) ${bowlerName} ${isSub(bIsSub)}`) ||
    (item?.wicketType === "stumped" &&
      `(st) ${fielder1Name} ${isSub(f1IsSub)} (b) ${bowlerName} ${isSub(
        bIsSub
      )}`) ||
    (item?.wicketType === "caught" &&
      item?.bowler.name === item?.fielder1?.name &&
      `(c & b) ${bowlerName} ${isSub(bIsSub)}`) ||
    (item?.wicketType === "caught" &&
      item?.bowler.name !== item?.fielder1?.name &&
      `(c) ${fielder1Name} ${isSub(f1IsSub)} (b) ${bowlerName} ${isSub(
        bIsSub
      )}`) ||
    (item?.wicketType === "runout" &&
      item?.fielder2?.name !== "" &&
      `(run out) ${fielder1Name} ${isSub(f1IsSub)} & ${fielder2Name} ${isSub(
        f2IsSub
      )}`) ||
    (item?.wicketType === "runout" &&
      `(run out) ${fielder1Name} ${isSub(f1IsSub)}`) ||
    (item?.wicketType === "timed_out" && "Timed Out") ||
    (item?.wicketType === "obstruct" && "Obstructing the Field") ||
    (item?.wicketType === "mankad" &&
      `run out(mankad) ${bowlerName} ${isSub(bIsSub)}`) ||
    (item?.wicketType === "Retired Out" && "Retired Out ") ||
    (item?.wicketType === "hit ball twice" &&
      `hit ball twice (b) ${bowlerName} ${isSub(bIsSub)}`) ||
    (item?.wicketType === "Retired Not Out" && "Retired Not Out") ||
    (item?.wicketType === "Not Out" && "not out");

  return playerName;
}
export function getBattingArr(arr) {
  return arr
    ?.map((item, i) => {
      const sr = item?.strikeRate;
      let srIsNum = !typeof sr === "string";
      const numSr = !srIsNum ? Number(sr) : sr;

      return {
        key: i,
        name: `${fixSpecialChar(item?.batsman?.name)} ${
          item?.batsman?.isSub ? "(Sub) " : ""
        }`,
        players: generateWicketName(item)
          ? generateWicketName(item)
          : item?.wicketType
          ? item?.wicketType
          : null || "Nil",
        runs: item?.runs ? item?.runs : 0,
        balls: item?.balls ? item?.balls : 0,
        four: item?.fours ? item?.fours : 0,
        six: item?.sixes ? item?.sixes : 0,
        strike: numSr?.toFixed(1) ? numSr?.toFixed(1) : 0.0,
        dots: item?.dotBalls,
      };
    })
    ?.filter((i) => i.players !== "Did Not Bat");
}
export function getBowlingArr(arr) {
  return arr
    ?.map((item, i) => {
      const ec = item?.economyRate;
      let ecIsNum = !typeof ec === "string";
      const numEc = !ecIsNum ? Number(ec) : ec;

      return {
        key: i,
        bowler: fixSpecialChar(item?.bowler?.name),
        overs: item?.overs ? item?.overs : 0,
        maidens: item?.maidens ? item?.maidens : 0,
        runs: item?.runs ? item?.runs : 0,
        wickets: item?.wickets ? item?.wickets : 0,
        econ: numEc ? numEc?.toFixed(1) : 0,
      };
    })
    ?.filter((i) => i.overs !== "0.0" && i.overs !== 0);
}

export function getFallOfWickets(arr) {
  let wickets = 1;
  return arr?.map((item, i) => {
    return {
      key: i,
      batsman: fixSpecialChar(item?.batsman?.name),
      score: item?.score + "/" + wickets++,
      over: item?.overs,
    };
  });
}

export default function ExtrasCompomemt({ tournamentsData }) {
  return (
    <div className={styles.TournamentsExtras}>
      <div>
        Extras :&nbsp;{tournamentsData?.extras}
        &nbsp;(w&nbsp;{tournamentsData?.wides}, &nbsp;nb&nbsp;
        {tournamentsData?.noBalls}, &nbsp;b&nbsp;
        {tournamentsData?.byes}, &nbsp;lb&nbsp;
        {tournamentsData?.legByes})
      </div>
      <div>
        Penalty :&nbsp;
        {tournamentsData?.penalty}
      </div>
      <div>Total :&nbsp;{tournamentsData?.score}</div>
    </div>
  );
}

export function generateSuperOverCols(index, teamName, count, innsNum) {
  console.log(
    index,
    count,
    index === 0 && innsNum + fixSpecialChar(teamName),
    "iiiii",
    index + 1
  );
  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    //  if (index === 8) {
    //   obj.props.colSpan = 0;
    //  }
    return obj;
  };
  return [
    {
      title:
        (index === 0 && innsNum + fixSpecialChar(teamName)) ||
        (count > 1 && index > 0 && teamName + " ( Super Over " + index) +
          " )" ||
        teamName,

      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "",
      dataIndex: "players",
      key: "players",
      width: "20%",
      render: renderContent,
    },
    {
      title: "Runs",
      dataIndex: "runs",
      key: "runs",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Balls",
      dataIndex: "balls",
      key: "balls",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "4's",
      dataIndex: "four",
      key: "four",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "6's",
      dataIndex: "six",
      key: "six",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Strike Rate",
      dataIndex: "strike",
      key: "strike",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Dots",
      dataIndex: "dots",
      key: "dots",
      width: "10%",
      align: "center",
      render: renderContent,
    },
  ];
}
