import styles from "./GooglePlaceSearchinput.module.scss";
import React, { useState } from "react";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import GoogleMap from "google-map-react";


function GoogleplaceSearchInput({
    label,
    labelColor = false,
    placeholder,
    value,
    onChange,
    name,
    type,
    onBlur,
    required = false,
    error,
    touched,
    maxLength,
    disabled,
    isNumber = false,
    AddLocation,
    latitude,
    longitude
}) {

    const [position, setPosition] = useState({
        center: {
            // lat: latitude &&  latitude,
            // lng: longitude &&  longitude,
            lat: latitude && (typeof (latitude) === 'string') ? parseInt(latitude) : latitude,
            lng: longitude && (typeof (longitude) === 'string') ? parseInt(longitude) : longitude,
        },
        zoom: 11,
    });
    const [pickupAddress, setPickupAddress] = useState(value ? value : '');
    const [showmap, setShowmap] = useState(false);



    const defaultProps = {
        center: {
            lat: 10.1926,
            lng: 76.3869,
        },
        zoom: 11,
    };


    const renderMarkers = (map, maps) => {
        
        let marker =
            new maps.Marker({
                position: {
                    lat: position.center.lat,
                    lng: position.center.lng,
                },
                map,
                //   title: map.name,
            })

        return marker;
    };

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                

                setPickupAddress(address);
                setShowmap(true)
                setPosition({
                    center: {
                        lat: latLng.lat,
                        lng: latLng.lng,
                    },
                    zoom: 11,
                });
                AddLocation(address, latLng)
            })
            .catch((error) => console.error("Error", error));
    };

    const handlePickupAddressChange = (addressInput) => {

        setPickupAddress(addressInput);
    };
    return (
        <div className={styles.GoogleSearchInputWrapper}>
            <p className={labelColor ? styles.nameWhite : styles.name}>
                {label}
                {required && <span className={styles.required}>*</span>}
            </p>
            <PlacesAutocomplete
                value={pickupAddress}
                onChange={handlePickupAddressChange}
                onSelect={(e) => handleSelect(e)}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <div>
                        <Input
                            {...getInputProps({
                                placeholder: "Enter Address",
                                className:
                                    "location-search-input ",
                            })}
                            style={{ borderRadius: 5 }}
                            pattern={isNumber ? "[0-9]{1,5}" : ""}
                        />
                        <div className="autocomplete-dropdown-container mt-2 items-center justify-center rounded-md">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {
                                        backgroundColor: "#ADD8E6",
                                        cursor: "pointer",
                                        paddingTop: 5,
                                    }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                        borderBottomWidth: 1,
                                        paddingTop: 5,
                                    };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>



            <div className={styles.erroMsg}>
                {error && touched && <span className="text-danger">{error}</span>}
            </div>
            {(showmap || (latitude && longitude)) &&
                <div style={{ width: '100%', height: 300 }}>
                    <GoogleMap
                        bootstrapURLKeys={{ key: "" }}
                        center={position.center}
                        Zoom={position.zoom}
                        defaultCenter={position.center}
                        defaultZoom={position.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                            renderMarkers(map, maps)
                        }
                    ></GoogleMap>
                </div>
            }
        </div>
    );
}

export default GoogleplaceSearchInput;
