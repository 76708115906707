import React, { useState } from "react";
import BattingOverlay from "Common/BattingOverlay/BattingOverlay";
import BowlingOverlay from "Common/BowlingOverlay/BowlingOverlay";
import MatchSummaryOverlay from "Common/MatchSummaryOverlay";
import ScoreOverlay from "Common/ScoreOverlay";
import LogoOverlay from "Common/LogoOverlay";
import sixAnimation from "../../images/sixgif.gif";
import fourAnimation from "../../images/Fourgif.gif";
import wicketAnimation from "../../images/wicketgif.gif";

import { useEffect } from "react";

import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  startAt,
  Firestore,
  onSnapshot,
} from "firebase/firestore";
import firebaseDb from "firebase";
import { useLocation, useParams } from "react-router-dom";
import { findLastObj } from "utils/helpers";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
import { Modal, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";

function StreamingOverlayScreen() {
  const [selectedOption, setSelectedOption] = useState("scoreOverlay");
  // const [currentStat, setCurentStat] = useState(null);
  const [loadPage, setLoadPage] = useState(false);
  const [matchResponse, setMatchResponse] = useState([]);
  const [innsData, setInnsData] = useState([]);
  const [alloversData, setAllOversData] = useState([]);
  const [animationTypeState, setAnimationTypeState] = useState("blank");

  const animations = [
    { name: "six", animation: sixAnimation },
    { name: "four", animation: fourAnimation },
    { name: "wicket", animation: wicketAnimation },
  ];

  const params = useParams();
  const tournamentId = matchResponse?.tournamentId;

  console.log(params, "ppp");

  const {
    callAPI: fetchBanner,
    response: resBanner,
    error: errBanner,
    loading: fetchingBanner,
  } = useAxios({
    url:
      serviceUrl.getCompetitionById + `${tournamentId}?include_relation=false`,
    method: "get",
  });
  const {
    callAPI: validateId,
    response: resValidate,
    error: errValidate,
    loading: loadValidate,
  } = useAxios({
    url: serviceUrl.validateProviderId + params?.id,
    method: "get",
  });

  useEffect(() => {
    if (params?.id) {
      validateId();
    }
  }, [params.id]);

  useEffect(() => {
    if (tournamentId) {
      fetchBanner();
    }
  }, [tournamentId]);

  const mId = resValidate?.matchId || "";

  const competitionData = resBanner?.data || [];

  const adLogoArr = competitionData?.competition_settings
    ?.filter((item) => item?.key?.includes("sponsor_logo"))
    ?.filter((x) => x.key !== "sponsor_logo");
  console.log(mId, matchResponse, "ad");
  const sponsorLogo =
    competitionData?.competition_settings?.find(
      (item) => item?.key === "top_right_logo"
    )?.value || "";

  const competitionLogo =
    competitionData?.competition_settings?.find(
      (item) => item?.key === "top_right_logo"
    )?.value || "";

  const collectionName = process.env.REACT_APP_F_MAIN_COLLECTION;
  const colRef = collection(firebaseDb, collectionName);
  const q = query(colRef, where("matchId", "==", mId));

  useEffect(() => {
    if (mId) {
      const unsubscribe = onSnapshot(q, (snap) => {
        setLoadPage(true);

        const matches = [];

        snap.docs.forEach((matchDoc) => {
          const matchData = { ...matchDoc.data(), id: matchDoc.id };

          // Get data from nested collection 'innings' for each match
          const inningsRef = collection(colRef, matchDoc.id, "innings");

          const unsubscribeInnings = onSnapshot(
            inningsRef,
            async (inningsSnap) => {
              const inningsData = []; // Initialize inningsData array here for each match

              const inningsPromises = inningsSnap.docs.map(
                async (inningsDoc) => {
                  const inningsItem = {
                    ...inningsDoc.data(),
                    id: inningsDoc.id,
                  };

                  // Get data from nested collection 'overs' within each 'innings' document
                  const oversRef = collection(
                    inningsRef,
                    inningsDoc.id,
                    "overs"
                  );
                  const unsubscribeOvers = onSnapshot(oversRef, (oversSnap) => {
                    const oversData = oversSnap.docs.map((oversDoc) => ({
                      ...oversDoc.data(),
                      id: oversDoc.id,
                    }));

                    setAllOversData(oversData);
                  });

                  inningsData.push(inningsItem);

                  return () => {
                    unsubscribeOvers();
                  };
                }
              );

              await Promise.all(inningsPromises);

              matchData.innings = inningsData;
              setInnsData(inningsData);
            }
          );

          matches.push(matchData);

          return () => {
            unsubscribeInnings();
          };
        });

        setMatchResponse(matches[0]);
        setLoadPage(false);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [mId]);

  const overlays = matchResponse?.overlays;

  //disable anumation aftere 15 sec
  const animationType = matchResponse?.overlays?.animationType;

  useEffect(() => {
    let timeoutId;

    if (animationType) {
      setAnimationTypeState(animationType);

      timeoutId = setTimeout(() => {
        setAnimationTypeState("blank");
      }, 8000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [animationType]);

  ///multiple innings data ///////
  const hasMultipleInns = findLastObj(innsData)?.targetOvers === 0;

  const battingTeamId = findLastObj(innsData)?.battingTeamId;
  const homeTeamArr = innsData?.filter(
    (team) => matchResponse.homeTeamId === team.battingTeamId
  );
  const awayTeamArr = innsData?.filter(
    (team) => matchResponse.awayTeamId === team.battingTeamId
  );

  const currentBattTeamArr =
    battingTeamId === matchResponse?.homeTeamId ? homeTeamArr : awayTeamArr;

  const currentBowlingTeamArr =
    battingTeamId === matchResponse?.homeTeamId ? awayTeamArr : homeTeamArr;

  const crntBattingTeamTotal = currentBattTeamArr.reduce(
    (accumulator, currentValue) => {
      return accumulator + +currentValue?.teamSummary?.score;
    },
    0
  );
  const crntBowingTeamTotal = currentBowlingTeamArr.reduce(
    (accumulator, currentValue) => {
      return accumulator + +currentValue?.teamSummary?.score;
    },
    0
  );

  const crntRunsGap =
    crntBattingTeamTotal > crntBowingTeamTotal
      ? crntBattingTeamTotal - crntBowingTeamTotal
      : crntBowingTeamTotal - crntBattingTeamTotal;

  const matchStatus =
    (crntRunsGap === 0 && "Scores level") ||
    (crntBowingTeamTotal > crntBattingTeamTotal &&
      findLastObj(currentBattTeamArr)?.battingTeamName +
        " trail by " +
        crntRunsGap +
        " runs") ||
    (crntBowingTeamTotal < crntBattingTeamTotal &&
      findLastObj(currentBattTeamArr)?.battingTeamName +
        " lead by " +
        crntRunsGap +
        " runs");

  console.log(overlays, "inns");

  ////----------------------------/////////////

  const renderSelectedComponent = () => {
    switch (overlays?.mode) {
      case "match_summary":
        return (
          <MatchSummaryOverlay
            loading={loadPage}
            matchData={matchResponse}
            innings={innsData}
            testMatchData={{ hasMultipleInns, matchStatus }}
            intervalType={
              overlays?.intervalType === "default"
                ? null
                : overlays?.intervalType
            }
            //   currentStat={currentStat}
          />
        );
      case "batting_stats":
        return (
          <BattingOverlay
            loading={loadPage}
            innings={innsData[overlays?.inningsNum - 1]}
            matchData={matchResponse}
          />
        );
      case "bowling_stats":
        return (
          <BowlingOverlay
            loading={loadPage}
            matchData={matchResponse}
            innings={innsData[overlays?.inningsNum - 1]}
          />
        );
      case "default":
        return (
          <ScoreOverlay
            loading={loadPage}
            innings={innsData}
            overs={alloversData}
            matchData={matchResponse}
            testMatchData={{ hasMultipleInns, matchStatus }}
            intervalType={
              overlays?.intervalType === "default"
                ? null
                : overlays?.intervalType
            }
            showAnimation={
              animations?.find((i) => i.name === animationTypeState)
                ?.animation || null
            }
            // setCurrentStat={(i) => setCurentStat(i)}
          />
        );
      case "blank":
        return null;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        position: "relative",
        height: "100vh",
        // backgroundImage: `url(${bgImage})`, // Set the background image
        // backgroundSize: "cover", // Ensure the image covers the entire container
        // backgroundRepeat: "no-repeat", // Do not repeat the background image
      }}
    >
      <LogoOverlay logos={{ adLogoArr, competitionLogo }} overlays={overlays} />
      {loadValidate || !resValidate ? (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 150,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            width: "100vw",
            height: "100vh",
            color: "white",
          }}
        >
          {loadValidate && !resValidate ? (
            <Spin size="large" />
          ) : (
            !loadValidate &&
            !resValidate && (
              <WarningOutlined
                style={{ color: "red" }}

                //  onClick={() => handleAdd(item)}
              />
            )
          )}
          <p>
            {" "}
            {loadValidate && !resValidate
              ? "Validating"
              : !loadValidate && !resValidate && "Validation Failed"}{" "}
          </p>
        </div>
      ) : (
        renderSelectedComponent()
      )}
    </div>
  );
}

export default StreamingOverlayScreen;
