import styles from "./ScheduleExpandList.module.scss";
import React, { useEffect, useState } from "react";
import CsaButton from "Common/CsaButton";
import { Row, Col, Typography, Spin } from "antd";
import nologo from "../../images/nologoclub.png";
import { useDispatch, useSelector } from "react-redux";
import { setOpenState, setScheduleData } from "redux/slice/commonSlice";
import useAxios from "./../../utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";
import Summary from "./Summary";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, fixSpecialChar } from "utils/helpers";
import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import ProviderLoginPopup from "./ProviderLoginPopup";
import StreamingServiceModal from "./StreamingServiceModal";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import firebaseDb from "firebase";

const ExpandList = ({ ...props }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const openState = useSelector((state) => state.auth.openState);

  // const openState = useSelector((state) => state.auth.scheduleData.openState);
  const matchDataState = useSelector((state) => state.auth.scheduleData);
  const isback = useSelector((state) => state.auth.isBack);

  const {
    onchangecomponent,
    matchData,
    matchId,
    tName,
    banner,
    cmData,
  } = props;
  //
  // const isback = isBack

  const [hideBody, setHideBody] = useState(true);
  const initialState = isback ? openState : -1;

  const [expandablelistId, setExpandableListId] = useState(initialState);
  const [expandState, setExpandState] = useState(true);
  const [stremingData, setStreamingData] = useState({});
  const [showStreamingPopup, setShowStreamingPopup] = useState({
    login: false,
    url: false,
    idx: null,
  });
  const [loadPage, setLoadPage] = useState(false);
  const [matchDataFb, setMatchDataFb] = useState([]);
  const [curInns, setCurInns] = useState([]);
  const openOrCloseAll = () => {
    setHideBody((current) => !current);
  };

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getStreamUrl,
    method: "get",
  });

  useEffect(() => {
    if (response) {
      const parts = response?.data?.split("/");
      const id = parts[parts.length - 1];
      navigate("/match/streaming/" + id);
    } else if (error) {
      addToast(error?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [error, response]);

  const {
    callAPI: fetchCompetitionById,
    response: responseCompetition,
    error: errCompetition,
    loading: fetchingCompetititon,
  } = useAxios({
    url:
      serviceUrl.getCompetitionById +
      `${matchId}/schedule?group_by=date&include_summary=false`,
    method: "get",
  });
  useEffect(() => {
    fetchCompetitionById();
  }, []);

  const collectionName = process.env.REACT_APP_F_MAIN_COLLECTION;
  const colRef = collection(firebaseDb, collectionName);
  const q = query(colRef, where("tournamentId", "==", matchId?.toString()));

  useEffect(() => {
    setLoadPage(true);

    const unsubscribeMain = onSnapshot(q, (snapshot) => {
      const promises = snapshot.docs.map(async (doc) => {
        const inningsColRef = collection(doc.ref, "innings");
        const inningsQ = query(inningsColRef);

        const unsubscribeInnings = onSnapshot(inningsQ, (inningsSnapshot) => {
          let inningsData = [];

          inningsSnapshot.forEach((inningsDoc) => {
            inningsData.push({
              ...inningsDoc.data(),
              inningsId: inningsDoc.id,
            });
          });

          const matchData = {
            ...doc.data(),
            id: doc.id,
            innings: inningsData,
          };

          setMatchDataFb((prevResponse) => {
            const index = prevResponse.findIndex(
              (item) => item.id === matchData.id
            );
            if (index !== -1) {
              const updatedResponse = [...prevResponse];
              updatedResponse[index] = matchData;
              return updatedResponse;
            } else {
              // If the match doesn't exist in state, add it
              return [...prevResponse, matchData];
            }
          });
        });

        return unsubscribeInnings;
      });

      Promise.all(promises).then(() => {
        setLoadPage(false);
      });
    });

    return () => unsubscribeMain();
  }, []);

  const matchArr = responseCompetition?.data || [];

  useEffect(() => {
    if (errCompetition) {
      addToast(errCompetition?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [addToast, errCompetition]);

  const datas = matchArr;
  const expand = isback && expandState ? openState : expandablelistId;
  console.log(datas, "sss");

  const ExpandableList = (i, eachItem) => {
    setExpandState(false);
    setExpandableListId(
      isback && expandState
        ? openState === i
          ? -1
          : i
        : expandablelistId === i
        ? -1
        : i
    );

    dispatch(setOpenState(i));
    dispatch(setScheduleData(datas));
  };

  // useEffect(() => {
  //   dispatch(setScheduleData({
  //     openState:expandablelistId,
  //     currentData:matchArr
  //     }))
  // },[expandablelistId])

  console.log(datas, "ddd");

  const navigate = useNavigate();
  const handleNavigate = (d) => {
    navigate(`${d.id}`, {
      state: {
        //  page: "matchinfo",
        scId: d.id,
        mId: matchId,
        tournamentName: tName,
        banner: banner,
        openState: hideBody,
        currentData: matchData,
        isBack: false,
      },
    });
    onchangecomponent({
      //  page: "matchinfo",
      scId: d.id,
      mId: matchId,
      tournamentName: tName,
      banner: banner,
      openState: hideBody,
      currentData: matchData,
      isBack: false,
    });
  };

  return (
    <>
      {" "}
      {!responseCompetition && fetchingCompetititon ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        Object.keys(datas).map((key, index) => {
          //
          return (
            <div className={styles.ExpandListWrapper} key={index}>
              <div className={styles.ListTitleWrapper}>
                <div className="expand-table-common-icon">
                  <div
                    onClick={() => ExpandableList(index + 1)} //openOrCloseAll click header button event
                    style={{
                      display: !(expand === index + 1)
                        ? "none"
                        : "inline-block",
                    }}
                    className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
                  />
                  <div
                    onClick={() => ExpandableList(index + 1)}
                    style={{
                      display: !(expand === index + 1)
                        ? "inline-block"
                        : "none",
                    }}
                    className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
                  />
                </div>

                <p className={styles.ListTitle}>{key}</p>
              </div>

              {expand === index + 1
                ? //  data[key]?.sort(({ match_time: a }, {match_time: b }) => a > b ? 1 : a < b ? -1 : 0).map((data) => {
                  //
                  datas[key]
                    ?.slice()
                    .sort(({ match_time: a }, { match_time: b }) =>
                      a > b ? 1 : a < b ? -1 : 0
                    )
                    .map((data, k) => {
                      return matchDataFb.length > 0 &&
                        data?.status === "completed" ? (
                        <Summary
                          onchangecomponent={onchangecomponent}
                          matchData={data}
                          inningsData={matchDataFb.find(
                            (x) => x?.id?.toString() === data?.id?.toString()
                          )}
                          matchId={matchId}
                          tName={tName}
                          banner={banner}
                          openState={hideBody}
                          key={k}
                        />
                      ) : (
                        <div
                          key={k}
                          className={
                            styles.ScheduleMatchWrapper
                            // : styles.ScheduleMatchWrapperyellowbg
                          }
                        >
                          <Row>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              lg={{ span: 8 }}
                            >
                              <Row className={styles.TeamSection}>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  lg={{ span: 5 }}
                                  className="d-flex align-items-center justify-content-center "
                                >
                                  <img
                                    src={
                                      data?.match_settings?.find(
                                        (elem) => elem.key === "home_team"
                                      )?.data?.profile_url || nologo
                                    }
                                    alt=""
                                    className={styles.Teamimg}
                                  />
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  lg={{ span: 19 }}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {fixSpecialChar(
                                      data?.match_settings?.find(
                                        (elem) => elem.key === "home_team"
                                      )?.data?.team_name
                                    ) || ""}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              lg={{ span: 8 }}
                            >
                              <Row className={styles.TeamSection}>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  lg={{ span: 2 }}
                                  className=" d-flex align-items-center justify-content-center"
                                >
                                  <Typography>VS</Typography>
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  lg={{ span: 17 }}
                                  className=" d-flex align-items-center justify-content-center"
                                >
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {fixSpecialChar(
                                      data?.match_settings?.find(
                                        (elem) => elem.key === "away_team"
                                      )?.data?.team_name
                                    ) || ""}
                                  </Typography>
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  lg={{ span: 5 }}
                                  className=" d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    src={
                                      data?.match_settings?.find(
                                        (elem) => elem.key === "away_team"
                                      )?.data?.profile_url || nologo
                                    }
                                    alt=""
                                    className={styles.Teamimg}
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              lg={{ span: 8 }}
                            >
                              <Row
                                className={styles.MatchInfoSection}
                                //  className="d-flex w-100 justify-content-center "
                              >
                                <LinkOutlined
                                  style={{
                                    position: "absolute",
                                    padding: "5px",
                                    backgroundColor: "#ffcb05",
                                    top: "5px",
                                    right: "5px",
                                    borderRadius: "5px",
                                    color: "#00793a",
                                    cursor: "pointer",
                                    zIndex: "100",
                                  }}
                                  onClick={
                                    // () => callAPI(data?.id, true)
                                    //  callStreamingApi
                                    () =>
                                      setShowStreamingPopup({
                                        login: stremingData?.id ? false : true,
                                        url: stremingData?.id ? true : false,
                                        idx: k,
                                      })
                                  }
                                />
                                {showStreamingPopup.idx === k && (
                                  <ProviderLoginPopup
                                    k={k}
                                    showStreamingPopup={showStreamingPopup}
                                    setShowStreamingPopup={(v) =>
                                      setShowStreamingPopup(v)
                                    }
                                    data={data?.id}
                                    setStreamingData={(d) =>
                                      setStreamingData(d)
                                    }
                                    matchName={
                                      fixSpecialChar(
                                        data?.match_settings?.find(
                                          (elem) => elem.key === "home_team"
                                        )?.data?.team_name
                                      ) +
                                      " Vs " +
                                      fixSpecialChar(
                                        data?.match_settings?.find(
                                          (elem) => elem.key === "away_team"
                                        )?.data?.team_name
                                      )
                                    }
                                  />
                                )}

                                <StreamingServiceModal
                                  streamingData={stremingData}
                                  showStreamingPopup={showStreamingPopup}
                                  setShowStreamingPopup={(v) =>
                                    setShowStreamingPopup(v)
                                  }
                                />
                                <Col
                                  // xs={{ span: 24 }}
                                  // sm={{ span: 24 }}
                                  lg={{ span: 15 }}
                                  className={styles.MatchInfoText}
                                >
                                  <Typography>
                                    Format:&nbsp;&nbsp;
                                    {data?.match_settings?.find(
                                      (elem) => elem.key === "match_format"
                                    )?.display_name || ""}
                                  </Typography>
                                  <Typography>
                                    Time:&nbsp;&nbsp;
                                    {data?.match_settings?.find(
                                      (elem) => elem.key === "match_time"
                                    )?.value || ""}
                                  </Typography>
                                  <Typography>
                                    Venue:&nbsp;&nbsp;
                                    {fixSpecialChar(
                                      data?.match_settings?.find(
                                        (elem) => elem.key === "venue"
                                      )?.data?.field_name
                                    ) || ""}
                                  </Typography>
                                  <Typography>
                                    Results:&nbsp;&nbsp;
                                    {(data?.win_status && data?.win_status) ||
                                      (data?.status === "in_progress" &&
                                        "Ongoing") ||
                                      capitalizeFirstLetter(data?.status) ||
                                      "Ongoing"}
                                  </Typography>
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  lg={{ span: 8 }}
                                  className="d-flex w-100 justify-content-center align-items-center"
                                >
                                  <CsaButton
                                    label="Match Info"
                                    disabled={data?.status === "scheduled"}
                                    textColor="#FFF"
                                    btnColor="#007237"
                                    onClick={() => handleNavigate(data)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    })
                : ""}
            </div>
          );
        })
      )}
    </>
  );
};

export default ExpandList;
