import React, { useState, useCallback } from "react";
import { Tabs } from "antd";
//Components
import Wrapper from "Layout/Wrapper";
import styles from "./Dashboard.module.scss";
import CsaCards from "Common/CsaCards";
import Tournaments from "pages/Tournaments";
import Progress from "pages/ProgressTournaments/Progress";
import Schedule from "pages/ScheduleTournaments/Schedule";
import Statistics from "pages/Statistics";
import PlayerInformation from "pages/PlayerInformation/PlayerInformation";
import TeamInformation from "pages/TeamInformation/TeamInformation";
import { useDispatch, useSelector } from "react-redux";
import { setIsBack, setStatsData } from "redux/slice/commonSlice";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ScoreOverlay from "Common/ScoreOverlay";
import BowlingOverlay from "Common/BowlingOverlay/BowlingOverlay";
import BattingOverlay from "Common/BattingOverlay/BattingOverlay";
import MatchSummaryOverlay from "Common/MatchSummaryOverlay";

const { TabPane } = Tabs;
function Dashboard() {
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [id, setId] = useState();
  const [tabpanestate, settabpanestate] = useState("");
  const [mId, setmId] = useState();
  const [banner, setBanner] = useState();
  const [tName, setTName] = useState();
  const [scoreId, setScoreId] = useState();
  const [skip, setSkip] = useState(false);
  const [mData, setmData] = useState();
  const [statsTabState, setStatsTabState] = useState("players");
  const [memberName, setmeberName] = useState("players");
  const [isBack, setIsBackk] = useState(false);
  const [status, setStatus] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("tournaments")) {
      setActiveKey("2");
    }
    if (path.includes("stats")) {
      setActiveKey("4");
    }
    switch (path) {
      case "/":
        navigate("score");
        break;
      case "/" || "/score":
        setActiveKey("1");
        break;
    }
  }, [location.pathname]);

  const onKeyChange = (key) => {
    dispatch(setIsBack(false));
    setActiveKey(key);
    settabpanestate("");
    if (key == "2") {
      navigate("tournaments");
    }
    if (key == "1") {
      navigate("score");
    }
    if (key == "4") {
      navigate("stats");
    }
  };
  const handleNavigate = useCallback(
    ({ key, tId }) => {
      setActiveKey(key);
      setId(tId);
    },
    [setActiveKey]
  );
  const onchangecomponent = useCallback(
    ({
      page,
      mId,
      banner,
      tournamentName,
      scId,
      key,
      skip,
      memberName,
      statsTab,
      isBack,
      currentData,
    }) => {
      setActiveKey(key);
      setSkip(skip);
      settabpanestate(page);
      setmId(mId);
      setBanner(banner);
      setTName(tournamentName);
      setScoreId(scId);
      setmData(mData);
      setStatsTabState(statsTab);
      setmeberName(memberName);
      setIsBackk(isBack);
      dispatch(setIsBack(isBack || false));
      // dispatch(setStatsData(currentData));
    },
    [settabpanestate]
  );
  const previousData = useSelector((state) => state.auth.statsData);

  const props = {
    styles,
    info,
    setInfo,
    isLocal,
    setLocal,
    setActiveKey,
    status,
    setStatus,
    id,
    setId,
    skip,
    tName,
    banner,
    mId,
    scoreId,
    onchangecomponent,
    handleNavigate,
    statsTabState,
  };

  return (
    <Wrapper>
      <div className={styles.DashboardWrapper}>
        <div className={styles.contents}>
          <>
            <div>
              <Tabs
                type="card"
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={onKeyChange}
              >
                <TabPane tab="Live Matches" key="1">
                  <Outlet context={{ propsData: props }} />
                </TabPane>
                <TabPane tab="Tournaments / Leagues" key="2">
                  <Outlet context={{ propsData: props }} />
                </TabPane>

                <TabPane tab="Statistics" key="4">
                  <Outlet context={{ propsData: props }} />
                </TabPane>
              </Tabs>
            </div>
          </>
        </div>
      </div>
      {/* <MatchSummaryOverlay /> */}
      {/* <BattingOverlay />   */}
      {/* <BowlingOverlay /> */}
      {/* <ScoreOverlay /> */}
    </Wrapper>
  );
}

export default Dashboard;
