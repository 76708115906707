import React, { useState, useCallback, useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import styles from "./Statistics.module.scss";
import CsaButton from "Common/CsaButton";
import { Carousel, Tabs } from "antd";
import { useToasts } from "react-toast-notifications";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "./../../utils/axios";
import * as masterData from "../../utils/commonMasterData";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Row,
  Col,
  AutoComplete,
  Input,
  Spin,
  Table,
  Pagination,
} from "antd";

import team1 from "../../images/team1.svg";
import team2 from "../../images/team2.svg";
import CsaTextInput from "Common/CsaTextInput";
import CsaMoreMenu from "Common/CsaMoreMenu";
import CsaDropDown from "Common/CsaDropDown";
import { ContactsOutlined } from "@ant-design/icons";
import {
  setIsBack,
  setStatsData,
  setTeamStatsData,
} from "redux/slice/commonSlice";
import CsaViewMenu from "Common/CsaViewMenu";

//const getplayerstats = "player-statitics"

const { TabPane } = Tabs;
const Statistics = ({ ...props }) => {
  const dispatch = useDispatch();

  // const { onchangecomponent, matchId, mData, statsTabState, currentData } = props;

  const { propsData } = useOutletContext();
  const {
    onchangecomponent,
    matchId,
    mData,
    statsTabState,
    currentData,
  } = propsData;
  const location = useLocation();
  const navigate = useNavigate();
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const previousData = useSelector((state) => state.auth.statsData);
  const isback = useSelector((state) => state.auth.isBack);
  const memberId = localStorage.getItem("csa_member_id");
  const [data, setData] = useState(previousData);
  const [activeKey, setActiveKey] = useState();
  const [playerFname, setplayerFname] = useState("");
  const [playerLname, setplayerLname] = useState("");
  const [playerGender, setplayerGender] = useState();
  const [playerStatus, setplayerStatus] = useState();
  const [playerTeam, setplayerTeam] = useState("");

  const [teamName, setTeamName] = useState("");
  const [teamAgeGroup, setTeamAgeGroup] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [teamLevel, setTeamLevel] = useState("");
  const [teamGender, setTeamGender] = useState("");
  const [teamDA, setTeamDA] = useState("");

  const {
    callAPI: playerSearchApi,
    response: resPlayerSearch,
    error: errPlayerSearch,
    loading: loadingPlayerSearch,
  } = useAxios({
    url: serviceUrl.getplayerStats,
    method: "get",
  });

  ////////teamSearchApi and teamstats api////////////////////////////////////////

  const {
    callAPI: postTeamStats,
    response: resTeamStats,
    error: errorTeamStats,
    loading: loadingTeamStats,
  } = useAxios({
    url: serviceUrl.getStats + "team",
    method: "post",
  });

  const {
    callAPI: fetchTeam,
    response: resTeam,
    error: errorTeam,
    loading: loadingTeam,
  } = useAxios({
    url: serviceUrl.getteamStats,
    method: "get",
  });

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getMemberMasterData,
    method: "get",
  });

  useEffect(() => {
    console.count("stats");
    callAPI();
  }, []);

  ////////////////

  const allFieldEmpty =
    (playerFname === "" &&
      playerTeam === "" &&
      playerLname === "" &&
      playerGender === undefined) ||
    ("" && playerStatus === undefined) ||
    "";

  const allTeamsEmpty =
    (teamName === "" && teamAgeGroup === "") ||
    (undefined && teamMember === "") ||
    (undefined && teamLevel === "") ||
    (undefined && teamGender === "") ||
    (undefined && teamDA === "") ||
    undefined;

  useEffect(() => {
    const path = location.pathname;
    if (location?.pathname == "/home/stats") {
      navigate("players");
    }
    if (location?.pathname == "/stats") {
      navigate("players");
    }

    if (path.includes("players")) {
      setActiveKey("1");
    }
    if (path.includes("teams")) {
      setActiveKey("2");
    }
  }, [location.pathname]);

  useEffect(() => {
    (activeKey === "1" && navigate("players")) ||
      (activeKey === "2" && navigate("teams"));
  }, [activeKey]);

  /////////Team//////////////////

  useEffect(() => {
    activeKey === "2" && resTeam && idsArr && postTeamStats(idsArr);
  }, [activeKey, resTeam]);

  useEffect(() => {
    activeKey === "2" &&
      allTeamsEmpty &&
      fetchTeam(
        `keyword=&level=All&uid=&member_id=All&age=All&differently_abled=All&gender=All&team=All&page=0`,
        true
      );
  }, [activeKey, allTeamsEmpty]);

  /////////Player//////////////////

  useEffect(() => {
    activeKey === "1" &&
      allFieldEmpty &&
      playerSearchApi(
        `?teams=${""}&fname=${""}&lname=${""}&status=${"All"}&gender=${"All"}&page=${0}&per_page=50`,
        true
      );
  }, [activeKey, allFieldEmpty]);

  /////////player Data////////////////

  const resStatsData = resPlayerSearch?.rows || [];

  const statsData = resStatsData?.map((i) => ({
    f_name: i?.fname?.toString()?.replace(/&#039;/g, "'"),
    l_name: i?.lname?.toString()?.replace?.(/&#039;/g, "'"),
    teams_name: i?.teams?.toString()?.replace(/&#039;/g, "'"),
    ...i,
  }));

  const handlePlayerSearch = () => {
    playerSearchApi(
      `?teams=${playerTeam ? playerTeam : ""}&fname=${
        playerFname ? playerFname : ""
      }&lname=${playerLname ? playerLname : ""}&status=${
        playerStatus ? playerStatus : "All"
      }&gender=${playerGender ? playerGender : "All"}`,
      true
    );
    dispatch(setIsBack(false));
  };

  const onChange = (page, pageSize) => {
    playerSearchApi(
      `?teams=${playerTeam ? playerTeam : ""}&fname=${
        playerFname ? playerFname : ""
      }&lname=${playerLname ? playerLname : ""}&status=${
        playerStatus ? playerStatus : "All"
      }&gender=${playerGender ? playerGender : "All"}&page=${
        page - 1
      }&items_per_page=${pageSize}`,
      true
    );
    dispatch(setIsBack(false));
  };

  /////////////////////////

  ///////passing team ids to stats api post object for recieving teamstats/////////

  const datas = resTeam?.rows || [];
  let idsArr = { ids: [] };
  for (let j = 0; j < datas?.length; j++) {
    idsArr = { ids: [datas[j].team_id.toString(), ...idsArr.ids] };
  }

  //////////////////////////

  const handleTeamSearch = () => {
    fetchTeam(
      `keyword=${teamName ? teamName : ""}&level=${
        teamLevel ? teamLevel?.split("%20").join(" ") : "All"
      }&uid=&member_id=${teamMember ? teamMember : "All"}&age=${
        teamAgeGroup ? teamAgeGroup : "All"
      }&differently_abled=${teamDA ? teamDA : "None"}&gender=${
        teamGender ? teamGender : "All"
      }&team=All`,
      true
    );
    dispatch(setIsBack(false));
  };

  const onTeamChange = (page, pageSize) => {
    fetchTeam(
      `keyword=${teamName ? teamName : ""}&level=${
        teamLevel ? teamLevel?.split("%20").join(" ") : "All"
      }&uid=&member_id=${teamMember ? teamMember : "All"}&age=${
        teamAgeGroup ? teamAgeGroup : "All"
      }&differently_abled=${teamDA ? teamDA : "All"}&gender=${
        teamGender ? teamGender : "All"
      }&team=All&page=${page - 1}&items_per_page=${pageSize}`,
      true
    );
    dispatch(setIsBack(false));
  };

  const resTeamData = resTeam?.rows;

  const teamData = resTeamData?.map((i) => ({
    teamname: i?.team_name?.replace(/&#039;/g, "'"),
    players: i?.player_count?.[0]?.count,
    ...i,
  }));

  const teamstatData = resTeamStats?.data || [];

  ///////////combaining data from stats and user managment apis///////
  const statsids = Object.keys(teamstatData)?.map((key, index) => ({
    id: key,
    games_played: teamstatData[key]?.total_games_played,
    games_won: teamstatData[key]?.total_games_won,
    games_lost: teamstatData[key]?.total_games_lost,
  }));

  let newTeamData = [];
  newTeamData = teamData?.map((obj) => {
    const index = statsids.findIndex((el) => el["id"] == obj["team_id"]);
    const { games_played } = index !== -1 ? statsids[index] : {};
    const { games_lost } = index !== -1 ? statsids[index] : {};
    const { games_won } = index !== -1 ? statsids[index] : {};

    return {
      ...obj,
      games_played: games_played ? games_played : 0,
      games_won: games_won ? games_won : 0,
      games_lost: games_lost ? games_lost : 0,
    };
  });

  ////////////dropdown options/////////
  const levels = masterData?.optionsTeamLevelMember?.map((i) => ({
    label: i,
    value: i,
  }));
  const ageGroupteam = masterData?.ageGroupArr?.map((i) => ({
    label: i,
    value: i,
  }));
  const genderListteam = masterData?.genderListTeam?.map((i) => ({
    label: i,
    value: i,
  }));
  const difAbledteam = masterData?.differentlyAbled?.map((i) => ({
    label: i,
    value: i,
  }));

  const memberData = response?.map((p) => ({
    label: p?.member_name,
    value: p.member_id,
    ...p,
  }));
  /////////////////////

  const handleTeamView = (record) => {
    navigate(`teams/${record.team_id}`, {
      state: {
        id: record.team_id,
        memberName: record.field_members_names,
      },
    });
    onchangecomponent({
      //  page: "teaminfo",
      mId: record.team_id,
      memberName: record.field_members_names,
    });
    !isback &&
      dispatch(
        setStatsData({
          teamData: newTeamData,
          teamName: teamName,
          page: resTeam?.pager?.current_page,
          total: resTeam?.pager?.total_items,
        })
      );
  };

  const handlePlayerView = (record) => {
    navigate(`players/${record.id}`, {
      state: {
        id: record.id,
        currentData: statsData,
      },
    });
    onchangecomponent({
      //  page: "playerinfo",
    });
    !isback &&
      dispatch(
        setStatsData({
          data: statsData,
          playerFname: playerFname,
          page: resPlayerSearch?.pager?.current_page,
          total: resPlayerSearch?.pager?.total_items,
        })
      );
  };

  ///////////////////

  const onKeyChange = (key) => {
    dispatch(setIsBack(false));
    dispatch(setStatsData(null));

    setActiveKey(key);
  };
  const columnsTeams = [
    {
      title: "Team Name",
      dataIndex: "teamname",
      key: "teamname",
    },
    {
      title: "Member",
      dataIndex: "field_members_names",
      key: "field_members_names",
    },
    {
      title: "Level",
      dataIndex: "field_level",
      key: "level",
    },
    {
      title: "No of Players",
      dataIndex: "players",
      key: "players",
    },
    {
      title: "Games Played",
      dataIndex: "games_played",
      key: "played",
    },
    {
      title: "Games Won",
      dataIndex: "games_won",
      key: "won",
    },
    {
      title: "Games Lost",
      dataIndex: "games_lost",
      key: "lost",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (data, record) => (
        <CsaViewMenu handleView={() => handleTeamView(record)} />
      ),
      //  align: "center",
    },
  ];

  const columns = [
    {
      title: "Player First Name",
      dataIndex: "f_name",
      key: "f_name",
    },
    {
      title: "Player Last Name",
      dataIndex: "l_name",
      key: "l_name",
    },
    {
      title: "Team",
      dataIndex: "teams_name",
      key: "teams_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (data, record) => (
        <CsaViewMenu handleView={() => handlePlayerView(record)} />
      ),
      // align: "center",
    },
  ];

  const playerData = {
    loadingPlayerSearch,
    columns,
    isback,
    previousData,
    statsData,
    loadingTeam,
    columnsTeams,
    isback,
    previousData,
    teamData,
    newTeamData,
  };

  return (
    <>
      {activeKey === "1" ? (
        <>
          <div className={styles.StatisticsHeader}>
            <p className={styles.heading}>Player Statistics</p>
          </div>
          <div>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaTextInput
                    label="Player First Name"
                    placeholder="Enter Player First Name"
                    name="firstname"
                    type="text"
                    defaultValue={isback ? previousData.playerFname : ""}
                    onChange={(e) => setplayerFname(e.target.value)}
                    value={playerFname}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaTextInput
                    label="Player Last Name"
                    placeholder="Enter Player Last Name"
                    name="lastname"
                    type="text"
                    onChange={(e) => setplayerLname(e.target.value)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaTextInput
                    label="Team Name"
                    placeholder="Enter Team Name"
                    name="teamname"
                    type="text"
                    onChange={(e) => setplayerTeam(e.target.value)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Gender"
                    placeholder="Select Gender"
                    required
                    name="gender"
                    menuArr={masterData.genderList}
                    onClear={(e) => setplayerGender("")}
                    onChange={(e) => setplayerGender(e)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Status"
                    placeholder="Select Status"
                    // required
                    name="status"
                    menuArr={masterData.playerStatusList}
                    onClear={(e) => setplayerStatus("")}
                    onChange={(e) => {
                      setplayerStatus(e);
                    }}
                  />
                </div>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 8 }}
                className="d-flex w-100 justify-content-end align-items-end"
              >
                <div className="m-2">
                  <CsaButton
                    onClick={() => handlePlayerSearch()}
                    label="Search"
                    textColor="#FFF"
                    btnColor="#007237"
                    loading={loadingPlayerSearch}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div className={styles.StatisticsHeader}>
            <p className={styles.heading}>Team Statistics</p>
          </div>
          <div>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaTextInput
                    label="Team Name"
                    placeholder="Enter Team Name"
                    name="teamName"
                    type="text"
                    defaultValue={isback ? previousData?.teamName || "" : ""}
                    onChange={(e) => setTeamName(e.target.value)}
                    value={teamName}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Age Group"
                    placeholder="Select Age Group"
                    name="ageGrop"
                    optionType
                    onClear={(e) => setTeamAgeGroup("")}
                    onChange={(e) => setTeamAgeGroup(e)}
                    menuArr={ageGroupteam}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Member"
                    placeholder="Select Member"
                    name="member"
                    optionType
                    onClear={(e) => setTeamMember("")}
                    onChange={(e) => setTeamMember(e)}
                    menuArr={memberData}
                  />
                </div>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Level"
                    placeholder="Select Level"
                    name="levelTeam"
                    optionType
                    onClear={(e) => setTeamLevel("")}
                    onChange={(e) => setTeamLevel(e)}
                    menuArr={levels}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Gender"
                    placeholder="Select Gender"
                    name="Gender"
                    onClear={(e) => setTeamGender("")}
                    onChange={(e) => setTeamGender(e)}
                    menuArr={genderListteam}
                    // value={level.toUpperCase()}
                    // menuArr={getLevels()}
                    optionType
                    // onChange={(e) => {
                    //     setLevel(e);
                    //     getRoles(e);
                    //     setRole(null);
                    // }}
                    // onBlur={() => setFieldTouched("level", true)}
                    // touched={touched.level}
                    // error={errors.level || null}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Differently Abled"
                    placeholder="Select "
                    name="differentlyabled"
                    onClear={(e) => setTeamDA("")}
                    onChange={(e) => setTeamDA(e)}
                    optionType
                    menuArr={difAbledteam}
                  />
                </div>
              </Col>
              <div className="m-2 d-flex w-100 justify-content-end align-items-end">
                <CsaButton
                  label="Search"
                  textColor="#FFF"
                  btnColor="#007237"
                  loading={loadingTeam}
                  onClick={handleTeamSearch}
                />
              </div>
            </Row>
          </div>
        </>
      )}
      {/* <TeamStatiticsSearch /> */}

      <div className={styles.HomeWrapper}>
        <div className={styles.contents}>
          <>
            <div>
              <Tabs
                type="card"
                //  defaultActiveKey={statsTabState === "teams" ? "2" : "1"}
                activeKey={activeKey}
                onChange={onKeyChange}
              >
                <TabPane tab="Player Statistics" key="1">
                  <div>
                    <Outlet context={{ playerData }} />
                    <div className=" d-flex w-100 justify-content-end mt-5">
                      <Pagination
                        onChange={onChange}
                        total={
                          isback
                            ? previousData.total
                            : resPlayerSearch?.pager?.total_items
                        }
                        current={
                          isback
                            ? previousData.page + 1
                            : resPlayerSearch?.pager?.current_page + 1
                        }
                        size={"small"}
                        //  pageSize={}
                      />
                    </div>
                  </div>
                  {/* <PlayerInformation /> */}
                </TabPane>
                <TabPane tab="Team  Statistics" key="2">
                  <div>
                    <Outlet context={{ playerData }} />
                    <div className=" d-flex w-100 justify-content-end mt-5">
                      <Pagination
                        onChange={onTeamChange}
                        total={
                          isback
                            ? previousData.total
                            : resTeam?.pager?.total_items
                        }
                        current={
                          isback
                            ? previousData.page + 1
                            : resTeam?.pager?.current_page + 1
                        }
                        // current={}
                        size={"small"}
                      />
                    </div>
                  </div>
                  {/* <TeamStatitics onchangecomponent={onchangecomponent} teamSearchValue={teamSearchValue} mData={mData} /> */}
                  {/* <TeamInformation /> */}
                </TabPane>
              </Tabs>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Statistics;
