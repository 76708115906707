import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//Components
import MatchRefereeRegisterForm from "../../Common/MatchRefereeRegisterForm";
import MatchRefereeRegisterForm2 from "../../Common/MatchRefereeRegisterForm/MatchRefereeRegisterForm2";
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./MatchReferees.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function MatchReferees() {
  const [img, setImg] = useState([]);
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
    img,
    setImg,
  };
  const location = useLocation();
  const id = location?.state?.id;
  const type = location?.state?.type;
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Match Referees", value: "/match-referee-list" },
    { key: "Referees", value: "/register-match-referee" },
  ];

  return (
    <Wrapper>
      <div className={styles.MatchRefereesWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Match Refree
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>

          <div>
            {step === 1 && <MatchRefereeRegisterForm {...props} />}
            {step === 2 && <MatchRefereeRegisterForm2 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default MatchReferees;
