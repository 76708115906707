import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Row, Col, Pagination } from "antd";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import * as masterData from "../../utils/commonMasterData";
import { useDispatch, useSelector } from "react-redux";
import { setCMSData } from "redux/slice/commonSlice";
import { fixSpecialChar } from "utils/helpers";

function CMSTeams({ styles, setStep, setInfo, info, setLocal }) {
  const { addToast } = useToasts();

  const CMSData = useSelector((state) => state.auth.cms_data);

  const tabData = CMSData?.tab2Step2 || {};
  const tabData1 = CMSData?.tab1Step1 || {};
  const tabData2 = CMSData?.tab1Step2 || {};
  const ageGroup = tabData1?.ageGroup;
  const gender = tabData1?.gender;
  const differentlyAbled = tabData1?.differentlyAbled;
  const numOfPool = tabData2?.numberOfPools;
  const numOfTeamPerPool = tabData2?.numberOfTeamsPerPool;
  const teamTableCondition = numOfPool * numOfTeamPerPool;

  const [isPagination, setIsPagination] = useState(false);
  const [resData, setResData] = useState([]);
  const [subMemberValue, setSubmemberValue] = useState("");
  const [sMemberId, setSMemberID] = useState("");

  const [tableData, setTableData] = useState(
    CMSData?.tab2Step2?.tableData || CMSData?.tab2Step2 || []
  );
  const steps = ["Hosting & Participating Members", "Teams", "Venues"];

  const columns = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Sub Member",
      dataIndex: "subMember",
      key: "subMember",
    },

    {
      title: "Team Name",
      dataIndex: "teamName",
      key: "teamName",
    },

    {
      title: "Action",
      key: "action",
      render: (item) =>
        tableData.some((i) => i.key === item?.key) ? (
          <CheckOutlined style={{ cursor: "pointer", color: "#007237" }} />
        ) : (
          <PlusOutlined
            style={{ cursor: "pointer", color: "#007237" }}
            onClick={() => handleAdd(item)}
          />
        ),
      // align: "center",
    },
  ];
  const columns1 = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Sub Member",
      dataIndex: "subMember",
      key: "subMember",
    },
    {
      title: "Team Name",
      dataIndex: "teamName",
      key: "teamName",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <DeleteOutlined
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => handleRemove(item)}
        />
      ),
      // align: "center",
    },
  ];

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getRegisteredMemberList,
    method: "get",
  });
  const {
    callAPI: SearchAPI,
    response: searchResponse,
    error: searchErr,
    loading: searchLoad,
  } = useAxios({
    url: serviceUrl.getCMSTeamList,
    method: "get",
    query: true,
  });

  const {
    callAPI: FetchAPI,
    response: fetchResponse,
    error: fetchErr,
    loading: fetchLoad,
  } = useAxios({
    url: serviceUrl.getCMSSubMemberList,
    method: "get",
    query: true,
  });
  useEffect(() => {
    callAPI();
    FetchAPI();
  }, []);

  const getMemberNameById = (arr, id) => {
    let objectArray = [];
    let memberItem;
    if (arr?.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        let memberNamePar = response?.find((item) => item?.id == arr[i])?.name;
        if (memberNamePar) {
          objectArray.push({
            value: arr[i],
            label: memberNamePar,
          });
        }
      }
    } else {
      memberItem = response?.find((item) => item?.id == id)?.name;
    }
    return id ? memberItem : objectArray;
  };
  const getSubMemberNameById = (id) => {
    let subMemberItem;
    if (id) {
      subMemberItem =
        fetchResponse && fetchResponse && values?.subMember === "clubs_schools"
          ? fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.club_school_name
          : values?.subMember === "high_schools"
          ? fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.high_school_name
          : values?.subMember === "primary_schools"
          ? fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.primary_school_name
          : fetchResponse?.rows?.find((item) => item?.uid == id)
              ?.ancillary_member_name;
    }
    return subMemberItem;
  };

  const data = tabData;

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      membersName: data ? data?.membersName : "",
      subMember: data ? data?.subMember : "",
      subMemberName: data ? data?.subMemberName : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (tableData.length < teamTableCondition) {
        addToast(`Please select total ${teamTableCondition} team only`, {
          appearance: "warning",
        });
      } else if (tableData.length > teamTableCondition) {
        addToast(`Please select total ${teamTableCondition} team only`, {
          appearance: "warning",
        });
      } else {
        dispatch(
          setCMSData({
            ...CMSData,
            tab1Step1: CMSData?.tab1Step1,
            tab1Step2: CMSData?.tab1Step2,
            tab1: CMSData?.tab1,
            tab2Step1: CMSData?.tab2Step1,
            tab2Step2: tableData,
            tab2Step3: CMSData?.tab2Step3,
            tab2: CMSData?.tab2,
            tab3Step1: CMSData?.tab3Step1,
            tab3Step2: CMSData?.tab3Step2,
            tab3: CMSData?.tab3,
            tableData,
          })
        );
        setInfo({
          step1: info?.step1,
          step2: values,
          tableData: tableData,
          step3: info?.step3,
          tableFieldData: info?.tableFieldData,
        });
        setStep(3);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  useEffect(() => {
    // FetchAPI(`?type=${values?.subMember}&member_id=${values?.membersName}`, true);
    setFieldValue("subMemberName", "");
  }, [values?.subMember]);

  const dataTable = searchResponse?.rows
    ?.filter((x) => x?.age_group === ageGroup)
    ?.filter((x) => x?.gender === gender)
    ?.filter((x) => x?.differently_abled === differentlyAbled)
    ?.map((item) => ({
      key: item?.id,
      member: item?.member_id ? getMemberNameById([], item?.member_id) : "Nil",
      subMember: item?.field_sub_member || "Nil",
      teamName: fixSpecialChar(item?.team_name),
      ...item,
    }));

  useEffect(() => {
    if (
      dataTable?.length === 0 &&
      searchResponse?.pager?.current_page + 1 <
        searchResponse?.pager?.total_pages
    ) {
      if (values.subMember && !values?.subMemberName && !values?.membersName) {
        SearchAPI(
          `?type=${values?.subMember}&member_id=${"All"}&page=${
            searchResponse?.pager?.current_page + 1
          }`,
          true
        );
      } else {
        SearchAPI(
          `?uid=${values?.subMemberName}&member_id=${
            values?.membersName
          }&page=${searchResponse?.pager?.current_page + 1}`,
          true
        );
      }
    } else {
      return;
    }
  }, [dataTable]);

  const handleAdd = (arrField) => {
    const obj = {};
    const isThere = tableData?.find((item) => item?.teamId === arrField?.id);
    if (!isThere) {
      obj.key = arrField?.id;
      obj.teamId = arrField?.id;
      obj.member = getMemberNameById([], arrField?.member_id);
      obj.subMember = arrField?.subMember || "Nil";
      obj.teamName = fixSpecialChar(arrField?.team_name);
      setTableData((tableData) => [...tableData, obj]);
    } else {
      addToast("Same Team added Already", {
        appearance: "warning",
      });
    }
  };
  const handleRemove = (fieldArr) => {
    const arr = tableData?.filter((item) => item?.teamId !== fieldArr?.teamId);
    setTableData(arr);
  };

  const [searchCriteria, setSearchCriteria] = useState(null);

  const handleSearch = () => {
    if (!values.membersName && !values?.subMember && !values?.subMemberName) {
      addToast("Please select any of one search criteria", {
        appearance: "warning",
      });
    } else {
      let criteria = null;
      if (!values.subMember && !values?.subMemberName && values?.membersName) {
        criteria = `?member_id=${values?.membersName}`;
      } else if (values.subMember && !values?.subMemberName) {
        criteria = `?member_id=${values?.membersName}&type=${values.subMember}`;
      } else {
        criteria = `?uid=${values?.subMemberName}&member_id=${values?.membersName}&type=${values.subMember}`;
      }

      setSearchCriteria(criteria);
      SearchAPI(criteria, true);
    }
  };

  const onChange = (page) => {
    console.log(page, "ppp");
    if (searchCriteria) {
      SearchAPI(`${searchCriteria}&page=${page}`, true);
    }
  };
  useEffect(() => {
    (values?.subMember &&
      !values?.membersName &&
      FetchAPI(`?type=${values?.subMember}`, true)) ||
      (values?.subMember &&
        values?.membersName &&
        FetchAPI(`?type=${values?.subMember}&member_id=${sMemberId}`, true));
    //setFieldValue("subMemberName", "");
  }, [values?.subMember, values.membersName]);

  useEffect(() => {
    setResData([]);
  }, [values.subMember, values?.membersName]);

  useEffect(() => {
    if (fetchResponse) {
      isPagination
        ? setResData([...resData, ...fetchResponse?.rows])
        : setResData(fetchResponse?.rows);
    }
  }, [fetchResponse]);

  const handleSubmemberSearch = (val) => {
    setIsPagination(false);
    setSubmemberValue(val);
    FetchAPI(
      `?type=${values?.subMember ? values?.subMember : ""}&member_id=${
        sMemberId ? sMemberId : ""
      }&keyword=${val}`,
      true
    );
  };

  const handleScroll = (event) => {
    setIsPagination(true);
    const target = event.target;

    const totalHeight = target.scrollHeight - 1;
    const scrollHeight = target.scrollTop + target.offsetHeight;
    const totalPage = fetchResponse?.pager?.total_pages;
    const curPage = fetchResponse?.pager.current_page + 1;

    if (
      (totalPage != curPage &&
        parseInt(scrollHeight.toFixed(0)) === totalHeight) ||
      parseInt(scrollHeight.toFixed(0)) - 1 === totalHeight
    ) {
      FetchAPI(
        `?type=${values?.subMember ? values?.subMember : ""}&member_id=${
          sMemberId ? sMemberId : ""
        }&keyword=${subMemberValue}&page=${
          fetchResponse?.pager.current_page + 1
        }`,
        true
      );
    }
  };

  return (
    <div className="mt-3 pt-3 px-3">
      <CsaStepper steps={steps} activeKey={1} />
      {/* {fetching ? (
    <Spin />
   ) : ( */}
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Member"
            placeholder="Select Member"
            name="membersName"
            value={values.membersName}
            optionType
            menuArr={getMemberNameById(info?.step1?.membersNameP)}
            onChange={(e) => {
              setFieldValue("membersName", e);
              setSMemberID(e);
            }}
            onBlur={() => setFieldTouched("membersName", true)}
            touched={touched.membersName}
            error={errors.membersName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Sub-Member Type"
            placeholder="Select Sub-Member Type"
            name="subMember"
            value={values.subMember}
            optionType
            menuArr={masterData.submemberList}
            onChange={(e) => {
              setFieldValue("subMember", e);
            }}
            defaultValue={values.subMember}
            onBlur={handleBlur}
            touched={touched.subMember}
            error={errors.subMember || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Sub-Member Name"
            placeholder="Select Sub-Member Name"
            name="subMemberName"
            onChange={(e) => {
              setFieldValue("subMemberName", e);
            }}
            value={values.subMemberName}
            optionType
            loading={fetchLoad}
            onScroll={handleScroll}
            onSearch={handleSubmemberSearch}
            menuArr={
              resData && resData?.length > 0
                ? resData?.map((p) => ({
                    key: p?.uid,
                    value: p?.uid?.toString(),
                    label:
                      values?.subMember === "clubs_schools"
                        ? p?.club_school_name
                        : values?.subMember === "high_schools"
                        ? p?.high_school_name
                        : values?.subMember === "primary_schools"
                        ? p?.primary_school_name
                        : p?.ancillary_member_name,
                    ...p,
                  }))
                : []
            }
            onBlur={handleBlur}
            touched={touched.subMemberName}
            error={errors.subMemberName || null}
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="d-flex justify-content-end"
        >
          <CsaButton
            label="Search"
            textColor="#FFF"
            onClick={() => handleSearch()}
            btnColor="#007237"
          />
        </Col>
      </Row>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Search Results:</p>
        <Table
          className="center-aligned"
          loading={searchLoad}
          columns={columns}
          dataSource={dataTable}
          pagination={false}
          scroll={{
            x: 500,
          }}
          rowClassName={(rec) =>
            tableData.some((item) => item.key === rec?.key) && "disabled-row"
          }
        />
        <div className=" d-flex w-100 justify-content-end mt-5">
          <Pagination
            onChange={onChange}
            total={searchResponse?.pager?.total_items}
            size={"small"}
            current={searchResponse?.pager?.current_page + 1}
          />
        </div>
      </div>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Selected Participating Teams:</p>
        <Table
          className="center-aligned"
          //   loading={loading}
          columns={columns1}
          dataSource={tableData}
          pagination={false}
          scroll={{
            x: 580,
          }}
        />
        {/* <div className=" d-flex w-100 justify-content-end mt-5">
     <Pagination
       onChange={onChange}
       //    total={response?.pager?.total_items}
       size={"small"}
      />
    </div> */}
      </div>
      <div className="mt-4  d-flex w-100 justify-content-end">
        <CsaButton
          label="Back"
          textColor="#007237"
          onClick={() => {
            setInfo({
              step1: info?.step1,
              step2: values,
              tableData: tableData,
              step3: info?.step3,
              tableFieldData: info?.tableFieldData,
            });
            setStep(1);
          }}
          btnColor="#FFCB03"
        />
        <CsaButton
          label="Continue"
          textColor="#FFF"
          onClick={() => handleSubmit()}
          btnColor="#007237"
          //  loading={loading}
          disabled={tableData?.length === 0}
        />
      </div>

      {/* )} */}
    </div>
  );
}

export default CMSTeams;
