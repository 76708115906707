import CsaButton from "Common/CsaButton";
import React from "react";
import { useNavigate } from "react-router-dom";

const CsaBackNavigateButton = () => {
  const navigate = useNavigate();

  return (
    <CsaButton
      label="Back"
      textColor="#FFF"
      onClick={() => navigate(-1)}
      btnColor="#007237"
    />
  );
};

export default CsaBackNavigateButton;
