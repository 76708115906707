import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "antd";
import styles from "./TournamentsScoreCard.module.scss";

import ExpandTable from "Common/ExpandTable";

import TournamentData from "../../tournament_data.json";
import { useSelector, useDispatch } from "react-redux";
import { setScheduleData, setTableOpen } from "redux/slice/commonSlice";
import { createObjectWithKeys, fixSpecialChar } from "utils/helpers";
import ExtrasCompomemt, {
  generateSuperOverCols,
  getBattingArr,
  getBowlingArr,
  getFallOfWickets,
} from "./TournamentHelpers";

const renderContent = (value, row, index) => {
  const obj = {
    children: value,
    props: {},
  };
  //  if (index === 8) {
  //   obj.props.colSpan = 0;
  //  }
  return obj;
};

const matchDetailColumns = [
  {
    title: "Match Details",
    dataIndex: "match",
    key: "match",
    width: "100%",
  },
];

const TournamentsScoreCard = ({ ...props }) => {
  const { tournamentsData, inningsData } = props;

  const [openTables, setOpenTables] = useState({
    openTable: true,
    openTable2: true,
    openTable3: true,
    openTable4: true,
  });

  const hasMultipleInns = tournamentsData?.numOvers === 0;
  const hasSuperOver = inningsData?.some(
    (inning) => inning.isSuperOver === true
  );
  console.log(tournamentsData, hasSuperOver, "ttttt");

  const firstInningscolumns = [
    {
      title:
        "1st innings: " + fixSpecialChar(inningsData?.[0]?.battingTeamName),
      dataIndex: "name",
      key: "name",
      width: "20%",
      //   render: (text, row, index) => {
      //    if (index < 11) {
      //     return <span>{text}</span>;
      //    }
      //    return {
      //     children: <span style={{ fontStyle: "italic" }}>{text}</span>,
      //     props: {
      //      colSpan: `${index === 12 ? 12 : null}`,
      //      style: { fontStyle: "italic" },
      //     },
      //    };
      //   },
    },
    {
      title: "",
      dataIndex: "players",
      key: "players",
      width: "20%",
      render: renderContent,
      // render: (text, row, index) => {
      //   if (index === 7) {
      //     return <span style={{ fontStyle: "italic" }}>{text}</span>;
      //   }
      // }
    },
    {
      title: "Runs",
      dataIndex: "runs",
      key: "runs",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Balls",
      dataIndex: "balls",
      key: "balls",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "4's",
      dataIndex: "four",
      key: "four",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "6's",
      dataIndex: "six",
      key: "six",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Strike Rate",
      dataIndex: "strike",
      key: "strike",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Dots",
      dataIndex: "dots",
      key: "dots",
      width: "10%",
      align: "center",
      render: renderContent,
    },
  ];
  const firstInnsTeamId = inningsData?.[0]?.battingTeamId;
  const firstInnsArr = inningsData?.filter(
    (item) => item.battingTeamId === firstInnsTeamId
  );
  const secInnsArr = inningsData?.filter(
    (item) => item.battingTeamId !== firstInnsTeamId
  );
  const firstInns2 =
    firstInnsArr?.length > 1 ? firstInnsArr?.reverse()[0] : null;
  const secInnsTeamArr = inningsData?.filter(
    (item) => item.battingTeamId !== firstInnsTeamId
  );

  const [openSuperOvrTables, setOpenSuperOvrTables] = useState(
    createObjectWithKeys(15)
  );
  const [openSuperOvrTables2, setOpenSuperOvrTables2] = useState(
    createObjectWithKeys(15)
  );

  console.log(
    // firstInnsArr,
    // inningsData,
    // secInnsArr,
    "inns"
  );

  const firstInningscolumns2 = [
    {
      title: "2nd innings: " + fixSpecialChar(firstInns2?.battingTeamName),
      dataIndex: "name",
      key: "name",
      width: "20%",
      //   render: (text, row, index) => {
      //    if (index < 11) {
      //     return <span>{text}</span>;
      //    }
      //    return {
      //     children: <span style={{ fontStyle: "italic" }}>{text}</span>,
      //     props: {
      //      colSpan: `${index === 12 ? 12 : null}`,
      //      style: { fontStyle: "italic" },
      //     },
      //    };
      //   },
    },
    {
      title: "",
      dataIndex: "players",
      key: "players",
      width: "20%",
      render: renderContent,
      // render: (text, row, index) => {
      //   if (index === 7) {
      //     return <span style={{ fontStyle: "italic" }}>{text}</span>;
      //   }
      // }
    },
    {
      title: "Runs",
      dataIndex: "runs",
      key: "runs",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Balls",
      dataIndex: "balls",
      key: "balls",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "4's",
      dataIndex: "four",
      key: "four",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "6's",
      dataIndex: "six",
      key: "six",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Strike Rate",
      dataIndex: "strike",
      key: "strike",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Dots",
      dataIndex: "dots",
      key: "dots",
      width: "10%",
      align: "center",
      render: renderContent,
    },
  ];

  const secondInningscolumns = [
    {
      title: `1st innings:  ${
        secInnsTeamArr?.[0]?.battingTeamName
          ? fixSpecialChar(inningsData?.[1]?.battingTeamName)
          : fixSpecialChar(firstInnsArr?.[0]?.bowlingTeamName) || ""
      }`,
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text, row, index) => {
        if (index < 11) {
          return <span>{text}</span>;
        }
        return {
          children: <span style={{ fontStyle: "italic" }}>{text}</span>,
          props: {
            colSpan: `${index === 8 ? 8 : null}`,
            style: { fontStyle: "italic" },
          },
        };
      },
    },
    {
      title: "",
      dataIndex: "players",
      key: "players",
      width: "20%",
      render: renderContent,
      // render: (text, row, index) => {
      //   if (index === 7) {
      //     return <span style={{ fontStyle: "italic" }}>{text}</span>;
      //   }
      // }
    },
    {
      title: "Runs",
      dataIndex: "runs",
      key: "runs",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Balls",
      dataIndex: "balls",
      key: "balls",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "4's",
      dataIndex: "four",
      key: "four",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "6's",
      dataIndex: "six",
      key: "six",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Strike Rate",
      dataIndex: "strike",
      key: "strike",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Dots",
      dataIndex: "dots",
      key: "dots",
      width: "10%",
      align: "center",
      render: renderContent,
    },
  ];
  const secondInningscolumns2 = [
    {
      title: `2nd innings:  ${
        secInnsTeamArr?.[1]?.battingTeamName
          ? fixSpecialChar(inningsData?.[1]?.battingTeamName)
          : ""
      }`,
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text, row, index) => {
        if (index < 11) {
          return <span>{text}</span>;
        }
        return {
          children: <span style={{ fontStyle: "italic" }}>{text}</span>,
          props: {
            colSpan: `${index === 8 ? 8 : null}`,
            style: { fontStyle: "italic" },
          },
        };
      },
    },
    {
      title: "",
      dataIndex: "players",
      key: "players",
      width: "20%",
      render: renderContent,
      // render: (text, row, index) => {
      //   if (index === 7) {
      //     return <span style={{ fontStyle: "italic" }}>{text}</span>;
      //   }
      // }
    },
    {
      title: "Runs",
      dataIndex: "runs",
      key: "runs",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Balls",
      dataIndex: "balls",
      key: "balls",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "4's",
      dataIndex: "four",
      key: "four",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "6's",
      dataIndex: "six",
      key: "six",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Strike Rate",
      dataIndex: "strike",
      key: "strike",
      width: "10%",
      align: "center",
      render: renderContent,
    },
    {
      title: "Dots",
      dataIndex: "dots",
      key: "dots",
      width: "10%",
      align: "center",
      render: renderContent,
    },
  ];

  const inningsBatting1 = getBattingArr(inningsData?.[0]?.summaryBatting);

  const inningsBatting11 = getBattingArr(firstInns2?.summaryBatting);

  const inningsBatting2 = getBattingArr(secInnsTeamArr?.[0]?.summaryBatting);
  const inningsBatting22 = getBattingArr(secInnsTeamArr?.[1]?.summaryBatting);

  const inningsBowling1 = getBowlingArr(inningsData?.[0]?.summaryBowling);
  const inningsBowling11 = getBowlingArr(firstInns2?.summaryBowling);

  const inningsBowling2 = getBowlingArr(secInnsTeamArr?.[0]?.summaryBowling);
  const inningsBowling22 = getBowlingArr(secInnsTeamArr?.[1]?.summaryBowling);

  const fallOfWickets1 = getFallOfWickets(inningsData?.[0]?.fallOfWickets);
  const fallOfWickets11 = getFallOfWickets(firstInns2?.fallOfWickets);
  console.log(inningsBatting11, "fww");
  const fallOfWickets2 = getFallOfWickets(secInnsTeamArr?.[0]?.fallOfWickets);
  const fallOfWickets22 = getFallOfWickets(secInnsTeamArr?.[1]?.fallOfWickets);

  const umpire =
    (tournamentsData?.umpire1Id &&
      tournamentsData?.umpire2Id &&
      tournamentsData?.umpire3Id &&
      tournamentsData.umpire1Id +
        " , " +
        tournamentsData.umpire2Id +
        " , " +
        tournamentsData.umpire3Id) ||
    (tournamentsData?.umpire1Id &&
      tournamentsData?.umpire2Id &&
      !tournamentsData?.umpire3Id &&
      tournamentsData.umpire1Id + " , " + tournamentsData.umpire2Id) ||
    (tournamentsData?.umpire1Id &&
      !tournamentsData?.umpire2Id &&
      !tournamentsData?.umpire3Id &&
      tournamentsData.umpire1Id);

  const matchDetailData = [
    {
      key: "1",
      match:
        "Toss:    " +
        fixSpecialChar(tournamentsData?.toss?.teamName) +
        " chose to " +
        tournamentsData?.toss?.value,
    },
    {
      key: "2",
      match: "Umpires: " + fixSpecialChar(umpire) || "",
    },
    {
      key: "3",
      match: "Player of the Match: ",
    },
    {
      key: "4",
      match: "Location:    " + fixSpecialChar(tournamentsData?.venue),
    },
  ];

  console.log(openSuperOvrTables, "open");

  return (
    <>
      <div className={styles.TournamentsScoreCard}>
        {hasSuperOver ? (
          <>
            {firstInnsArr?.reverse()?.map((inns, index) => {
              const isTableOpen = openSuperOvrTables[index] || false;
              return (
                <>
                  <ExpandTable
                    className="yellow-expand-table"
                    dataSource={getBattingArr(inns?.summaryBatting)}
                    columns={generateSuperOverCols(
                      index,
                      inns?.battingTeamName,
                      firstInnsArr?.length,
                      "1st innings: "
                    )}
                    tableId={index}
                    openedTable={() => {
                      setOpenSuperOvrTables((prevOpenTables) => ({
                        ...prevOpenTables,
                        [index]: !isTableOpen,
                      }));
                    }}
                    scroll={{
                      x: 700,
                    }}
                  />
                  {openSuperOvrTables[index] && (
                    <ExtrasCompomemt tournamentsData={inns?.teamSummary} />
                  )}
                  <div className="my-4">
                    {openSuperOvrTables[index] && (
                      <Row gutter={[24, 24]}>
                        <Col
                          className="gutter-row"
                          xs={24}
                          lg={24}
                          xl={16}
                          md={24}
                        >
                          <Table
                            className="small-table"
                            columns={TournamentData.BowlingTable.columns}
                            dataSource={getBowlingArr(inns?.summaryBowling)}
                            pagination={false}
                            size={"small"}
                            scroll={{
                              x: 500,
                            }}
                          />
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={24}
                          lg={24}
                          xl={8}
                          md={24}
                        >
                          <Table
                            className="small-table"
                            columns={TournamentData.WicketsTable.columns}
                            dataSource={getFallOfWickets(inns?.fallOfWickets)}
                            pagination={false}
                            size={"small"}
                            scroll={{
                              x: 300,
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </>
              );
            })}
            {secInnsArr?.map((inns, index) => {
              const isTableOpen = openSuperOvrTables2[index] || false;
              return (
                <>
                  <ExpandTable
                    className="green-expand-table"
                    dataSource={getBattingArr(inns?.summaryBatting)}
                    columns={generateSuperOverCols(
                      index,
                      inns?.battingTeamName,
                      secInnsArr?.length,
                      "1st innings: "
                    )}
                    tableId={index}
                    openedTable={() => {
                      setOpenSuperOvrTables2((prevOpenTables) => ({
                        ...prevOpenTables,
                        [index]: !isTableOpen,
                      }));
                    }}
                    scroll={{
                      x: 700,
                    }}
                  />
                  {openSuperOvrTables2[index] && (
                    <ExtrasCompomemt tournamentsData={inns?.teamSummary} />
                  )}
                  <div className="my-4">
                    {openSuperOvrTables2[index] && (
                      <Row gutter={[24, 24]}>
                        <Col
                          className="gutter-row"
                          xs={24}
                          lg={24}
                          xl={16}
                          md={24}
                        >
                          <Table
                            className="small-table"
                            columns={TournamentData.BowlingTable.columns}
                            dataSource={getBowlingArr(inns?.summaryBowling)}
                            pagination={false}
                            size={"small"}
                            scroll={{
                              x: 500,
                            }}
                          />
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={24}
                          lg={24}
                          xl={8}
                          md={24}
                        >
                          <Table
                            className="small-table"
                            columns={TournamentData.WicketsTable.columns}
                            dataSource={getFallOfWickets(inns?.fallOfWickets)}
                            pagination={false}
                            size={"small"}
                            scroll={{
                              x: 300,
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <>
            <ExpandTable
              className="yellow-expand-table"
              dataSource={inningsBatting1}
              columns={firstInningscolumns}
              tableId="1"
              openedTable={() =>
                setOpenTables({
                  ...openTables,
                  openTable: !openTables.openTable,
                })
              }
              scroll={{
                x: 700,
              }}
            />
            {openTables.openTable && (
              <ExtrasCompomemt
                tournamentsData={inningsData?.[0]?.teamSummary}
              />
            )}
            <div className="my-4">
              {openTables.openTable && (
                <Row gutter={[24, 24]}>
                  <Col className="gutter-row" xs={24} lg={24} xl={16} md={24}>
                    <Table
                      className="small-table"
                      columns={TournamentData.BowlingTable.columns}
                      dataSource={inningsBowling1}
                      pagination={false}
                      size={"small"}
                      scroll={{
                        x: 500,
                      }}
                    />
                  </Col>
                  <Col className="gutter-row" xs={24} lg={24} xl={8} md={24}>
                    <Table
                      className="small-table"
                      columns={TournamentData.WicketsTable.columns}
                      dataSource={fallOfWickets1}
                      pagination={false}
                      size={"small"}
                      scroll={{
                        x: 300,
                      }}
                    />
                  </Col>
                </Row>
              )}
            </div>
            {hasMultipleInns && firstInns2 && (
              <>
                <ExpandTable
                  className="yellow-expand-table"
                  dataSource={inningsBatting11}
                  columns={firstInningscolumns2}
                  tableId="1"
                  openedTable={() =>
                    setOpenTables({
                      ...openTables,
                      openTable2: !openTables.openTable2,
                    })
                  }
                  scroll={{
                    x: 700,
                  }}
                />
                {openTables.openTable2 && (
                  <ExtrasCompomemt tournamentsData={firstInns2?.teamSummary} />
                )}
                <div className="my-4">
                  {openTables.openTable2 && (
                    <Row gutter={[24, 24]}>
                      <Col
                        className="gutter-row"
                        xs={24}
                        lg={24}
                        xl={16}
                        md={24}
                      >
                        <Table
                          className="small-table"
                          columns={TournamentData.BowlingTable.columns}
                          dataSource={inningsBowling11}
                          pagination={false}
                          size={"small"}
                          scroll={{
                            x: 500,
                          }}
                        />
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        lg={24}
                        xl={8}
                        md={24}
                      >
                        <Table
                          className="small-table"
                          columns={TournamentData.WicketsTable.columns}
                          dataSource={fallOfWickets11}
                          pagination={false}
                          size={"small"}
                          scroll={{
                            x: 300,
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </>
            )}
            <div className="my-4">
              <ExpandTable
                className="green-expand-table"
                dataSource={inningsBatting2}
                columns={secondInningscolumns}
                tableId="2"
                openedTable={() =>
                  setOpenTables({
                    ...openTables,
                    openTable3: !openTables.openTable3,
                  })
                }
                scroll={{
                  x: 700,
                }}
              />

              {openTables.openTable3 && (
                <ExtrasCompomemt
                  tournamentsData={secInnsTeamArr?.[0]?.teamSummary}
                />
              )}
            </div>
            <div className="my-4">
              {openTables.openTable3 && (
                <Row gutter={[24, 24]}>
                  <Col className="gutter-row" xs={24} lg={24} xl={16} md={24}>
                    <Table
                      // className="yellow-expand-table"
                      columns={TournamentData.BowlingTable.columns}
                      dataSource={inningsBowling2}
                      pagination={false}
                      size={"small"}
                      scroll={{
                        x: 500,
                      }}
                    />
                  </Col>
                  <Col className="gutter-row" xs={24} lg={24} xl={8} md={24}>
                    <Table
                      // className="yellow-expand-table"
                      columns={TournamentData.WicketsTable.columns}
                      dataSource={fallOfWickets2}
                      pagination={false}
                      size={"small"}
                      scroll={{
                        x: 300,
                      }}
                    />
                  </Col>
                </Row>
              )}
            </div>
            {hasMultipleInns && secInnsTeamArr?.[1] && (
              <>
                <div className="my-4">
                  <ExpandTable
                    className="green-expand-table"
                    dataSource={inningsBatting22}
                    columns={secondInningscolumns2}
                    tableId="2"
                    openedTable={() =>
                      setOpenTables({
                        ...openTables,
                        openTable4: !openTables.openTable4,
                      })
                    }
                    scroll={{
                      x: 700,
                    }}
                  />

                  {openTables.openTable4 && (
                    <ExtrasCompomemt
                      tournamentsData={secInnsTeamArr?.[1].teamSummary}
                    />
                  )}
                </div>
                <div className="my-4">
                  {openTables.openTable4 && (
                    <Row gutter={[24, 24]}>
                      <Col
                        className="gutter-row"
                        xs={24}
                        lg={24}
                        xl={16}
                        md={24}
                      >
                        <Table
                          // className="yellow-expand-table"
                          columns={TournamentData.BowlingTable.columns}
                          dataSource={inningsBowling22}
                          pagination={false}
                          size={"small"}
                          scroll={{
                            x: 500,
                          }}
                        />
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        lg={24}
                        xl={8}
                        md={24}
                      >
                        <Table
                          // className="yellow-expand-table"
                          columns={TournamentData.WicketsTable.columns}
                          dataSource={fallOfWickets22}
                          pagination={false}
                          size={"small"}
                          scroll={{
                            x: 300,
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="my-4">
                  <ExpandTable
                    className="yellow-expand-table"
                    dataSource={matchDetailData}
                    columns={matchDetailColumns}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TournamentsScoreCard;
