/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//"https://www.csacms.ml/web";
//REACT_APP_API_URL=https://csacms.xyz/
const useAxios = ({
 url,
 method,
 body = null,
 customHeaders,
 isAuth = true,
}) => {
 const [response, setResponse] = useState(null);
 const [error, setError] = useState("");
 const [loading, setLoading] = useState(false);
 const token = `Bearer ${localStorage.getItem("csa_token")}`;
 const headers = isAuth
  ? {
     "Content-Type": customHeaders ? customHeaders : "application/json",
     Authorization: token,
    }
  : {
     "Content-Type": customHeaders ? customHeaders : "application/json",
    };

 const callAPI = (data, query = false) => {
  setLoading(true);
  const config = {
   method,
   url: query ? url + data : url,
   headers,
   data: body || data,
  };
  axios(config)
   .then((response) => {
    setResponse(response?.data);
    setLoading(false);
   })
   .catch((error) => {
    setError(error?.response?.data || error);
    setLoading(false);
   });
 };

 return { callAPI, response, error, loading };
};

export default useAxios;
