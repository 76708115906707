/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { Card, Col, Row, Avatar, Image, Table, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useLocation } from "react-router-dom";
import { fixSpecialChar } from "utils/helpers";

export default function TeamView(props) {
  const { viewData } = props;
  const location = useLocation();
  const { id } = location.state;

  const {
    callAPI: fetchPlayers,
    response: resPlayers,
    error: errorPlayers,
    loading: loadingPlayers,
  } = useAxios({
    url: serviceUrl.getPlayersList,
    method: "get",
  });

  useEffect(() => {
    fetchPlayers(`${id}`, true);
  }, []);

  const onChangePage = (pg) => {
    fetchPlayers(`${id}?page=${pg - 1}`, true);
  };

  const columns = [
    {
      title: "Player Name:",
      key: "firstname",
      render: (item) => (
        <p>
          {item?.firstname} {item?.lastname}
        </p>
      ),
    },
    {
      title: "Batting Skill ",
      dataIndex: "battingSkill",
      key: "battingSkill",
    },
    {
      title: "Batting Hand",
      dataIndex: "battingHand",
      key: "battingHand",
    },
    {
      title: "Bowling Skill ",
      dataIndex: "bowlingSkill",
      key: "bowlingSkill",
    },
    {
      title: "Bowling Hand",
      dataIndex: "bowlingHand",
      key: "bowlingHand",
      // align:"right"
    },
  ];

  const dataTable = resPlayers?.rows?.map((item) => ({
    key: item?.nid,
    firstname: fixSpecialChar(item?.first_name),
    lastname: fixSpecialChar(item?.last_name),
    battingSkill: item?.field_batting_skill ? item?.field_batting_skill : "N/A",
    battingHand: item?.field_batting_hand ? item?.field_batting_hand : "N/A",
    bowlingSkill: item?.field_bowl_skill ? item?.field_bowl_skill : "N/A",
    bowlingHand: item?.field_bowling_hand ? item?.field_bowling_hand : "N/A",
    role: item?.roles,
    ...item,
  }));

  return (
    <Card
      style={{
        width: "100%",
        backgroundColor: "#f1f1f1",
        justifyContent: "center",
        borderRadius: 15,
        borderWidth: 0.5,
        borderColor: "#f3f3f3",
      }}
    >
      <Row>
        {viewData && (
          <>
            <Col span={24} className="w-100 mb-3 d-flex justify-content-center">
              {viewData?.field_profile ? (
                <Avatar
                  size={150}
                  src={
                    <Image
                      src={viewData?.field_profile}
                      style={{
                        width: 150,
                        height: 150,
                      }}
                    />
                  }
                />
              ) : (
                <Avatar size={150}>CSA </Avatar>
              )}
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Team Name</h6>
              <p>{viewData?.team_name.replace(/&#039;/g, "'")}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Team Level</h6>
              <p>{viewData?.field_team_level || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Age Group</h6>
              <p>{viewData?.field_age_group || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Gender</h6>
              <p>{viewData?.field_gender || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Differently Abled</h6>
              <p>{viewData?.field_differently_abled || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Status</h6>
              <p>
                {viewData?.field_status === "Yes"
                  ? "Activated"
                  : "Disabled" || "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Team Manager</h6>
              <p>
                {viewData?.field_team_manager.replace(/&#039;/g, "'") || "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Team Coach</h6>
              <p>
                {viewData?.team_coach.length != 0
                  ? viewData?.team_coach[0]?.first_name.replace(
                      /&#039;/g,
                      "'"
                    ) +
                    " " +
                    viewData?.team_coach[0]?.last_name.replace(/&#039;/g, "'")
                  : "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Fielding Coach</h6>
              <p>
                {viewData?.fielding_coach.length != 0
                  ? viewData?.fielding_coach[0]?.first_name +
                    " " +
                    viewData?.fielding_coach[0]?.last_name
                  : "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Fitness Coach</h6>
              <p>
                {viewData?.fitness_coach.length != 0
                  ? viewData?.fitness_coach[0]?.first_name.replace(
                      /&#039;/g,
                      "'"
                    ) +
                    " " +
                    viewData?.fitness_coach[0]?.last_name.replace(
                      /&#039;/g,
                      "'"
                    )
                  : "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Bowling Coach</h6>
              <p>
                {viewData?.bowling_coach.length != 0
                  ? viewData?.bowling_coach[0]?.first_name +
                    " " +
                    viewData?.bowling_coach[0]?.last_name
                  : "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Batting Coach</h6>
              <p>
                {viewData?.batting_coach.length != 0
                  ? viewData?.batting_coach[0]?.first_name?.replace(
                      /&#039;/g,
                      "'"
                    ) +
                    " " +
                    viewData?.batting_coach[0]?.last_name?.replace(
                      /&#039;/g,
                      "'"
                    )
                  : "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Doctor</h6>
              <p>
                {viewData?.batting_coach.length != 0
                  ? viewData?.batting_coach[0]?.first_name?.replace(
                      /&#039;/g,
                      "'"
                    ) +
                    " " +
                    viewData?.batting_coach[0]?.last_name?.replace(
                      /&#039;/g,
                      "'"
                    )
                  : "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Physio</h6>
              <p>
                {viewData?.batting_coach.length != 0
                  ? viewData?.batting_coach[0]?.first_name?.replace(
                      /&#039;/g,
                      "'"
                    ) +
                    " " +
                    viewData?.batting_coach[0]?.last_name?.replace(
                      /&#039;/g,
                      "'"
                    )
                  : "N/A"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1"> Analyst</h6>
              <p>
                {viewData?.batting_coach.length != 0
                  ? viewData?.batting_coach[0]?.first_name.replace(
                      /&#039;/g,
                      "'"
                    ) +
                    " " +
                    viewData?.batting_coach[0]?.last_name.replace(
                      /&#039;/g,
                      "'"
                    )
                  : "N/A"}
              </p>
            </Col>
          </>
        )}
      </Row>
      <h6 className="mb-1">Team Players:</h6>
      <div className="mt-2 pt-2">
        <Table
          className="center-aligned"
          loading={loadingPlayers}
          columns={columns}
          dataSource={dataTable}
          pagination={false}
        />
        <div className=" d-flex w-100 justify-content-end mt-5">
          <Pagination
            // defaultCurrent={1}
            total={resPlayers?.pager?.total_items}
            size={"small"}
            onChange={onChangePage}
            current={resPlayers?.pager?.current_page + 1}
          />
        </div>
      </div>
    </Card>
  );
}
