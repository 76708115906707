import React, { useState } from "react";
import { useLocation } from "react-router-dom";
//Components
import FieldRegisterForm from "../../Common/FieldRegisterForm";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./Fields.module.scss";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function Fields() {
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {} });
  const props = {
    styles,
    setStep,
    info,
    setInfo,
  };
  const location = useLocation();
  const { isView = false, isEdit = false } = location?.state || {};
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Facilities", value: "/field-list" },
    { key: "Fields", value: "/register-field" },
  ];
  return (
    <Wrapper>
      <div className={styles.FieldsWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Field
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>{step === 1 && <FieldRegisterForm {...props} />}</div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Fields;
