import React, { useState } from "react";
import { useLocation } from "react-router-dom";
//Components
import AncillaryMemberRegisterForm from "../../Common/AncillaryMemberRegisterForm";
import AncillaryMemberRegisterForm2 from "../../Common/AncillaryMemberRegisterForm/AncillaryMemberRegisterForm2";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./AncillaryMember.module.scss";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function AncillaryMember() {
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
  };
  const location = useLocation();
  const { isView = false, isEdit = false } = location?.state || {};

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Ancillary Members", value: "/ancillary-member-list" },
    { key: "Ancillary Member", value: "/register-ancillary-member" },
  ];

  return (
    <Wrapper>
      <div className={styles.AncillaryMemberWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Ancillary Member
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>
            {step === 1 && <AncillaryMemberRegisterForm {...props} />}
            {step === 2 && <AncillaryMemberRegisterForm2 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default AncillaryMember;
