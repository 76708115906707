import CsaButton from "Common/CsaButton";
import CsaImageUpload from "Common/CsaImageUpload";
import { Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import S3 from "react-aws-s3";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setCMSData } from "redux/slice/commonSlice";
import { Value } from "sass";
import { imgUrlToFile, multiImgUrlToFile } from "utils/helpers";

const CmsStreaming = ({
  styles,
  setStep,
  setActiveKey,
  streamLogos,
  setStreamLogos,
  info,
  setInfo,
}) => {
  const [logosId, setLogosId] = useState({
    ltLogo: "https://csa-team-prod.s3-ap-south-1.amazonaws.com/1709877378.png",
    rtLogo: null,
    spLogo1: null,
    spLogo2: null,
    spLogo3: null,
    spLogo4: null,
    spLogo5: null,
  });
  const CMSData = useSelector((state) => state.auth.cms_data) || {};
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, type, editData } = location?.state || {};
  const isEdit = type === "EDIT";

  console.log(streamLogos, logosId, "kkkk");

  useEffect(() => {
    if (CMSData?.streamingLogos && isEdit) {
      Object.entries(CMSData?.streamingLogos).forEach(([key, value]) => {
        if (streamLogos[key].length === 0 && CMSData?.streamingLogos[key]) {
          console.log(key, value, "dddd");
          multiImgUrlToFile(value, value, setStreamLogos, key);
          setLogosId((prevState) => ({ ...prevState, [key]: value }));
        }
      });
    }
  }, [CMSData?.streamingLogos]);

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
  };

  var ts = Math.round(new Date().getTime() / 1000);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    console.log(options, file, "sss");
    const reader = new FileReader();
    reader.onload = function (e) {
      const files = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      const fileName = ts + file.type.replace("image/", ".");
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(files, fileName)
        .then((data) =>
          setLogosId({ ...logosId, [options.filename]: data.location })
        )
        .then(onSuccess(files))
        .catch((err) => console.error(err));
    };

    reader.readAsArrayBuffer(file);
    return true;
  };

  const getUploadProps = (name) => ({
    name,
    fileList: streamLogos[name],
    customRequest: uploadImage,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setStreamLogos({ ...streamLogos, [name]: info?.fileList });
    },
    onRemove() {
      setLogosId({ ...logosId, [name]: null });
      setStreamLogos({ ...streamLogos, [name]: [] });
    },
  });

  const handleSubmit = (step, key) => {
    setInfo({ ...info, logosId });

    dispatch(setCMSData({ ...CMSData, streamingLogos: logosId }));
    setStep(step);
    setActiveKey(key);
  };
  return (
    <Row gutter={[10, 16]} className="mt-3 pt-3" align="Start">
      <Col span={12} className={styles.imageUpload}>
        <CsaImageUpload
          disabled={true}
          {...getUploadProps("ltLogo")}
          type="streamLogo"
        />
        {/* <button  >Remove</button> */}
        <p className={styles.name}>Left Top Logo</p>
        <p style={{ fontSize: "12px" }}>(200*100px)</p>
      </Col>
      <Col span={12} className={styles.imageUpload}>
        <CsaImageUpload {...getUploadProps("rtLogo")} type="streamLogo" />
        {/* <button  >Remove</button> */}
        <p className={styles.name}>Right Top Logo</p>
        <p style={{ fontSize: "12px" }}>(200*100px)</p>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "75px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CsaImageUpload {...getUploadProps("spLogo1")} type="ad-banner" />
          {/* <button  >Remove</button> */}
          <p className={styles.name}>Sponsor Logo 1</p>
          <p style={{ fontSize: "12px" }}>(1920*150px)</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CsaImageUpload {...getUploadProps("spLogo2")} type="ad-banner" />
          {/* <button  >Remove</button> */}
          <p className={styles.name}>Sponsor Logo 2</p>
          <p style={{ fontSize: "12px" }}>(1920*150px)</p>
        </div>
      </Col>
      <Col span={8} className={styles.imageUpload}>
        <CsaImageUpload {...getUploadProps("spLogo3")} type="ad-banner" />
        {/* <button  >Remove</button> */}
        <p className={styles.name}>Sponsor Logo 3</p>
        <p style={{ fontSize: "12px" }}>(1920*150px)</p>
      </Col>
      <Col span={8} className={styles.imageUpload}>
        <CsaImageUpload {...getUploadProps("spLogo4")} type="ad-banner" />
        {/* <button  >Remove</button> */}
        <p className={styles.name}>Sponsor Logo 4</p>
        <p style={{ fontSize: "12px" }}>(1920*150px)</p>
      </Col>
      <Col span={8} className={styles.imageUpload}>
        <CsaImageUpload {...getUploadProps("spLogo5")} type="ad-banner" />
        {/* <button  >Remove</button> */}
        <p className={styles.name}>Sponsor Logo 5</p>
        <p style={{ fontSize: "12px" }}>(1920*150px)</p>
      </Col>
      <div className="mt-4  d-flex w-100 justify-content-end">
        <Space>
          <CsaButton
            label="Cancel"
            textColor="#007237"
            onClick={() => handleSubmit(1, "1")}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Continue"
            textColor="#FFF"
            onClick={() => handleSubmit(1, "3")}
            btnColor="#007237"
            //   disabled={checkValidity()}
          />
        </Space>
      </div>
    </Row>
  );
};

export default CmsStreaming;
