import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./Nets.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";

const NetsTable = () => {
 const authRole = localStorage.getItem("selRole");
 const userLevel = localStorage.getItem("csa_level");
 const uid = useSelector((state) => state.auth.login_as_uid);
 const memberId = localStorage.getItem("csa_member_id");
 const navigate = useNavigate();
 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.getNetDetails,
  method: "get",
 });
 useEffect(() => {
  userLevel === "member"
   ? callAPI(`level=${userLevel.toUpperCase()}&member_id=${memberId}`, true)
   : userLevel === "sub-member"
   ? callAPI(
      `level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
      true
     )
   : callAPI(`level=${userLevel.toUpperCase()}`, true);
 }, []);

 const pathArr = [
  { key: "Home", value: "/home" },
  { key: "Facilities", value: "/net-list" },
 ];
 const columns = [
  {
   title: "Nets Number",
   dataIndex: "net_number",
   key: "netNumber",
  },

  {
   title: "Pitch Type",
   dataIndex: "field_pitch_type",
   key: "pitchType",
  },
  {
   title: "Indoor/Outdoor",
   dataIndex: "field_net_type",
   key: "field_net_type",
  },
  {
   title: "Status",
   dataIndex: "status1",
   key: "status1",
  },
  authRole === "MEMBERS ADMINISTRATOR" ||
  authRole === "MEMBERS ADMIN ASSISTANT" ||
  authRole === "SUB MEMBERS ADMINISTRATOR" ||
  authRole === "SUB-MEMBERS ADMINISTRATOR"
   ? {
      title: "Action",
      key: "action",
      render: (item) => (
       <CsaMoreMenu
        handleView={() =>
         navigate("/register-net", {
          state: { id: item?.id, isView: true },
         })
        }
        handleEdit={() =>
         navigate("/register-net", {
          state: { id: item?.id, isEdit: true },
         })
        }
       />
      ),
    //  align: "right",
     }
   : {},
 ];
 const data = response?.rows
  // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
  ?.map((item) => ({
   key: item?.id,
   status1: item?.field_status === "Yes" ? "Activated" : "Disabled",
   ...item,
  }));
 const onSelect = (value) => {
  
 };

 const onChange = (page) => {
  userLevel === "member"
   ? callAPI(
      `keyword=&level=${userLevel.toUpperCase()}&member_id=${memberId}&page=${
       page - 1
      }`,
      true
     )
   : userLevel === "sub-member"
   ? callAPI(
      `keyword=&level=${userLevel.toUpperCase()}&uid=${uid}&page=${
       page - 1
      }&member_id=${memberId}`,
      true
     )
   : callAPI(
      `keyword=&level=${userLevel.toUpperCase()}&page=${page - 1}`,
      true
     );
 };
 const handleSearch = (e) => {
  userLevel === "member"
   ? callAPI(
      `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
      true
     )
   : userLevel === "sub-member"
   ? callAPI(
      `keyword=${e}&level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
      true
     )
   : callAPI(`keyword=${e}&level=${userLevel.toUpperCase()}`, true);
 };

 return (
  <Wrapper>
   <div className={styles.NetsWrapper}>
    <CsaBreadcrumb pathArr={pathArr} />
    <div className={styles.contents}>
     <p className={styles.heading}>Nets</p>
     <Row
      gutter={{
       xs: 8,
       sm: 16,
       md: 24,
       lg: 32,
      }}
      className="mt-1 pt-1"
      align="start"
     >
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <AutoComplete
        style={{
         width: "100%",
         marginBottom: 10,
        }}
        // options={options}
        onSelect={(val, option) => onSelect(val, option.label)}
        onSearch={handleSearch}
       >
        <Input.Search
         size="large"
         placeholder="Search..."
         allowClear
         enterButton
        />
       </AutoComplete>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <div className=" d-flex w-100 justify-content-end">
        {authRole === "MEMBERS ADMINISTRATOR" ||
        authRole === "MEMBERS ADMIN ASSISTANT" ||
        authRole === "SUB MEMBERS ADMINISTRATOR" ||
        authRole === "SUB-MEMBERS ADMINISTRATOR" ? (
         <CsaButton
          label="Create"
          textColor="#FFF"
          onClick={() => navigate("/register-net")}
          btnColor="#007237"
         />
        ) : null}
       </div>
      </Col>
     </Row>
     <div className="mt-2 pt-2">
      <Table
      className="center-aligned"
       loading={loading}
       columns={columns}
       dataSource={data}
       pagination={false}
       scroll={{
        x: 600,
      }}
      />
      <div className=" d-flex w-100 justify-content-end mt-5">
       <Pagination
        onChange={onChange}
        total={response?.pager?.total_items}
        size={"small"}
       />
      </div>
     </div>
    </div>
   </div>
  </Wrapper>
 );
};

export default NetsTable;
