/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setCmsId, setPoolId } from "redux/slice/commonSlice";
import {
  Table,
  Row,
  Col,
  Pagination,
  DatePicker,
  TimePicker,
  Select,
  Form,
  Button,
  Input,
  Spin,
} from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  ConsoleSqlOutlined,
  DeleteFilled,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { useDispatch, useSelector } from "react-redux";
import { setCMSData } from "redux/slice/commonSlice";
import { useToasts } from "react-toast-notifications";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
import { fixSpecialChar } from "utils/helpers";
// import useAxios from "utils/axios";
// import { serviceUrl } from "services/serviceUrl";

function CMSSchedule({ styles, setStep, setInfo, info }) {
  const navigate = useNavigate();
  const cmsData = useSelector((state) => state.auth.cms_data);
  const cmsID = useSelector((state) => state.auth.cms_id);
  const [defaults, setDefault] = useState(null);
  const [itemArr, setItemArr] = useState([]);
  const [homeTeam, setHomeTeam] = useState();

  const [hasErr, saveErr] = useState();
  const [showTable, setShowTable] = useState(false);
  const [fieldValue, setFieldValue] = useState({
    pool: "",
    row: "",
    home: "",
    away: "",
    venue: "",
    scorer: "",
    format: "",
    date: "",
    time: "",
  });
  const [detaileddata, setdetaileddata] = useState([]);
  const [everypoolsdata, seteverypoolsdata] = useState([]);
  const [mainfinaldata, setmainfinaldata] = useState([]);
  const [mainsemifinaldata, setmainsemifinaldata] = useState([]);
  const [mainquaterfinaldata, setmainquaterfinaldata] = useState([]);
  const [mainsheduleresponse, setsheduleresponse] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.setMatch + `${cmsID}/matches`,
    method: "post",
  });
  const [form] = Form.useForm();

  // const getFieldValue = (fieldName) => {
  //   return Form.useWatch(fieldName, form);
  // };

  //

  //const isEdit = useSelector((state) => state.auth.isEdit);
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  let fieldValues = form.getFieldValue();

  function fieldIsEmpty(field) {
    let fieldValue = form.getFieldValue(field.name.join("."));
    return (
      fieldValue === undefined || [].concat(fieldValue).join().trim() === ""
    );
  }
  function fieldHasError(field) {
    return field.errors.length > 0;
  }
  const erFields = form
    .getFieldsError()
    .filter((field) => fieldIsEmpty(field) || fieldHasError(field));

  const filteredErFields = erFields.filter((item) => {
    return item.name.some((n) => n.indexOf("Pool") !== -1);
  });

  //     function isValidForm() {
  //
  //       const fields = form
  //         .getFieldsError()
  //         .filter((field) => fieldIsEmpty(field) || fieldHasError(field));
  //
  //       setIsDisabled(fields.length > 0);
  //     }

  let hasErrors;
  hasErrors =
    form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

  console.log(form.getFieldsError(), "Location==========");
  const {
    callAPI: fetchDetail,
    response: fetchResponse,
    error: err,
    loading: fetching,
  } = useAxios({
    url: serviceUrl.getCMSDetail + `${cmsID}`,
    method: "get",
  });
  useEffect(() => {
    fetchDetail();
  }, []);

  const {
    callAPI: fetchCompetitionById,
    response: responseCompetition,
    error: errCompetition,
    loading: fetchingCompetititon,
  } = useAxios({
    url:
      serviceUrl.getCompetitionById +
      `${cmsID}/schedule?group_by=date&include_summary=false`,
    method: "get",
  });

  useEffect(() => {
    fetchCompetitionById();
  }, []);

  const {
    callAPI: fetchschedulePool,
    response: fetchResponseschedulePool,
    error: errschedulePool,
    loading: fetchingschedulePool,
  } = useAxios({
    url: serviceUrl.getPool + `${cmsID}` + "/schedule?group_by=pool",
    method: "get",
  });

  useEffect(() => {
    fetchschedulePool();
    // setsheduleresponse(fetchResponseschedulePool && fetchResponseschedulePool.data)
  }, []);

  const matchArr = responseCompetition?.data || [];

  const dataArr = Object.keys(matchArr)?.map((key, index) => ({
    id: key,
    data: matchArr[key],
  }));

  const {
    callAPI: fetchDetailPool,
    response: fetchResponsePool,
    error: errPool,
    loading: fetchingPool,
  } = useAxios({
    url: serviceUrl.getPool + `${cmsID}` + "/pools",
    method: "get",
  });
  useEffect(() => {
    fetchDetailPool();
  }, []);

  let allTeamsData = [];
  const allteams = fetchResponsePool?.data?.map((data) => {
    allTeamsData.push(data.teams);
  });

  const sortedAllTeamsData = allTeamsData.flat(1);
  console.log("finaldataaaaaaaaaaaa", sortedAllTeamsData);

  const poolArr =
    Array.from(
      Array(
        Number(
          fetchResponse?.data?.competition_settings?.find(
            (item) => item?.key === "number_of_pools"
          )?.value || 0
        ) + 1
      ).keys()
    )?.slice(1) || [];
  const morethanOnePools = poolArr?.length > 1;
  const compSettings = fetchResponse?.data?.competition_settings || null;
  const result = compSettings?.reduce(function (r, a) {
    r[a?.key] = r[a?.key] || [];
    r[a?.key].push(a);
    return r;
  }, Object.create(null));
  const settingArr = compSettings
    ? Object?.keys(result)?.map((item, i) => ({
        key: item,
        value: result[item]?.map((val) =>
          val?.value ? val?.value : val?.values
        ),
        data: result[item]?.map((val) => (val?.data ? val?.data : [])),
      }))
    : [];
  console.log("competition", settingArr);
  const getKeyValue = (arr) =>
    arr?.map((item) => ({ label: item.key, value: item.field }));
  const {
    teamList = fetchDetailPool?.data?.teams,
    venueList = settingArr?.find((elem) => elem?.key === "venue_list")?.data,
    matchFormat = settingArr?.find((elem) => elem?.key === "match_format")
      ?.value?.[0],
    scorerList = settingArr?.find((elem) => elem?.key === "scorer_list_cms")
      ?.data,
  } = defaults || [];
  console.log("venueList", scorerList);
  const finalType = settingArr?.find((item) => item?.key === "finals")?.value;
  const quarterPoolCount = finalType?.find((item) => item === "quarter-finals")
    ? "quarter-finals"
    : null;
  const semiPoolCount = finalType?.find((item) => item === "semi-finals")
    ? "semi-finals"
    : null;
  const finalPoolCount = finalType?.find((item) => item === "finals")
    ? "finals"
    : null;
  const steps = ["Pools", "Schedule"];
  const { Option } = Select;

  const [scheduleData, setScheduleData] = useState([]);

  const numberOfRules =
    (Number(cmsData?.tab1Step2?.numberOfMatchesVsEachTeamInPool) *
      Number(cmsData?.tab1Step2?.numberOfTeamsPerPool) *
      Number(cmsData?.tab1Step2?.numberOfTeamsPerPool - 1)) /
    Number(2);

  const groupByKey = (list, key) =>
    list.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );
  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  const formatObject = (array) => {
    // console.log(array, "array--********************8888---array");

    const latestArray = [];
    array.map((i) => {
      // console.log('==================rowObject%%%%%%%%%%==================');
      // console.log(i);
      let obj;
      // rowObjects.map((i) => {
      // console.log(i, "********************");
      obj = {
        match_date: i?.find((p) => p.type === "date")?.value?.toString(),
        match_time: i?.find((p) => p.type === "time")?.value?.toString(),
        match_settings: [
          {
            key: "match_stage",
            value: i?.find((p) => p.tableconfig)?.tableconfig.toString(),
          },
          {
            key: "match_date",
            value: i?.find((p) => p.type === "date")?.value?.toString(),
          },
          {
            key: "match_time",
            value: i?.find((p) => p.type === "time")?.value?.toString(),
          },
          {
            key: "home_team",
            value: i?.find((p) => p.type === "home")?.value?.toString(),
          },
          {
            key: "away_team",
            value: i?.find((p) => p.type === "away")?.value?.toString(),
          },
          {
            key: "venue",
            value: i?.find((p) => p.type === "venue")?.value?.toString(),
          },
          {
            key: "scorer",
            value: i?.find((p) => p.type === "scorer")?.value?.toString(),
          },
          {
            key: "match_format",
            value: i?.find((p) => p.type === "format")?.value,
          },
        ],
      };
      setdetaileddata((t) => [...t, obj]);
      // })
      latestArray.push(obj);
    });
    // console.log('=======array============');
    // console.log(latestArray);
    // console.log('====================================');
    return latestArray;
  };

  const formatEditObject = (array) => {
    const latestArray = [];
    array.map((i) => {
      let obj;
      // console.log(i, "********************");
      // const matchdate = i?.find((p) => p.type === "date")?.value
      obj = {
        match_date: i?.find((p) => p.type === "date")?.value?.toString(),
        match_time: i?.find((p) => p.type === "time")?.value?.toString(),
        match_settings: [
          i?.find((p) => p.tableconfig)?.tableconfig && {
            key: "match_stage",
            value: i?.find((p) => p.tableconfig)?.tableconfig.toString(),
          },
          i?.find((p) => p.type === "date")?.value && {
            key: "match_date",
            value: i?.find((p) => p.type === "date")?.value?.toString(),
          },
          i?.find((p) => p.type === "time")?.value && {
            key: "match_time",
            value: i?.find((p) => p.type === "time")?.value?.toString(),
          },
          i?.find((p) => p.type === "home")?.value && {
            key: "home_team",
            value: i?.find((p) => p.type === "home")?.value?.toString(),
          },
          i?.find((p) => p.type === "away")?.value && {
            key: "away_team",
            value: i?.find((p) => p.type === "away")?.value?.toString(),
          },
          i?.find((p) => p.type === "venue")?.value && {
            key: "venue",
            value: i?.find((p) => p.type === "venue")?.value?.toString(),
          },
          i?.find((p) => p.type === "scorer")?.value && {
            key: "scorers",
            values: i?.find((p) => p.type === "scorer")?.value,
          },
          i?.find((p) => p.type === "format")?.value && {
            key: "match_format",
            value: i?.find((p) => p.type === "format")?.value,
          },
        ],
        id: i?.find((p) => p.id === p.id)?.id,
      };
      // setdetaileddata((t) => [...t, obj])
      // })

      const object = obj;
      // console.log(filteredArray,"777777777777777777777777777777777777777");
      Object.keys(obj).forEach((key) => {
        // console.log(obj, key, "1111111111111111111111111111111111111111111111111111");
        if (obj[key] === undefined) {
          delete obj[key];
        }

        if (key === "match_settings") {
          const matchste = obj[key];

          const results = matchste.filter((element) => {
            return element !== undefined;
          });

          obj[key] = results;
        }
      });
      latestArray.push(object);
    });
    return latestArray;
  };

  const handleSubmit = () => {
    const teamCountPPool = settingArr?.find(
      (elem) => elem?.key === "number_of_teams_per_Pool"
    )?.value?.[0];
    let rowData = groupByKey(scheduleData, "row");
    console.log("rowData", Object.values(rowData).flat(1));
    const newArray = Object.values(rowData).flat(1);
    for (let i = 0; i <= poolArr.length + 3; i++) {
      let pool = newArray.filter((item, index) => item.pool === i);
      let newObj = groupBy(pool, "row");
      console.log(pool, scheduleData, newArray, "testValues");
      let newObject;

      if (isEdit) {
        console.log("newObj", newObj);
        if (Object.keys(newObj).length >= 1) {
          newObject = formatEditObject(Object.values(newObj));
          newObject.map((data) => {
            callAPI(data);
            console.log("editMatchData", data);
          });
        }
      } else {
        if (Object.keys(newObj).length >= 1) {
          newObject = formatObject(Object.values(newObj));
          console.log(newObject, "newObject--------");

          newObject.map((data) => {
            callAPI(data);
            console.log("createMacthData", data);
          });
        }
      }
    }

    Object.values(newArray)?.forEach((i) => {
      // console.log("as", i, i?.find((p) => p.pool)?.pool);
      //  callAPI(obj);
    });
  };
  useEffect(() => {
    if (response?.data?.id) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully created",

        {
          appearance: "success",
        }
      );
      navigate("/cms-list");
      // dispatch(setCmsId(null));
      dispatch(setCmsId(response?.data?.id));
      !error && dispatch(setPoolId(null));
    } else if (error) {
      addToast(error?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  const saveScheduleData = async (
    pool,
    type,
    value,
    index,
    tabletype,
    tableconfig,
    id
  ) => {
    // if (!value) {
    //   return;
    // }
    const row = index;
    let reqObj = {
      pool,
      type,
      value,
      row: index,
      tabletype,
      tableconfig,
      id: id && id,
    };
    let dataDub = scheduleData;
    let orderedData;

    const sameItem = scheduleData?.find(
      (item) => item?.pool === 1 && item?.row === 1
    );
    console.log("=======", sameItem);

    console.log(reqObj, "reqObj----------------reqObj");

    const currentId = reqObj;
    setItemArr(currentId);
    console.log(scheduleData, "---------smt");
    for (let i = 0; i <= poolArr.length; i++) {
      if (pool === i) {
        orderedData = {
          pool: i,
          type: type,
        };
      }

      // console.log('====new data======');
      // console.log(orderedData);
      // console.log('====================================');
    }

    let alReadyExist =
      scheduleData?.find(
        (item) =>
          item?.pool === pool &&
          item?.type === type &&
          item?.row === row &&
          item?.tabletype === tabletype
      ) || null;
    console.log("=======", alReadyExist);
    if (alReadyExist) {
      let dataIndex = alReadyExist
        ? dataDub.findIndex(
            (item) =>
              item?.pool === pool &&
              item?.type === type &&
              item?.row === row &&
              item?.tabletype === tabletype
          )
        : "";
      dataDub[dataIndex] = reqObj;
    } else {
      await dataDub?.push(reqObj);
    }
    setScheduleData(dataDub);
  };

  console.log(detaileddata, "===================detaileddata");
  const handleClear = (e) => {
    console.log("=======cleared=====", e);
  };
  console.log(poolArr, "pollArr");

  console.log(morethanOnePools, "poolNum");

  function checkIsRequired(tableconfig) {
    const isRequired =
      tableconfig === "quarter-final" ||
      tableconfig === "semi-final" ||
      tableconfig === "final" ||
      morethanOnePools
        ? false
        : true;
    return isRequired;
  }

  function createCellName(tableconfig, key, index, title) {
    const name = `${
      (tableconfig === "quarter-final" && "qarter-final") ||
      (tableconfig === "semi-final" && "semi-final") ||
      (tableconfig === "final" && "final") ||
      "Pool"
    } ${key} Row ${index + 1} ${title} `;
    return name;
  }

  const column = (key, tabletype, tableconfig) => {
    return [
      {
        title: "Date",
        dataIndex: "date",
        render: (row, record, index, text) => {
          console.log(
            record,
            "record11111111111111111111111111111111111111111record"
          );
          return (
            <Form.Item
              name={createCellName(tableconfig, key, index, "Date")}
              initialValue={isEdit && record.date ? moment(record.date) : null}
              // rules={[
              //   {
              //     required:checkIsRequired(tableconfig),
              //     message: "Please enter a valid date",
              //   },
              // ]}
            >
              <DatePicker
                style={{ minWidth: 120 }}
                onBlur={(e) => console.log("event", e)}
                defaultValue={
                  isEdit && record.date ? moment(record.date) : null
                }
                disabledDate={(d) =>
                  !d ||
                  d.isBefore(
                    moment(cmsData?.tab1Step1?.startDate, "YYYY-MM-DD")
                  ) ||
                  d.isAfter(
                    moment(cmsData?.tab1Step1?.endDate, "YYYY-MM-DD").subtract(
                      -1,
                      "days"
                    )
                  )
                }
                allowClear={true}
                onClear={(e) =>
                  saveScheduleData(
                    key,
                    "date",
                    e,
                    index,
                    tabletype,
                    tableconfig
                  )
                }
                format="YYYY-MM-DD"
                onChange={(e) =>
                  saveScheduleData(
                    key,
                    "date",
                    e ? moment(e)?.format("YYYY-MM-DD") : null,
                    index,
                    tabletype,
                    tableconfig,
                    record && record.id
                  )
                }
              />
            </Form.Item>
          );
        },
      },
      {
        title: "Time",
        dataIndex: "time",
        render: (row, record, index) => (
          <Form.Item
            name={createCellName(tableconfig, key, index, "Time")}
            initialValue={
              isEdit && record.time ? moment(record.time, "HH:mm") : null
            }
            // rules={[
            //   {
            //     required:checkIsRequired(tableconfig),
            //     message: "Please enter a valid time",
            //   },
            // ]}
          >
            <TimePicker
              style={{ minWidth: 120 }}
              format="HH:mm"
              defaultValue={
                isEdit && record.time ? moment(record.time, "HH:mm") : null
              }
              onChange={(e) =>
                //x console.log("//////////",moment(e)?.format("HH:mm"))
                saveScheduleData(
                  key,
                  "time",
                  moment(e)?.format("HH:mm"),
                  index,
                  tabletype,
                  tableconfig,
                  record && record.id
                )
              }
              allowClear={true}
              onClear={(e) =>
                saveScheduleData(key, "time", e, index, tabletype, tableconfig)
              }
            />
          </Form.Item>
        ),
      },
      {
        title: "Home Team",
        dataIndex: "hometeam",
        render: (row, record, index) => (
          <Form.Item
            name={createCellName(tableconfig, key, index, "Home Team")}
            // onFieldsChange={handleFormChange}
            initialValue={isEdit ? record.hometeam : null}
            //  rules={[
            //   {
            //     required:checkIsRequired(tableconfig),
            //       message: "Please enter a valid Home team",
            //   },
            // ]}
          >
            <Select
              style={{ minWidth: 120 }}
              placeholder="Select Home Team"
              allowClear={true}
              onSelect={setHomeTeam()}
              defaultValue={isEdit ? record.hometeam : null}
              onClear={(e) =>
                saveScheduleData(
                  key,
                  "home",
                  e,
                  index,
                  tabletype,
                  tableconfig,
                  record && record.id
                )
              }
              // initialValue={}
              onChange={(e) =>
                saveScheduleData(
                  key,
                  "home",
                  e || undefined,
                  index,
                  tabletype,
                  tableconfig,
                  record && record.id
                )
              }
            >
              {/* {fetchResponsePool?.data
              ?.find((item) => item?.name == key)
              ?.teams?.map((e) => (
                <Option value={e?.teamId}>{e?.teamName}</Option>
              ))} */}
              {tableconfig === "quarter-final" ||
              tableconfig === "semi-final" ||
              tableconfig === "final"
                ? form.getFieldValue(
                    `${
                      (tableconfig === "quarter-final" && "qarter-final") ||
                      (tableconfig === "semi-final" && "semi-final") ||
                      (tableconfig === "final" && "final") ||
                      "Pool"
                    } ${key} Row ${index + 1} Away Team `
                  )
                  ? sortedAllTeamsData
                      ?.filter(
                        (x) =>
                          x?.teamId !=
                          form.getFieldValue(
                            `${
                              (tableconfig === "quarter-final" &&
                                "qarter-final") ||
                              (tableconfig === "semi-final" && "semi-final") ||
                              (tableconfig === "final" && "final") ||
                              "Pool"
                            } ${key} Row ${index + 1} Away Team `
                          )
                      )
                      .map((e) => (
                        <Option value={e?.teamId}>
                          {e?.teamName?.replace(/&#039;/g, "'")}
                        </Option>
                      ))
                  : sortedAllTeamsData.map((e) => (
                      <Option value={e?.teamId}>
                        {e?.teamName?.replace(/&#039;/g, "'")}
                      </Option>
                    ))
                : form.getFieldValue(
                    `${
                      (tableconfig === "quarter-final" && "qarter-final") ||
                      (tableconfig === "semi-final" && "semi-final") ||
                      (tableconfig === "final" && "final") ||
                      "Pool"
                    } ${key} Row ${index + 1} Away Team `
                  )
                ? fetchResponsePool?.data
                    .find((item) => item?.name == key)
                    ?.teams?.filter(
                      (x) =>
                        x?.teamId !=
                        form.getFieldValue(
                          `${
                            (tableconfig === "quarter-final" &&
                              "qarter-final") ||
                            (tableconfig === "semi-final" && "semi-final") ||
                            (tableconfig === "final" && "final") ||
                            "Pool"
                          } ${key} Row ${index + 1} Away Team `
                        )
                    )
                    .map((e) => (
                      <Option value={e?.teamId}>
                        {e?.teamName?.replace(/&#039;/g, "'")}
                      </Option>
                    ))
                : fetchResponsePool?.data
                    ?.find((item) => item?.name == key)
                    ?.teams?.map((e) => (
                      <Option value={e?.teamId}>
                        {e?.teamName?.replace(/&#039;/g, "'")}
                      </Option>
                    ))}
            </Select>
          </Form.Item>
        ),
      },
      {
        title: "Away Team",
        dataIndex: "awayteam",
        render: (row, record, index) => (
          <Form.Item
            name={createCellName(tableconfig, key, index, "Away Team")}
            initialValue={isEdit ? record.awayteam : null}
            // rules={[
            //   {
            //     required:checkIsRequired(tableconfig),
            //     message: "Please enter a valid away team",
            //   },
            //   {
            //     // validator:validateAwayTeam
            //   },
            //   // ({ getFieldValue }) => ({
            //   //   validator(_, value) {
            //   //     if (value || getFieldValue(index.toString()+key.toString()+"Home") === value) {
            //   //       return Promise.resolve();
            //   //     }
            //   //     return Promise.reject(new Error('Home and away team cannot be same!'));
            //   //   },
            //   // }),
            // ]}
          >
            <Select
              style={{ minWidth: 120 }}
              placeholder="Select Away Team"
              defaultValue={isEdit ? record.awayteam : null}
              onChange={(e) =>
                saveScheduleData(
                  key,
                  "away",
                  e,
                  index,
                  tabletype,
                  tableconfig,
                  record && record.id
                )
              }
              allowClear={true}
              onClear={(e) =>
                saveScheduleData(key, "away", e, index, tabletype, tableconfig)
              }
            >
              {tableconfig === "quarter-final" ||
              tableconfig === "semi-final" ||
              tableconfig === "final"
                ? form.getFieldValue(
                    `${
                      (tableconfig === "quarter-final" && "qarter-final") ||
                      (tableconfig === "semi-final" && "semi-final") ||
                      (tableconfig === "final" && "final") ||
                      "Pool"
                    } ${key} Row ${index + 1} Home Team `
                  )
                  ? sortedAllTeamsData
                      ?.filter(
                        (x) =>
                          x?.teamId !=
                          form.getFieldValue(
                            `${
                              (tableconfig === "quarter-final" &&
                                "qarter-final") ||
                              (tableconfig === "semi-final" && "semi-final") ||
                              (tableconfig === "final" && "final") ||
                              "Pool"
                            } ${key} Row ${index + 1} Home Team `
                          )
                      )
                      .map((e) => (
                        <Option value={e?.teamId}>
                          {e?.teamName?.replace(/&#039;/g, "'")}
                        </Option>
                      ))
                  : sortedAllTeamsData.map((e) => (
                      <Option value={e?.teamId}>
                        {e?.teamName?.replace(/&#039;/g, "'")}
                      </Option>
                    ))
                : form.getFieldValue(
                    `${
                      (tableconfig === "quarter-final" && "qarter-final") ||
                      (tableconfig === "semi-final" && "semi-final") ||
                      (tableconfig === "final" && "final") ||
                      "Pool"
                    } ${key} Row ${index + 1} Home Team `
                  )
                ? fetchResponsePool?.data
                    .find((item) => item?.name == key)
                    ?.teams?.filter(
                      (x) =>
                        x?.teamId !=
                        form.getFieldValue(
                          `${
                            (tableconfig === "quarter-final" &&
                              "qarter-final") ||
                            (tableconfig === "semi-final" && "semi-final") ||
                            (tableconfig === "final" && "final") ||
                            "Pool"
                          } ${key} Row ${index + 1} Home Team `
                        )
                    )
                    .map((e) => (
                      <Option value={e?.teamId}>
                        {e?.teamName?.replace(/&#039;/g, "'")}
                      </Option>
                    ))
                : fetchResponsePool?.data
                    ?.find((item) => item?.name == key)
                    ?.teams?.map((e) => (
                      <Option value={e?.teamId}>
                        {e?.teamName?.replace(/&#039;/g, "'")}
                      </Option>
                    ))}
            </Select>
          </Form.Item>
        ),
      },
      {
        // fetchResponsePool?.data
        //   ?.find((item) => item?.name == key)
        //   ?.teams?.filter((x) =>  x?.teamId != homeArr?.value)?.
        //fetchResponsePool?.data.teams?.filter((x) => x?.teamId != homeArr?.value) : fetchResponsePool?.data?.teams?

        title: "Venue",
        dataIndex: "venue",
        render: (row, record, index) => (
          <Form.Item
            name={createCellName(tableconfig, key, index, "Venue")}
            initialValue={isEdit ? record.venue : null}
            // rules={[
            //   {
            //     required:checkIsRequired(tableconfig),
            //     message: "Please enter a valid venue",
            //   },
            // ]}
          >
            <Select
              style={{ minWidth: 120 }}
              placeholder="Select Venue"
              defaultValue={isEdit ? record.venue : null}
              allowClear={true}
              onClear={(e) =>
                saveScheduleData(
                  key,
                  "venue",
                  e,
                  index,
                  tabletype,
                  tableconfig,
                  record && record.id
                )
              }
              onChange={(e) =>
                saveScheduleData(
                  key,
                  "venue",
                  e,
                  index,
                  tabletype,
                  tableconfig,
                  record && record.id
                )
              }
            >
              {getKeyValue(venueList)?.map((item) => (
                <Option value={item?.label} key={item?.label}>
                  {item?.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ),
      },
      {
        title: "Scorer",
        dataIndex: "scorer",
        render: (row, record, index) => {
          console.log(record, "scorer-----");
          return (
            <Form.Item
              name={createCellName(tableconfig, key, index, "Scorer")}
              initialValue={record?.scorer}
              // rules={[
              //   {
              //     required:checkIsRequired(tableconfig),
              //     message: "Please enter a valid scorer",
              //   },
              // ]}
            >
              <Select
                style={{ minWidth: 120 }}
                placeholder="Select Scorer"
                mode="multiple"
                allowClear={true}
                defaultValue={record?.scorer}
                onClear={(e) =>
                  saveScheduleData(
                    key,
                    "scorer",
                    e,
                    index,
                    tabletype,
                    tableconfig
                  )
                }
                onChange={(e) =>
                  saveScheduleData(
                    key,
                    "scorer",
                    e.map((number) => number.toString()),
                    index,
                    tabletype,
                    tableconfig,
                    record && record.id
                  )
                }
              >
                {scorerList?.map((item) => (
                  <Option key={item?.key} value={item?.id}>
                    {fixSpecialChar(item?.field_first_name)}{" "}
                    {fixSpecialChar(item?.field_last_name)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
        },
      },
      {
        title: "Format",
        dataIndex: "format",
        render: (row, record, index) => (
          <Form.Item
            name={createCellName(tableconfig, key, index, "Format")}
            initialValue={isEdit ? record.format : null}
            // rules={[
            //   {
            //     required:checkIsRequired(tableconfig),
            //     message: "Please enter a valid format",
            //   },
            // ]}
          >
            <Select
              placeholder="Select Format"
              defaultValue={isEdit ? record.format : null}
              onChange={(e) =>
                saveScheduleData(
                  key,
                  "format",
                  e,
                  index,
                  tabletype,
                  tableconfig,
                  record && record.id
                )
              }
              allowClear={true}
              onClear={(e) =>
                saveScheduleData(
                  key,
                  "format",
                  e,
                  index,
                  tabletype,
                  tableconfig
                )
              }
            >
              <Option value={matchFormat}>{matchFormat?.toUpperCase()}</Option>
            </Select>
          </Form.Item>
        ),
      },
    ];
  };

  console.log(fieldValue, "========check========");

  const tableDataItem = fetchResponsePool?.data?.map((item) => ({
    date: "",
    time: "",
    home: item?.teams?.map((item) => ({
      label: item?.teamName,
      value: item?.teamId,
    })),
    away: item?.teams?.map((item) => ({
      label: item?.teamId,
      value: item?.teamName,
    })),
    venue: [],
    scorer: [],
    format: [],
    ...item,
  }));
  //////////////////////////////////edit autofil////////////////
  console.log(
    fetchResponseschedulePool,
    "fetchResponseschedulePool====fetchResponseschedulePool"
  );

  let finalpoolDatas = [];
  let poolDatas = [];
  let semifinalpoolsData = [];
  let quarterfinalpoolsData = [];

  useEffect(() => {
    // setsheduleresponse(rowvalues && rowvalues)
    mainfunction();
  }, [fetchResponseschedulePool]);

  useEffect(() => {
    if (fetchingschedulePool) {
      setShowTable(false);
    } else if (fetchResponseschedulePool) {
      setShowTable(true);
    } else if (errschedulePool) {
      setShowTable(true);
    }
    // const timeout = setTimeout(() => {
    //   setShowTable(true);
    // }, 15000);

    // return () => clearTimeout(timeout);
  }, [fetchingschedulePool, fetchResponsePool, errschedulePool]);

  function getFilteredScorers(matchSettings) {
    const scorers = matchSettings
      .filter((obj) => obj.key === "scorers")
      .map((item) => parseInt(item.value));

    if (scorers.length > 0) {
      return scorers;
    } else {
      return matchSettings
        .filter((obj) => obj.key === "scorer")
        .map((item) => parseInt(item.value));
    }
  }

  const mainfunction = () => {
    const rowvalues =
      fetchResponseschedulePool && fetchResponseschedulePool.data;

    if (rowvalues !== null) {
      const keyofdata = Object.keys(rowvalues);
      keyofdata.map((item) => {
        if (item === "final") {
          rowvalues["final"].map((data) => {
            const obf = {
              date: data.match_date,
              time: data.match_time,
              hometeam: data.match_settings.find(
                (data) => data.key === "home_team"
              )?.value,
              awayteam: data.match_settings.find(
                (data) => data.key === "away_team"
              )?.value,
              venue: parseInt(
                data.match_settings.find((data) => data.key === "venue")?.value
              ),
              scorer: getFilteredScorers(data.match_settings),
              format: data.match_settings.find(
                (data) => data.key === "match_format"
              )?.value,
              id: data.id,
            };
            finalpoolDatas.push(obf);
          });
        } else if (item === "semi-final") {
          rowvalues[`${item}`].map((data) => {
            const obf = {
              date: data.match_date,
              time: data.match_time,
              hometeam: data.match_settings.find(
                (data) => data.key === "home_team"
              )?.value,
              awayteam: data.match_settings.find(
                (data) => data.key === "away_team"
              )?.value,
              venue: parseInt(
                data.match_settings.find((data) => data.key === "venue")?.value
              ),
              scorer: getFilteredScorers(data.match_settings),
              format: data.match_settings.find(
                (data) => data.key === "match_format"
              )?.value,
              id: data.id,
            };
            semifinalpoolsData.push(obf);
          });
        } else if (item === "quarter-final") {
          rowvalues["quarter-final"].map((data) => {
            const obf = {
              date: data.match_date,
              time: data.match_time,
              hometeam: data.match_settings.find(
                (data) => data.key === "home_team"
              )?.value,
              awayteam: data.match_settings.find(
                (data) => data.key === "away_team"
              )?.value,
              venue: parseInt(
                data.match_settings.find((data) => data.key === "venue")?.value
              ),
              scorer: getFilteredScorers(data.match_settings),
              format: data.match_settings.find(
                (data) => data.key === "match_format"
              )?.value,
              id: data.id,
            };
            quarterfinalpoolsData.push(obf);
          });
        } else {
          rowvalues[`${item}`].map((data) => {
            const objec = {
              date: data.match_date,
              time: data.match_time,
              hometeam: data.match_settings.find(
                (data) => data.key === "home_team"
              )?.value,
              awayteam: data.match_settings.find(
                (data) => data.key === "away_team"
              )?.value,
              venue: parseInt(
                data.match_settings.find((data) => data.key === "venue")?.value
              ),
              scorer: getFilteredScorers(data.match_settings),
              format: data.match_settings.find(
                (data) => data.key === "match_format"
              )?.value,
              pools: item,
              id: data.id,
            };
            poolDatas.push(objec);
          });
        }
      });
      seteverypoolsdata(poolDatas);
      setmainfinaldata(finalpoolDatas);
      setmainsemifinaldata(semifinalpoolsData);
      setmainquaterfinaldata(quarterfinalpoolsData);
    }
  };

  const Arrayfilter = (arrays, keys) => {
    const data = arrays.filter((obj) => {
      return obj.pools === keys;
    });

    const tableData1 = Array(numberOfRules).fill(tableDataItem?.[0]);
    const tableData = tableData1?.map((x, i) => ({ ...x, ...{ row: i + 1 } }));
    const tableDatalength = tableData.length;
    const filtereddatalength = data.length;

    if (tableDatalength > filtereddatalength) {
      const remaining = tableDatalength - filtereddatalength;

      for (let i = 0; i < remaining; i++) {
        data.push({ ...{ row: i + 1 } });
      }
    }

    return data;
  };

  console.log(
    fetchResponseschedulePool && fetchResponseschedulePool.data,
    "abcd------"
  );
  console.log(
    everypoolsdata,
    "poolDatas---66666666666666666666666666666666666---poolDatas"
  );
  console.log(
    semifinalpoolsData,
    "semifinalpoolsData===semifinalpoolsDatasemifinalpoolsDatasemifinalpoolsDatasemifinalpoolsData"
  );

  console.log(mainsheduleresponse, "mainsheduleresponse---mainsheduleresponse");
  console.log(mainsemifinaldata, "main semi 222222222222222222222222222222222");
  console.log(
    mainquaterfinaldata,
    "main quater3333333333333333333333333333333333"
  );
  console.log(mainfinaldata, "mainfinaldata1111555555555555555555555555555555");

  //////////////////////////////////edit autofil////////////////
  // const finalpoolDataslength = finalpoolDatas.length
  // const semifinalpoolsDatalength = semifinalpoolsData.length
  // const quarterfinalpoolsDatalength = quarterfinalpoolsData.length

  const finalpoolDataslength = mainfinaldata.length;
  const semifinalpoolsDatalength = mainsemifinaldata.length;
  const quarterfinalpoolsDatalength = mainquaterfinaldata.length;

  console.log(
    finalpoolDataslength,
    semifinalpoolsDatalength,
    quarterfinalpoolsDatalength,
    "lengthhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh"
  );
  // checking the filtered pools array length
  const poolslength = (key) => {
    const datalength = Arrayfilter(everypoolsdata, `${key + 1}`).length;
    console.log(datalength, "datalength");
    return datalength;
  };

  console.log(
    everypoolsdata,
    everypoolsdata.length,
    "finalpoolDataslength--finalpoolDataslength"
  );
  console.log(
    isEdit && finalpoolDataslength > 0 && !errschedulePool,
    "aaaaaaaaaaaaaaaaaaaaaaaa",
    isEdit
  );
  console.log(
    errschedulePool,
    "errschedulePool--errschedulePool",
    !errschedulePool
  );

  const tableData1 = Array(numberOfRules).fill(tableDataItem?.[0]);
  const tableDataQ = Array(4).fill(tableDataItem?.[0]);
  const tableDataS = Array(2).fill(tableDataItem?.[0]);
  const tableDataF = Array(1).fill(tableDataItem?.[0]);
  const tableData = tableData1?.map((x, i) => ({ ...x, ...{ row: i + 1 } }));
  console.log(tableDataQ, "rowwwwwwwwwwwwwwwwwwwwwwww----count");
  // console.log(numberOfRules, "numberOfRules==numberOfRules")
  // console.log(poolArr.length, "poolArr.length====poolArr.length");
  const tableDataQuarter = tableDataQ?.map((x, i) => ({
    ...x,
    ...{ row: i + 1 },
  }));
  const tableDataSemi = tableDataS?.map((x, i) => ({
    ...x,
    ...{ row: i + 1 },
  }));
  const tableDataFinal = tableDataF?.map((x, i) => ({
    ...x,
    ...{ row: i + 1 },
  }));
  const finalLengthQf = 4 - quarterfinalpoolsDatalength;
  const editDataQ = Array(finalLengthQf).fill(tableDataItem?.[0]);
  const editDataQarter = editDataQ?.map((x, i) => ({
    ...x,
    ...{ row: i + 1 },
  }));
  const qfEd = mainquaterfinaldata.concat(editDataQarter);
  console.log("qfddddddda", qfEd);

  const finalLengthSf = 2 - semifinalpoolsDatalength;
  const editDataSf = Array(finalLengthSf).fill(tableDataItem?.[0]);
  const editDataSemi = editDataSf?.map((x, i) => ({
    ...x,
    ...{ row: i + 1 },
  }));
  const sfEd = mainsemifinaldata.concat(editDataSemi);
  console.log("qfddddddda", sfEd);

  //   const finalLengthPool = numberOfRules - everypoolsdata.length
  //   const editDataP = Array(finalLengthPool).fill(tableDataItem?.[0])
  //   const editDataPool = editDataP?.map((x, i) => ({
  //     ...x,
  //     ...{ row: i + 1},

  //   }));
  // const poolEd =  everypoolsdata.concat(editDataPool)
  //   console.log("qfddddddda",poolEd)

  //console.log("111111",finalQfdata)

  // console.log("tableData", tableData);
  console.log(poolslength, showTable, isEdit, "emptypooltest");
  const finaldetaileddata = () => {
    if (isEdit && finalpoolDataslength > 0) {
      console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
      return finalpoolDatas;
    } else {
      console.log("ssssssssssssssssssssssssssssssssssssssss");
      return tableDataFinal;
    }
  };

  // useEffect(() => {
  //   setmainfinaldata(finalpoolDatas)
  // }, []);

  return !showTable ? (
    <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
      <Spin size="large" />
    </div>
  ) : (
    <div className="mt-3 pt-3 px-3">
      <CsaStepper steps={steps} activeKey={1} />
      {/* {fetching ? (
    <Spin />
   ) : ( */}

      {Array.from(Array(poolArr?.length).keys())?.map((el, i) => {
        console.log(
          poolslength(i),
          "poolslength(i)===poolslength(i)",
          poolslength(i) >= 1
        );
        return (
          <div className="mt-2 pt-2" key={i}>
            <p className={styles.name}>Pool {i + 1}:</p>
            <Form
              form={form}
              autoComplete="off"
              //  onValuesChange={onValuesChange}
              onFinish={(values) => {
                console.log("valuessssss", { values });
              }}
              onFinishFailed={(error) => {
                saveErr({ error });
                console.log("errorrrrrrr", { error });
              }}
            >
              <Table
                className="center-aligned"
                //   loading={loading}
                columns={column(i + 1, `pool${i + 1}`, i + 1)}
                dataSource={
                  isEdit && poolslength(i) >= 0
                    ? Arrayfilter(everypoolsdata, `${i + 1}`)
                    : tableData
                }
                pagination={false}
                key={i}
                scroll={{
                  x: 900,
                }}
              />
            </Form>
          </div>
        );
      })}
      {quarterPoolCount
        ? Array.from(Array(1).keys())?.map((el, i) => {
            return (
              <div className="mt-2 pt-2" key={i}>
                <p className={styles.name}>Quarter Final {i + 1}:</p>
                <Form
                  form={form}
                  autoComplete="off"
                  //  onValuesChange={onValuesChange}
                  onFinish={(values) => {
                    console.log("valuessssss", { values });
                  }}
                  onFinishFailed={(error) => {
                    saveErr({ error });
                    console.log("errorrrrrrr", { error });
                  }}
                >
                  {/* <Table
                //   loading={loading}
                columns={column(i + poolArr.length + 1, `quarter${i + 1}`, 'quarter-final')}
                dataSource={isEdit && (quarterfinalpoolsDatalength > 0) ? mainquaterfinaldata : tableDataQuarter}
                pagination={false}
                key={i}
              /> */}

                  {isEdit && quarterfinalpoolsDatalength > 0 && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 1,
                        `quarter${i + 1}`,
                        "quarter-final"
                      )}
                      dataSource={qfEd}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 600,
                      }}
                    />
                  )}

                  {isEdit && quarterfinalpoolsDatalength === 0 && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 1,
                        `quarter${i + 1}`,
                        "quarter-final"
                      )}
                      dataSource={tableDataQuarter}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 600,
                      }}
                    />
                  )}

                  {!isEdit && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 1,
                        `quarter${i + 1}`,
                        "quarter-final"
                      )}
                      dataSource={tableDataQuarter}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 600,
                      }}
                    />
                  )}
                </Form>
              </div>
            );
          })
        : []}
      {semiPoolCount
        ? Array.from(Array(1).keys())?.map((el, i) => {
            return (
              <div className="mt-2 pt-2" key={i}>
                <p className={styles.name}>Semi Final {i + 1}:</p>
                <Form
                  form={form}
                  autoComplete="off"
                  //  onValuesChange={onValuesChange}
                  onFinish={(values) => {
                    console.log("valuessssss", { values });
                  }}
                  onFinishFailed={(error) => {
                    saveErr({ error });
                    console.log("errorrrrrrr", { error });
                  }}
                >
                  {/* <Table
                //   loading={loading}
                columns={column(i + poolArr.length + 2, `semi${i + 1}`, 'semi-final')}
                dataSource={isEdit && (semifinalpoolsDatalength > 0) ? mainsemifinaldata : tableDataSemi}
                pagination={false}
                key={i}
              /> */}

                  {isEdit && semifinalpoolsDatalength > 0 && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 2,
                        `semi${i + 1}`,
                        "semi-final"
                      )}
                      dataSource={sfEd}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 900,
                      }}
                    />
                  )}

                  {isEdit && semifinalpoolsDatalength === 0 && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 2,
                        `semi${i + 1}`,
                        "semi-final"
                      )}
                      dataSource={tableDataSemi}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 900,
                      }}
                    />
                  )}
                  {!isEdit && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 2,
                        `semi${i + 1}`,
                        "semi-final"
                      )}
                      dataSource={tableDataSemi}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 900,
                      }}
                    />
                  )}
                </Form>
              </div>
            );
          })
        : []}
      {finalPoolCount
        ? Array.from(Array(1).keys())?.map((el, i) => {
            return (
              <div className="mt-2 pt-2" key={i}>
                <p className={styles.name}>Final {i + 1}:</p>
                <Form
                  form={form}
                  autoComplete="off"
                  //  onValuesChange={onValuesChange}
                  onFinish={(values) => {
                    console.log("valuessssss", { values });
                  }}
                  onFinishFailed={(error) => {
                    saveErr({ error });
                    console.log("errorrrrrrr", { error });
                  }}
                >
                  {/* <Table
                //   loading={loading}
                columns={column(i + poolArr.length + 3, `final${i + 1}`, 'final')}
                dataSource={(isEdit && (finalpoolDataslength > 0)) ? mainfinaldata : tableDataFinal}
                // dataSource={finaldetaileddata()}
                pagination={false}
                key={i}

              /> */}
                  {isEdit && finalpoolDataslength > 0 && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 3,
                        `final${i + 1}`,
                        "final"
                      )}
                      dataSource={mainfinaldata}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 900,
                      }}
                    />
                  )}
                  {isEdit && finalpoolDataslength === 0 && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 3,
                        `final${i + 1}`,
                        "final"
                      )}
                      dataSource={tableDataFinal}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 900,
                      }}
                    />
                  )}

                  {!isEdit && (
                    <Table
                      className="center-aligned"
                      //   loading={loading}
                      columns={column(
                        i + poolArr.length + 3,
                        `final${i + 1}`,
                        "final"
                      )}
                      dataSource={tableDataFinal}
                      pagination={false}
                      key={i}
                      scroll={{
                        x: 900,
                      }}
                    />
                  )}
                </Form>
              </div>
            );
          })
        : []}
      <div className="mt-4  d-flex w-100 justify-content-end">
        <CsaButton
          label="Back"
          textColor="#007237"
          btnColor="#FFCB03"
          onClick={() => {
            setInfo({ step1: info?.step1 });
            setStep(1);
          }}
        />
        <Form form={form}>
          <Form.Item>
            <CsaButton
              label="Save"
              textColor="#FFF"
              onClick={() => handleSubmit()}
              btnColor="#007237"
              htmlType="submit"
              disabled={scheduleData.length <= 0}
              // disabled={
              //   !form.isFieldsTouched(true) ||
              //   form.getFieldsError().filter(({ errors }) => errors.length)
              //     .length > 0
              // }
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>

      {/* )} */}
    </div>
  );
}

export default CMSSchedule;
