import React from "react";
import { useState } from "react";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styles from "./CsaMoreMenu.module.scss";

function CsaMoreMenu({ handleView, handleEdit }) {
  return (
    <div className={styles.CsaMoreMenuWrapper}>
      <Button
        onClick={() => handleView()}
        className={styles.menuMore}
        icon={<EyeOutlined />}
        style={{ backgroundImage: "linear-gradient(90deg, #004f27, #00793a)" }}
      />
      {handleEdit && (
        <Button
          onClick={() => handleEdit()}
          className={styles.menuMore}
          icon={<EditOutlined />}
          style={{ backgroundColor: "#ffcb05" }}
        />
      )}
    </div>
  );
}

export default CsaMoreMenu;
