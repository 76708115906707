import styles from "./CsaStepper.module.scss";
import React from "react";
import { Steps } from "antd";

function CsaStepper({ steps, activeKey }) {
 const { Step } = Steps;
 return (
  <div className={styles.CsaStepperWrapper}>
   <Steps
    current={activeKey}
    size={"small"}
    labelPlacement="vertical"
    progressDot
    // style={{ paddingLeft: "20%", paddingRight: "20%" }}
    className={styles.steps}
   >
    {steps.map((item) => (
     <Step key={item} title={item} />
    ))}
   </Steps>
  </div>
 );
}

export default CsaStepper;
