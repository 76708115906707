import React, { useEffect, useState } from "react";
import styles from "./Progress.module.scss";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import useAxios from "./../../utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { Tabs, Input, AutoComplete, Row, Col, Spin, Pagination } from "antd";
import ProgressMatchCard from "Common/ProgressMatchCard";
import { useDispatch, useSelector } from "react-redux";
import { setIsBack, setTournamentTab } from "redux/slice/commonSlice";
import errImg from "../../images/bat.png";
const Progress = ({ ...props }) => {
  const tabData = useSelector((state) => state.auth.tournamentTab);

  const [curTab, setCurTab] = useState();
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const { propsData } = useOutletContext();
  const { onchangecomponent, onKeyChange } = propsData;
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;

  const splitPath = path.split("/");
  const finalPath = splitPath[splitPath.length - 1];

  console.log(finalPath, path, "sss");

  useEffect(() => {
    if (finalPath === "tournaments" || path === "/tournaments/") {
      navigate(`in-progress`);
    }
  }, []);

  useEffect(() => {
    setCurTab(finalPath);
  }, [finalPath]);

  const onChangeTab = (newActiveKey) => {
    setSearchValue("");
    setCurTab(newActiveKey);
    dispatch(
      setTournamentTab({
        page: "2",
        tab: newActiveKey,
      })
    );
    navigate(newActiveKey);
  };

  const competitionData = {
    curTab,
    onchangecomponent,
    searchValue,
  };

  const mapTabs = [
    { label: "In Progress", key: "in-progress" },
    { label: "Future", key: "future" },
    { label: "Completed", key: "completed" },
  ];

  return (
    <>
      <div className={styles.TournamentsHeader}>
        <p className={styles.heading}>Tournaments / League / Series</p>
        <AutoComplete
          style={{
            flex: "0 0 auto",
            marginBottom: 10,
          }}
          onSearch={(e) => setSearchValue(e)}
        >
          <Input.Search
            size="large"
            placeholder="Search Tournaments/Leagues"
            allowClear
            enterButton
          />
        </AutoComplete>
      </div>
      <>
        <Tabs type="card" activeKey={curTab} onChange={onChangeTab}>
          {mapTabs?.map((item) => {
            return (
              <TabPane tab={item.label} key={item.key}>
                {curTab === item.key && (
                  <Outlet context={{ competitionData }} />
                )}
              </TabPane>
            );
          })}
        </Tabs>
        <div className=" d-flex w-100 justify-content-end mt-5"></div>
      </>
    </>
  );
};

export default Progress;
