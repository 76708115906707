import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: false,
  csa_level: null,
  csa_role: null,
  cms_data: null,
  tabCurrentState: 1,
  cms_id: null,
  user_img_url: null,
  user_img_fid: null,
  login_as_uid: null,
  match_details: null,
  isEdit: false,
  poolId: null,
  tournamentTab: null,
  scheduleData: "",
  isBack: false,
  openState: false,
  statsData: null,
  activeTabSchedule: "1",
  currentTime: parseInt(Date.now() / 1000),
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    isLogin: (state, data) => {
      state.login = data?.payload;
    },
    setCSALevel: (state, data) => {
      state.csa_level = data?.payload;
    },
    setCSARole: (state, data) => {
      state.csa_role = data?.payload;
    },
    setCMSData: (state, data) => {
      state.cms_data = data?.payload;
    },
    setCurrentTabState: (state, data) => {
      state.tabCurrentState = data.payload;
    },
    setCmsId: (state, data) => {
      state.cms_id = data.payload;
    },
    setUserImgUrl: (state, data) => {
      state.user_img_url = data.payload;
    },
    setUserImgFid: (state, data) => {
      state.user_img_fid = data.payload;
    },
    setLoginAsUid: (state, data) => {
      state.login_as_uid = data.payload;
    },
    setMatchDetails: (state, data) => {
      state.match_details = data.payload;
    },
    setIsEdit: (state, data) => {
      state.isEdit = data.payload;
    },
    setPoolId: (state, data) => {
      state.poolId = data.payload;
    },
    setTournamentTab: (state, data) => {
      state.tournamentTab = data.payload;
    },
    setScheduleData: (state, data) => {
      state.scheduleData = data.payload;
    },
    setIsBack: (state, data) => {
      state.isBack = data.payload;
    },
    setOpenState: (state, data) => {
      state.openState = data.payload;
    },
    setStatsData: (state, data) => {
      state.statsData = data.payload;
    },
    setActiveTabSchedule: (state, data) => {
      state.activeTabSchedule = data.payload;
    },
    setCurrentTime: (state, data) => {
      state.currentTime = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  isLogin,
  setCSALevel,
  setCSARole,
  setCMSData,
  setCurrentTabState,
  setCmsId,
  setUserImgUrl,
  setUserImgFid,
  setLoginAsUid,
  setMatchDetails,
  setIsEdit,
  setPoolId,
  setTournamentTab,
  setScheduleData,
  setIsBack,
  setOpenState,
  setStatsData,
  setActiveTabSchedule,
  setCurrentTime,
} = commonSlice.actions;

export default commonSlice.reducer;
