import React, { useEffect, useState } from "react";
import { Table } from "antd";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";

export default function TournamentBowlingStatsTable({ ...props }) {
  const { addToast } = useToasts();

  const { matchId } = props;
  
  const {
    callAPI: fetchStats,
    response: resStats,
    error: errStats,
    loading: loadStats,
  } = useAxios({
    url: serviceUrl.getTournamentStats,
    method: "get",
  });

  useEffect(() => {
    fetchStats(`${matchId}/bowling`, true);
  }, []);
  useEffect(() => {
    if (errStats) {
      addToast(errStats?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [errStats]);

  const res = resStats?.data || [];
  
  let pos = 1;

  const sort = res.sort(function (a, b) {
    let wk1 = a.bowl_wkts;
    let wk2 = b.bowl_wkts;

    let ec1 = a.bowl_econ;
    let ec2 = b.bowl_econ;

    let sr1 = a.bowl_sr;
    let sr2 = b.bowl_sr;

    let ov1 = a.bowl_overs;
    let ov2 = b.bowl_overs;

    if (wk1 > wk2) return -1;
    if (wk1 < wk2) return 1;

    if (ov1 !== 0 && ov2 === 0) return -1;
    if (ov1 === 0 && ov2 !== 0) return 1;

    if (ec1 < ec2) return -1;
    if (ec1 > ec2) return 1;

    // if (sr1 < sr2) return -1;
    // if (sr1 > sr2) return 1;

    return 0;
  });
  // 

  const resData = sort?.map((i) => ({
    position: pos++,
    name: i?.name + " " + i?.surname,
    team: i?.team,
    games: i?.games,
    bowl_wkts: i?.bowl_wkts,
    bowl_5_ws: i?.bowl_5_ws,
    bowl_dots: i?.bowl_dots,
    bowl_mdns: i?.bowl_mdns,
    bowl_overs: i?.bowl_overs.toFixed(1),
    bowl_runs: i?.bowl_runs,
    bb: i?.bb || 0,
    sr: i?.bowl_sr?.toFixed(1) || 0,
    bowl_econ: i?.bowl_econ?.toFixed(1) || 0,
  }));

  const columns = [
    {
      title: "Pos",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Player",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Team Name",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Matches",
      dataIndex: "games",
      key: "games",
    },
    {
      title: "Overs",
      dataIndex: "bowl_overs",
      key: "bowl_overs",
    },
    {
      title: "Maidens",
      dataIndex: "bowl_mdns",
      key: "bowl_mdns",
    },
    {
      title: "Dot Balls",
      dataIndex: "bowl_dots",
      key: "bowl_dots",
    },
    {
      title: "Runs",
      dataIndex: "bowl_runs",
      key: "bowl_runs",
    },
    {
      title: "Wickets",
      dataIndex: "bowl_wkts",
      key: "bowl_wkts",
    },
    {
      title: "5W",
      dataIndex: "bowl_5_ws",
      key: "bowl_5_ws",
    },
    {
      title: "Best",
      dataIndex: "bb",
      key: "bb",
    },
    {
      title: "Strike Rate",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Econ",
      dataIndex: "bowl_econ",
      key: "bowl_econ",
    },
  ];

  return (
    <>
      <Table
      className="center-aligned"
        loading={loadStats}
        columns={columns}
        dataSource={resData}
        pagination={false}
        scroll={{
          x: 800,
        }}
      />
    </>
  );
}
