import styles from "./CsaDropDown.module.scss";
import React from "react";
import { Select } from "antd";

function CsaDropDown({
  label,
  placeholder,
  menuArr,
  onChange,
  name,
  onBlur,
  error,
  required = false,
  defaultValue = null,
  value,
  touched,
  disabled,
  onClear,
  optionType = false,
  onScroll,
  loading,
  onSearch,
  onSelect,
}) {
  const { Option } = Select;
  return (
    <div className={styles.CsaDropDownWrapper}>
      <p className={styles.name}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </p>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder={placeholder}
        style={{ width: "100%" }}
        onChange={onChange}
        onClear={onClear}
        name={name}
        onBlur={onBlur}
        defaultValue={defaultValue ? defaultValue : null}
        value={value === "" ? null : value}
        disabled={disabled}
        allowClear
        onPopupScroll={onScroll}
        loading={loading}
        onSearch={onSearch}
        onSelect={onSelect}
      >
        {optionType
          ? menuArr?.map((opt) => (
              <Option value={opt?.value} key={opt?.value}>
                {opt?.label}
              </Option>
            ))
          : menuArr?.map((item) => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))}
      </Select>
      {error && touched && <pre className="text-danger">{error}</pre>}
    </div>
  );
}

export default CsaDropDown;
