import { Table } from "antd";
import React from "react";
import { useOutletContext } from "react-router-dom";

function TeamStatsTable() {
  const { playerData } = useOutletContext();
  
  const {
    loadingTeam,
    columnsTeams,
    isback,
    previousData,
    teamData,
    newTeamData,
  } = playerData;

  return (
    <>
      {" "}
      <Table
    //  className="center-aligned"
        loading={loadingTeam}
        columns={columnsTeams}
        dataSource={isback ? previousData.teamData : newTeamData}
        pagination={false}
        scroll={{
          x: 800,
        }}
      />
    </>
  );
}

export default TeamStatsTable;
