import React, { useState } from "react";
import { useLocation } from "react-router-dom";
//Components
import HighSchoolRegisterForm from "../../Common/HighSchoolRegisterForm";
import HighSchoolRegisterForm2 from "../../Common/HighSchoolRegisterForm/HighSchoolRegisterForm2";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./HighSchools.module.scss";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function HighSchools() {
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
  };
  const location = useLocation();
  const { isView = false, isEdit = false } = location?.state || {};
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "High Schools", value: "/high-school-list" },
    { key: "High School", value: "/register-high-school" },
  ];
  return (
    <Wrapper>
      <div className={styles.HighSchoolsWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} High School
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>
            {step === 1 && <HighSchoolRegisterForm {...props} />}
            {step === 2 && <HighSchoolRegisterForm2 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default HighSchools;
