import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";
//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./CompetitionManagement.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { findHightestLevel, fixSpecialChar } from "utils/helpers";
import { useDispatch } from "react-redux";
import { setCMSData, setCmsId, setPoolId } from "redux/slice/commonSlice";
import { useToasts } from "react-toast-notifications";

const CompetitionManagementTable = () => {
  const authRole = localStorage.getItem("selRole");
  const authLevel = localStorage.getItem("csa_level");
  const memberName = localStorage.getItem("csa_member_name");
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const cmsID = useSelector((state) => state.auth.cms_id);
  console.log(memberName, authLevel, "=====id========");
  const { addToast } = useToasts();

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Competition Managements", value: "/cms-list" },
  ];

  const columns = [
    {
      title: "Competition Name",
      dataIndex: "competitionName",
      key: "competitionName",
    },
    {
      title: "Competition Type",
      dataIndex: "competitionType",
      key: "competitionType",
    },
    {
      title: "Season Number",
      dataIndex: "seasonNumber",
      key: "seasonNumber",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Match Format",
      dataIndex: "matchFormat",
      key: "matchFormat",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => {
        switch (data) {
          case "scheduled":
            return "Scheduled";
          case "in_progress":
            return "In Progress";
          case "completed":
            return "Completed";
          default:
            return data;
        }
      },
    },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT"
      ? {
          title: "Action",
          key: "action",
          render: (data, record) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/cms-view", { state: { id: data?.id, type: "VIEW" } })
              }
              handleEdit={() =>
                record.status === "completed"
                  ? addToast("Unable to edit a completed competition", {
                      appearance: "warning",
                    })
                  : handleEdit(data)
              }
            />
          ),
          //  align: "center",
        }
      : {},
  ];

  const poolId = useSelector((state) => state.auth.poolId);
  console.log("pooooolId", poolId);

  const handleEdit = async (data) => {
    const compSettings = data?.competition_settings || null;
    const result = compSettings?.reduce(function (r, a) {
      r[a?.key] = r[a?.key] || [];
      r[a?.key].push(a);
      return r;
    }, Object.create(null));
    const settingArr = compSettings
      ? Object?.keys(result)?.map((item, i) => ({
          key: item,
          value: result[item]?.map((val) =>
            val?.value ? val?.value : val?.values
          ),
          data: result[item]?.map((val) => (val?.data ? val?.data : [])),
        }))
      : [];
    console.log("tableedit", data, settingArr);
    const levelCheck = data
      ? authLevel === "csa"
        ? settingArr?.find((item) => item?.key === "csa-levels")?.value?.[0]
        : settingArr?.find((item) => item?.key === "member-levels")?.value?.[0]
      : "";
    const reqData = {
      tab1Step1: {
        competitionName: data ? data?.competitionName : "",
        competitionType: data
          ? settingArr?.find((item) => item?.key === "competition_type")
              ?.value?.[0]
          : "",
        level: data
          ? authLevel === "csa"
            ? settingArr?.find((item) => item?.key === "csa-levels")?.value?.[0]
            : settingArr?.find((item) => item?.key === "member-levels")
                ?.value?.[0]
          : "",
        seasonNumber: data
          ? settingArr?.find((item) => item?.key === "season_number")
              ?.value?.[0]
          : "",
        league: data
          ? levelCheck === "club"
            ? settingArr?.find(
                (item) => item?.key === "competition_league_club"
              )?.value?.[0]
            : levelCheck === "primary-school" || levelCheck === "high-school"
            ? settingArr?.find(
                (item) => item?.key === "competition_league_school"
              )?.value?.[0]
            : settingArr?.find(
                (item) => item?.key === "competition_league_other"
              )?.value?.[0]
          : "",
        ageGroup: data
          ? settingArr?.find((item) => item?.key === "age_group")?.value?.[0]
          : "",
        gender: data
          ? settingArr?.find((item) => item?.key === "gender")?.value?.[0]
          : "",
        differentlyAbled: data
          ? settingArr?.find((item) => item?.key === "differently_abled")
              ?.value?.[0]
          : "None",
        startDate: data ? data?.start_date : "",
        endDate: data ? data?.end_date : "",
        competitionLogo: data
          ? settingArr?.find((item) => item?.key === "competition_logo")
              ?.value?.[0]
          : null,
        sponsorLogo: data
          ? settingArr?.find((item) => item?.key === "sponsor_logo")?.value?.[0]
          : null,
      },
      streamingLogos: {
        rtLogo:
          compSettings?.find((i) => i.key === "top_right_logo")?.value || null,
        spLogo1:
          compSettings?.find((i) => i.key === "sponsor_logo_1")?.value || null,
        spLogo2:
          compSettings?.find((i) => i.key === "sponsor_logo_2")?.value || null,
        spLogo3:
          compSettings?.find((i) => i.key === "sponsor_logo_3")?.value || null,
        spLogo4:
          compSettings?.find((i) => i.key === "sponsor_logo_4")?.value || null,
        spLogo5:
          compSettings?.find((i) => i.key === "sponsor_logo_5")?.value || null,
      },
      tab1Step2: {
        matchFormat: data
          ? settingArr?.find((item) => item?.key === "match_format")?.value?.[0]
          : "",
        numberOfPools: data
          ? settingArr?.find((item) => item?.key === "number_of_pools")
              ?.value?.[0]?.[0]
          : "",
        numberOfTeamsPerPool: data
          ? settingArr?.find((item) => item?.key === "number_of_teams_per_Pool")
              ?.value?.[0]
          : "",
        numberOfMatchesVsEachTeamInPool: data
          ? settingArr?.find(
              (item) => item?.key === "number_of_matches_vs_each_team_in_pool"
            )?.value?.[0]
          : "",
        inningsPerTeam: data
          ? settingArr?.find((item) => item?.key === "innings_per_team")
              ?.value?.[0]
          : "",
        oversPerInnings: data
          ? settingArr?.find((item) => item?.key === "num_overs_per_innings")
              ?.value?.[0]
          : "",
        recommendedBall: data
          ? settingArr?.find((item) => item?.key === "ball_colour")?.value?.[0]
          : "",
        scoringType: data
          ? settingArr
              ?.find((item) => item?.key === "scoring_type")
              ?.value?.map((p) => p)
          : "",
        finals: data
          ? settingArr
              ?.find((item) => item?.key === "finals")
              ?.value?.map((p) => p)
          : "",
        reserveDays: data
          ? settingArr
              ?.find((item) => item?.key === "reserve_day")
              ?.value?.map((p) => p)
          : "",
        analysisOptions: data
          ? settingArr
              ?.find((item) => item?.key === "analysis_options")
              ?.value?.map((p) => p)
          : "",
      },
      tab2Step1: {
        membersNameH: data
          ? settingArr
              ?.find((item) => item?.key === "cms_host_member_list")
              ?.value?.map((p) => p)
          : "",
        membersNameP: data
          ? settingArr
              ?.find((item) => item?.key === "cms_participating_member_List")
              ?.value?.map((p) => p)
          : "",
      },
      tab2Step2: {
        tableData: data
          ? settingArr
              ?.find((item) => item?.key === "team_list_CMS")
              ?.data?.map((p) => ({
                ...p,
                teamName: fixSpecialChar(p?.teamName),
              }))
          : [],
      },
      tab2Step3: {
        tableFieldData: data
          ? settingArr?.find((item) => item?.key === "venue_list")?.data
          : [],
      },
      tab3Step1: {
        tableData2: data
          ? settingArr?.find((item) => item?.key === "scorer_list_cms")?.data
          : [],
      },
      tab3Step2: {
        tableData2: data
          ? settingArr?.find((item) => item?.key === "umpire_cms_list")?.data
          : [],
      },
    };
    await dispatch(setCMSData(reqData));
    console.log("reqData", reqData);
    const finalsValues = settingArr
      ?.find((item) => item?.key === "finals")
      ?.value?.map((p) => p);

    navigate("/cms-edit", {
      state: { id: data?.id, type: "EDIT", editData: data },
    });
  };
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getCMSList,
    method: "get",
  });
  const {
    callAPI: searchAPI,
    response: searchRes,
    error: searchErr,
    loading: searchLoading,
  } = useAxios({
    url: serviceUrl.serachCMS,
    method: "get",
  });
  const creator = authLevel !== "csa" && memberName ? memberName : "csa";
  const data = searchRes
    ? searchRes?.data?.map((item) => {
        return {
          key: item?.id,
          competitionName: item?.name,
          competitionType: item?.competition_settings?.find(
            (elem) => elem?.key === "competition_type"
          )?.display_name,
          level:
            authLevel === "csa"
              ? item?.competition_settings?.find(
                  (elem) => elem?.key === "csa-levels"
                )?.display_name
              : item?.competition_settings?.find(
                  (elem) => elem?.key === "member-levels"
                )?.display_name || "",
          seasonNumber: item?.competition_settings?.find(
            (elem) => elem?.key === "season_number"
          )?.display_name,
          matchFormat: item?.competition_settings?.find(
            (elem) => elem?.key === "match_format"
          )?.display_name,
          status: item?.status,
          ...item,
        };
      })
    : response?.data
        // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
        ?.map((item) => {
          return {
            key: item?.id,
            competitionName: item?.name,
            competitionType: item?.competition_settings?.find(
              (elem) => elem?.key === "competition_type"
            )?.display_name,
            level:
              authLevel === "csa"
                ? item?.competition_settings?.find(
                    (elem) => elem?.key === "csa-levels"
                  )?.display_name
                : item?.competition_settings?.find(
                    (elem) => elem?.key === "member-levels"
                  )?.display_name || "",
            seasonNumber: item?.competition_settings?.find(
              (elem) => elem?.key === "season_number"
            )?.display_name,
            matchFormat: item?.competition_settings?.find(
              (elem) => elem?.key === "match_format"
            )?.display_name,
            status: item?.status,
            ...item,
          };
        });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCMSData(null));
    authRole === "CSA ADMINISTRATOR" || authRole === "CSA ADMIN ASSISTANT"
      ? callAPI(`?page=1&per_page=10&user_type=csa`, true)
      : callAPI(
          `?&created_by=${creator}&page=1&per_page=10&user_type=csa`,
          true
        );
    dispatch(setCmsId(null));
    dispatch(setPoolId(null));
  }, []);

  useEffect(() => {
    if (error) {
      console.log("error", error);
    }
    if (response) {
      console.log("response", response);
    }
  }, [error, response]);

  const handleSearch = (e) => {
    setSearchValue(e);
    authRole === "CSA ADMINISTRATOR" || authRole === "CSA ADMIN ASSISTANT"
      ? searchAPI(`?name=${e}&page=1&per_page=10&user_type=csa`, true)
      : searchAPI(`?name=${e}&created_by=${creator}&page=1&per_page=10`, true);
  };

  const onChange = (page) => {
    console.log(page, "page--page");
    searchRes
      ? authRole === "CSA ADMINISTRATOR" || authRole === "CSA ADMIN ASSISTANT"
        ? searchAPI(
            `?name=${searchValue}&page=1&per_page=10&user_type=csa`,
            true
          )
        : searchAPI(
            `?name=${searchValue}&created_by=${creator}&page=${page}&per_page=10`,
            true
          )
      : authRole === "CSA ADMINISTRATOR" || authRole === "CSA ADMIN ASSISTANT"
      ? callAPI(`?&page=${page}&per_page=10&user_type=csa`, true)
      : callAPI(
          `?created_by=${creator}&page=${page}&per_page=10&user_type=csa`,
          true
        );
  };

  return (
    <Wrapper>
      <div className={styles.CoachesWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Competition Management</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                options={options}
                // onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className=" d-flex w-100 justify-content-end">
                {authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT" ||
                authRole === "MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBERS ADMIN ASSISTANT" ? (
                  <CsaButton
                    label="Create"
                    textColor="#FFF"
                    onClick={() => navigate("/cms-create")}
                    btnColor="#007237"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2">
            <Table
              className="center-aligned"
              loading={searchRes ? searchLoading : loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 800,
              }}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={
                  searchRes
                    ? searchRes?.pagination?.total
                    : response?.pagination?.total
                }
                current={
                  searchRes
                    ? searchRes?.pagination?.page
                    : response?.pagination?.page
                }
                size={"small"}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CompetitionManagementTable;
