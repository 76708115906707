import styles from "./CsaCards.module.scss";
import React, { useState, useEffect } from "react";
import { Card, Row, Col, AutoComplete, Input, Spin, Pagination } from "antd";
import ScoreCard from "Common/ScoreCard";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "./../../utils/axios";
import moment from "moment";
import errImg from "../../images/bat.png";
import { useOutletContext } from "react-router-dom";
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  startAt,
  Firestore,
  onSnapshot,
} from "firebase/firestore";
import firebaseDb from "firebase";

function CsaCards({ hideTitle, ...props }) {
  //  const { onchangecomponent, activeTab } = props;
  const [inns, setCurInns] = useState([]);
  const [loadPage, setLoadPage] = useState(false);
  const [fResponse, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);
  const { propsData } = useOutletContext();
  const {
    onchangecomponent,
    activeTab,
    matchId,
    scoreId,
    skip,
    tName,
    banner,
  } = propsData;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const filteredRes = fResponse?.filter((i) => i?.innings?.length > 0);
  const currentPosts = filteredRes?.slice(indexOfFirstPost, indexOfLastPost);

  const collectionName = process.env.REACT_APP_F_MAIN_COLLECTION;

  const colRef = collection(firebaseDb, collectionName);

  const excludedStatuses = ["Finished"];
  const q = query(colRef, where("matchStatus", "not-in", excludedStatuses));

  useEffect(() => {
    setLoadPage(true);

    const unsubscribeMain = onSnapshot(q, (snapshot) => {
      const promises = snapshot.docs.map(async (doc) => {
        const inningsColRef = collection(doc.ref, "innings");
        const inningsQ = query(inningsColRef);

        const unsubscribeInnings = onSnapshot(inningsQ, (inningsSnapshot) => {
          let inningsData = [];

          inningsSnapshot.forEach((inningsDoc) => {
            inningsData.push({
              ...inningsDoc.data(),
              inningsId: inningsDoc.id,
            });
          });

          const matchData = {
            ...doc.data(),
            id: doc.id,
            innings: inningsData,
          };

          // Update state with the new match data
          setResponse((prevResponse) => {
            const index = prevResponse.findIndex(
              (item) => item.id === matchData.id
            );
            if (index !== -1) {
              // If the match already exists in state, update it
              const updatedResponse = [...prevResponse];
              updatedResponse[index] = matchData;
              return updatedResponse;
            } else {
              // If the match doesn't exist in state, add it
              return [...prevResponse, matchData];
            }
          });
        });

        return unsubscribeInnings;
      });

      Promise.all(promises).then(() => {
        setLoadPage(false);
        console.log("Data updated in real-time");
      });
    });

    return () => unsubscribeMain();
  }, []);

  return (
    <>
      {!hideTitle && (
        <div className={styles.CsaCardsHeader}>
          <p className={styles.heading}>Live Matches</p>
          {/* <AutoComplete
       style={{
        flex: "0 0 auto",
        marginBottom: 10,
       }}
       options={options}
       // onSelect={onSelect}
       onSearch={() => {}}
      >
       <Input.Search
        size="large"
        placeholder="Search..."
        allowClear
        enterButton
       />
      </AutoComplete> */}
        </div>
      )}
      <>
        <div className={styles.CsaCardsWrapper}>
          {loadPage ? (
            <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
              <Spin size="large" />
            </div>
          ) : currentPosts?.length === 0 ? (
            <div className={styles.errContainer}>
              <div className={styles.errImgContainer}>
                <img src={errImg} className={styles.errImg} />
                <p className={styles.errText}>
                  Sorry...There are no live matches at the moment.
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                gap: "20px",
              }}
            >
              {currentPosts?.map((item) => {
                return (
                  <ScoreCard
                    propsData={propsData}
                    onchangecomponent={onchangecomponent}
                    scoreData={item}
                    key={item?.id}
                  />
                );
              })}
            </div>
          )}
          <div className=" d-flex w-100 justify-content-end mt-5">
            {/* <CsaButton label="next" onClick={() => showNext({ item: fResponse[0] })} /> */}
            <Pagination
              onChange={(page) => setCurrentPage(page)}
              current={currentPage}
              total={filteredRes?.length}
              pageSize={12}
              size={"small"}
              // onShowSizeChanger={false}
              showSizeChanger={false}
            />
          </div>
        </div>
      </>
    </>
  );
}

export default CsaCards;
