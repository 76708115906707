import React, { useEffect, useState } from "react";
import { Checkbox, Col, Row, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDatePicker from "Common/CsaDatePicker";
import CsaDropDown from "Common/CsaDropDown";
import CsaFileUpload from "Common/CsaFileUpload";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
const CoachSchema = Yup.object().shape({
  level1: Yup.string().required("Select Level1"),
  level2: Yup.string().required("Select Level2"),
  level3: Yup.string().required("Select Level3"),
  level4: Yup.string().required("Select Level4"),
  safeguard: Yup.string().required("Select safeguard"),
  dateObtainedSafeguard: Yup.string(),

  status: Yup.string().required("Select Status"),
});

function CoachRegisterForm2({
  styles,
  setStep,
  setInfo,
  info,
  existingUserData,
  img,
  setImg,
}) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const membre_name = localStorage.getItem("csa_member_name");
  const sub_member_name = localStorage.getItem("sub_member_name");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const userExisting = info?.userExisting;
  // const user_level_existing =
  //   (authLevel === "sub-member" && sub_member_id) ||
  //   (authLevel === "member" && memberId) ||
  //   (authLevel === "csa" && uid);

  function findCert(key) {
  const item = info?.files?.find(item => item.key === key);
  return item ? item.url.toString() : undefined;
}
  
  console.log(userExisting, existingUserData, "sub");
  const steps = ["Personal Information", "Experience"];
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  const [uploadCert, setUploadCert] = useState([]);
  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editCoach : serviceUrl.setCoach,
    method: isEdit ? "put" : "post",
  });

  const {
    callAPI: fetchValidate,
    response: resValidate,
    error: errValidate,
    loading: loadValidate,
  } = useAxios({
    url: serviceUrl.validateHeadCoach,
    method: "get",
  });

  useEffect(() => {
    if (authLevel === "sub-member") {
      fetchValidate(sub_member_id, true);
    }
  }, []);

    useEffect(() => {
    if (info?.files) {
      setUploadCert(info?.files)
    }
  }, [info]);

  const hasCoachAssignedAlready = resValidate?.status;

  let level =
    authLevel === "member"
      ? "MEMBER"
      : authLevel === "csa"
      ? "CSA"
      : "SUB-MEMBER";
  const setLevel = (arr) => {
    if (info?.userExisting === true) {
      arr.push(level);
    }
    return arr;
  };

  const filesData = {
          certificateUploadl1:findCert("certificateUploadl1"),
          certificateUploadl2:findCert("certificateUploadl2") ,
          certificateUploadl3:findCert("certificateUploadl3"),
          certificateUploadl4:findCert("certificateUploadl4"),
          certificateUploadSafeguard:findCert("certificateUploadSafeguard") 
  }

  const data =
    isEdit || userExisting
      ? {
          level1: info?.step2?.level1 || info?.editData?.level1,
          dateObtainedl1: info?.step2?.dateObtainedl1 || info?.editData?.dateObtainedl1,
          certificateUploadl1:findCert("certificateUploadl1") ||info?.editData?.certificateUploadl1,
          level2:info?.step2?.level2 || info?.editData?.level2,
          dateObtainedl2:info?.step2?.dateObtainedl2 || info?.editData?.dateObtainedl2,
          certificateUploadl2:findCert("certificateUploadl2") || info?.editData?.certificateUploadl2,
          level3:info?.step2?.level3 || info?.editData?.level3,
          dateObtainedl3:info?.step2?.dateObtainedl3 || info?.editData?.dateObtainedl3,
          certificateUploadl3:findCert("certificateUploadl3") || info?.editData?.certificateUploadl3,
          level4:info?.step2?.level4 || info?.editData?.level4,
          dateObtainedl4:info?.step2?.dateObtained4 || info?.editData?.dateObtainedl4,
          certificateUploadl4:findCert("certificateUploadl4") || info?.editData?.certificateUploadl4,
          safeguard:info?.step2?.safeguard || info?.editData?.safeguard,
          dateObtainedSafeguard:info?.step2?.dateObtainedSafeguard || info?.editData?.dateObtainedSafeguard,
          certificateUploadSafeguard:findCert("certificateUploadSafeguard") ||
          info?.editData?.certificateUploadSafeguard,
          coachingForum: info?.editData?.coachingForum,
          hLCoached: info?.editData?.hLCoached,
          status: info?.editData?.status,
          headCoach: info?.editData?.head_coach === "1" ? true : false,
        }
      : {...info?.step2,...filesData}
      
      || {};

  console.log(info, "editData");
  const formik = useFormik({
    initialValues: {
      level1: data ? data?.level1 || "No" : "No",
      dateObtainedl1: data ? data?.dateObtainedl1 : "",
      certificateUploadl1: data ? data?.certificateUploadl1 : "",
      level2: data ? data?.level2 || "No" : "No",
      dateObtainedl2: data ? data?.dateObtainedl2 : "",
      certificateUploadl2: data ? data?.certificateUploadl2 : "",
      level3: data ? data?.level3 || "No" : "No",
      dateObtainedl3: data ? data?.dateObtainedl3 : "",
      certificateUploadl3: data ? data?.certificateUploadl3 : "",
      level4: data ? data?.level4 || "No" : "No",
      dateObtainedl4: data ? data?.dateObtainedl4 : "",
      certificateUploadl4: data ? data?.certificateUploadl4 : "",
      safeguard: data ? data?.safeguard || "No" : "No",
      dateObtainedSafeguard: data ? data?.dateObtainedSafeguard : "",
      certificateUploadSafeguard: data ? data?.certificateUploadSafeguard : "",
      coachingForum: data ? data?.coachingForum || "No" : "No",
      hLCoached: data ? data?.hLCoached : "",
      status: data ? data?.status : "",
      headCoach: data?.headCoach,
    },
    validationSchema: CoachSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.level1 === "Yes" && !values.dateObtainedl1) {
        addToast("Please select Date against level1", {
          appearance: "warning",
        });
      } else if (values.level2 === "Yes" && !values.dateObtainedl2) {
        addToast("Please select Date against level2", {
          appearance: "warning",
        });
      } else if (values.level3 === "Yes" && !values.dateObtainedl3) {
        addToast("Please select Date against level3", {
          appearance: "warning",
        });
      } else if (values.level4 === "Yes" && !values.dateObtainedl4) {
        addToast("Please select Date against level4", {
          appearance: "warning",
        });
      } else if (values.safeguard === "Yes" && !values.dateObtainedSafeguard) {
        addToast("Please select Date against safeguard", {
          appearance: "warning",
        });
      } else {
        const step2Files = {
          level1: values.level1 === "Yes" ? true : false,
          level1_date: values.dateObtainedl1,
          level1_file:
            uploadCert
              ?.find((item) => item?.key === "certificateUploadl1")
              ?.value?.toString() || info?.editData?.cFId1?.toString(),
          level1_file_url:
            uploadCert?.find((item) => item?.key === "certificateUploadl1")
              ?.url || info?.editData?.certificateUploadl1,
          level2: values.level2 === "Yes" ? true : false,
          level2_date: values.dateObtainedl2,
          level2_file:
            uploadCert
              ?.find((item) => item?.key === "certificateUploadl2")
              ?.value?.toString() || info?.editData?.cFId2?.toString(),
          level2_file_url:
            uploadCert?.find((item) => item?.key === "certificateUploadl2")
              ?.url || info?.editData?.certificateUploadl2,
          level3: values.level3 === "Yes" ? true : false,
          level3_date: values.dateObtainedl3,
          level3_file:
            uploadCert
              ?.find((item) => item?.key === "certificateUploadl3")
              ?.value?.toString() || info?.editData?.cFId3?.toString(),
          level3_file_url:
            uploadCert?.find((item) => item?.key === "certificateUploadl3")
              ?.url || info?.editData?.certificateUploadl3,
          level4: values.level4 === "Yes" ? true : false,
          level4_date: values.dateObtainedl4,
          level4_file:
            uploadCert
              ?.find((item) => item?.key === "certificateUploadl4")
              ?.value?.toString() || info?.editData?.cFId4?.toString(),
          level4_file_url:
            uploadCert?.find((item) => item?.key === "certificateUploadl4")
              ?.url || info?.editData?.certificateUploadl4,
          safeguard: values.safeguard === "Yes" ? true : false,
          safeguard_date: values.dateObtainedSafeguard,
          safeguard_file:
            uploadCert
              ?.find((item) => item?.key === "certificateUploadSafeguard")
              ?.value?.toString() || info?.editData?.safeguardFId?.toString(),
          safeguard_file_url:
            uploadCert?.find(
              (item) => item?.key === "certificateUploadSafeguard"
            )?.url || info?.editData?.certificateUploadSafeguard,
        }
        setInfo({ step1: info?.step1, step2: values,...step2Files });
        const obj = {
          email_address: info?.step1?.emailAddress,
          nationality: info?.step1?.nationality,
          id_number: info?.step1?.iDNumber?.toString() || "",
          passport_number: info?.step1?.passportNumber?.toString() || "",
          contact_number: info?.step1?.contactNumber?.toString() || "",
          first_name: info?.step1?.firstName,
          last_name: info?.step1?.lastName,
          dob: info?.step1?.dateofBirth,
          race: info?.step1?.race,
          gender: info?.step1?.gender,
          differently_abled: info?.step1?.differentlyAbled,
          highest_level: values.hLCoached,
          coaching_forum: values.coachingForum === "Yes" ? true : false,
          status: values?.status === "Active" ? true : false || false,
          fid: info?.file,
          ...step2Files,
          user_level_existing:
            // info?.userExisting === true
            //   ? info?.existingUserData?.user_level
            //   :
            isEdit ? info?.editData?.user_level : level,
          user_level:
            // info?.userExisting === true
            //   ? info?.existingUserData?.user_level
            //   :
            isEdit ? info?.editData?.user_level : level,
          roles:
            info?.userExisting === true ? ["coach"] : isEdit ? null : ["coach"],
          member_id: level === "CSA" ? "" : memberId,
          uid: uid,
          member_name: membre_name,
          ...(authLevel === "sub-member" && {
            sub_member_id: sub_member_id,
            sub_member_name: sub_member_name,
            headCoach: values.headCoach,
          }),
        };

        callAPI(
          id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
        );
      }
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/coach-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  const checkValidity = () => {
    const { level1, level2, level3, level4, safeguard, status } = values;
    const isVAlid =
      level1 &&
      level2 &&
      level3 &&
      level4 &&
      safeguard &&
      status &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  console.log(values, info,uploadCert, "vals");

  const handleRemoveFile  =(f)=> {
console.log(f,"fff")
  }

  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Level 1"
            placeholder="Select Level1"
            name="level1"
            value={values.level1}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("level1", e);
            }}
            required
            defaultValue={values.level1 || "No"}
            onBlur={handleBlur}
            touched={touched.level1}
            error={errors.level1 || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedl1"
            setFieldValue={setFieldValue}
            value={values.dateObtainedl1}
            onBlur={handleBlur}
            touched={touched.dateObtainedl1}
            required={values?.level1 === "Yes" ? true : false}
            disabled={values?.level1 === "No" ? true : false}
            error={errors.dateObtainedl1 || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            onRemove={(f) => console.log(f)}
            label="Certificate Upload"
            type="file"
            name="certificateUploadl1"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadl1}
            disabled={values?.level1 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Level 2"
            placeholder="Select Level2"
            name="level2"
            value={values.level2}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("level2", e);
            }}
            required
            defaultValue={values.level2 || "No"}
            onBlur={handleBlur}
            touched={touched.level2}
            error={errors.level2 || null}
          />
        </Col>
        <>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <CsaDatePicker
              label="Date Obtained"
              placeholder="Select Date Obtained"
              name="dateObtainedl2"
              setFieldValue={setFieldValue}
              value={values.dateObtainedl2}
              onBlur={handleBlur}
              touched={touched.dateObtainedl2}
              error={errors.dateObtainedl2 || null}
              required={values?.level2 === "Yes" ? true : false}
              disabled={values?.level2 === "No" ? true : false}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <CsaFileUpload
              label="Certificate Upload"
              type="file"
              name="certificateUploadl2"
              setFile={setUploadCert}
              files={uploadCert}
              editFile={data?.certificateUploadl2}
              disabled={values?.level2 === "No" ? true : false}
            />
          </Col>
        </>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Level 3"
            placeholder="Select Level3"
            name="level3"
            value={values.level3}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("level3", e);
            }}
            required
            defaultValue={values.level3 || "No"}
            onBlur={handleBlur}
            touched={touched.level3}
            error={errors.level3 || null}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedl3"
            setFieldValue={setFieldValue}
            value={values.dateObtainedl3}
            onBlur={handleBlur}
            touched={touched.dateObtainedl3}
            error={errors.dateObtainedl3 || null}
            required={values?.level3 === "Yes" ? true : false}
            disabled={values?.level3 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate Upload"
            type="file"
            name="certificateUploadl3"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadl3}
            disabled={values?.level3 === "No" ? true : false}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Level 4"
            placeholder="Select Level4"
            name="level4"
            value={values.level4}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("level4", e);
            }}
            required
            defaultValue={values.level4 || "No"}
            onBlur={handleBlur}
            touched={touched.level4}
            error={errors.level4 || null}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedl4"
            setFieldValue={setFieldValue}
            value={values.dateObtainedl4}
            onBlur={handleBlur}
            touched={touched.dateObtainedl4}
            error={errors.dateObtainedl4 || null}
            required={values?.level4 === "Yes" ? true : false}
            disabled={values?.level4 === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate Upload"
            type="file"
            name="certificateUploadl4"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadl4}
            disabled={values?.level4 === "No" ? true : false}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Safeguard"
            placeholder="Select safeguard"
            name="safeguard"
            required
            menuArr={["Yes", "No"]}
            value={values.safeguard}
            onChange={(e) => {
              setFieldValue("safeguard", e);
            }}
            defaultValue={values.safeguard || "No"}
            onBlur={handleBlur}
            touched={touched.safeguard}
            error={errors.safeguard || null}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDatePicker
            label="Date Obtained"
            placeholder="Select Date Obtained"
            name="dateObtainedSafeguard"
            setFieldValue={setFieldValue}
            value={values.dateObtainedSafeguard}
            onBlur={handleBlur}
            touched={touched.dateObtainedSafeguard}
            error={errors.dateObtainedSafeguard || null}
            required={values?.safeguard === "Yes" ? true : false}
            disabled={values?.safeguard === "No" ? true : false}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaFileUpload
            label="Certificate Upload"
            type="file"
            name="certificateUploadSafeguard"
            setFile={setUploadCert}
            files={uploadCert}
            editFile={data?.certificateUploadSafeguard}
            disabled={values?.safeguard === "No" ? true : false}
          />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Coaching Forum"
            placeholder="Select Coaching Forum"
            name="coachingForum"
            menuArr={["Yes", "No"]}
            required
            value={values.coachingForum}
            onChange={(e) => {
              setFieldValue("coachingForum", e);
            }}
            defaultValue={values.coachingForum || "No"}
            onBlur={() => setFieldTouched("coachingForum", true)}
            touched={touched.coachingForum}
            error={errors.coachingForum || null}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CsaDropDown
            label="Highest Level Coached"
            placeholder="Select Highest Level Coached"
            name="hLCoached"
            menuArr={masterData.highestLevelOfficiatedList}
            value={values.hLCoached}
            onChange={(e) => {
              setFieldValue("hLCoached", e);
            }}
            onBlur={handleBlur}
            touched={touched.hLCoached}
            error={errors.hLCoached || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Status"
            placeholder="Select Status"
            required
            name="status"
            value={values.status}
            menuArr={masterData.statusList}
            onChange={(e) => {
              setFieldValue("status", e);
            }}
            onBlur={() => setFieldTouched("status", true)}
            touched={touched.status}
            error={errors.status || null}
          />
        </Col>
        {authLevel === "sub-member" && (
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {loadValidate ? (
              <Spin />
            ) : (
              <>
                <p
                  style={{
                    gap: 5,
                    color: "#000000",
                    fontSize: "15px",
                    // backgroundColor: "green",
                  }}
                >
                  Head Coach:
                </p>
                <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                  <Checkbox
                    disabled={
                      !data.headCoach && resValidate?.status === "success"
                    }
                    name="headCoach"
                    value={values.headCoach}
                    checked={values.headCoach}
                    onChange={(e) =>
                      setFieldValue("headCoach", e.target.checked)
                    }
                  />
                  <p
                    style={{
                      color: "#000000",
                      fontSize: "15px",
                    }}
                  >
                    Note: Only one head coach allowed
                  </p>
                </div>
              </>
            )}
          </Col>
        )}
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({ step1: info?.step1, step2: values,files:uploadCert,file:info?.file });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={checkValidity()}
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default CoachRegisterForm2;
