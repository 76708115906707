import { Card, Col, Row, Avatar, Image, Table } from "antd";
import React from "react";

export default function CoachView(props) {
  const { viewData } = props;
  const columns = [
    {
      title: "Level ",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "Obtained",
      dataIndex: "level",
      key: "level",
      align: "center",
    },
    {
      title: "Date Obtained",
      dataIndex: "dateObtained",
      key: "dateObtained",
      align: "center",
    },
    {
      title: "Certificate ",
      key: "certificate",
      render: (data) =>
        data?.certificate === "N/A" ? (
          "N/A"
        ) : (
          <a href={data?.certificate} target="_blank">
            certificate
          </a>
        ),
      align: "center",
    },
  ];
  const data = [
    {
      key: "1",
      title: "Level 1",
      level: viewData?.level1,
      dateObtained:
        viewData?.level1 === "Yes" ? viewData?.dateObtainedl1 : "N/A",
      certificate: viewData?.certificateUploadl1
        ? viewData?.certificateUploadl1
        : "N/A",
    },
    {
      key: "2",
      title: "Level 2",
      level: viewData?.level2,
      dateObtained:
        viewData?.level2 === "Yes" ? viewData?.dateObtainedl2 : "N/A",
      certificate: viewData?.certificateUploadl2
        ? viewData?.certificateUploadl2
        : "N/A",
    },
    {
      key: "3",
      title: "Level 3",
      level: viewData?.level3,
      dateObtained:
        viewData?.level3 === "Yes" ? viewData?.dateObtainedl3 : "N/A",
      certificate: viewData?.certificateUploadl3
        ? viewData?.certificateUploadl3
        : "N/A",
    },
    {
      key: "4",
      title: "Level 4",
      level: viewData?.level4,
      dateObtained:
        viewData?.level4 === "Yes" ? viewData?.dateObtainedl4 : "N/A",
      certificate: viewData?.certificateUploadl4
        ? viewData?.certificateUploadl4
        : "N/A",
    },
    {
      key: "5",
      title: "Safeguard",
      level: viewData?.safeguard,
      dateObtained:
        viewData?.safeguard === "Yes" ? viewData?.dateObtainedSafeguard : "N/A",
      certificate: viewData?.certificateUploadSafeguard
        ? viewData?.certificateUploadSafeguard
        : "N/A",
    },
  ];
  return (
    <Card
      style={{
        width: "100%",
        backgroundColor: "#f1f1f1",
        justifyContent: "center",
        borderRadius: 15,
        borderWidth: 0.5,
        borderColor: "#f3f3f3",
      }}
    >
      <Row>
        {viewData && (
          <>
            <Col span={24} className="w-100 mb-3 d-flex justify-content-center">
              {viewData?.profile ? (
                <Avatar
                  size={150}
                  src={
                    <Image
                      src={viewData?.profile}
                      style={{
                        width: 150,
                        height: 150,
                      }}
                    />
                  }
                />
              ) : (
                <Avatar size={150}>CSA </Avatar>
              )}
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Nationality</h6>
              <p>{viewData?.nationality}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">ID Number</h6>
              <p>{viewData?.iDNumber || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Passport No.</h6>
              <p>{viewData?.passportNumber || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Email Address</h6>
              <p>{viewData?.emailAddress || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">First Name</h6>
              <p>{viewData?.firstName.replace(/&#039;/g, "'") || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Last Name</h6>
              <p>{viewData?.lastName.replace(/&#039;/g, "'") || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Contact No.</h6>
              <p>{viewData?.contactNumber || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Date of Birth</h6>
              <p>{viewData?.dateofBirth || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Race</h6>
              <p>{viewData?.race || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Gender</h6>
              <p>{viewData?.gender || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Differently Abled</h6>
              <p>{viewData?.differentlyAbled || "N/A"}</p>
            </Col>

            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Coaching Forum</h6>
              <p>{viewData?.coachingForum || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Highest Level Coached</h6>
              <p>{viewData?.hLCoached || "N/A"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Status</h6>
              <p>
                {viewData?.status === "Active"
                  ? "Activated"
                  : "Disabled" || "N/A"}
              </p>
            </Col>
          </>
        )}
      </Row>
      <h6 className="mb-1">Level:</h6>
      <div className="mt-2 pt-2">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </Card>
  );
}
