/* eslint-disable no-unused-expressions */
import styles from "./LoginAs.module.scss";
import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useFormik } from "formik";
import * as Yup from "yup";
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import { Col, Row, AutoComplete, Input, Select } from "antd";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import { getLevels } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DownCircleOutlined, DownOutlined } from "@ant-design/icons";
import {
  setCSALevel,
  setCSARole,
  setUserImgFid,
  setUserImgUrl,
  setLoginAsUid,
} from "redux/slice/commonSlice";
import Logo from "../../images/CSA_Green_Logo.jpg";
import * as masterData from "../../utils/commonMasterData";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";

const MembersSchema = Yup.object().shape({
  membersName: Yup.string().required("Select Member Name"),
});
const SubMembersSchema = Yup.object().shape({
  membersName: Yup.string().required("Select Member Name"),
  subMember: Yup.string().required("Select Sub Member Type"),
  subMemberName: Yup.string().required("Select Sub Member Name"),
});
function LoginAs() {
  const dispatch = useDispatch();
  const userLevel = useSelector((state) => state.auth.csa_level);
  let authRole = useSelector((state) => state.auth.csa_role)
    ?.split(" ")
    .map(
      ([firstChar, ...rest]) =>
        firstChar.toUpperCase() + rest.join("").toLowerCase()
    )
    .join(" ")
    .replace(/Csa/g, "CSA");

  let role;

  // authRoles === "Csa Administrator" && authRole = "CSA Administrator" ||
  //  authRoles === "Csa Admin Assistant" && authRole = "CSA Admin Assistant" ||
  //  authRoles === "Csa Coaches Manager" && authRole = "CSA Coaches Manager" ||
  //  authRoles === "Csa Umpires Manager" && authRole = "CSA Umpires Manager" ||
  //  authRoles === "Csa Match Referee Manager" && authRole = "CSA Match Referee Manager" ||
  //  authRoles === "Csa View Only" && authRole ="CSA View Only"

  const memberId = localStorage.getItem("csa_member_id");
  const memberName = localStorage.getItem("csa_member_name");
  const subMemberId = localStorage.getItem("sub_member_id");
  const sMemberType = localStorage.getItem("sub_member_type");
  const userLevels = localStorage.getItem("user_level");
  const canChangeMember = JSON.parse(userLevels)?.includes("CSA");
  console.log(canChangeMember, "ullls");

  const loginAsUid = useSelector((state) => state.auth.login_as_uid) || null;
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Login As", value: "/login-as" },
  ];
  const [options, setOptions] = useState("");
  const [isPagination, setIsPagination] = useState(false);
  const [subMemberValue, setSubmemberValue] = useState("");
  const [resData, setResData] = useState([]);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [level, setLevel] = useState(userLevel);
  const [selRole, setRole] = useState(authRole);
  const [sMemberId, setSMemberID] = useState(memberId);
  const [subMemberInfo, setSubMemberInfo] = useState({ key: subMemberId });
  const [imageResponse, setImageResponse] = useState(null);
  const [imageFetchLevel, setImageFetchLevel] = useState(null);
  const [profileImageRes, setProfileImageRes] = useState(null);

  const [selMemberName, setMemberName] = useState(memberName);
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getMemberMasterData,
    method: "get",
  });
  const {
    callAPI: success,
    response: successResponse,
    error: err,
    loading: successLoading,
  } = useAxios({
    url: serviceUrl.getProfile,
    method: "get",
  });
  const {
    callAPI: fetch,
    response: fetchResponse,
    error: fetchErr,
    loading: fetchLoading,
  } = useAxios({
    url: serviceUrl.getUserImage + subMemberId,
    method: "get",
  });
  const {
    callAPI: fetchImg,
    response: fetchResponseImg,
    error: fetchErrImg,
    loading: fetchLoadingImg,
  } = useAxios({
    url:
      serviceUrl.getUserImage +
      selRole?.split(" ")?.join("_")?.trimStart()?.toLowerCase() +
      `?member_id=${sMemberId}`,
    method: "get",
  });

  const {
    callAPI: getUserLogo,
    response: resUserLogo,
    error: errUserLogo,
    loading: loadUserLogo,
  } = useAxios({
    url: serviceUrl.getLogos,
    method: "get",
  });

  const {
    callAPI: fetchImgCSA,
    response: fetchResponseImgCSA,
    error: fetchErrImgCSA,
    loading: fetchLoadingImgCSA,
  } = useAxios({
    url:
      serviceUrl.getUserImage +
      selRole?.split(" ")?.join("_")?.trimStart()?.toLowerCase(),
    method: "get",
  });
  const {
    callAPI: FetchAPISubMember,
    response: fetchResponseSubMember,
    error: fetchErrSubMember,
    loading: fetchLoadSubMember,
  } = useAxios({
    url: serviceUrl.getCMSSubMemberList,
    method: "get",
    query: true,
  });
  useEffect(() => {
    callAPI();
    success();
  }, []);

  useEffect(() => {
    if (fetchErrSubMember?.message) {
      addToast(
        fetchErrSubMember?.message ||
          "Something Went wrong while fetching Sub Member data",
        { appearance: "error" }
      );
    }
  }, [fetchErrSubMember]);

  const foundMemberCond =
    getLevels().find((element) => element.value === "CSA") &&
    level?.toUpperCase() == "MEMBER";

  let str = "john";
  str = str
    .split(" ")
    .map(
      ([firstChar, ...rest]) =>
        firstChar?.toUpperCase() + rest.join("").toLowerCase()
    )
    .join(" ");

  const curtSelRoles = JSON.parse(localStorage.getItem("roles"));
  const curtSelRole = localStorage.getItem("selRole");

  const curHighLevel =
    (JSON.parse(localStorage.getItem("user_level")).includes("CSA") && "csa") ||
    (JSON.parse(localStorage.getItem("user_level")).includes("MEMBER") &&
      "member") ||
    (JSON.parse(localStorage.getItem("user_level")).includes("SUB-MEMBER") &&
      "sub-member");

  const filteredList =
    (selRole === "Members Primary School Coordinator" &&
      masterData.submemberList.filter((x) => x.value === "primary_schools")) ||
    (selRole === "Members High School Coordinator" &&
      masterData.submemberList.filter((x) => x.value === "high_schools")) ||
    (selRole === "Member Ancillary Administrator" &&
      masterData.submemberList.filter((x) => x.value === "ancillary_member")) ||
    (selRole === "Member Club Coordinator" &&
      masterData.submemberList.filter((x) => x.value === "clubs_schools"));
  const isRoleIncludes =
    curtSelRoles.includes("members_primary_school_coordinator") ||
    curtSelRoles.includes("member_ancillary_administrator") ||
    curtSelRoles.includes("member_club_coordinator") ||
    curtSelRoles.includes("members_high_school_coordinator");
  const isSubMemberCoordinator = curHighLevel === "member" && isRoleIncludes;

  const roleToSubTypeMap = {
    "MEMBERS PRIMARY SCHOOL COORDINATOR": "primary_schools",
    "MEMBER CLUB COORDINATOR": "clubs_schools",
    "MEMBERS HIGH SCHOOL COORDINATOR": "high_schools",
    "MEMBER ANCILLARY ADMINISTRATOR": "ancillary_member",
  };

  const SelectedSubType = roleToSubTypeMap[curtSelRole] || null;
  console.log(SelectedSubType, curHighLevel, isRoleIncludes, "hhll");

  const foundSubmemberCond =
    (getLevels().find((element) => element.value === "SUB-MEMBER") &&
      level?.toUpperCase() === "SUB-MEMBER") ||
    isSubMemberCoordinator;

  const getRoles = (e) => {
    const rolesArr = localStorage.getItem("roles") || null;
    const roleList = successResponse?.[0]?.roles_target_id.split(",") || [];
    let roleListA = roleList?.length > 0 ? roleList?.map((x) => x?.trim()) : [];

    const isMemberAdmin = [
      "members_admin_assistant",
      "members_administrator",
    ].some((item) => JSON.parse(rolesArr).includes(item));

    for (let i = 0; i < masterData.memberAdminRoles.length; i++) {
      const elementB = masterData.memberAdminRoles[i];
      if (!roleListA.includes(elementB)) {
        isMemberAdmin && roleListA.push(elementB);
      }
    }

    console.log(isMemberAdmin, roleListA, e, "aaaa");
    const roles =
      roleList?.length > 0 ? roleListA : rolesArr ? JSON.parse(rolesArr) : [];

    console.log(roles, "aaaa");

    let reqArr = [];
    let rolesByLevels =
      e === "Sub-Member" ||
      e === "SUB-MEMBER" ||
      e === "sub-member" ||
      e === "Sub-Members"
        ? roles?.filter(
            (item) =>
              item?.includes("sub_members") || item?.includes("Sub-Members")
          )
        : e === "MEMBER" || e === "member" || e === "Member" || e === "Members"
        ? roles?.filter(
            (item) =>
              item?.startsWith("member_") ||
              item?.startsWith("members_") ||
              item?.startsWith("Members") ||
              item?.startsWith("Member")
          )
        : roles?.filter(
            (item) => item?.includes("csa") || item?.includes("CSA")
          );

    rolesByLevels?.forEach((element) => {
      //
      let role =
        roleList?.length > 0
          ? element
              ?.split(" ")
              .map(
                ([firstChar, ...rest]) =>
                  firstChar.toUpperCase() + rest.join("").toLowerCase()
              )
              .join(" ")
          : element?.split("_")?.join(" ")?.trimStart()?.toUpperCase();

      (role === "Csa Administrator" && reqArr.push("CSA Administrator")) ||
        (role === "Csa Admin Assistant" &&
          reqArr.push("CSA Admin Assistant")) ||
        (role === "Csa Coaches Manager" &&
          reqArr.push("CSA Coaches Manager")) ||
        (role === "Csa Umpires Manager" &&
          reqArr.push("CSA Umpires Manager")) ||
        (role === "Csa Match Referee Manager" &&
          reqArr.push("CSA Match Referee Manager")) ||
        (role === "Csa View Only" && reqArr.push("CSA View Only")) ||
        (role === "Sub-members Administrator" &&
          reqArr.push("Member Pathway Structure Administrator")) ||
        (role === "Sub-members View Only" &&
          reqArr.push("Member Pathway Structure View Only")) ||
        reqArr.push(role);
    });

    return reqArr;
  };

  const formik = useFormik({
    initialValues: {
      level: "",
      roles: "",
      membersName: memberName,
      subMemberName: localStorage.getItem("sub_member_id"),
      subMember: isSubMemberCoordinator ? SelectedSubType : sMemberType,
    },
    validationSchema:
      foundMemberCond === true
        ? MembersSchema
        : foundSubmemberCond === true
        ? SubMembersSchema
        : null,
    onSubmit: (values) => {
      if (level.toLowerCase() === "sub-member") {
        //  handleLoginAs();
        getUserLogo(`?type=All&sub_member_id=${subMemberInfo?.key}`, true);
        setImageFetchLevel("sub-member");
        fetch();
      } else if (level.toLowerCase() === "member") {
        fetchImg();
        getUserLogo(`?type=member&member_id=${sMemberId}`, true);
        setImageFetchLevel("member");
      } else if (level.toLowerCase() === "csa") {
        fetchImgCSA();
        setImageFetchLevel("csa");
      }

      //  handleLoginAs();
    },
  });

  const clearUser = (l) => {
    if (l === "sub") {
      localStorage.removeItem("sub_member_name");
      localStorage.removeItem("sub_member_id");
      localStorage.removeItem("sub_member_type");
      localStorage.removeItem("sub_member_uid");
    }
  };

  const handleLoginAs = () => {
    if (level?.toLowerCase() === "sub-member") {
      if (
        userLevel === level.toLowerCase() &&
        authRole === selRole &&
        memberId === sMemberId &&
        subMemberInfo &&
        subMemberId === subMemberInfo?.key
      ) {
        addToast("Already you logged in as same Sub Member", {
          appearance: "warning",
        });
        return;
      } else {
        localStorage.setItem("csa_level", level?.toLowerCase());
        dispatch(setCSALevel(level?.toLowerCase()));
        dispatch(setCSARole(selRole.toUpperCase()));
        localStorage.setItem("selRole", selRole.toUpperCase());
        localStorage.setItem("csa_member_name", selMemberName);
        localStorage.setItem("csa_member_id", sMemberId);
        const { key, opt } = subMemberInfo;
        const subUid = resData
          ?.find((i) => i.id?.toString() === key)
          ?.uid?.toString();
        localStorage.setItem("sub_member_name", opt?.children);
        localStorage.setItem("sub_member_id", key);
        localStorage.setItem("sub_member_type", values?.subMember);
        localStorage.setItem("sub_member_uid", subUid);
        navigate("/home");
      }
    } else if (level === "MEMBER" || level === "member") {
      if (
        userLevel === level.toLowerCase() &&
        authRole === selRole &&
        memberId === sMemberId
      ) {
        addToast("Already you logged in as same role and level", {
          appearance: "warning",
        });
        return;
      } else {
        localStorage.setItem("csa_level", level?.toLowerCase());
        dispatch(setCSALevel(level?.toLowerCase()));
        dispatch(setCSARole(selRole.toUpperCase()));
        localStorage.setItem("selRole", selRole.toUpperCase());
        localStorage.setItem("csa_member_name", selMemberName);
        localStorage.setItem("csa_member_id", sMemberId);
        clearUser("sub");

        navigate("/home");
      }
    } else {
      if (userLevel === level.toLowerCase() && authRole === selRole) {
        addToast("Already you logged in as same role, level", {
          appearance: "warning",
        });
      } else {
        localStorage.setItem("csa_level", level?.toLowerCase());
        dispatch(setCSALevel(level?.toLowerCase()));
        dispatch(setCSARole(selRole?.toUpperCase()));
        localStorage.setItem("selRole", selRole?.toUpperCase());
        // localStorage.setItem("")
        navigate("/home");
      }
    }
  };

  useEffect(() => {
    callAPI();
  }, [foundMemberCond]);

  const onSelect = (key, opt) => {
    setMemberName(opt?.member_name);
    setSMemberID(opt.key);
    // localStorage.setItem("csa_member_name", opt?.member_name);
    // localStorage.setItem("csa_member_id", opt.key);
  };

  console.log(sMemberId, "iddd");

  const onSelectSubMemberName = (key, opt) => {
    setSubMemberInfo({ key, opt });
  };

  useEffect(() => {
    if (level === "CSA") {
      localStorage.setItem("csa_member_name", "csa");
    }
  }, [level]);

  const onSearch = (val) => {
    setOptions(val);
  };

  const namesData = response?.map((p) => ({
    key: p?.member_id,
    value: p.member_name,
    ...p,
  }));

  const nameData =
    namesData?.length !== 0 &&
    namesData?.sort((a, b) => a.value.trim().localeCompare(b.value.trim()));

  const newData = nameData?.filter(
    (obj) =>
      obj.key !== 0 &&
      obj.value.toString().toLowerCase().includes(options.toLowerCase())
  );
  //setOptions(filtered)

  const filteredNewData = newData?.map((i) => ({
    key: i?.key,
    label: i?.value,
    value: i?.value,
    member_name: i?.member_name,
    member_id: i?.member_id,
  }));

  console.log(filteredNewData, "fff");
  //
  //
  //  const onSearch = (val) => {
  //  if (!val) return;
  //   let filtered = masterData.memberList.filter(
  //    (obj) =>
  //     obj.key !== 0 &&
  //     obj.value.toString().toLowerCase().includes(val.toLowerCase())
  //   );
  //   setOptions(filtered);
  //
  //  };
  const {
    errors,
    setFieldTouched,
    touched,
    handleSubmit,
    values,
    setFieldValue,
    handleBlur,
  } = formik;
  console.log(level, userLevel, subMemberId, values, "userlevel");

  useEffect(() => {
    if (values?.subMember && sMemberId) {
      FetchAPISubMember(
        `?type=${values?.subMember}&member_id=${sMemberId}`,
        true
      );
      //  setFieldValue("subMemberName", "");
    }
  }, [values?.subMember, values.membersName]);

  useEffect(() => {
    if (values?.subMemberName)
      dispatch(
        setLoginAsUid(
          resData
            ?.find((i) => i.id?.toString() === values?.subMemberName)
            ?.uid?.toString()
        )
      );
    else {
      let imageRes =
        fetchResponseImg || fetchResponseImgCSA || fetchResponse || [];
      imageRes &&
        imageRes.length > 0 &&
        dispatch(setLoginAsUid(imageRes?.[0]?.uid));
    }
  }, [
    values?.subMemberName,
    resData,
    fetchResponseImg,
    fetchResponse,
    fetchResponseImgCSA,
  ]);

  useEffect(() => {
    if (resUserLogo?.length) {
      setProfileImageRes(resUserLogo);
    }
  }, [resUserLogo]);
  console.count("image");
  useEffect(() => {
    const imageRes =
      fetchResponse || fetchResponseImg || fetchResponseImgCSA || [];

    if (level.toLowerCase() === "sub-member" && profileImageRes?.length > 0) {
      if (
        !profileImageRes?.[0]?.field_picture &&
        imageFetchLevel === "sub-member"
      ) {
        getUserLogo(`?type=member&member_id=${sMemberId}`, true);
        setProfileImageRes([]);
        setImageFetchLevel("member");
        return;
      } else {
        dispatch(setUserImgUrl(profileImageRes?.[0]?.field_picture));
        dispatch(setUserImgFid(Number(profileImageRes?.[0]?.fid)));
        handleLoginAs();
        return;
      }
    } else if (level.toLowerCase() === "member" && resUserLogo?.length > 0) {
      if (fetchResponse || fetchResponseImg || fetchResponseImgCSA) {
        setImageResponse(imageRes);
        dispatch(setUserImgUrl(resUserLogo?.[0]?.field_picture));
        dispatch(setUserImgFid(Number(resUserLogo?.[0]?.fid)));
      }
    } else if (level.toLowerCase() === "csa") {
      if (fetchResponse || fetchResponseImg || fetchResponseImgCSA) {
        setImageResponse(imageRes);
      }
    }
  }, [fetchResponseImg, fetchResponseImgCSA, profileImageRes, fetchResponse]);

  //   useEffect(()=> {

  //       if(level.toLowerCase() === "sub-member" && resUserLogo?.length >0 ){
  //  dispatch(setUserImgUrl(resUserLogo?.[0]?.field_picture));
  //  handleLoginAs();
  //       }
  //   },[resUserLogo])

  useEffect(() => {
    if (level?.toLowerCase() === "csa" && imageResponse?.length > 0) {
      dispatch(setUserImgFid(imageResponse?.[0]?.fid));
      dispatch(setUserImgUrl(imageResponse?.[0]?.img_url));
      //  imageResponse?.[0]?.uid
      //    ? dispatch(setLoginAsUid(imageResponse?.[0]?.uid))
      //    : dispatch(setLoginAsUid(values?.subMemberName));

      localStorage.setItem(
        "csa_province",
        JSON.stringify(
          imageResponse?.[0]?.field_province
            ? imageResponse?.[0]?.field_province
            : null
        )
      );

      handleLoginAs();
    } else if (
      level?.toLowerCase() === "member" &&
      imageResponse?.length === 0
    ) {
      addToast("Selected Role is not available ", {
        appearance: "warning",
      });
      return;
    } else if (level?.toLowerCase() === "member" && imageResponse?.length > 0) {
      if (selRole !== "Members Administrator") {
        imageResponse?.[0]?.img_url &&
          dispatch(setUserImgUrl(imageResponse?.[0]?.img_url)) &&
          dispatch(setUserImgFid(imageResponse?.[0]?.fid));
        console.log(selRole, "rrr");
      }
      //  dispatch(setUserImgUrl(imageResponse?.[0]?.img_url));
      //  imageResponse?.[0]?.uid
      //    ? dispatch(setLoginAsUid(imageResponse?.[0]?.uid))
      //    : dispatch(setLoginAsUid(values?.subMemberName));
      localStorage.setItem(
        "csa_province",
        JSON.stringify(
          imageResponse?.[0]?.field_province
            ? imageResponse?.[0]?.field_province
            : null
        )
      );

      handleLoginAs();
    } else if (
      level?.toLowerCase() === "member" &&
      imageResponse?.length > 0 &&
      imageResponse?.field_province === ""
    ) {
      addToast("Province not found for member", {
        appearance: "warning",
      });
    }
  }, [imageResponse]);
  console.log(selRole, "rrr2");

  useEffect(() => {
    setResData([]);
  }, [values.subMember]);

  useEffect(() => {
    if (fetchResponseSubMember) {
      isPagination
        ? setResData([...resData, ...fetchResponseSubMember?.rows])
        : setResData(fetchResponseSubMember?.rows);
    }
  }, [fetchResponseSubMember]);

  const handleSearch = (val) => {
    setIsPagination(false);
    setSubmemberValue(val);
    FetchAPISubMember(
      `?type=${
        values?.subMember ? values?.subMember : ""
      }&member_id=${sMemberId}&keyword=${val}`,
      true
    );
  };

  const handleScroll = (event) => {
    setIsPagination(true);

    const target = event.target;
    const totalHeight = target.scrollHeight - 1;
    const scrollHeight = target.scrollTop + target.offsetHeight;
    const totalPage = fetchResponseSubMember?.pager?.total_pages;
    const curPage = fetchResponseSubMember?.pager.current_page + 1;

    if (
      (totalPage != curPage &&
        parseInt(scrollHeight.toFixed(0)) === totalHeight) ||
      parseInt(scrollHeight.toFixed(0)) - 1 === totalHeight
    ) {
      FetchAPISubMember(
        `?type=${
          values?.subMember ? values?.subMember : ""
        }&member_id=${sMemberId}&keyword=${subMemberValue}&page=${
          fetchResponseSubMember?.pager.current_page + 1
        }`,
        true
      );
    }
  };

  console.log(getRoles(level), "mmm");

  return (
    <Wrapper>
      <div className={styles.LoginAsWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Login As</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3"
            align="Start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Levels"
                placeholder="Select Level"
                required
                name="level"
                value={level?.toUpperCase()}
                menuArr={getLevels()}
                optionType
                onChange={(e) => {
                  setLevel(e);
                  getRoles(e);
                  setRole(null);
                }}
                onBlur={() => setFieldTouched("level", true)}
                touched={touched.level}
                error={errors.level || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Roles"
                placeholder="Select roles"
                required
                name="roles"
                //value={selRole}
                value={selRole}
                menuArr={getRoles(level)}
                onChange={(e) => {
                  setRole(e);
                  setFieldValue("subMember", "");
                }}
                onBlur={() => setFieldTouched("roles", true)}
                touched={touched.roles}
                error={errors.roles || null}
              />
            </Col>
            {foundMemberCond === true ? (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <p className={styles.name}>
                  {"Members Name"}
                  {<span className={styles.required}>*</span>}
                </p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  allowClear
                  optionType
                  disabled={!canChangeMember}
                  // menuArr={filteredNewData}
                  options={filteredNewData}
                  name="membersName"
                  // required
                  showSearch
                  onChange={(e) => {
                    setFieldValue("membersName", e);
                    setFieldValue("subMemberName", "");
                  }}
                  placeholder="Enter Members Name"
                  value={values.membersName}
                  onSelect={(val, option) => onSelect(val, option)}
                  onSearch={onSearch}
                  onBlur={(e) => {
                    handleBlur(e);
                    // const input = e.target.value;
                    // if (input) {
                    //  let filtered = masterData.memberList?.find(
                    //   (val) => val?.value?.toLowerCase() === input?.toLowerCase()
                    //  );
                    //  if (filtered) {
                    //   setFieldValue("membersName", filtered?.value);
                    //  } else {
                    //   setFieldValue("membersName", "");
                    //  }
                    // }
                  }}
                  error={errors.membersName || null}
                />
                {errors.membersName && (
                  <pre className="text-danger">{errors.membersName}</pre>
                )}
              </Col>
            ) : null}
            {foundSubmemberCond === true ? (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <p className={styles.name}>
                    {"Members Name"}
                    {<span className={styles.required}>*</span>}
                  </p>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    allowClear
                    optionType
                    disabled={!canChangeMember}
                    //  menuArr={filteredNewData}
                    options={filteredNewData}
                    showSearch
                    // options={options.length !== 0 ? options : response?.map((p) => ({
                    //  key: p?.member_id,
                    //  value: p.member_name,
                    //  ...p,
                    // }))}
                    name="membersName"
                    //  required
                    onChange={(e) => {
                      setFieldValue("membersName", e);
                      setFieldValue("subMemberName", "");
                    }}
                    placeholder="Enter Members Name"
                    value={values.membersName}
                    onSelect={(val, option) => onSelect(val, option)}
                    onSearch={onSearch}
                    onBlur={(e) => {
                      handleBlur(e);

                      // const input = e.target.value;
                      // if (input) {
                      //  let filtered = masterData.memberList?.find(
                      //   (val) => val?.value?.toLowerCase() === input?.toLowerCase()
                      //  );
                      //  if (filtered) {
                      //   setFieldValue("membersName", filtered?.value);
                      //  } else {
                      //   setFieldValue("membersName", "");
                      //  }
                      // }
                    }}
                    error={errors.membersName || null}
                  />
                  {errors.membersName && (
                    <pre className="text-danger">{errors.membersName}</pre>
                  )}
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="Sub-Member Type"
                    placeholder="Select Sub-Member Type"
                    name="subMember"
                    optionType
                    menuArr={
                      isSubMemberCoordinator
                        ? filteredList
                        : masterData.submemberList
                    }
                    onChange={(e) => {
                      setFieldValue("subMember", e);
                      setFieldValue("subMemberName", "");
                    }}
                    value={values.subMember}
                    onBlur={handleBlur}
                    touched={touched.subMember}
                    error={errors.subMember || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="Sub-Member Name"
                    placeholder="Select Sub-Member Name"
                    name="subMemberName"
                    onScroll={handleScroll}
                    loading={fetchLoadSubMember}
                    onSearch={handleSearch}
                    onSelect={(val, option) =>
                      onSelectSubMemberName(val, option)
                    }
                    onChange={(e) => {
                      setFieldValue("subMemberName", e);
                    }}
                    value={resData?.length ? values.subMemberName : ""}
                    optionType
                    menuArr={
                      resData && resData?.length > 0
                        ? resData
                            ?.map((p) => ({
                              value: p?.id?.toString(),
                              label:
                                values?.subMember === "clubs_schools"
                                  ? p?.club_school_name
                                  : values?.subMember === "high_schools"
                                  ? p?.high_school_name
                                  : values?.subMember === "primary_schools"
                                  ? p?.primary_school_name
                                  : p?.ancillary_member_name,
                              ...p,
                            }))
                            ?.sort((a, b) =>
                              a?.label?.trim().localeCompare(b?.label?.trim())
                            )
                        : []
                    }
                    onBlur={handleBlur}
                    touched={touched.subMemberName}
                    error={errors.subMemberName || null}
                  />
                </Col>
              </>
            ) : null}

            <Col span={24} className="w-100 d-flex justify-content-center my-2">
              <CsaButton
                disabled={!selRole}
                loading={fetchLoadingImgCSA || loadUserLogo || fetchLoadingImg}
                textColor="#FFF"
                btnColor="#007237"
                label="Login"
                onClick={() => handleSubmit()}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
}

export default LoginAs;
