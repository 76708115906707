import styles from "./CsaTextInput.module.scss";
import React from "react";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

function CsaTextInput({
  label,
  labelColor = false,
  placeholder,
  value,
  onChange,
  name,
  type,
  onBlur,
  required = false,
  error,
  touched,
  maxLength,
  disabled,
  isNumber = false,
  defaultValue,
  key,
}) {
  const handleChange = (num) => {
    if (isNumber) {
      let keyCode = num?.keyCode;
      //
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        keyCode === 8 ||
        keyCode === 9 ||
        (keyCode >= 96 && keyCode <= 105)
      ) {
      } else {
        num.preventDefault();
      }
    }
  };

  return (
    <div key={key} className={styles.CsaTextInputWrapper}>
      <p className={labelColor ? styles.nameWhite : styles.name}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </p>

      {type === "password" ? (
        <Input.Password
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
          type={type}
          onBlur={onBlur}
          style={{ borderRadius: 5 }}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      ) : (
        <Input
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={handleChange}
          value={value}
          name={name}
          type={type}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={disabled}
          style={{ borderRadius: 5 }}
          pattern={isNumber ? "[0-9]{1,5}" : ""}
          defaultValue={defaultValue}
        />
      )}
      <div className={styles.erroMsg}>
        {error && touched && <span className="text-danger">{error}</span>}
      </div>
    </div>
  );
}

export default CsaTextInput;
