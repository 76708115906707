import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//Components
import MemberRegisterForm from "../../Common/MemberRegisterForm";
import MemberRegisterForm2 from "../../Common/MemberRegisterForm/MemberRegisterForm2";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./Members.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function Members() {
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
  };
  const location = useLocation();
  const id = location?.state?.id;
  const type = location?.state?.type;
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Members", value: "/member-list" },
    { key: "Member", value: "/register-member" },
  ];

  return (
    <Wrapper>
      <div className={styles.MembersWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Member
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>
            {step === 1 && <MemberRegisterForm {...props} />}
            {step === 2 && <MemberRegisterForm2 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Members;
