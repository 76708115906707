import React, { useEffect } from "react";
import styles from "./ProgressMatchCard.module.scss";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { setMatchDetails } from "redux/slice/commonSlice";
import Emptyimage from "../../images/no-img.png";
import csaimage from "../../images/csayellow.png";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";
import Meta from "antd/lib/card/Meta";
export default function ProgressMatchCard({ ...props }) {
  const { matchData, onKeyChange } = props;
  const { addToast } = useToasts();

  const competitionBanner = matchData?.competition_settings?.find(
    (elem) => elem?.key === "competition_logo"
  )?.value;
  const sponsor_logo = matchData?.competition_settings?.find(
    (elem) => elem?.key === "sponsor_logo"
  )?.value;
  const dispatch = useDispatch();

  dispatch(
    setMatchDetails({
      tournamentName: matchData.name,
      banner: sponsor_logo,
    })
  );
  const navigate = useNavigate();
  const handleNavigate = () => {
    // onchangecomponent({
    //   //  page: "matchschedule",
    //   mId: matchData?.id,
    //   banner: competitionBanner,
    //   tournamentName: matchData?.name,
    // });
    navigate(`${matchData?.id}`, {
      state: {
        mId: matchData?.id,
        banner: sponsor_logo,
        tournamentName: matchData?.name,
      },
    });
  };

  console.log(matchData, "mD");

  return (
    <>
      {/* <div className={styles.ProgressCard} onClick={() => handleNavigate()}>
        <div className={styles.imageWrapper}>
          <img
            src={competitionBanner ? competitionBanner : csaimage}
            alt={""}
          />
        </div>
        <p>{matchData?.name}</p>
      </div> */}
      <Card
        hoverable
        onClick={() => handleNavigate()}
        style={{
          cursor: "pointer",
          width: 320,
        }}
        cover={
          <img
            alt="csa"
            src={competitionBanner ? competitionBanner : csaimage}
          />
        }
      >
        <Meta title={matchData?.name} />
      </Card>
    </>
  );
}

// {competitionBanner ?
//   <div className={styles.imageWrapper}>
//     <img src={competitionBanner ? competitionBanner : csaimage} alt="TeamLogo" />
//     {/* <img src={team2} alt="TeamLogo2" /> */}
//   </div> :
//   <div className={styles.emptyimgWrapper}>
//     <div>
//     <img className={styles.emptylogo} src={Emptyimage} alt="TeamLogo" />
//     </div>
//   </div>}
