import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import styles from "./CsaFileUpload.module.scss";
import axios from "axios";
import { serviceUrl } from "services/serviceUrl";

function CsaFileUpload({
  label,
  name,
  required,
  files,
  setFile,
  editFile,
  editFileId,
  disabled,
  onRemove
}) {
  const headers = {
    authorization: "authorization-text",
  };

  const [fileList, setFileList] = useState(
    editFile
      ? [
          {
            uid: editFileId,
            name: editFile?.replace(
              "https://filessystem.s3.ap-south-1.amazonaws.com/s3fs-public/",
              ""
            ),
            status: "done",
            response: 200, // custom error message to show
            url: editFile,
          },
        ]
      : []
  );
  const [loading, setLoading] = useState(false);

  const uploadFile = async (options) => {
    setLoading(true);
    const { onSuccess, onError, file } = options;
    // const file = data1?.file;
    const reader = new FileReader();
    reader.onload = function (e) {
      const blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      const fileName = file.name;
      const headers = {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + localStorage.getItem("csa_token"),
        "Content-Disposition": `file;filename="${fileName}"`,
      };
      axios
        .post(serviceUrl.fileUpload, blob, {
          headers: headers,
        })
        .then((res) => {
          setLoading(false);
          onSuccess(file);
          setFileList([
            {
              uid: res?.data?.fid?.[0]?.value,
              name: res?.data?.filename?.[0]?.value,
              status: "done",
              response: res?.status, // custom error message to show
              url: res?.datat?.uri?.value,
            },
          ]);
          setFile([
            ...files,
            {
              key: name,
              value: res?.data?.fid?.[0]?.value,
              url: res?.data?.uri[0]?.url,
            },
          ]);
        })
        .catch((err) => {
          setLoading(false);
          onError({ event: err });
        });
    };
    reader.readAsArrayBuffer(file);
    return true;
  };

  const fileProps = {
    name: label || name,
    multiple: false,
    // beforeUpload: () => {
    //     return false;
    // },
    fileList: fileList,
    customRequest: uploadFile,
    accept: "application/pdf",
    headers: headers,
    onChange(info) {},
    onRemove(file) {
      setFileList([]);
            if (onRemove) {
        onRemove(file); // Trigger onRemove prop
      }
    },
  };

  return (
    <div className={styles.CsaFileUploadWrapper}>
      <p className={styles.name}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </p>
      <Upload {...fileProps}>
        <Button
          className={disabled ? styles.btnInActive : styles.btn}
          loading={loading}
          disabled={disabled}
        >
          Upload
        </Button>
      </Upload>
    </div>
  );
}

export default CsaFileUpload;
