import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Col, Row, Space, AutoComplete, Spin, Button } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
//Components
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaDatePicker from "Common/CsaDatePicker";
import CsaImageUpload from "Common/CsaImageUpload";
import * as masterData from "../../utils/commonMasterData";
import MyProfileView from "../../Common/MyProfileForm/MyProfileView";
import useAxios from "utils/axios";
import axios from "axios";
import { serviceUrl } from "services/serviceUrl";
import { imgUrlToFile } from "utils/helpers";

const phoneRegExp =
 /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ProfileSchema = Yup.object().shape({
 nationality: Yup.string().required("Select nationality"),
 passportNumber: Yup.string().required("Enter Passport Number"),
 firstName: Yup.string().required("Enter First Name"),
 lastName: Yup.string().required("Enter Last Name"),
 emailAddress: Yup.string()
  .email("Invalid Email Address")
  .required("Enter Email Address"),
 contactNumber: Yup.string()
  .matches(phoneRegExp, "Phone number is not valid")
  .min(10, "Please enter a valid 10 digit mobile number")
  .max(10, "Please enter a valid 10 digit mobile number")
  .required("Phone number required"),
 dateofBirth: Yup.string().required("Select Date of Birth"),
 race: Yup.string().required("Select Race"),
 gender: Yup.string().required("Select Gender"),
 differentlyAbled: Yup.string().required("Select differently abled"),
});
const ProfileLocalSchema = Yup.object().shape({
 nationality: Yup.string().required("Select nationality"),
 iDNumber: Yup.string()
  .matches(/^\d+$/, "Only numbers")
  .required("Enter  National Identity Number.")
  .min(13, "Please enter a valid 13 digit National Identity Number.")
  .max(13, "Please enter a valid 13 digit National Identity Number."),
 passportNumber: Yup.string(),
 firstName: Yup.string().required("Enter First Name"),
 lastName: Yup.string().required("Enter Last Name"),
 emailAddress: Yup.string()
  .email("Invalid Email Address")
  .required("Enter Email Address"),
 contactNumber: Yup.string()
  .matches(phoneRegExp, "Phone number is not valid")
  .min(10, "Please enter a valid 10 digit mobile number")
  .max(10, "Please enter a valid 10 digit mobile number"),
 dateofBirth: Yup.string().required("Select Date of Birth"),
 race: Yup.string().required("Select Race"),
 gender: Yup.string().required("Select Gender"),
 differentlyAbled: Yup.string().required("Select differently abled"),
});
function MyProfileForm({ styles, setStep, setInfo, info, img, setImg }) {
 // use useLocation read data form Checkout page
 const navigate = useNavigate();
 const [isView, setView] = useState(true);
 const [options, setOptions] = useState([]);
 const [fileId, setFileId] = useState(null);
 const [isLocal, setLocal] = useState(true);
 const {
  callAPI: success,
  response: successResponse,
  error: err,
  loading: successLoading,
 } = useAxios({
  url: serviceUrl.getProfile,
  method: "get",
 });
 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.setProfile,
  method: "put",
 });
 useEffect(() => {
  setLocal(successResponse?.[0]?.foreign_user === "True" ? false : true);
 }, [successResponse]);
 const data =
  isView || !isView
   ? {
      nationality: successResponse?.[0]?.field_nationality || "",
      iDNumber:
       successResponse?.[0]?.foreign_user === "False"
        ? successResponse?.[0]?.id_number
        : "" || "",
      passportNumber: successResponse?.[0]?.field_passport_number || "",
      firstName: successResponse?.[0]?.field_first_name || "",
      lastName: successResponse?.[0]?.field_last_name || "",
      emailAddress: successResponse?.[0]?.mail || "",
      contactNumber: successResponse?.[0]?.field_contact_number || "",
      dateofBirth: successResponse?.[0]?.field_date_of_birth,
      race: successResponse?.[0]?.field_race || "",
      gender: successResponse?.[0]?.field_gender || "",
      differentlyAbled: successResponse?.[0]?.field_differently_abled || "",
      status:
       successResponse?.[0]?.status === "On" ? "Active" : "Disable" || "",
      profile: successResponse?.[0]?.field_profile || "",
      fid: successResponse?.[0]?.fid || "",
      uid: successResponse?.[0]?.uid || "",
     }
   : info?.step1 || {};
 const { addToast } = useToasts();
 useEffect(() => {
  if (
   successResponse &&
   successResponse?.[0]?.field_profile &&
   img?.length === 0 &&
   !isView
  ) {
   imgUrlToFile(
    successResponse?.[0]?.field_profile,
    successResponse?.[0]?.fid,
    setImg
   );
   setFileId(successResponse?.[0]?.fid);
  }
 }, [successResponse]);
 useEffect(() => {
  success();
 }, []);

 useEffect(() => {
  if (err) {
  }
 }, [err]);
 const handleClear = () => {
  resetForm();
  navigate(-1);
 };
 const onSearch = (val) => {
  if (!val) return;
  let filtered = masterData.nationality.filter(
   (obj) =>
    obj.key !== 0 &&
    obj.value.toString().toLowerCase().includes(val.toLowerCase())
  );
  setOptions(filtered);
 };
 const onSelect = (value) => {
  
 };

 const formik = useFormik({
  initialValues: {
   nationality: data ? data?.nationality || "South Africa" : "South Africa",
   iDNumber: data ? data?.iDNumber : "",
   passportNumber: data ? data?.passportNumber : "",
   firstName: data ? data?.firstName : "",
   lastName: data ? data?.lastName : "",
   emailAddress: data ? data?.emailAddress : "",
   contactNumber: data ? data?.contactNumber : "",
   dateofBirth: data ? data?.dateofBirth : "",
   race: data ? data?.race : "",
   gender: data ? data?.gender : "",
   differentlyAbled: data ? data?.differentlyAbled || "None" : "None",
  },
  //   enableReinitialize: true,
  validationSchema: isLocal ? ProfileLocalSchema : ProfileSchema,
  enableReinitialize: true,
  onSubmit: (values) => {
   setInfo({ step1: values });
   const obj = {
    email_address: values?.emailAddress,
    nationality: values?.nationality,
    id_number: values?.iDNumber,
    passport_number: values?.passportNumber,
    contact_number: values?.contactNumber,
    first_name: values?.firstName,
    last_name: values?.lastName,
    dob: values?.dateofBirth,
    race: values?.race,
    gender: values?.gender,
    differently_abled: values?.differentlyAbled,
    fid: fileId,
   };
   
   callAPI({
    ...obj,
    ...{ id: successResponse?.[0]?.id },
    ...{ uid: successResponse?.[0]?.uid_1 },
    ...{ user_level: successResponse?.[0]?.field_user_level },
   });
  },
 });
 useEffect(() => {
  if (response) {
   addToast(
    "Updated Successfully",
    { appearance: "success" },
    () => setView(true),
    success(),
    navigate("/user-profile")
   );
   resetForm();
  } else if (error) {
   addToast(error?.Message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [response, error]);
 //  useEffect(() => {
 //   if (isEdit || isView) {
 //    fetch();
 //   }
 //  }, [isEdit, isView]);

 const {
  errors,
  touched,
  handleSubmit,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  resetForm,
 } = formik;

 const checkValidity = () => {
  const {
   nationality,
   iDNumber,
   passportNumber,
   firstName,
   lastName,
   emailAddress,
   dateofBirth,
   race,
   gender,
   differentlyAbled,
  } = values;
  const isVAlid = isLocal
   ? nationality &&
     iDNumber &&
     firstName &&
     lastName &&
     emailAddress &&
     dateofBirth &&
     race &&
     gender &&
     differentlyAbled &&
     Object.keys(errors).length === 0
   : nationality &&
     passportNumber &&
     firstName &&
     lastName &&
     emailAddress &&
     dateofBirth &&
     race &&
     gender &&
     differentlyAbled &&
     Object.keys(errors).length === 0;
  return isVAlid ? false : true;
 };
 const uploadImage = async (options) => {
  const { onSuccess, onError, file } = options;
  // const file = data1?.file;
  const reader = new FileReader();
  reader.onload = function (e) {
   const blob = new Blob([new Uint8Array(e.target.result)], {
    type: file.type,
   });
   const fileName = file.name;
   const headers = {
    "Content-Type": "application/octet-stream",
    Authorization: "Bearer " + localStorage.getItem("csa_token"),
    "Content-Disposition": `file;filename="${fileName}"`,
   };
   axios
    .post(serviceUrl.imgUpload, blob, {
     headers: headers,
    })
    .then((res) => {
     setFileId(res?.data?.fid?.[0]?.value);
     onSuccess(file);
    })
    .catch((err) => {
     onError({ event: err });
    });
  };
  reader.readAsArrayBuffer(file);
  return true;
 };

 const props = {
  name: "file",
  fileList: img,
  customRequest: uploadImage,
  headers: {
   authorization: "authorization-text",
  },
  onChange(info) {
   setImg(info?.fileList);
  },
  onRemove() {
   setFileId(null);
  },
 };

 return isView ? (
  <div>
   <div className={styles.CsaMoreMenuWrapper}>
    <Button
     onClick={() => setView(!isView)}
     icon={<EditOutlined />}
     className={styles.menuMore}
     style={{ backgroundColor: "#ffcb05" }}
    />
   </div>
   <Row>
    {successLoading ? (
     <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
      <Spin size="large" />
     </div>
    ) : (
     <MyProfileView viewData={data} />
    )}
   </Row>
  </div>
 ) : (
  <div>
   <Row
    gutter={{
     xs: 8,
     sm: 16,
     md: 24,
     lg: 32,
    }}
    className="mt-3 pt-3"
    align="Start"
   >
    <Col xs={24} sm={24} md={24} lg={24} xl={24} className={styles.imageUpload}>
     <CsaImageUpload {...props} />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <p className={styles.name}>
      {"Nationality"}
      {<span className={styles.required}>*</span>}
     </p>
     <AutoComplete
      style={{
       width: "100%",
      }}
      allowClear
      options={options}
      name="nationality"
      required
      onChange={(e) => {
       setFieldValue("nationality", e);
       setLocal(e === "South Africa");
      }}
      defaultValue={values.nationality || "South Africa"}
      value={values.nationality}
      onSelect={(val, option) => onSelect(val, option.label)}
      onSearch={onSearch}
      onBlur={(e) => {
       handleBlur(e);
       const input = e.target.value;
       if (input) {
        let filtered = masterData.nationality?.find(
         (val) => val?.value?.toLowerCase() === input?.toLowerCase()
        );
        if (filtered) {
         setFieldValue("nationality", filtered?.value);
        } else {
         setFieldValue("nationality", "");
        }
       }
      }}
      error={errors.nationality || null}
      disabled={!isView}
     ></AutoComplete>
     {errors.nationality && (
      <pre className="text-danger">{errors.nationality}</pre>
     )}
    </Col>

    {isLocal && (
     <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <CsaTextInput
       label="ID Number"
       placeholder="Enter ID Number"
       type="text"
       name="iDNumber"
       maxLength={13}
       onChange={handleChange}
       value={values.iDNumber}
       required={isLocal}
       onBlur={handleBlur}
       touched={touched.iDNumber}
       error={errors.iDNumber || null}
       disabled={!isView}
      />
     </Col>
    )}
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="Passport Number"
      placeholder="Enter Passport Number"
      type="text"
      name="passportNumber"
      onChange={handleChange}
      value={values.passportNumber}
      required={!isLocal}
      onBlur={handleBlur}
      touched={touched.passportNumber}
      error={errors.passportNumber || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="First Name"
      placeholder="Enter First Name"
      type="text"
      name="firstName"
      onChange={handleChange}
      value={values.firstName}
      required
      onBlur={handleBlur}
      touched={touched.firstName}
      error={errors.firstName || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="Last Name"
      placeholder="Enter Last Name"
      type="text"
      name="lastName"
      onChange={handleChange}
      value={values.lastName}
      required
      onBlur={handleBlur}
      touched={touched.lastName}
      error={errors.lastName || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="Email Address"
      placeholder="Enter Email Address"
      type="text"
      name="emailAddress"
      onChange={handleChange}
      value={values.emailAddress}
      required
      onBlur={handleBlur}
      touched={touched.emailAddress}
      error={errors.emailAddress || null}
      disabled={!isView}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="Contact Number"
      placeholder="Enter Contact Number"
      type="text"
      name="contactNumber"
      maxLength={10}
      onChange={handleChange}
      value={values.contactNumber}
      onBlur={handleBlur}
      touched={touched.contactNumber}
      error={errors.contactNumber || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDatePicker
      label="Date of Birth"
      placeholder="Select Date of Birth"
      name="dateofBirth"
      setFieldValue={setFieldValue}
      value={values.dateofBirth}
      required
      onBlur={handleBlur}
      touched={touched.dateofBirth}
      error={errors.dateofBirth || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Race"
      placeholder="Select Race"
      required
      name="race"
      value={values.race}
      menuArr={masterData.raceList}
      onChange={(e) => {
       setFieldValue("race", e);
      }}
      onBlur={() => setFieldTouched("race", true)}
      touched={touched.race}
      error={errors.race || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Gender"
      placeholder="Select Gender"
      required
      name="gender"
      value={values.gender}
      menuArr={masterData.genderList}
      onChange={(e) => {
       setFieldValue("gender", e);
      }}
      onBlur={() => setFieldTouched("gender", true)}
      touched={touched.gender}
      error={errors.gender || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Differently Abled"
      placeholder="Select Differently Abled"
      required
      name="differentlyAbled"
      value={values.differentlyAbled}
      menuArr={masterData.differentlyAbled}
      onChange={(e) => {
       setFieldValue("differentlyAbled", e);
      }}
      defaultValue={values.differentlyAbled || "None"}
      onBlur={() => setFieldTouched("differentlyAbled", true)}
      touched={touched.differentlyAbled}
      error={errors.differentlyAbled || null}
     />
    </Col>

    <div className="mt-4 d-flex w-100 justify-content-end">
     <Space>
      <CsaButton
       label="Cancel"
       textColor="#007237"
       onClick={() => handleClear()}
       btnColor="#FFCB03"
      />
      <CsaButton
       label="Update"
       textColor="#FFF"
       onClick={() => handleSubmit()}
       btnColor="#007237"
       disabled={checkValidity()}
      />
     </Space>
    </div>
   </Row>
  </div>
 );
}

export default MyProfileForm;
