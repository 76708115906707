import React, { useEffect, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
import { useSelector } from "react-redux";

const PlayersSchema = Yup.object().shape({
  //  battingSkill: Yup.string().required("Select Level1"),
  //  battingHand: Yup.string().required("Select Level2"),
  //  bowlingSkill: Yup.string().required("Select Level3"),
  //  bowlingHand: Yup.string().required("Select Level4"),
});

function PlayersRegisterForm2({ styles, setStep, setInfo, info, img, setImg }) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const sub_member_name = localStorage.getItem("sub_member_name");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const steps = ["General Information", "Playing Style"];
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editPlayer : serviceUrl.setPlayer,
    method: isEdit ? "put" : "post",
  });
  let level =
    authLevel === "member"
      ? "MEMBER"
      : authLevel === "csa"
      ? "CSA"
      : "SUB-MEMBER";
  const setLevel = (arr) => {
    if (info?.userExisting === true) {
      arr.push(level);
    }
    return arr;
  };
  const data = isEdit
    ? {
        battingSkill: info?.editData?.battingSkill || "",
        battingHand: info?.editData?.battingHand || "",
        bowlingSkill: info?.editData?.bowlingSkill || "",
        bowlingHand: info?.editData?.bowlingHand || "",
      }
    : info?.userExisting
    ? {
        battingHand: info?.existingUserData?.field_batting_hand || "",
        battingSkill: info?.existingUserData?.field_batting_skill || "",
        bowlingSkill: info?.existingUserData?.field_bowl_skill || "",
        bowlingHand: info?.existingUserData?.field_bowling_hand || "",
      }
    : info?.step2 || {};
  const formik = useFormik({
    initialValues: {
      battingSkill: data ? data?.battingSkill : "",

      battingHand: data ? data?.battingHand : "",

      bowlingSkill: data ? data?.bowlingSkill : "",

      bowlingHand: data ? data?.bowlingHand : "",
      consent: isEdit ? true : "",
    },
    validationSchema: PlayersSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: info?.step1,
        step2: values,
      });
      const obj = {
        email_address: info?.step1?.emailAddress,
        nationality: info?.step1?.nationality,
        id_number: info?.step1?.iDNumber,
        passport_number: info?.step1?.passportNumber,
        contact_number: info?.step1?.contactNumber,
        first_name: info?.step1?.firstName,
        last_name: info?.step1?.lastName,
        dob: info?.step1?.dateofBirth,
        race: info?.step1?.race,
        gender: info?.step1?.gender,
        differently_abled: info?.step1?.differentlyAbled,
        batting_skill: values.battingSkill,
        batting_hand: values.battingHand,
        bowling_skill: values.bowlingSkill,
        bowling_hand: values.bowlingHand,
        consent: values.consent,
        status: info?.step1?.status === "Assigned" ? true : false || false,
        fid: info?.file,
        user_level:
          info?.userExisting === true
            ? setLevel(info?.existingUserData?.user_level)
            : isEdit
            ? info?.editData?.user_level
            : level,
        roles:
          info?.userExisting === true ? ["player"] : isEdit ? null : ["player"],
        member_id: level === "CSA" ? "" : memberId,
        uid: uid,
        ...(authLevel === "sub-member" && {
          sub_member_id: sub_member_id,
          sub_member_name: sub_member_name,
        }),
      };
      callAPI(
        id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
      );
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/player-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Batting Skill"
            placeholder="Select Batting Skill"
            name="battingSkill"
            value={values.battingSkill}
            menuArr={masterData.battingSkillPlayer}
            onChange={(e) => {
              setFieldValue("battingSkill", e);
            }}
            onBlur={handleBlur}
            touched={touched.battingSkill}
            error={errors.battingSkill || null}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Batting Hand"
            placeholder="Select Batting Hand"
            name="battingHand"
            value={values.battingHand}
            menuArr={masterData.battingHandPlayer}
            onChange={(e) => {
              setFieldValue("battingHand", e);
            }}
            onBlur={handleBlur}
            touched={touched.battingHand}
            error={errors.battingHand || null}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Bowling Skill"
            placeholder="Select Bowling Skill"
            name="bowlingSkill"
            value={values.bowlingSkill}
            menuArr={masterData.bowlingSkillPlayer}
            onChange={(e) => {
              setFieldValue("bowlingSkill", e);
            }}
            onBlur={handleBlur}
            touched={touched.bowlingSkill}
            error={errors.bowlingSkill || null}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Bowling"
            placeholder="Select Bowling Hand"
            name="bowlingHand"
            value={values.bowlingHand}
            menuArr={masterData.bowlingHandPlayer}
            onChange={(e) => {
              setFieldValue("bowlingHand", e);
            }}
            onBlur={handleBlur}
            touched={touched.bowlingHand}
            error={errors.bowlingHand || null}
          />
        </Col>
        <div style={{ marginLeft: "15px" }}>
          <p
            style={{
              gap: 5,
              color: "#000000",
              fontSize: "15px",
              // backgroundColor: "green",
              margin: 0,
            }}
          >
            Consent <span style={{ color: "red" }}> *</span>
          </p>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Checkbox
              name="consent"
              defaultChecked={values?.consent}
              value={values.consent}
              onChange={(e) => setFieldValue("consent", e.target.checked)}
            />
            <p
              style={{
                color: "#000000",
                fontSize: "15px",
              }}
            >
              I hereby confirm that I have received concent from the player that
              I am adding (Or a guardian if under 18). See
              <a href="3"> Terms & Conditions</a>
            </p>
          </div>
        </div>
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({
                step1: info?.step1,
                step2: values,
                step3: info?.step3,
                file: info.file,
              });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            disabled={!values.consent}
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default PlayersRegisterForm2;
