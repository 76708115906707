/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaCheckBox from "Common/CsaCheckBox";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { fixSpecialChar } from "utils/helpers";

const StaffMembersSchema = Yup.object().shape({
  designation: Yup.string().required("Enter Designation"),
  status: Yup.string().required("Select Status"),
});

function StaffMembersRegisterForm2({
  styles,
  setStep,
  setInfo,
  info,
  img,
  setImg,
}) {
  const navigate = useNavigate();

  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const sub_member_name = localStorage.getItem("sub_member_name");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const steps = ["Personal Information", "Designation and Access"];
  const { addToast } = useToasts();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";

  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editStaff : serviceUrl.setStaff,
    method: isEdit ? "put" : "post",
  });


  const getRolesKey = (arr) => {
    let reqArr = [];
    let masterArr =
      authLevel === "member"
        ? masterData.optionsMember
        : authLevel === "csa"
        ? masterData.optionsCsa
        : masterData.optionsSubMember;
    masterArr?.forEach((el) => {
      if (arr?.find((item) => item?.trim() === el?.label)) {
        reqArr.push(el?.value);
      }
    });

    return reqArr;
  };
  let editData =
    info?.editData?.role?.length > 0
      ? info?.editData?.role?.map((x) => x?.trim())
      : info?.step2?.role;
  let level =
    authLevel === "member"
      ? "MEMBER"
      : authLevel === "csa"
      ? "CSA"
      : "SUB-MEMBER";
  const setLevel = (arr) => {
    if (info?.userExisting === true) arr.push(level);
    return arr;
  };
  const getRemoveRoles = (arr) => {
    let selectedArr = arr;
    let removeArr = editData
      ? getRolesKey(editData)?.filter((x) => !selectedArr.includes(x))
      : "";
    return removeArr;
  };
  const data = isEdit
    ? {
        designation:
          fixSpecialChar(info?.editData?.designation) ||
          fixSpecialChar(info?.step2?.designation),
        status: info?.editData?.status || info?.step2?.status,
        role: editData,
      }
    : info?.step2 || {};

  console.log(info,"data")


  const formik = useFormik({
    initialValues: {
      designation: data ? data?.designation : "",
      status: data ? data?.status : "",
      role: data ? getRolesKey(editData) : "",
    },
    validationSchema: StaffMembersSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values?.role?.length === 0) {
        addToast("Please select one role", {
          appearance: "warning",
        });
      } else {
        setInfo({ step1: info?.step1, step2: values });
        const obj = {
          email_address: info?.step1?.emailAddress,
          nationality: info?.step1?.nationality,
          id_number: info?.step1?.iDNumber?.toString() || "",
          passport_number: info?.step1?.passportNumber?.toString() || "",
          contact_number: info?.step1?.contactNumber?.toString() || "",
          first_name: info?.step1?.firstName,
          last_name: info?.step1?.lastName,
          dob: info?.step1?.dateofBirth,
          race: info?.step1?.race,
          gender: info?.step1?.gender,
          differently_abled: info?.step1?.differentlyAbled,
          designation: values?.designation,
          status: values?.status === "Active" ? true : false || false,
          fid: info?.file,
          user_level_existing:
            // info?.userExisting === true
            //   ? setLevel(info?.existingUserData?.user_level)
            //   :
            isEdit ? info?.editData?.user_level : level,
          roles:
            info?.userExisting === true
              ? values.role
              : isEdit
              ? values.role
              : values.role,
          user_level:
            // info?.userExisting === true
            //   ? info?.existingUserData?.user_level
            //   :
            isEdit ? info?.editData?.user_level : level,
          removed_roles: isEdit ? getRemoveRoles(values.role) : "",
          member_id: level === "CSA" ? "" : memberId,
          uid: uid,
          ...(authLevel === "sub-member" && {
            sub_member_id: sub_member_id,
            sub_member_name: sub_member_name,
          }),
        };

        callAPI(
          id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
        );
      }
    },
  });

  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/staff-list")
      );
      resetForm();
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      addToast(
        error?.Message ||
          error?.message ||
          error?.error ||
          "Something went wrong",
        {
          appearance: "error",
        }
      );
     // setStep(1);
    }
  }, [error]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const checkValidity = () => {
    const { designation, status, role } = values;
    const isVAlid =
      designation && status && role && Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="Designation"
            placeholder="Enter Designation"
            type="text"
            name="designation"
            onChange={handleChange}
            value={values.designation}
            required
            onBlur={handleBlur}
            touched={touched.designation}
            error={errors.designation || null}
          />
        </Col>
        {/* {roles?.find((item) => item !== "member" || "sub-member") ? (
     <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <CsaTextInput
        label="Location"
        placeholder="Enter Location"
        type="text"
        name="location"
        onChange={handleChange}
        value={values.location}
        required
        onBlur={handleBlur}
        touched={touched.province}
      error={errors.province || null}error={errors && touched ? errors.location : ""}
       />
      </Col>
     </>
    ) : null} */}

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Status"
            placeholder="Select Status"
            required
            name="status"
            value={values.status}
            menuArr={masterData.statusList}
            onChange={(e) => {
              setFieldValue("status", e);
            }}
            onBlur={() => setFieldTouched("status", true)}
            touched={touched.status}
            error={errors.status || null}
          />
        </Col>

        <Col span={24}>
          <CsaCheckBox
            label="Roles"
            name="role"
            required
            options={
              authLevel === "member"
                ? masterData.optionsMember
                : authLevel === "csa"
                ? masterData.optionsCsa
                : masterData.optionsSubMember
            }
            value={values.role}
            onChange={(checkedValue) => {
              setFieldValue("role", checkedValue);
            }}
            onBlur={handleBlur}
            touched={touched.role}
            error={errors.role || null}
          />
        </Col>

        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({
                file:info?.file,
                step1: info?.step1,
                step2: values,
              });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />

          <CsaButton
            label={isEdit ? "Update" : "Submit"}
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={checkValidity()}
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default StaffMembersRegisterForm2;
