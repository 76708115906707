import styles from "./Login.module.scss";
import React from "react";
import { Col, Row, Space } from "antd";
//Components
import CsaLoginForm from "../../Common/CsaLoginForm";
// import Logo from "../../images/CSA_Green_Logo.jpg";
import Logo from "../../images/logo.png";
import Wrapper from "Layout/Wrapper";
function Login() {
    return (
        <Wrapper hide={true}>
            <div className={styles.LoginWrapper}>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={12} xl={12} className={styles.grid}>
                        <div className="w-100 d-xl-flex d-lg-flex align-items-center justify-content-center mb-3 d-none">
                            <div className={styles.imgWrap}>
                                <img src={Logo} alt="icon" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.grid}>
                        <CsaLoginForm />
                    </Col>
                </Row>
            </div>
        </Wrapper>
    );
}

export default Login;
