import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDatePicker from "Common/CsaDatePicker";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
import { useSelector } from "react-redux";

const PlayersSchema = Yup.object().shape({
  contracted: Yup.string().required("Select Contracted"),
});
const PlayersContractSchema = Yup.object().shape({
  contracted: Yup.string().required("Select Contracted"),
  contractStartDate: Yup.string().required("Select Contract Start Date"),
  contractEndDate: Yup.string().required("Select Contract End Date"),
});

function PlayersRegisterForm3({ styles, setStep, setInfo, info, img, setImg }) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const steps = ["General Information", "Playing Style", "Contract"];
  const [isContract, setContract] = useState(false);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editPlayer : serviceUrl.setPlayer,
    method: isEdit ? "put" : "post",
  });
  let level =
    authLevel === "member"
      ? "MEMBER"
      : authLevel === "csa"
      ? "CSA"
      : "SUB-MEMBER";
  const setLevel = (arr) => {
    if (info?.userExisting === true) {
      arr.push(level);
    }
    return arr;
  };
  const data = isEdit
    ? {
        contracted: info?.step3?.contracted|| info?.editData?.contracted,
        contractStartDate:info?.step3?.contractStartDate|| info?.editData?.contractStartDate,
        contractEndDate:info?.step3?.contractEndDate|| info?.editData?.contractEndDate,
      }
    : info?.userExisting
    ? {
        contracted:info?.step3?.contracted|| info?.existingUserData?.field_contracted || "",
        contractStartDate:info?.step3?.contractStartDate||
          info?.existingUserData?.field_contract_start_date || "",
        contractEndDate: info?.step3?.contractEndDate|| info?.existingUserData?.field_contract_end_date || "",
      }
    : info?.step3 || {};

  const formik = useFormik({
    initialValues: {
      contracted: data ? data?.contracted : "",
      contractStartDate: data ? data?.contractStartDate : "",
      contractEndDate: data ? data?.contractEndDate : "",
    },
    validationSchema: isContract ? PlayersContractSchema : PlayersSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: info?.step1,
        step2: info?.step2,
        step3: values,
      });
      const obj = {
        email_address: info?.step1?.emailAddress,
        nationality: info?.step1?.nationality,
        id_number: info?.step1?.iDNumber?.toString() || "",
        passport_number: info?.step1?.passportNumber?.toString() || "",
        contact_number: info?.step1?.contactNumber?.toString() || "",
        first_name: info?.step1?.firstName,
        last_name: info?.step1?.lastName,
        dob: info?.step1?.dateofBirth,
        race: info?.step1?.race,
        gender: info?.step1?.gender,
        differently_abled: info?.step1?.differentlyAbled,
        batting_skill: info?.step2?.battingSkill,
        batting_hand: info?.step2?.battingHand,
        bowling_skill: info?.step2?.bowlingSkill,
        consent: info?.step2?.consent,
        bowling_hand: info?.step2?.bowlingHand,
        contracted: values.contracted === "Yes" ? true : false,
        contract_start_date:
          values.contracted === "Yes" ? values.contractStartDate : "",
        contract_end_date:
          values.contracted === "Yes" ? values.contractEndDate : "",
        status: info?.step1?.status === "Assigned" ? true : false || false,
        fid: info?.file,
        user_level:
          info?.userExisting === true
            ? setLevel(info?.existingUserData?.user_level)
            : isEdit
            ? info?.editData?.user_level
            : level,
        roles:
          info?.userExisting === true ? ["player"] : isEdit ? null : ["player"],
        member_id: level === "CSA" ? "" : memberId,
        uid: uid,
      };
      callAPI(
        id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
      );
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/player-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || error?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    setFieldValue,

    resetForm,
  } = formik;
  const checkValidity = () => {
    const { contracted, contractStartDate, contractEndDate } = values;
    const isVAlid =
      contracted &&
      contractEndDate &&
      contractStartDate &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  console.log(info,"iii")

  return (
    <div>
      <CsaStepper steps={steps} activeKey={2} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Contracted"
            placeholder="Select Contracted"
            name="contracted"
            value={values.contracted}
            menuArr={["Yes", "No"]}
            onChange={(e) => {
              setFieldValue("contracted", e);
              setContract(e === "Yes" ? true : false || "");
            }}
            required
            defaultValue={values.contracted}
            onBlur={handleBlur}
            touched={touched.contracted}
            error={errors.contracted || null}
          />
        </Col>
        {isContract || values.contracted === "Yes" ? (
          <>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDatePicker
                label="Contract Start Date"
                placeholder="Select Contract Start Date"
                name="contractStartDate"
                setFieldValue={setFieldValue}
                required
                value={values.contractStartDate}
                disableFuture={false}
                onBlur={handleBlur}
                touched={touched.contractStartDate}
                error={errors.contractStartDate || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDatePicker
                label="Contract End Date"
                placeholder="Select Contract End Date"
                name="contractEndDate"
                disableFuture={false}
                setFieldValue={setFieldValue}
                required
                value={values.contractEndDate}
                onBlur={handleBlur}
                touched={touched.contractEndDate}
                error={errors.contractEndDate || null}
                minDate={values.contractStartDate}
              />
            </Col>
          </>
        ) : null}

        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({
                step1: info?.step1,
                step2: info?.step2,
                step3: values,
                file: info.file,
                editData: info?.editData,
                existingUserData: info?.isExistingUser
                  ? info?.existingUserData
                  : {},
                userExisting: info?.isExistingUser ? info?.userExisting : false,
              });
              setStep(2);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={
              !values.contracted
                ? true
                : values.contracted === "No"
                ? false
                : checkValidity()
            }
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default PlayersRegisterForm3;
