import React, { useState, useEffect } from "react";
import styles from "./LogoOverlay.module.scss";
import Logo from "../../images/pitchvisionlogo.png";

function LogoOverlay({ logos }) {
  // const {overlays} = props
  // console.log(overlays,'oooo')
  const [showBanner, setShowBanner] = useState(false);
  const [bannerIdx, setBannerIdx] = useState(0);

  const { competitionLogo, adLogoArr } = logos;

  console.log(adLogoArr, "ddd");

  useEffect(() => {
    let hideBannerTimeout;

    if (showBanner) {
      hideBannerTimeout = setTimeout(() => {
        if (adLogoArr && adLogoArr?.length) {
          setBannerIdx((prevIndex) => (prevIndex + 1) % adLogoArr.length);
        }

        setShowBanner(false);
      }, 18000); // Hide the banner after 15 seconds
    } else if (adLogoArr && adLogoArr?.length) {
      hideBannerTimeout = setTimeout(() => {
        setShowBanner(true);
      }, 120000); // Show the next banner after 2 minutes
    }

    return () => {
      clearTimeout(hideBannerTimeout);
    };
  }, [showBanner, adLogoArr]);

  const currentBanner =
    (adLogoArr?.length && adLogoArr[bannerIdx])?.value || null;

  return (
    <div className={styles.ovWrapper}>
      {!showBanner && <img className={styles.logo} src={Logo} alt="icon" />}
      {!showBanner && competitionLogo && (
        <img className={styles.logo2} src={competitionLogo} alt="icon" />
      )}
      {showBanner && currentBanner && (
        <img className={styles.banner} src={currentBanner} alt="icon" />
      )}
      {/* {!showBanner && <img src={nologo} alt="icon" />} */}
    </div>
  );
}

export default LogoOverlay;
