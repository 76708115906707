// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_F_API_KEY,
  authDomain: process.env.REACT_APP_F_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_F_PROJECT_ID,
  storageBucket: process.env.REACT_APP_F_STOREGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_F_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_F_APP_ID,
  measurementId: process.env.REACT_APP_F_MM_ID,
};

const app = initializeApp(firebaseConfig);
const firebaseDb = getFirestore(app);

export default firebaseDb;
