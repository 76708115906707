import React from "react";
import { useState } from "react";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styles from "./CsaMoreMenu.module.scss";

function CsaViewMenu({ handleView }) {
  return (
    <div className={styles.CsaMoreMenuWrapper}>
      <Button
        onClick={() => handleView()}
        className={styles.menuMore}
        icon={<EyeOutlined />}
        style={{ backgroundImage: "linear-gradient(90deg, #004f27, #00793a)" }}
      />
    </div>
  );
}

export default CsaViewMenu;
