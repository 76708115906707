import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, AutoComplete, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import confirm from "antd/lib/modal/confirm";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaDatePicker from "Common/CsaDatePicker";
import CsaImageUpload from "Common/CsaImageUpload";
import * as masterData from "../../utils/commonMasterData";
import useAxios from "utils/axios";
import axios from "axios";
import ScorerView from "../../Common/ScorerRegisterForm/ScorerView";
import { serviceUrl } from "services/serviceUrl";
import { imgUrlToFile } from "utils/helpers";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ScorerSchema = Yup.object().shape({
  nationality: Yup.string().required("Select nationality"),
  passportNumber: Yup.string().required("Enter Passport Number"),
  firstName: Yup.string().required("Enter First Name"),
  lastName: Yup.string().required("Enter Last Name"),
  emailAddress: Yup.string()
    .email("Invalid Email Address")
    .required("Enter Email Address"),
  contactNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Please enter a valid 10 digit mobile number")
    .max(10, "Please enter a valid 10 digit mobile number"),
  dateofBirth: Yup.string().required("Select Date of Birth"),
  race: Yup.string().required("Select Race"),
  gender: Yup.string().required("Select Gender"),
  differentlyAbled: Yup.string().required("Select differently abled"),
});
const ScorerLocalSchema = Yup.object().shape({
  nationality: Yup.string().required("Select nationality"),
  iDNumber: Yup.string()
    .matches(/^\d+$/, "Only numbers")
    .required("Enter  National Identity Number.")
    .min(13, "Please enter a valid 13 digit National Identity Number.")
    .max(13, "Please enter a valid 13 digit National Identity Number."),
  passportNumber: Yup.string(),
  firstName: Yup.string().required("Enter First Name"),
  lastName: Yup.string().required("Enter Last Name"),
  emailAddress: Yup.string()
    .email("Invalid Email Address")
    .required("Enter Email Address"),
  contactNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Please enter a valid 10 digit mobile number")
    .max(10, "Please enter a valid 10 digit mobile number"),
  dateofBirth: Yup.string().required("Select Date of Birth"),
  race: Yup.string().required("Select Race"),
  gender: Yup.string().required("Select Gender"),
  differentlyAbled: Yup.string().required("Select differently abled"),
});
function ScorerRegisterForm({
  styles,
  setStep,
  setInfo,
  info,
  isLocal,
  setLocal,
  img,
  setImg,
}) {
  const authRole = localStorage.getItem("selRole");
  const isCSA = JSON.parse(localStorage.getItem("user_level")).includes("CSA")
  console.log(authRole,isCSA,"rrrr")
  
  const location = useLocation();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const steps = ["Personal Information", "Qualifications"];
  const { id, type } = location?.state || {};
  const [fileId, setFileId] = useState(null);
  const [isExistingUser, setExistingUser] = useState(false);
  const [uploading, setUploading] = useState(false);
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const {
    callAPI: fetchDetail,
    response: success,
    error: err,
    loading: fetching,
  } = useAxios({
    url: serviceUrl.getScorerDetailByID + `${id}`,
    method: "get",
  });
  const {
    callAPI: getUserDetailById,
    response: userDetail,
    error: userErr,
    loading: userFetching,
  } = useAxios({
    url: serviceUrl.getScorerDetailByIDNumber,
    method: "get",
    query: true,
  });
  const data =
    isEdit || isView
      ? {
          nationality: success?.[0]?.nationality,
          iDNumber:
            success?.[0]?.foreign_user === "False"
              ? success?.[0]?.id_number
              : "", //need to change based on api
          passportNumber: success?.[0]?.passport_number,
          firstName: success?.[0]?.first_name?.replace(/&#039;/g, "'"),
          lastName: success?.[0]?.last_name?.replace(/&#039;/g, "'"),
          emailAddress: success?.[0]?.email_address,
          contactNumber: success?.[0]?.contact_number,
          dateofBirth: success?.[0]?.date_of_birth,
          race: success?.[0]?.race,
          gender: success?.[0]?.gender,
          differentlyAbled: success?.[0]?.field_differently_abled,
          gradeA: success?.[0]?.grade_a,
          dateObtainedgA: success?.[0]?.grade_a_date,
          certificateUploadgA: success?.[0]?.grade_a_file,
          cFId1: success?.[0]?.grade_a_fid,
          gradeB: success?.[0]?.grade_b,
          dateObtainedgB: success?.[0]?.lgrade_b_date,
          certificateUploadgB: success?.[0]?.grade_b_file,
          cFId2: success?.[0]?.grade_b_fid,
          gradeC: success?.[0]?.grade_c,
          dateObtainedgC: success?.[0]?.grade_c_date,
          certificateUploadgC: success?.[0]?.grade_c_file,
          cFId3: success?.[0]?.grade_c_fid,
          gradeD: success?.[0]?.grade_d,
          dateObtainedgD: success?.[0]?.grade_d_date,
          certificateUploadgD: success?.[0]?.grade_d_file,
          dFId: success?.[0]?.grade_d_fid,
          premierLeague: success?.[0]?.premier_league,
          status: success?.[0]?.status === "Active" ? "Active" : "Disable",
          profile: success?.[0]?.profile,
          fid: success?.[0]?.fid,
          uid: success?.[0]?.uid,
          user_level: success?.[0]?.user_level,
        }
      : isExistingUser
      ? {
          nationality: userDetail?.[0]?.nationality,
          iDNumber:
            userDetail?.[0]?.foreign_user === "False"
              ? userDetail?.[0]?.id_number
              : "",
          passportNumber: userDetail?.[0]?.passport_number,
          firstName: userDetail?.[0]?.first_name,
          lastName: userDetail?.[0]?.last_name,
          emailAddress: userDetail?.[0]?.email_address,
          contactNumber: userDetail?.[0]?.contact_number,
          dateofBirth: userDetail?.[0]?.date_of_birth,
          race: userDetail?.[0]?.race,
          gender: userDetail?.[0]?.gender,
          differentlyAbled: userDetail?.[0]?.differently_abled,
          gradeA: userDetail?.[0]?.grade_a,
          dateObtainedgA: userDetail?.[0]?.grade_a_date,
          certificateUploadgA: userDetail?.[0]?.grade_a_file,
          cFId1: userDetail?.[0]?.grade_a_fid,
          gradeB: userDetail?.[0]?.grade_b,
          dateObtainedgB: userDetail?.[0]?.lgrade_b_date,
          certificateUploadgB: userDetail?.[0]?.grade_b_file,
          cFId2: userDetail?.[0]?.grade_b_fid,
          gradeC: userDetail?.[0]?.grade_c,
          dateObtainedgC: userDetail?.[0]?.grade_c_date,
          certificateUploadgC: userDetail?.[0]?.grade_c_file,
          cFId3: userDetail?.[0]?.grade_c_fid,
          gradeD: userDetail?.[0]?.grade_d,
          dateObtainedgD: userDetail?.[0]?.grade_d_date,
          certificateUploadgD: userDetail?.[0]?.grade_d_file,
          dFId: userDetail?.[0]?.grade_d_fid,
          premierLeague: userDetail?.[0]?.premier_league,
          status: userDetail?.[0]?.status === "Active" ? "Active" : "Disable",
          profile: userDetail?.[0]?.profile,
          fid: userDetail?.[0]?.fid,
          uid: userDetail?.[0]?.uid,
          user_level: userDetail?.[0]?.user_level,
        }
      : info?.step1;

      useEffect(() => {
  const handleData = (d) => {
    if (d?.[0]?.profile && img?.length === 0) {
      imgUrlToFile(d?.[0]?.profile, d?.[0]?.fid, setImg);
      setFileId(d?.[0]?.fid);
    }
    setLocal(d?.[0]?.foreign_user === "True" ? false : true);
  };
if(info?.file) {
  setFileId(info?.file)
}
  if (success) {
    handleData(success);
  }
  if (userDetail) {
    handleData(userDetail);
  }
}, [success, userDetail]);

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);


  const handleClear = () => {
    resetForm();
    navigate(-1);
  };
  const onSearch = (val) => {
    if (!val) return;
    let filtered = masterData.nationality.filter(
      (obj) =>
        obj.key !== 0 &&
        obj.value.toString().toLowerCase().includes(val.toLowerCase())
    );
    setOptions(filtered);
  };
  const onSelect = (value) => {};
  const formik = useFormik({
    initialValues: {
      nationality: data ? data?.nationality || "South Africa" : "South Africa",
      iDNumber: data ? data?.iDNumber : "",
      passportNumber: data ? data?.passportNumber : "",
      firstName: data ? data?.firstName : "",
      lastName: data ? data?.lastName : "",
      emailAddress: data ? data?.emailAddress : "",
      contactNumber: data ? data?.contactNumber : "",
      dateofBirth: data ? data?.dateofBirth : "",
      race: data ? data?.race : "",
      gender: data ? data?.gender : "",
      differentlyAbled: data ? data?.differentlyAbled || "None" : "None",
    },
    validationSchema: isLocal ? ScorerLocalSchema : ScorerSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: values,
        step2: info?.step2,
        file: fileId,
        files:info?.files,
        editData: isEdit || isView || isExistingUser ? data : {},
        existingUserData: isExistingUser ? userDetail?.[0] : {},
        userExisting: isExistingUser ? isExistingUser : false,
        userExistingRole: isExistingUser
          ? userDetail?.[0]?.roles.split(",")
          : null,
      });
      setStep(2);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  useEffect(() => {
    if (values.iDNumber?.length === 13) {
      getUserDetailById(values.iDNumber, true);
    }
  }, [values?.iDNumber]);

  useEffect(() => {
    if (
      userDetail &&
      userDetail?.length > 0 &&
      !isEdit &&
      !isView &&
      !values.firstName
    ) {
      confirm({
        title: "Confirm",
        content:
          "This user already registered for another role.Do you still want to continue with new role?",
        onOk: () => setExistingUser(true),
        onCancel: () => resetForm(),
        okText: "Yes",
        cancelText: "No",
        okButtonProps: { style: { backgroundColor: "#007237" } },
        cancelButtonProps: {
          style: { backgroundColor: "#FFCB03", color: "#007237" },
        },
      });
    }
  }, [userDetail]);
  const checkValidity = () => {
    const {
      nationality,
      iDNumber,
      passportNumber,
      firstName,
      lastName,
      emailAddress,
      dateofBirth,
      race,
      gender,
      differentlyAbled,
    } = values;
    const isVAlid = isLocal
      ? nationality &&
        iDNumber &&
        firstName &&
        lastName &&
        emailAddress &&
        dateofBirth &&
        race &&
        gender &&
        differentlyAbled &&
        Object.keys(errors).length === 0
      : nationality &&
        passportNumber &&
        firstName &&
        lastName &&
        emailAddress &&
        dateofBirth &&
        race &&
        gender &&
        differentlyAbled &&
        Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    // const file = data1?.file;
    setUploading(true)
    const reader = new FileReader();
    reader.onload = function (e) {
      const blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      const fileName = file.name;
      const headers = {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + localStorage.getItem("csa_token"),
        "Content-Disposition": `file;filename="${fileName}"`,
      };
      axios
        .post(serviceUrl.imgUpload, blob, {
          headers: headers,
        })
        .then((res) => {
          setFileId(res?.data?.fid?.[0]?.value);
          onSuccess(file);
        })
        .catch((err) => {
          onError({ event: err });
        }).finally(() => {
          setUploading(false);
        });
    };
    reader.readAsArrayBuffer(file);
    return true;
  };

  const props = {
    name: "file",
    fileList: img,
    customRequest: uploadImage,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setImg(info?.fileList);
    },
    onRemove() {
      setFileId(null);
    },
  };
  return isView ? (
    <div>
      <Row>
        {fetching ? (
          <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <ScorerView viewData={data} />
        )}
      </Row>
    </div>
  ) : (
    <div>
      <CsaStepper steps={steps} activeKey={0} />
      {fetching ? (
        <Spin />
      ) : (
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="mt-3 pt-3"
          align="Start"
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className={styles.imageUpload}
          >
            <CsaImageUpload {...props} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <p className={styles.name}>
              {"Nationality"}
              {<span className={styles.required}>*</span>}
            </p>
            <AutoComplete
              style={{
                width: "100%",
              }}
              allowClear
              options={options}
              name="nationality"
              required
              onChange={(e) => {
                setFieldValue("nationality", e);
                setLocal(e === "South Africa");
              }}
              defaultValue={values.nationality || "South Africa"}
              value={values.nationality}
              onSelect={(val, option) => onSelect(val, option.label)}
              onSearch={onSearch}
              onBlur={(e) => {
                handleBlur(e);
                const input = e.target.value;
                if (input) {
                  let filtered = masterData.nationality?.find(
                    (val) => val?.value?.toLowerCase() === input?.toLowerCase()
                  );
                  if (filtered) {
                    setFieldValue("nationality", filtered?.value);
                  } else {
                    setFieldValue("nationality", "");
                  }
                }
              }}
              error={errors.nationality || null}
              disabled={
                authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT"
                  ? null
                  : isEdit
              }
            ></AutoComplete>
            {errors.nationality && (
              <pre className="text-danger">{errors.nationality}</pre>
            )}
          </Col>

          {isLocal && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="ID Number"
                placeholder="Enter ID Number"
                type="text"
                name="iDNumber"
                maxLength={13}
                onChange={handleChange}
                value={values.iDNumber}
                required={isLocal}
                onBlur={handleBlur}
                touched={touched.iDNumber}
                error={errors.iDNumber || null}
                disabled={
                  authRole === "CSA ADMINISTRATOR" ||
                  authRole === "CSA ADMIN ASSISTANT"
                    ? null
                    : isEdit
                }
                isNumber={true}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Passport Number"
              placeholder="Enter Passport Number"
              type="text"
              name="passportNumber"
              onChange={handleChange}
              value={values.passportNumber}
              required={!isLocal}
              onBlur={handleBlur}
              touched={touched.passportNumber}
              error={errors.passportNumber || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="First Name"
              placeholder="Enter First Name"
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
              required
              onBlur={handleBlur}
              touched={touched.firstName}
              error={errors.firstName || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Last Name"
              placeholder="Enter Last Name"
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
              required
              onBlur={handleBlur}
              touched={touched.lastName}
              error={errors.lastName || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Email Address"
              placeholder="Enter Email Address"
              type="text"
              name="emailAddress"
              onChange={handleChange}
              value={values.emailAddress}
              required
              onBlur={handleBlur}
              touched={touched.emailAddress}
              error={errors.emailAddress || null}
              disabled={
                // authRole === "CSA ADMINISTRATOR" ||
                // authRole === "CSA ADMIN ASSISTANT"
                isCSA  ? null
                  : isEdit
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Contact Number"
              placeholder="Enter Contact Number"
              type="text"
              name="contactNumber"
              maxLength={10}
              onChange={handleChange}
              value={values.contactNumber}
              onBlur={handleBlur}
              touched={touched.contactNumber}
              error={errors.contactNumber || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaDatePicker
              label="Date of Birth"
              placeholder="Select Date of Birth"
              name="dateofBirth"
              setFieldValue={setFieldValue}
              value={values.dateofBirth}
              required
              onBlur={handleBlur}
              touched={touched.dateofBirth}
              error={errors.dateofBirth || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaDropDown
              label="Race"
              placeholder="Select Race"
              required
              name="race"
              value={values.race}
              menuArr={masterData.raceList}
              onChange={(e) => {
                setFieldValue("race", e);
              }}
              onBlur={() => setFieldTouched("race", true)}
              touched={touched.race}
              error={errors.race || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaDropDown
              label="Gender"
              placeholder="Select Gender"
              required
              name="gender"
              value={values.gender}
              menuArr={masterData.genderList}
              onChange={(e) => {
                setFieldValue("gender", e);
              }}
              onBlur={() => setFieldTouched("gender", true)}
              touched={touched.gender}
              error={errors.gender || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaDropDown
              label="Differently Abled"
              placeholder="Select Differently Abled"
              required
              name="differentlyAbled"
              value={values.differentlyAbled}
              menuArr={masterData.differentlyAbled}
              onChange={(e) => {
                setFieldValue("differentlyAbled", e);
              }}
              defaultValue={values.differentlyAbled || "None"}
              onBlur={() => setFieldTouched("differentlyAbled", true)}
              touched={touched.differentlyAbled}
              error={errors.differentlyAbled || null}
            />
          </Col>
          <div className="mt-4 d-flex w-100 justify-content-end">
            <Space>
              <CsaButton
                label="Cancel"
                textColor="#007237"
                onClick={() => handleClear()}
                btnColor="#FFCB03"
              />
              <CsaButton
                label="Continue"
        loading={uploading}
                textColor="#FFF"
                onClick={() => handleSubmit()}
                btnColor="#007237"
                disabled={checkValidity()}
              />
            </Space>
          </div>
        </Row>
      )}
    </div>
  );
}

export default ScorerRegisterForm;
