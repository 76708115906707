import { Card, Col, Row, Avatar, Image, Tag } from "antd";
import React from "react";

export default function MemberView(props) {
 const { viewData } = props;
const isLocal = viewData?.nationality === "South Africa"
 console.log(viewData,isLocal,"vv")

 return (
  <Card
   style={{
    width: "100%",
    backgroundColor: "#f1f1f1",
    justifyContent: "center",
    borderRadius: 15,
    borderWidth: 0.5,
    borderColor: "#f3f3f3",
   }}
  >
   <Row>
    {viewData && (
     <>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Province</h6>
       <p>{viewData?.province || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Address</h6>
       <p>{viewData?.address || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Members Name</h6>
       <p>{viewData?.membersName || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Web Address</h6>
       <p>{viewData?.webAddress || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Nationality</h6>
       <p>{viewData?.nationality}</p>
      </Col>
     {isLocal && <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">ID Number</h6>
       <p>{viewData?.iDNumber || "N/A"}</p>
      </Col>}
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Passport No.</h6>
       <p>{viewData?.passportNumber || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Email Address</h6>
       <p>{viewData?.emailAddress || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">First Name</h6>
       <p>{viewData?.firstName || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Last Name</h6>
       <p>{viewData?.lastName || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Contact No.</h6>
       <p>{viewData?.contactNumber || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Date of Birth</h6>
       <p>{viewData?.dateofBirth || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Race</h6>
       <p>{viewData?.race || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Gender</h6>
       <p>{viewData?.gender || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Differently Abled</h6>
       <p>{viewData?.differentlyAbled || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Status</h6>
       <p>
        {viewData?.status === "Active" ? "Activated" : "Disabled" || "N/A"}
       </p>
      </Col>
     </>
    )}
   </Row>
  </Card>
 );
}
