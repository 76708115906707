import { Modal, Table } from "antd";
import React from "react";
import nologo from "../../images/nologoclub.png";

import styles from "./BattingOverlay.module.scss";
import { generateWicketName } from "Common/TournamentsScoreCard/TournamentHelpers";
import { fixImage, getShortenedName } from "utils/helpers";
import OverlayModal from "Common/OverlaysModal/OverlayModal";

function setFontsizeByChar(v, n) {
  let x;
  const M = n ? n : 15;
  if (v <= M) {
    x = 2;
  } else {
    const numberOfDecrements = Math.floor((v - M) / 1);
    x = 2 - numberOfDecrements * 0.1;
  }
  console.log(v, x, "lll");
  return `${x}vw`;
}

function BattingOverlay({ matchData, innings, loading }) {
  const batTeamArr = innings;

  const batTeamName = batTeamArr?.battingTeamName;
  const bowlTeamName = batTeamArr?.bowlingTeamName;
  const currentScore = Number(batTeamArr?.teamSummary?.score);
  const currentWickets = batTeamArr?.teamSummary?.wickets;
  const currentOvers = batTeamArr?.teamSummary?.overs;
  const totaltOvers = batTeamArr?.targetOvers;
  const curentRR = batTeamArr?.teamSummary?.runRate || 0;
  const teamSummary = batTeamArr?.teamSummary;

  const batTeamLogo = matchData?.teams?.find(
    (i) => i.teamId === batTeamArr?.battingTeamId
  )?.teamLogo;
  const bowlTeamLogo = matchData?.teams?.find(
    (i) => i.teamId !== batTeamArr?.battingTeamId
  )?.teamLogo;

  const batSummaryArr = batTeamArr?.summaryBatting?.map((i) => ({
    name: getShortenedName(i?.batsman?.name?.toUpperCase(), 21),
    runs: i?.runs + " (" + i?.balls + ")",
    wicket: generateWicketName(i, 12, true),
    id: i?.batsman?.playerId,
  }));

  const existingNamesSet = new Set(batSummaryArr?.map((item) => item.id));

  const batting11 = matchData?.teams
    ?.find((i) => i.teamId === batTeamArr?.battingTeamId)
    ?.players?.map((p) => {
      const playerId = p?.playerId;
      const playerName = p?.name;

      if (!existingNamesSet.has(playerId)) {
        batSummaryArr.push({
          name: getShortenedName(playerName, 21),
          runs: "",
          wicket: "",
        });
        existingNamesSet.add(playerName);
      }
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "40%",
      render(text, record) {
        return {
          props: {
            style: {
              whiteSpace: "nowrap",
              textTransform: "uppercase",
              overflow: "hidden",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Wicket",
      dataIndex: "wicket",
      key: "wicket",
      align: "left",
      width: "50%",
      render(text, record) {
        return {
          props: {
            style: {
              color: "yellow",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "runs",
      dataIndex: "runs",
      key: "runs",
      align: "right",
      width: "18%",
    },
  ];

  // const testName = "dp world lions club";

  return (
    <OverlayModal>
      <div className={styles.mainWrapper}>
        {batTeamArr && (
          <div className={styles.teamNameWrapper}>
            <div>
              <h3
                style={{
                  fontSize: setFontsizeByChar(batTeamName?.length, 15),
                  width: "80%",
                }}
              >
                {batTeamName?.toUpperCase()}
              </h3>
              <h3 className={styles.scoreText}>
                {currentScore}/{currentWickets}
              </h3>
            </div>
            <span>
              <p>vs</p>{" "}
              <h3
                style={{
                  fontSize: setFontsizeByChar(bowlTeamName?.length, 15),
                  maxWidth: "90%",
                }}
              >
                {bowlTeamName?.toUpperCase()}
              </h3>
            </span>
          </div>
        )}
        <div className={styles.logoTableContainer}>
          <img
            className={styles.teamLogo}
            src={fixImage(batTeamLogo)}
            alt="Team Logo 2"
          />
          <div className={styles.tableWrapper}>
            <Table
              showHeader={false}
              className="overlay-table"
              columns={columns}
              dataSource={batSummaryArr}
              pagination={false}
              size={"small"}
            />
            {/* {batSummaryArr.map((item, index) => {
                return (
                  <div key={index} className={styles.battingList}>
                    <p>{item.name}</p> <p>{item.wicket}</p> <p>{item.runs}</p>
                  </div>
                );
              })} */}
            {batTeamArr && (
              <div className={styles.extrasRow}>
                <p>OVERS: {currentOvers}</p>{" "}
                <p>
                  EXTRAS: {teamSummary?.extras} ({teamSummary?.wides} WD,{" "}
                  {teamSummary?.legByes} LB, {teamSummary?.byes} B,{" "}
                  {teamSummary?.noBalls} NB)
                </p>
              </div>
            )}
          </div>
          <img
            className={styles.teamLogo}
            src={fixImage(bowlTeamLogo)}
            alt="Team Logo 2"
          />
        </div>
      </div>
    </OverlayModal>
  );
}

export default BattingOverlay;
