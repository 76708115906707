import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination, Spin } from "antd";
import * as Yup from "yup";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./Coaches.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { findHightestLevel } from "utils/helpers";
import CsaDropDown from "Common/CsaDropDown";
import CsaFileUpload from "Common/CsaFileUpload";
import CsaTextInput from "Common/CsaTextInput";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";

const ClubAdminSchema = Yup.object().shape({
  field_club_constitution_avilable: Yup.string().required(
    "Select Club Constitution"
  ),
  field_code_of_conduct_available: Yup.string().required(
    "Select Code of conduct"
  ),
  field_general_admin_inventory_av: Yup.string().required(
    "Select select general admin inventory"
  ),
  field_annual_general_meeting_agm: Yup.string().required(
    "Select annual general meeting"
  ),
  field_agm_minutes_available: Yup.string().required("Select agm minutes"),
  field_chair_person_appointed: Yup.string().required("Select chair person"),
  field_secretary_appointed: Yup.string().required("Select seceretary"),
  field_treasurer_appointed: Yup.string().required("Select treasurer"),
  field_responsible_for_facilities: Yup.string().required(
    "Select responsible for facilities"
  ),
});

const ClubAdministration = () => {
  const [uploadCert, setUploadCert] = useState([]);
  const sub_member_id = localStorage.getItem("sub_member_id");
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const {
    callAPI: getClubAdministration,
    response: resClubAdministration,
    error: errClubAdministration,
    loading: loadClubAdministration,
  } = useAxios({
    url: serviceUrl.clubAdministration,
    method: "get",
  });
  const hasEditData = resClubAdministration?.club_id === sub_member_id;

  const { callAPI, response, error, loading } = useAxios({
    url: hasEditData
      ? serviceUrl.clubAdministration + sub_member_id
      : serviceUrl.clubAdministration,
    method: hasEditData ? "put" : "post",
  });

  useEffect(() => {
    getClubAdministration(sub_member_id, true);
  }, []);

  useEffect(() => {
    if (response) {
      addToast(
        `${
          hasEditData ? "Updated" : "Created"
        } Club Administrator successfully`,
        {
          appearance: "success",
        }
      );
    }
    if (error) {
      addToast(error.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  console.log(resClubAdministration, "resClub");
  const editData = resClubAdministration || [];
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Club Administration", value: "/club-administration" },
  ];

  const formik = useFormik({
    initialValues: {
      field_club_constitution_avilable:
        editData?.field_club_constitution_avilable || "",
      field_code_of_conduct_available:
        editData?.field_code_of_conduct_available || "",
      field_general_admin_inventory_av:
        editData?.field_general_admin_inventory_av || "",
      field_annual_general_meeting_agm:
        editData?.field_annual_general_meeting_agm || "",
      field_agm_minutes_available: editData?.field_agm_minutes_available || "",
      field_chair_person_appointed:
        editData?.field_chair_person_appointed || "",
      field_secretary_appointed: editData?.field_secretary_appointed || "",
      field_secretary_name_and_surname:
        editData?.field_secretary_name_and_surname || "",
      field_treasurer_appointed: editData?.field_treasurer_appointed || "",
      field_treasurer_name_and_surname:
        editData?.field_treasurer_name_and_surname || "",
      field_responsible_for_facilities:
        editData?.field_responsible_for_facilities || "",
      field_chair_person_name_and_surn:
        editData?.field_chair_person_name_and_surn || "",
    },
    validationSchema: ClubAdminSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (hasEditData) {
        callAPI({
          ...values,
          id: sub_member_id,
          field_club_constitution: uploadCert?.find(
            (item) => item?.key === "field_club_constitution"
          )?.value,
          field_general_admin_inventory: uploadCert?.find(
            (item) => item?.key === "field_general_admin_inventory"
          )?.value,
          field_code_of_conduct: uploadCert?.find(
            (item) => item?.key === "field_code_of_conduct"
          )?.value,
          nid: editData?.nid,
        });
      } else {
        callAPI({
          ...values,

          club_name: localStorage.getItem("sub_member_name"),
          club_id: sub_member_id,
          field_club_constitution: uploadCert?.find(
            (item) => item?.key === "field_club_constitution"
          )?.value,
          field_general_admin_inventory: uploadCert?.find(
            (item) => item?.key === "field_general_admin_inventory"
          )?.value,
          field_code_of_conduct: uploadCert?.find(
            (item) => item?.key === "field_code_of_conduct"
          )?.value,
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    setFieldValue,
  } = formik;

  console.log(uploadCert, "certificates");
  return (
    <Wrapper>
      <div className={styles.CoachesWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Club Administration</p>
          {loadClubAdministration ? (
            <Spin size="large" />
          ) : (
            <>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 24,
                }}
                className="mt-3 pt-3"
                align="Start"
              >
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="Club Constitution Available"
                    // placeholder="Select Level1"
                    required
                    name="field_club_constitution_avilable"
                    value={values.field_club_constitution_avilable}
                    menuArr={["Yes", "No"]}
                    onChange={(e) => {
                      setFieldValue("field_club_constitution_avilable", e);
                    }}
                    // defaultValue={values.level1 || "No"}
                    // onBlur={handleBlur}
                    touched={touched.field_club_constitution_avilable}
                    error={errors.field_club_constitution_avilable || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <CsaFileUpload
                    label="Club Constitution"
                    type="file"
                    name="field_club_constitution"
                    setFile={setUploadCert}
                    files={uploadCert}
                    editFile={editData?.field_club_constitution}
                    // disabled={values?.level1 === "No" ? true : false}
                  />
                </Col>{" "}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="Code of Conduct Available"
                    //  placeholder="Select Level1"
                    required
                    name="field_code_of_conduct_available"
                    value={values.field_code_of_conduct_available}
                    menuArr={["Yes", "No"]}
                    onChange={(e) => {
                      setFieldValue("field_code_of_conduct_available", e);
                    }}
                    // required
                    // defaultValue={values.level1 || "No"}
                    // onBlur={handleBlur}
                    touched={touched.field_code_of_conduct_available}
                    error={errors.field_code_of_conduct_available || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <CsaFileUpload
                    label="Code of Conduct"
                    type="file"
                    name="field_code_of_conduct"
                    setFile={setUploadCert}
                    files={uploadCert}
                    editFile={editData?.field_code_of_conduct}
                    // disabled={values?.level1 === "No" ? true : false}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="General Admin Inventory Available"
                    //  placeholder="Select Level1"
                    required
                    name="field_general_admin_inventory_av"
                    value={values.field_general_admin_inventory_av}
                    menuArr={["Yes", "No"]}
                    onChange={(e) => {
                      setFieldValue("field_general_admin_inventory_av", e);
                    }}
                    // required
                    // defaultValue={values.level1 || "No"}
                    // onBlur={handleBlur}
                    touched={touched.field_general_admin_inventory_av}
                    error={errors.field_agm_minutes_available || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <CsaFileUpload
                    label="General Admin Inventory"
                    type="file"
                    name="field_general_admin_inventory"
                    setFile={setUploadCert}
                    files={uploadCert}
                    editFile={editData?.field_general_admin_inventory}
                    // disabled={values?.level1 === "No" ? true : false}
                  />
                </Col>
              </Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CsaDropDown
                  label="Annual General Meeting (AGM) "
                  //  placeholder="Select Level1"
                  required
                  name="field_annual_general_meeting_agm"
                  value={values.field_annual_general_meeting_agm}
                  menuArr={["Yes", "No"]}
                  onChange={(e) => {
                    setFieldValue("field_annual_general_meeting_agm", e);
                  }}
                  // required
                  // defaultValue={values.level1 || "No"}
                  // onBlur={handleBlur}
                  touched={touched.field_annual_general_meeting_agm}
                  error={errors.field_annual_general_meeting_agm || null}
                />
              </Col>{" "}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CsaDropDown
                  label="AGM Minutes Available"
                  //  placeholder="Select Level1"
                  required
                  name="levfield_agm_minutes_availableel1"
                  value={values.field_agm_minutes_available}
                  menuArr={["Yes", "No"]}
                  onChange={(e) => {
                    setFieldValue("field_agm_minutes_available", e);
                  }}
                  // required
                  // defaultValue={values.level1 || "No"}
                  // onBlur={handleBlur}
                  touched={touched.field_agm_minutes_available}
                  error={errors.field_agm_minutes_available || null}
                />
              </Col>
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 24,
                }}
                className="mt-3 pt-3"
                align="Start"
              >
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="Chair Person Appointed"
                    //  placeholder="Select Level1"
                    required
                    name="field_chair_person_appointed"
                    value={values.field_chair_person_appointed}
                    menuArr={["Yes", "No"]}
                    onChange={(e) => {
                      setFieldValue("field_chair_person_appointed", e);
                    }}
                    // required
                    // defaultValue={values.level1 || "No"}
                    // onBlur={handleBlur}
                    touched={touched.field_chair_person_appointed}
                    error={errors.field_chair_person_appointed || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <CsaTextInput
                    label="Chair Person Name and Surname"
                    // placeholder="Enter First Name"
                    type="text"
                    name="field_chair_person_name_and_surn"
                    onChange={(e) => {
                      setFieldValue(
                        "field_chair_person_name_and_surn",
                        e.target.value
                      );
                    }}
                    //   onChange={handleChange}
                    value={values.field_chair_person_name_and_surn}
                    //   required
                    //   onBlur={handleBlur}
                    //   touched={touched.field_chair_person_name_and_surn}
                    // error={errors.firstName || null}
                  />
                </Col>{" "}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="Seceretary Appointed"
                    //  placeholder="Select Level1"
                    required
                    name="field_secretary_appointed"
                    value={values.field_secretary_appointed}
                    menuArr={["Yes", "No"]}
                    onChange={(e) => {
                      setFieldValue("field_secretary_appointed", e);
                    }}
                    // required
                    // defaultValue={values.level1 || "No"}
                    // onBlur={handleBlur}
                    touched={touched.field_secretary_appointed}
                    error={errors.field_secretary_appointed || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <CsaTextInput
                    label="Seceretary Name and Surname"
                    //  placeholder="Enter First Name"
                    type="text"
                    name="field_secretary_name_and_surname"
                    onChange={(e) => {
                      setFieldValue(
                        "field_secretary_name_and_surname",
                        e.target.value
                      );
                    }}
                    value={values.field_secretary_name_and_surname}
                    //   required
                    //   onBlur={handleBlur}
                    //   touched={touched.firstName}
                    //   error={errors.firstName || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CsaDropDown
                    label="Treasurer Appointed"
                    //  placeholder="Select Level1"
                    required
                    name="field_treasurer_appointed"
                    value={values.field_treasurer_appointed}
                    menuArr={["Yes", "No"]}
                    onChange={(e) => {
                      setFieldValue("field_treasurer_appointed", e);
                    }}
                    // required
                    // defaultValue={values.level1 || "No"}
                    // onBlur={handleBlur}
                    touched={touched.field_treasurer_appointed}
                    error={errors.field_treasurer_appointed || null}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <CsaTextInput
                    label="Treasurer Name and Surname"
                    // placeholder="Enter First Name"
                    type="text"
                    name="field_treasurer_name_and_surname"
                    onChange={(e) => {
                      setFieldValue(
                        "field_treasurer_name_and_surname",
                        e.target.value
                      );
                    }}
                    value={values.field_treasurer_name_and_surname}
                    //   required
                    //   onBlur={handleBlur}
                    //   touched={touched.firstName}
                    //   error={errors.firstName || null}
                  />
                </Col>
              </Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CsaDropDown
                  label="Responsible for Facilites"
                  //  placeholder="Select Level1"
                  required
                  name="field_responsible_for_facilities"
                  value={values.field_responsible_for_facilities}
                  menuArr={["Yes", "No"]}
                  onChange={(e) => {
                    setFieldValue("field_responsible_for_facilities", e);
                  }}
                  // required
                  // defaultValue={values.level1 || "No"}
                  // onBlur={handleBlur}
                  touched={touched.field_responsible_for_facilities}
                  error={errors.field_responsible_for_facilities || null}
                />
              </Col>
            </>
          )}
        </div>
      </div>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 16,
          lg: 16,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
          <div className="mt-4 d-flex w-100 justify-content-end ">
            <CsaButton
              label="Cancel"
              textColor="#007237"
              onClick={() => navigate("/home")}
              btnColor="#FFCB03"
            />
            <CsaButton
              label="Submit"
              textColor="#FFF"
              onClick={() => handleSubmit()}
              btnColor="#007237"
              disabled={loadClubAdministration || !values}
              loading={loading}
            />
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ClubAdministration;
