import React, { useState } from "react";
//Components
import CsaChangePasswordForm from "../../Common/CsaChangePasswordForm";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./ChangePassword.module.scss";

function ChangePassword() {
 const [step, setStep] = useState(1);
 const [info, setInfo] = useState({ step1: {} });
 const props = {
  styles,
  setStep,
  info,
  setInfo,
 };

 const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Update Password", value: "/update-password" },
  ];
 return (
  <Wrapper>
   <div className={styles.ChangePasswordWrapper}>
    <CsaBreadcrumb pathArr={pathArr} />
    <div className={styles.contents}>
     <p className={styles.heading}>Change Password</p>
     <div>{step === 1 && <CsaChangePasswordForm {...props} />}</div>
    </div>
   </div>
  </Wrapper>
 );
}

export default ChangePassword;
