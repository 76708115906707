import { Card, Col, Row, Avatar, Image } from "antd";
import React from "react";

export default function MyProfileView(props) {
 const { viewData } = props;

 return (
  <Card
   style={{
    width: "100%",
    backgroundColor: "#f1f1f1",
    justifyContent: "center",
    borderRadius: 15,
    borderWidth: 0.5,
    borderColor: "#f3f3f3",
   }}
  >
   <Row>
    {viewData && (
     <>
      <Col span={24} className="w-100 mb-3 d-flex justify-content-center">
       {viewData?.profile ? (
        <Avatar
         size={150}
         src={
          <Image
           src={viewData?.profile}
           style={{
            width: 150,
            height: 150,
           }}
          />
         }
        />
       ) : (
        <Avatar size={150}>CSA </Avatar>
       )}
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Nationality</h6>
       <p>{viewData?.nationality}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">ID Number</h6>
       <p>{viewData?.iDNumber || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Passport No.</h6>
       <p>{viewData?.passportNumber || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Email Address</h6>
       <p>{viewData?.emailAddress || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">First Name</h6>
       <p>{viewData?.firstName || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Last Name</h6>
       <p>{viewData?.lastName || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Contact No.</h6>
       <p>{viewData?.contactNumber || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Date of Birth</h6>
       <p>{viewData?.dateofBirth || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Race</h6>
       <p>{viewData?.race || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Gender</h6>
       <p>{viewData?.gender || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Differently Abled</h6>
       <p>{viewData?.differentlyAbled || "Nil"}</p>
      </Col>

      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Status</h6>
       <p>
        {viewData?.status === "Active" ? "Activated" : "Disabled" || "Nil"}
       </p>
      </Col>
     </>
    )}
   </Row>
  </Card>
 );
}
