import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Spin } from "antd";
//Components
import CompetitionInformation from "../../Common/CompetitionInformation&Format";
import CompetitionFormat from "../../Common/CompetitionInformation&Format/CompetitionFormat";
import CompetitionScorerUmpire from "../../Common/CompetitionScorerUmpire";
import CMSUmpires from "../../Common/CompetitionScorerUmpire/CMSUmpires";
import CompetitionMembers from "../../Common/CompetitionMembersTeamsVenues/CMSMember";
import CMSTeams from "../../Common/CompetitionMembersTeamsVenues/CMSTeams";
import CMSVenues from "../../Common/CompetitionMembersTeamsVenues/CMSVenues";
import CMSPool from "../../Common/CompetitionPoolsSchedule";
import CMSSchedule from "../../Common/CompetitionPoolsSchedule/CMSSchedule";
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./CompetitionManagementIndex.module.scss";
import CMSView from "../../Common/CompetitionInformation&Format/CMSView";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  setCMSData,
  setCmsId,
  setCurrentTabState,
} from "redux/slice/commonSlice";
import CmsStreaming from "Common/CmsStreaming";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

const { TabPane } = Tabs;
function CompetitionManagement() {
  const tabStateChange =
    useSelector((state) => state.auth.tabCurrentState) || {};

  const [img, setImg] = useState([]);
  const [img1, setImg1] = useState([]);
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [streamLogos, setStreamLogos] = useState({
    ltLogo: [
      {
        uid: "https://csa-team-prod.s3-ap-south-1.amazonaws.com/1709877378.png",
        name: "pitchvision_logo",
        status: "done",
        url: "https://csa-team-prod.s3-ap-south-1.amazonaws.com/1709877378.png",
      },
    ],
    rtLogo: [],
    spLogo1: [],
    spLogo2: [],
    spLogo3: [],
    spLogo4: [],
    spLogo5: [],
  });
  //const [tabKey,setTabKey] = useState=("1")
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const onKeyChange = (key) => {
    // setTabKey(key)

    //   if (!isEdit ) {
    if (tabStateChange > key) {
      setActiveKey(key);
      setStep(1);
    } else {
      addToast(`Please complete step ${key - 1} to proceed`, {
        appearance: "warning",
      });
    }
    //   }
    //   else {
    //    setActiveKey(key);
    //    setStep(1);
    //   }
  };
  const cmsID = useSelector((state) => state.auth.cms_id);

  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
    img,
    setImg,
    img1,
    setImg1,
    setActiveKey,
    streamLogos,
    setStreamLogos,
  };
  const location = useLocation();
  const id = location?.state?.id;
  const type = location?.state?.type;
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Competition Managements", value: "/cms-list" },
    { key: "Competition Management", value: "/cms-create" },
  ];
  const {
    callAPI: fetchDetail,
    response: success,
    error: err,
    loading: fetching,
  } = useAxios({
    url: serviceUrl.getCMSDetail + `${id}`,
    method: "get",
  });

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  useEffect(() => {
    dispatch(setCurrentTabState(activeKey));
  }, [activeKey]);

  return (
    <Wrapper>
      <div className={styles.CoachesWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Competition
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          {isView ? (
            fetching ? (
              <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
                <Spin size="large" />
              </div>
            ) : (
              <CMSView viewData={success?.data} />
            )
          ) : (
            <>
              <div>
                <Tabs
                  type="card"
                  defaultActiveKey="1"
                  activeKey={activeKey}
                  onChange={onKeyChange}
                >
                  <TabPane tab="Comp Info & Format" key="1">
                    {step === 1 && <CompetitionInformation {...props} />}
                    {step === 2 && <CompetitionFormat {...props} />}
                  </TabPane>
                  <TabPane tab="Streaming Services" key="2">
                    <CmsStreaming {...props} />
                  </TabPane>
                  <TabPane tab="Members,Teams & Venues" key="3">
                    {step === 1 && <CompetitionMembers {...props} />}
                    {step === 2 && <CMSTeams {...props} />}
                    {step === 3 && <CMSVenues {...props} />}
                  </TabPane>

                  <TabPane tab="Scores & Umpires" key="4">
                    {step === 1 && <CompetitionScorerUmpire {...props} />}
                    {step === 2 && <CMSUmpires {...props} />}
                  </TabPane>
                  <TabPane tab="Pools & Schedule" key="5">
                    {step === 1 && <CMSPool {...props} />}
                    {step === 2 && <CMSSchedule {...props} />}
                  </TabPane>
                </Tabs>
              </div>
            </>
          )}
          {/* <CMSSchedule {...props} /> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default CompetitionManagement;
