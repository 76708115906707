import React, { useEffect, useState } from "react";
import { Table } from "antd";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";

export default function TournamentBattingStatsTable({ ...props }) {
  const { addToast } = useToasts();

  const { matchId } = props;
  
  const {
    callAPI: fetchStats,
    response: resStats,
    error: errStats,
    loading: loadStats,
  } = useAxios({
    url: serviceUrl.getTournamentStats,
    method: "get",
  });

  useEffect(() => {
    fetchStats(`${matchId}/batting`, true);
  }, []);
  useEffect(() => {
    if (errStats) {
      addToast(errStats?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [errStats]);
  let Pos = 1;
  const res = resStats?.data || [];
  

  const sort = res.sort(function (a, b) {
    let rn1 = a.bat_runs;
    let rn2 = b.bat_runs;

    let sr1 = a.bat_sr;
    let sr2 = b.bat_sr;

    let b1 = a.balls_faced;
    let b2 = b.balls_faced;

    if (rn1 > rn2) return -1;
    if (rn1 < rn2) return 1;

    if (sr1 > sr2) return -1;
    if (sr1 < sr2) return 1;

    if (b1 > b2) return -1;
    if (b1 < b2) return 1;

    return 0;
  });

  // 

  // 
  
  const resData = sort?.map((i) => ({
    position: Pos++,
    name: i?.name + " " + i?.surname,
    team: i?.team,
    games: i?.games,
    bat_runs: i?.bat_runs,
    balls_faced: i?.balls_faced,
    dot_balls: i?.dot_balls,
    bat_4s: i?.bat_4s,
    bat_6s: i?.bat_6s,
    fiftys: i?.bat_50s,
    hundreds: i?.bat_100s,
    bat_sr: i?.bat_sr?.toFixed(1) || 0,
    bat_avg: i?.bat_avg?.toFixed(1) || 0,
  }));

  const columns = [
    {
      title: "Pos",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Player",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Team Name",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Matches",
      dataIndex: "games",
      key: "games",
    },
    {
      title: "Runs",
      dataIndex: "bat_runs",
      key: "bat_runs",
    },
    {
      title: "Balls",
      dataIndex: "balls_faced",
      key: "balls_faced",
    },
    {
      title: "Dots",
      dataIndex: "dot_balls",
      key: "dot_balls",
    },
    {
      title: "4's",
      dataIndex: "bat_4s",
      key: "bat_4s",
    },
    {
      title: "6's",
      dataIndex: "bat_6s",
      key: "bat_6s",
    },
    {
      title: "50's",
      dataIndex: "fiftys",
      key: "50s",
    },
    {
      title: "100's",
      dataIndex: "hundreds",
      key: "100s",
    },
    {
      title: "Strike Rate",
      dataIndex: "bat_sr",
      key: "bat_sr",
    },
    {
      title: "Avg",
      dataIndex: "bat_avg",
      key: "bat_avg",
    },
  ];

  return (
    <>
      <Table
      className="center-aligned"
        //   className="table-striped-rows"
        loading={loadStats}
        columns={columns}
        dataSource={resData}
        pagination={false}
        scroll={{
          x: 800,
        }}
      />
    </>
  );
}
