import React, { useEffect, useState } from "react";
import styles from "./ScoreOverlay.module.scss";
import nologo from "../../images/nologoclub.png";
import sixAnimation from "../../images/sixanimation3.gif";
import tossImage from "../../images/toss.png";

import { Divider, Spin, Typography } from "antd";
import {
  calculateBallByBallRRR,
  findLastObj,
  fixImage,
  fixSpecialChar,
  getShortenedName,
  oversToBalls,
} from "utils/helpers";

const ScoreOverlay = ({
  showAnimation,
  innings,
  overs,
  loading,
  matchData,
  intervalType,
  testMatchData,
}) => {
  const [batters, setbatters] = useState({
    batterArr: null,
    wicketBatterId: "",
  });

  const { hasMultipleInns, matchStatus } = testMatchData;

  const batTeamArr = findLastObj(innings);
  const targetRuns = batTeamArr?.targetRuns;
  const isChasing = targetRuns > 0;
  const batTeamName = batTeamArr?.battingTeamName;
  const bowlTeamName = batTeamArr?.bowlingTeamName;
  const batTeamLogo = matchData?.teams?.find(
    (i) => i.teamId === batTeamArr?.battingTeamId
  )?.teamLogo;
  const bowlTeamLogo = matchData?.teams?.find(
    (i) => i.teamId !== batTeamArr?.battingTeamId
  )?.teamLogo;
  const currentScore = Number(batTeamArr?.teamSummary?.score);
  const currentWickets = batTeamArr?.teamSummary?.wickets;
  const currentOvers = batTeamArr?.teamSummary?.overs;
  const totaltOvers =
    batTeamArr?.targetOvers === 0 ? "" : batTeamArr?.targetOvers || 0;
  const curentRR = batTeamArr?.teamSummary?.runRate || 0;
  const runsGap = targetRuns - currentScore;
  const winStatus = matchData?.winMessage;
  const tossWonTeam = matchData?.toss?.teamId;

  const totalBalls = batTeamArr?.targetOvers * 6;
  const ballsFaced = oversToBalls(parseFloat(currentOvers));
  const ballByBallRRR = calculateBallByBallRRR(runsGap, totalBalls, ballsFaced);
  const hasSuperOver = innings?.find((i) => i.isSuperOver)?.isSuperOver;

  const batterArr = batTeamArr?.summaryBatting?.filter(
    (i) => i.wicketType === "Not Out"
  );

  const strikerArr = batTeamArr?.summaryBatting?.find(
    (player) => player?.battingPlayerId === batTeamArr?.battingPlayer1Id
  );

  const nonStrikerArr = batTeamArr?.summaryBatting?.find(
    (player) => player?.battingPlayerId === batTeamArr?.battingPlayer2Id
  );

  function setExtrasType(str, val, runs, wkt) {
    const shorted =
      (str === "legbyes" && "lb") ||
      (str === "wide" && "wd") ||
      (str === "noball" && "nb") ||
      (str === "byes" && "b");
    return wkt ? "W" : shorted === "nb" ? runs + shorted : val + shorted;
  }

  const filterOverByInns =
    overs?.length > 1 ? overs?.find((i) => i.id === "1") : overs[0];
  const currentOver = findLastObj(filterOverByInns?.overs);
  const mapDeliveries = currentOver?.deliveries?.map((balls) =>
    balls.extrasType || balls.wicketType
      ? setExtrasType(
          balls.extrasType,
          balls.extras,
          balls.runs,
          balls.wicketType
        )
      : balls.runs
  );
  const bowlerArr = batTeamArr?.summaryBowling?.find(
    (player) => player?.bowlingPlayerId === batTeamArr?.bowlingPlayerId
  );

  useEffect(() => {
    if (batterArr?.length === 2) {
      setbatters((prevData) => ({
        batterArr: batterArr,
        wicketBatterId: "",
      }));
    } else if (batterArr?.length === 1 && batters.batterArr) {
      setbatters((prevData) => ({
        batterArr: prevData.batterArr,
        wicketBatterId: prevData?.batterArr?.find(
          (i) => i.battingPlayerId !== batterArr[0]?.battingPlayerId
        )?.battingPlayerId,
      }));
    } else {
      setbatters((prevData) => ({
        batterArr: batterArr,
        wicketBatterId: "",
      }));
    }
  }, [batTeamArr]);

  console.log(batterArr, "bats");

  // const matchStatus =
  // (runsGap === 0 && "Scores level") ||
  // (bowlTeamTotal > batTeamTotal && " trail by " + runsGap + " runs") ||
  // (bowlTeamTotal < batTeamTotal && " lead by " + runsGap + " runs");

  const [cuttentStatIdx, setCurrentStatIndex] = useState(0);
  const p = findLastObj(batTeamArr?.partnerships)?.score || 0;
  const partnership = "PARTNERSHIP - " + p + " RUNS";
  const statsArr = [
    isChasing && !hasMultipleInns ? (
      <>
        RRA - {curentRR}{" "}
        <p
          style={{
            fontSize: "1.8vw",
            color: "white",
            textAlign: "center",
            marginRight: "0.8%",
            marginLeft: "0.8%",
            marginBottom: "0.5%",
          }}
        >
          |
        </p>
        RRR -{" "}
        {ballByBallRRR &&
        ballByBallRRR !== "infinity" &&
        ballByBallRRR !== "isNaN" &&
        typeof ballByBallRRR !== "string"
          ? ballByBallRRR?.toFixed(1)
          : 0}
      </>
    ) : (
      "RRA - " + curentRR
    ),
    partnership,
    hasMultipleInns
      ? matchStatus
      : isChasing && !hasMultipleInns
      ? "NEED " + runsGap + " MORE RUNS TO WIN"
      : undefined,
  ].filter(Boolean);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (statsArr.length) {
        setCurrentStatIndex((prevIndex) => (prevIndex + 1) % statsArr.length);
      }
    }, 10000);
    //  setCurrentStat(statsArr[cuttentStatIdx]);
    return () => clearInterval(intervalId);
  }, [statsArr]);

  const currentStat = statsArr[cuttentStatIdx];

  console.log(getShortenedName("Robin Singh", 12));

  return (
    <div className={styles.ovWrapper}>
      {loading || !batTeamArr ? (
        <div className=" d-flex align-items-center ">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.topSectionWraper}>
            <sapn className={styles.teamName}>
              <h5>{batTeamName?.toUpperCase()} </h5>
              {tossWonTeam === batTeamArr.battingTeamId && (
                <img className={styles.tossImg} src={tossImage} alt="toss" />
              )}
            </sapn>
            <h5 className={styles.runRate}>
              {winStatus
                ? winStatus
                : intervalType
                ? intervalType?.toUpperCase()
                : hasSuperOver
                ? "SUPER OVER"
                : currentStat}
            </h5>
            <sapn className={styles.teamName}>
              <h5>{bowlTeamName?.toUpperCase()}</h5>
              {tossWonTeam !== batTeamArr.battingTeamId && (
                <img className={styles.tossImg} src={tossImage} alt="toss" />
              )}
            </sapn>
          </div>
          <div className={styles.bottomSectionWrapper}>
            <img
              className={styles.teamLogo}
              src={fixImage(batTeamLogo)}
              alt="Team Logo"
            />
            <div className={styles.playerInfo}>
              <div className={styles.playerInfoText}>
                {batters?.batterArr?.slice(0, 2)?.map((i) => {
                  return (
                    <div
                      style={{
                        opacity:
                          batters?.wicketBatterId === i?.battingPlayerId
                            ? "0.5"
                            : "10",
                      }}
                      className={styles.playerInfo1}
                    >
                      <h5 className={styles.playerName}>
                        {" "}
                        {getShortenedName(
                          fixSpecialChar(i?.batsman?.name),
                          16
                        )}{" "}
                        {i?.battingPlayerId === batTeamArr?.battingPlayer1Id &&
                          "*"}
                      </h5>
                      <div>
                        <h5 className={styles.playerStat}>{i?.runs}</h5>
                        <p>({i?.balls})</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {isChasing && !hasMultipleInns && (
                <div className={styles.chasing}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      gap: "10%",
                    }}
                  >
                    <p className={styles.playerStat}>TARGET</p>
                    <h5>{targetRuns}</h5>
                  </div>
                </div>
              )}
            </div>
            {!winStatus && showAnimation ? (
              <img className={styles.gif} src={showAnimation} alt="anim" />
            ) : (
              <div className={styles.scoreInfo}>
                <h1 className={styles.scoreRuns}>
                  {currentScore || 0}/{currentWickets || 0}
                </h1>
                <div className={styles.scoreOvers}>
                  <h5>OVERS:</h5>
                  <h5>
                    {currentOvers || 0}
                    {totaltOvers && "/"}
                    {totaltOvers}
                  </h5>
                </div>
              </div>
            )}
            <div className={styles.playerInfo}>
              <div className={styles.playerInfoText}>
                <div className={styles.playerInfo1}>
                  <h5 className={styles.bowlerName}>
                    {getShortenedName(
                      bowlerArr?.bowler?.name?.toUpperCase(),
                      18
                    )}
                  </h5>
                  <div>
                    <h5 className={styles.playerStat}>
                      {bowlerArr?.wickets}-{bowlerArr?.runs}
                    </h5>
                    <p className={styles.playedBowls}>{bowlerArr?.overs}</p>
                  </div>
                </div>
                <div className={styles.overInfo}>
                  <h5 className={styles.overText}>OVER: </h5>
                  <div className={styles.deliveries}>
                    {mapDeliveries?.map((d, i) => {
                      return (
                        <h5 key={i} className={styles.bowlerStat}>
                          {d}
                        </h5>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <img
              className={styles.teamLogo}
              src={fixImage(bowlTeamLogo)}
              alt="Team Logo 2"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ScoreOverlay;
