import moment from "moment";
import nologo from "../images/nologoclub.png";
export const getSortValue = (arr) => {
  var items = [...arr];
  items.sort((a, b) => a.localeCompare(b));
  return items;
};

export const imgUrlToFile = (url, fileName, setState) => {
  setState([
    {
      uid: fileName,
      name: `${fileName}-img`,
      status: "done",
      url: url,
    },
  ]);
};

export const multiImgUrlToFile = (url, fileName, setState, name) => {
  setState((prevState) => ({
    ...prevState,
    [name]: [
      {
        uid: fileName,
        name: `${fileName}-img`,
        status: "done",
        url: url,
      },
    ],
  }));
};

export const getEnum = (type) => {
  if (type === "Yes") {
    return 1;
  } else {
    return 0;
  }
};

export const dateFormat = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  } else {
    return "";
  }
};

export const findHightestLevel = (
  level1,
  level2,
  level3,
  level4,
  fromScorer
) => {
  let hightestLevel;
  if (level4 === "Yes")
    fromScorer ? (hightestLevel = "D") : (hightestLevel = "4");
  else if (level3 === "Yes")
    fromScorer ? (hightestLevel = "C") : (hightestLevel = "3");
  else if (level2 === "Yes")
    fromScorer ? (hightestLevel = "B") : (hightestLevel = "2");
  else if (level1 === "Yes")
    fromScorer ? (hightestLevel = "A") : (hightestLevel = "1");
  else {
    hightestLevel = "0";
  }
  return hightestLevel === "0"
    ? "N/A"
    : fromScorer
    ? "Grade " + hightestLevel
    : "Level " + hightestLevel;
};

export const getLevels = () => {
  let roles = localStorage.getItem("user_level") || null;

  let roleList = roles ? JSON.parse(roles) : null;
  let unique = [...new Set(roleList)];
  let objectArray = [];

  for (let i = 0; i < unique.length; i++) {
    if (unique[i] === "SUB-MEMBER")
      objectArray.push({
        value: unique[i],
        label: "Member Pathway Structure",
      });
    else if (unique[i] === "MEMBER")
      objectArray.push({
        value: unique[i],
        label: "Member",
      });
    else objectArray.push({ value: unique[i], label: unique[i] });
  }
  //

  return roles ? objectArray : null;
};
export function fixSpecialChar(name) {
  if (typeof name === "number") {
    const stringName = name.toString();
    return stringName.replace(/&#039;/g, "'").replace(/&amp;/g, "&");
  }
  const fixedName = name?.replace(/&#039;/g, "'").replace(/&amp;/g, "&") ?? "";
  return fixedName;
}
export function findLastObj(array) {
  if (array?.length) {
    return array[array?.length - 1];
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const createObjectWithKeys = (count) => {
  if (typeof count !== "number" || count < 0) {
    console.log("Invalid Number");
    // throw new Error('Invalid input: Provide a non-negative number.');
  }

  const obj = {};
  for (let i = 0; i <= count; i++) {
    obj[i] = true;
  }
  return obj;
};

export function fixImage(img) {
  return (img === "null" && nologo) || (!img && nologo) || img;
}

export function oversToBalls(overs) {
  const wholeOvers = Math.floor(overs);
  const decimalPart = overs - wholeOvers;
  const balls = wholeOvers * 6 + Math.round(decimalPart * 10);
  return balls;
}

export function calculateBallByBallRRR(gap, totalBalls, ballsFaced) {
  const remainingBalls = totalBalls - ballsFaced;
  const ballByBallRRR = (gap / remainingBalls) * 6;
  return ballByBallRRR;
}
export function convertToHttps(url) {
  if (url.startsWith("http://")) {
    return url.replace("http://", "https://");
  } else {
    return url;
  }
}
export const getPayload = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function checkTokenValidity(token) {
  const now = parseInt(Date.now() / 1000);

  const payload = getPayload(token);
  const tempToken = 1707423245;
  console.log(
    // payload?.exp > now,
    // payload?.exp,
    // now,
    // payload?.exp - now,

    now,
    "time remaining"
  );
  const valid = payload?.exp > now;
  return valid;
}
export function getShortenedName(str1, max) {
  var split_names = str1?.trim()?.split(" ");

  let result = str1;

  if (split_names?.length > 3) {
    if (result.length > max) {
      result =
        split_names[0].charAt(0) +
        " " +
        split_names[1] +
        " " +
        split_names[2] +
        " " +
        split_names[3];
    }
    if (result.length > max) {
      result =
        split_names[0].charAt(0) +
        " " +
        split_names[1].charAt(0) +
        " " +
        split_names[2] +
        " " +
        split_names[3];
    }
    if (result.length > max) {
      result =
        split_names[0].charAt(0) +
        " " +
        split_names[1].charAt(0) +
        " " +
        split_names[2].charAt(0) +
        " " +
        split_names[3];
    }
    return result;
  }
  if (split_names?.length > 2) {
    if (result.length > max) {
      result =
        split_names[0].charAt(0) + " " + split_names[1] + " " + split_names[2];
    }

    if (result.length > max) {
      result =
        split_names[0].charAt(0) +
        " " +
        split_names[1].charAt(0) +
        " " +
        split_names[2];
    }
    return result;
  }
  if (split_names?.length > 1) {
    if (result.length > max) {
      result = split_names[0].charAt(0) + " " + split_names[1];
    }
    return result;
  }
}
export const comnCellStyles = {
  whiteSpace: "nowrap",
  textTransform: "uppercase",
  overflow: "hidden",
};
