import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./MiniCricket.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { fixSpecialChar } from "utils/helpers";

const MiniCricketTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getMiniCricketList,
    method: "get",
  });

  useEffect(() => {
    callAPI();
  }, []);

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Mini Cricket", value: "/mini-cricket" },
  ];
  const columns = [
    {
      title: "School Name",
      dataIndex: "schoolName",
      key: "schoolName",
    },

    {
      title: "Teams",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Players",
      dataIndex: "players",
      key: "players",
    },
    { title: "Fields", dataIndex: "fields", key: "fields" },
    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS PRIMARY SCHOOL COORDINATOR"
      ? {
          title: "Action",
          key: "action",
          render: (item) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-mini-cricket", {
                  state: { id: item?.id, isView: true },
                })
              }
              // handleEdit={() =>
              //  navigate("/edit-mini-cricket", {
              //   state: { id: item?.id, isEdit: true },
              //  })
              // }
            />
          ),
          //  align: "right",
        }
      : {},
  ];
  const data = response?.rows
    // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
    ?.map((item) => ({
      key: item?.id,
      schoolName: item?.school_name,
      team: item?.teams_count ? item?.teams_count[0]?.count : "0",
      players: item?.players_count ? item?.players_count[0]?.count : "0",
      fields: item?.field_count ? item?.field_count[0]?.count : "0",
      status1: item?.field_status === "Active" ? "Activated" : "Disabled",
      ...item,
    }));

  const onSelect = (value) => {};

  console.log(data, "ddd");

  const onChange = (page) => {
    callAPI(`?keyword=&member_id=${memberId}&page=${page - 1}`, true);
  };
  const handleSearch = (e) => {
    callAPI(`?keyword=${e}&member_id=${memberId}`, true);
  };
  return (
    <Wrapper>
      <div className={styles.PrimarySchoolsMWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Mini Cricket</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                // options={options}
                onSelect={(val, option) => onSelect(val, option.label)}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <div className=" d-flex w-100 justify-content-end">
        {authRole === "CSA ADMINISTRATOR" ||
        authRole === "CSA ADMIN ASSISTANT" ||
        authRole === "MEMBERS ADMINISTRATOR" ||
        authRole === "MEMBERS ADMIN ASSISTANT" ||
        authRole === "MEMBERS PRIMARY SCHOOL COORDINATOR" ? (
         <CsaButton
          label="Create"
          textColor="#FFF"
          onClick={() => navigate("/register-primary-school")}
          btnColor="#007237"
         />
        ) : null}
       </div>
      </Col> */}
          </Row>
          <div className="mt-2 pt-2">
            <Table
              className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 600,
              }}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={response?.pager?.total_items}
                size={"small"}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MiniCricketTable;
