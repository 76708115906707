import React, { useState } from "react";
import { useLocation } from "react-router-dom";
//Components
// import MiniCricketRegisterForm from "../../Common/PrimarySchoolRegisterForm";
// import MiniCricketRegisterForm2 from "../../Common/PrimarySchoolRegisterForm/PrimarySchoolRegisterForm2";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./MiniCricket.module.scss";
import MiniCricketRegisterForm from "Common/MiniCricketRegisterForm";
import PrimarySchoolRegisterForm2 from "Common/PrimarySchoolRegisterForm/PrimarySchoolRegisterForm2";
import MiniCricketRegisterForm2 from "Common/MiniCricketRegisterForm/MiniCricketRegisterForm2";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function MiniCricket() {
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
  };
  const location = useLocation();
  const { isView = false, isEdit = false } = location?.state || {};
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Mini Cricket", value: "/mini-cricket" },
    { key: "Mini Cricket", value: "/edit-mini-cricket" },
  ];

  return (
    <Wrapper>
      <div className={styles.PrimarySchoolsMWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Mini Cricket
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>
            {step === 1 && <MiniCricketRegisterForm {...props} />}
            {step === 2 && <MiniCricketRegisterForm2 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default MiniCricket;
