import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { useFormik } from "formik";
//Components
import CsaTextInput from "Common/CsaTextInput";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import styles from "./CsaLoginForm.module.scss";

const RecoveryUserNameSchema = Yup.object().shape({
 idPassportNumber: Yup.string().required("Enter ID number/passportNumber."),
});

function RecoveryUserNameForm() {
 const { addToast } = useToasts();
 const navigate = useNavigate();
 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.resetUserName,
  method: "post",
 });
 const formik = useFormik({
  initialValues: {
   idPassportNumber: "",
  },
  validationSchema: RecoveryUserNameSchema,
  onSubmit: (values) => {
   const obj = {
    id_number: values.idPassportNumber,
   };
   callAPI(obj);
  },
 });
 useEffect(() => {
  if (response) {
   addToast(
    response?.Message || "Check registered email for login instructions ",
    { appearance: "success" },
    () => navigate("/login")
   );
   resetForm();
  } else if (error) {
   addToast(error?.Message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [response, error]);
 const {
  handleChange,
  errors,
  values,
  handleBlur,
  handleSubmit,
  touched,
  resetForm,
 } = formik;
 const checkValidity = () => {
  const { idPassportNumber } = values;
  const isVAlid = idPassportNumber && Object.keys(errors).length === 0;
  return isVAlid ? false : true;
 };
 return (
  <div className={styles.CsaLoginFormWrapper}>
   <h4 className="text-center text-white">Recover User Name</h4>
   <div className="w-100 mt-2 pt-2">
    <CsaTextInput
     label="ID / Passport Number"
     placeholder="Enter ID / Passport Number"
     name="idPassportNumber"
     type="text"
     labelColor
     onChange={handleChange}
     value={values.idPassportNumber}
     required
     onBlur={handleBlur}
     touched={touched.idPassportNumber}
     error={errors.idPassportNumber || null}
    />
   </div>
   <CsaButton
    label="Submit"
    textColor="#004f27"
    htmlType="submit"
    onClick={() => handleSubmit()}
    disabled={checkValidity()}
    loading={loading}
   />
   <div className="w-100 d-flex align-items-center justify-content-center">
    <CsaButton
     label="Back to Login"
     textColor="#FFCB03"
     type="link"
     onClick={() => navigate("/login")}
    />
   </div>
  </div>
 );
}

export default RecoveryUserNameForm;
