import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin, Row, Col } from "antd";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaCheckBox from "Common/CsaCheckBox";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useDispatch, useSelector } from "react-redux";
import { setCMSData } from "redux/slice/commonSlice";

function CompetitionMembers({ styles, setStep, setInfo, info, setLocal }) {
  const { addToast } = useToasts();
  const location = useLocation();
  const CMSData = useSelector((state) => state.auth.cms_data);
  const { id, type, editData } = location?.state || {};
  const isEdit = type === "EDIT";
  const steps = ["Hosting & Participating Members", "Teams", "Venues"];
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getRegisteredMemberList,
    method: "get",
  });
  useEffect(() => {
    callAPI();
  }, []);
  // Redux Data
  const tabData = CMSData?.tab2Step1 || {};

  const reqData = tabData ? tabData : editData;

  const compSettings = reqData?.competition_settings || null;
  const result = compSettings?.reduce(function (r, a) {
    r[a?.key] = r[a?.key] || [];
    r[a?.key].push(a);
    return r;
  }, Object.create(null));

  const settingArr = compSettings
    ? Object?.keys(result)?.map((item, i) => ({
        key: item,
        value: result[item]?.map((val) =>
          val?.value ? val?.value : val?.values
        ),
      }))
    : [];

  const data =
    // isEdit
    // ? {
    //     membersNameP: settingArr
    //       ? settingArr
    //           ?.find((item) => item?.key === "cms_participating_member_List")
    //           ?.value?.map((p) => p)
    //       : "",
    //   }
    // :
    tabData;

  const dispatch = useDispatch();
  const reqArr = CMSData?.memberRes?.filter((item) =>
    CMSData?.partMembers?.includes(item?.id)
  );
  const formik = useFormik({
    initialValues: {
      membersNameH: data
        ? data?.membersNameH
        : settingArr?.find(
            (item) => item?.key === "cms_participating_member_List"
          )?.value,
      membersNameP: data
        ? data?.membersNameP
        : settingArr?.find(
            (item) => item?.key === "cms_participating_member_List"
          )?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!values.membersNameH) {
        addToast("Please select hosting member", { appearance: "warning" });
      } else if (!values.membersNameP) {
        addToast("Please select participating member", {
          appearance: "warning",
        });
      } else {
        const selMembers = await response?.map((p) => ({
          key: p?.id,
          value: p.id,
          label: p.name,
          ...p,
        }));

        await dispatch(
          setCMSData({
            ...CMSData,
            tab1Step1: CMSData?.tab1Step1,
            tab1Step2: CMSData?.tab1Step2,
            tab1: CMSData?.tab1,
            tab2Step1: values,
            tab2Step2: CMSData?.tab2Step2,
            tab2Step3: CMSData?.tab2Step3,
            tab2: CMSData?.tab2,
            tab3Step1: CMSData?.tab3Step1,
            tab3Step2: CMSData?.tab3Step2,
            tab3: CMSData?.tab3,
            selMembers: selMembers || reqArr,
            partMembers: values?.membersNameP,
            hostMembers: values?.membersNameH,
            memberRes: response,
          })
        );
        setInfo({
          step1: values,
          step2: info?.step2,
          step3: info?.step3,
        });
        setStep(2);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  return loading ? (
    <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
      <Spin size="large" />
    </div>
  ) : (
    <>
      <div className="mt-3 pt-3 px-3">
        <CsaStepper steps={steps} activeKey={0} />
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="mt-3 pt-3 "
          align="Start"
        >
          <Col span={24}>
            <CsaCheckBox
              label="Hosting Members"
              name="membersNameH"
              colSize={6}
              required
              options={response?.map((p) => ({
                key: p?.id,
                value: p.id,
                label: p.name,
                ...p,
              }))}
              value={values.membersNameH}
              onChange={(checkedValue) => {
                setFieldValue("membersNameH", checkedValue);
              }}
              onBlur={handleBlur}
              touched={touched.membersNameH}
              error={errors.membersNameH || null}
            />
          </Col>
        </Row>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="mt-3 pt-3 "
          align="Start"
        >
          <Col span={24}>
            <CsaCheckBox
              label="Participating Members"
              name="membersNameP"
              colSize={6}
              required
              options={response?.map((p) => ({
                key: p?.id,
                value: p.id,
                label: p.name,
                ...p,
              }))}
              value={values.membersNameP}
              onChange={(checkedValue) => {
                setFieldValue("membersNameP", checkedValue);
              }}
              onBlur={handleBlur}
              touched={touched.membersNameP}
              error={errors.membersNameP || null}
            />
          </Col>
        </Row>
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Continue"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
          />
        </div>
      </div>
    </>
  );
}

export default CompetitionMembers;
