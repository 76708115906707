/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { DeleteFilled } from "@ant-design/icons";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { fixSpecialChar } from "utils/helpers";

function TeamRegisterForm3({ styles, setStep, setInfo, info, img, setImg }) {
  const [inputValue, setInputValue] = useState("");
  const [isPagination, setIsPagination] = useState(false);
  const [resData, setResData] = useState([]);

  const authLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);

  const sub_member_name = localStorage.getItem("sub_member_name");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const memberId = localStorage.getItem("csa_member_id");
  const sub_member_type = localStorage.getItem("sub_member_type");

  const location = useLocation();
  const steps = ["Team Info", "Management Team", "Squad"];
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { id, type, isEdit = false } = location?.state || {};
  //const isEdit = info?.editData || null;
  const players = info?.players || null;
  const { callAPI, response, error, loading } = useAxios({
    url:
      authLevel?.toLowerCase() === "sub-member"
        ? serviceUrl?.getPlayerDetailsSubMember
        : authLevel?.toLowerCase() === "csa"
        ? serviceUrl?.getCsaPlayerDetails
        : serviceUrl.getPlayerDetails,
    method: "get",
  });
  const {
    callAPI: submit,
    response: success,
    error: err,
    loading: submitting,
  } = useAxios({
    url: isEdit ? serviceUrl.updateTeam : serviceUrl.createTeam,
    method: isEdit ? "put" : "post",
  });

  useEffect(() => {
    if (!inputValue) {
      authLevel === "member"
        ? callAPI(
            `keyword=&level=${authLevel.toUpperCase()}&member_id=${memberId}&items_per_page=20`,
            true
          )
        : authLevel === "sub-member"
        ? callAPI(`keyword=&sub_member_id=${sub_member_id}`, true)
        : callAPI(`keyword=&items_per_page=20`, true);
    }
  }, [inputValue]);

  useEffect(() => {
    if (success) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/team-list")
      );
      setInfo({});
    } else if (err) {
      addToast(err?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [success, err, addToast, isEdit, setInfo, navigate]);

  const [tableData, setTableData] = useState(info?.step3 || []);

  useEffect(() => {
    if (response && isEdit && tableData?.length == 0) {
      let playersIds = players || [];
      let playerArr = response?.players?.rows;
      let reqArr = [];
      playerArr?.forEach((element) => {
        if (playersIds?.find((item) => item?.nid === element?.id)) {
          reqArr.push(element);
        }
      });

      let body = isEdit
        ? playersIds?.map((p) => ({
            key: Number(p?.nid),
            value: p?.field_first_name + " " + p?.field_last_name,
            first_name: p?.field_first_name,
            last_name: p?.field_last_name,
            id: Number(p?.nid),
            ...p,
          }))
        : reqArr?.map((p) => ({
            key: p?.id,
            value: p.first_name + " " + p.last_name,
            ...p,
          }));
      setTableData(body);
    }
  }, [isEdit, response]);
  console.log(tableData, response, players, "tdata");
  const handleRemove = (id) => {
    const arr = tableData?.filter((item) => item?.id !== id);
    setTableData(arr);
  };

  const columns = [
    {
      title: "Player Name",
      key: "firstname",
      render: (item) => (
        <p>
          {item?.firstname} {item?.lastname}
        </p>
      ),
    },
    {
      title: "Batting Skill ",
      dataIndex: "battingSkill",
      key: "battingSkill",
    },
    {
      title: "Batting Hand",
      dataIndex: "battingHand",
      key: "battingHand",
    },
    {
      title: "Bowling Skill ",
      dataIndex: "bowlingSkill",
      key: "bowlingSkill",
    },
    {
      title: "Bowling Hand",
      dataIndex: "bowlingHand",
      key: "bowlingHand",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <DeleteFilled
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => handleRemove(item?.id)}
        />
      ),
      // align: "center",
    },
  ];

  const formik = useFormik({
    initialValues: {},

    onSubmit: () => {
      setInfo({ step1: info?.step1, step2: info?.step2, step3: tableData });
      const { step1, step2 } = info;
      const obj1 = {
        team_name: step1?.teamName,
        level: step1?.level,
        age_group: step1?.ageGroup,
        gender: step1?.gender,
        a_team: step1?.a_team,
        differently_abled: step1?.differentlyAbled,
        team_coach: step2?.teamCoach,
        team_manager: step2?.teamManager,
        team_doctor: step2?.teamDoctor,
        fitness_coach: step2?.fitnessCoach,
        fielding_coach: step2?.fieldingCoach,
        bowling_coach: step2?.bowlingCoach,
        batting_coach: step2?.battingCoach,
        analyst: step2?.analyst,
        physio: step2?.physio,
        status: step1?.status === "Active" ? 1 : 0,
        fid: info?.file || "",
        players: tableData?.map((item) => item),
        user_level:
          authLevel === "member"
            ? "MEMBER"
            : authLevel === "csa"
            ? "CSA"
            : "SUB-MEMBER",
        member_id: authLevel === "csa" ? "" : memberId,
        uid: uid,
        ...(authLevel === "sub-member" && {
          sub_member_id: sub_member_id,
          sub_member_name: sub_member_name,
        }),
      };
      const obj =
        authLevel === "sub-member" && !isEdit
          ? { ...obj1, sub_member_type }
          : obj1;
      submit(isEdit ? { ...obj, ...{ team_id: id } } : obj);
    },
  });
  const { handleSubmit } = formik;

  const handleSearch = (e) => {
    setIsPagination(false);
    setInputValue(e);
    authLevel === "member"
      ? callAPI(
          `keyword=${e}&level=${authLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : authLevel === "sub-member"
      ? callAPI(`keyword=${e}&sub_member_id=${sub_member_id}`, true)
      : callAPI(`keyword=${e}`, true);
  };

  const onSelect = (key, opt) => {
    const isThere = tableData?.find((item) => item?.id === opt?.id);
    if (!isThere) setTableData([...tableData, ...[opt]]);
    else {
      addToast("Same Player added Already", {
        appearance: "warning",
      });
    }
    setInputValue("");
  };

  const dataTable = tableData?.map((item) => ({
    key: item?.id,
    firstname: item?.first_name,
    lastname: item?.last_name,
    battingSkill: item?.field_batting_skill ? item?.field_batting_skill : "Nil",
    battingHand: item?.field_batting_hand ? item?.field_batting_hand : "Nil",
    bowlingSkill: item?.field_bowl_skill ? item?.field_bowl_skill : "Nil",
    bowlingHand: item?.field_bowling_hand ? item?.field_bowling_hand : "Nil",
    role: item?.roles,
    ...item,
  }));

  useEffect(() => {
    if (response) {
      isPagination
        ? setResData([...resData, ...response?.rows])
        : setResData(response?.rows);
      // setTimeout(() => {

      //     }, 1500)
    }
  }, [response]);

  const filtered = resData?.reduce((unique, item) => {
    return unique.some((obj) => obj.id === item.id)
      ? unique
      : [...unique, item];
  }, []);

  console.log(dataTable, tableData, "filtered");

  const handleScroll = (event) => {
    setIsPagination(true);
    const target = event.target;

    const totalHeight = target.scrollHeight - 1;
    const scrollHeight = target.scrollTop + target.offsetHeight;
    const totalPage = response?.pager?.total_pages;
    const curPage = response?.pager.current_page + 1;

    if (
      (totalPage != curPage &&
        parseInt(scrollHeight.toFixed(0)) === totalHeight) ||
      parseInt(scrollHeight.toFixed(0)) - 1 === totalHeight
    ) {
      authLevel === "member"
        ? callAPI(
            `keyword=${
              inputValue ? inputValue : ""
            }&level=${authLevel.toUpperCase()}&member_id=${memberId}&page=${
              response?.pager.current_page + 1
            }`,
            true
          )
        : authLevel === "sub-member"
        ? callAPI(
            `keyword=${
              inputValue ? inputValue : ""
            }&sub_member_id=${sub_member_id}&page=${
              response?.pager.current_page + 1
            }&items_per_page=${10}`,
            true
          )
        : callAPI(
            `keyword=${inputValue ? inputValue : ""}&page=${
              response?.pager.current_page + 1
            }`,
            true
          );
    }
  };

  console.log(info, isEdit, "info");

  return (
    <div>
      <CsaStepper steps={steps} activeKey={2} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-1 pt-1"
        align="start"
      >
        <div className={styles.contents}>
          <p className={styles.squadH}>Player:</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3 px-2"
            align="start"
          >
            <Col span={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                value={inputValue}
                options={filtered
                  ?.filter((x) => x?.status === "Assigned")
                  ?.filter((x) =>
                    info?.step1?.gender !== "Mixed"
                      ? x?.gender === info?.step1?.gender
                      : info?.step1?.gender === "Mixed"
                      ? x
                      : null
                  )
                  ?.filter(
                    (x) =>
                      x?.differently_abled === info?.step1?.differentlyAbled
                  )
                  ?.map((p) => ({
                    key: p?.id,
                    value:
                      fixSpecialChar(p.first_name) +
                      " " +
                      fixSpecialChar(p.last_name),
                    ...p,
                    disabled: dataTable?.some(
                      (item) => Number(item.id) === Number(p?.id)
                    ),
                  }))}
                onSelect={(val, option) => onSelect(val, option)}
                onSearch={handleSearch}
                // onClear={handleChange}
                // onChange={handleChange}
                onPopupScroll={handleScroll}
              >
                <Input.Search
                  size="large"
                  placeholder="Search and Select Player..."
                  allowClear
                  enterButton
                  loading={loading}
                  autoComplete="off"
                />
              </AutoComplete>
            </Col>
            <Col span={12}>
              <div className=" d-flex w-100 justify-content-end">
                {/* <CsaButton
                                    label="Add"
                                    textColor="#FFF"
                                    onClick={() => navigate('/register-player')}
                                    btnColor="#007237"
                                /> */}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2 px-2 W-50">
            <Table
              className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={dataTable}
              pagination={false}
            />
          </div>
        </div>

        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({
                step1: info?.step1,
                step2: info?.step2,
                step3: tableData,
                editData: info?.editData,
                file: info.file,
              });
              setStep(2);
            }}
            btnColor="#FFCB03"
            disabled={submitting}
          />
          <CsaButton
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={tableData?.length == 0}
            loading={submitting}
          />
        </div>
      </Row>
    </div>
  );
}

export default TeamRegisterForm3;
