import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./Teams.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";

const TeamTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  console.log(userLevel, "lll");
  const { callAPI, response, error, loading } = useAxios({
    url:
      userLevel === "csa" ? serviceUrl.getCsaTeamList : serviceUrl.getTeamList,
    method: "get",
  });

  useEffect(() => {
    userLevel === "member"
      ? callAPI(`level=${userLevel.toUpperCase()}&member_id=${memberId}`, true)
      : userLevel === "sub-member"
      ? callAPI(
          `level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI();
  }, []);

  const columns = [
    {
      title: "Team Name",
      dataIndex: "teamName",
      key: "teamName",
    },
    {
      title: "Team Manager",
      dataIndex: "teamManager",
      key: "teamManager",
    },
    {
      title: "Team Coach",
      key: "teamCoach",
      dataIndex: "teamCoach",
    },
    {
      title: "Level",
      dataIndex: "team_level",
      key: "team_level",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "1st / A Team",
      dataIndex: "aTeam",
      key: "aTeam",
    },
    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR"
      ? {
          title: "Action",
          key: "action",
          render: (item) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-team", {
                  state: { isView: true, id: item?.team_id },
                })
              }
              handleEdit={() =>
                navigate("/register-team", {
                  state: { isEdit: true, id: item?.team_id },
                })
              }
            />
          ),
          //  align: "right",
        }
      : {},
  ];

  const data = response?.rows
    // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
    ?.map((item) => {
      let teamCoach = item?.team_coach || "N/A";
      let req = teamCoach ? JSON.parse(teamCoach)?.[0] : "N/A";
      let name = `${req?.first_name.replace(/&#039;/g, "'") || ""} ${
        req?.last_name.replace(/&#039;/g, "'") || ""
      }`;
      return {
        key: item?.team_id,
        teamCoach: name?.trim().replace(/&#039;/g, "'") || "N/A",
        teamManager: item?.team_manager.replace(/&#039;/g, "'") || "N/A",
        status1: item?.status === "Active" ? "Activated" : "Disabled",
        teamName: item?.team_name?.replace(/&#039;/g, "'"),
        aTeam: item?.a_team === "1" ? "✔" : "〤",
        ...item,
      };
    });
  const onSelect = (value) => {};

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Teams", value: "/team-list" },
  ];
  const onChange = (page) => {
    userLevel === "member"
      ? callAPI(
          `keyword=""&level=${userLevel.toUpperCase()}&member_id=${memberId}&page=${
            page - 1
          }`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}&page=${
            page - 1
          }`,
          true
        )
      : callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&page=${page - 1}`,
          true
        );
  };
  const handleSearch = (e) => {
    userLevel === "member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}&uid=${uid}`,
          true
        )
      : callAPI(`keyword=${e}&level=${userLevel.toUpperCase()}`, true);
  };
  return (
    <Wrapper>
      <div className={styles.TeamsWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Teams</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                // options={options}
                onSelect={(val, option) => onSelect(val, option.label)}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className=" d-flex w-100 justify-content-end ">
                {authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT" ||
                authRole === "MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBERS ADMIN ASSISTANT" ||
                authRole === "SUB MEMBERS ADMINISTRATOR" ||
                authRole === "SUB-MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ? (
                  <CsaButton
                    label="Create"
                    textColor="#FFF"
                    onClick={() => navigate("/register-team")}
                    btnColor="#007237"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2">
            <Table
              className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 700,
              }}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={response?.pager?.total_items}
                size={"small"}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default TeamTable;
