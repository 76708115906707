import styles from "./CsaRangePicker.module.scss";
import { DatePicker } from "antd";
import React from "react";

function CsaRangePicker({
  label,
  onChange,
  name,
  onBlur,
  error,
  disabledDate,
  required = false,
  value = null,
}) {
  const { RangePicker } = DatePicker;
  return (
    <div className={styles.CsaDatePickerWrapper}>
      <p className={styles.name}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </p>
      <RangePicker
        className="w-100"
        onChange={onChange}
        disabledDate={disabledDate}
        name={name}
        onBlur={onBlur}
        format="DD/MM/YYYY"
        value={value ? value : ""}
      />
      {error && <pre className="text-danger">{error}</pre>}
    </div>
  );
}

export default CsaRangePicker;
