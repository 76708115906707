import styles from "./CsaBreadcrumb.module.scss";
import React from "react";
import { Breadcrumb, Divider } from "antd";
import { Link } from "react-router-dom";

function CsaBreadcrumb({ pathArr }) {
  return (
    <div className={styles.CsaBreadcrumbWrapper}>
      <div className="px-0">
        <Breadcrumb>
          {pathArr?.slice(0,-1)?.map((item) => (
            <Breadcrumb.Item key={item?.key}>
              <Link to={item?.value}>{item?.key}</Link>
            </Breadcrumb.Item>
          ))}
          <Breadcrumb.Item>{pathArr?.at(-1)?.key}</Breadcrumb.Item> 
        </Breadcrumb>
      </div>
      <Divider className="my-1" />
    </div>
  );
}

export default CsaBreadcrumb;
