import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";

//Components
import CsaStepper from "Common/CsaStepper";
import CsaDropDown from "Common/CsaDropDown";
import CsaCheckBox from "Common/CsaCheckBox";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useDispatch, useSelector } from "react-redux";
import { setCMSData, setCurrentTabState } from "redux/slice/commonSlice";
import SearchDropDown from "Common/SearchDropDown";

const FormatSchema = Yup.object().shape({
 recommendedBall: Yup.string ().required("Select ball colour"),
 matchFormat: Yup.string().required("Select Match Format"),
 numberOfPools: Yup.string().required("Select number of pools"),
 numberOfTeamsPerPool: Yup.string().required("Select number Of Teams Per Pool"),
 numberOfMatchesVsEachTeamInPool: Yup.string().required(
  "Select number Of Matches Vs Each Team In Pool"
 ),

 oversPerInnings: Yup.string().required("Select overs per innings"),
});

function CompetitionFormat({
 styles,
 setStep,
 setInfo,
 info,
 img,
 setImg,
 setActiveKey,

 status,
}) {
 const authLevel = localStorage.getItem("csa_level");
 const memberId = localStorage.getItem("csa_member_id");
 const steps = ["Competition Information", "Format"];
 const { addToast } = useToasts();
 const navigate = useNavigate();
 const location = useLocation();
 const { id, type, editData } = location?.state || {};
 const isEdit = type === "EDIT";
 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.setCMS,
  method: "post",
 });
 const dispatch = useDispatch();
 const CMSData = useSelector((state) => state.auth.cms_data);
 const tabData = CMSData?.tab1Step2 || {};

 const reqData = info?.tab1;
 const compSettings = reqData?.competition_settings || null;

 const result = compSettings?.reduce(function (r, a) {
  r[a?.key] = r[a?.key] || [];
  r[a?.key].push(a);
  return r;
 }, Object.create(null));
 const settingArr = compSettings
  ? Object?.keys(result)?.map((item, i) => ({
     key: item,
     value: result[item]?.map((val) => (val?.value ? val?.value : val?.values)),
    }))
  : [];

 const data = tabData;

 const formik = useFormik({
  initialValues: {
   matchFormat: data ? data?.matchFormat : "",
   numberOfPools: data ? data?.numberOfPools : "",
   numberOfTeamsPerPool: data ? data?.numberOfTeamsPerPool : "",
   numberOfMatchesVsEachTeamInPool: data
    ? data?.numberOfMatchesVsEachTeamInPool
    : "",
   inningsPerTeam: data ? data?.inningsPerTeam : "",
   oversPerInnings: data ? data?.oversPerInnings : "",
   recommendedBall: data ? data?.recommendedBall : "",
   scoringType: data ? data?.scoringType : "",
   finals: data ? data?.finals : "",
   reserveDays: data ? data?.reserveDays : "",
   analysisOptions: data ? data?.analysisOptions : "",
  },
  validationSchema: FormatSchema,
  enableReinitialize: true,
  onSubmit: (values) => {
   let totalTeamCount = values?.numberOfPools * values?.numberOfTeamsPerPool;
   // if (!values.scoringType) {
   //  addToast("Please select scoring type", { appearance: "warning" });
   // } 
   // else if (!values.finals) {
   //  addToast("Please select finals", { appearance: "warning" });
   // }
   if (
    values?.finals?.find((item) => item === "quarter-finals") &&
    totalTeamCount < 9
   ) {
    addToast(
     "Team count does not match with the minimum requirement of this level",
     {
      appearance: "warning",
     }
    );
   } else if (
    values?.finals?.find((item) => item === "semi-finals") &&
    totalTeamCount < 5
   ) {
    addToast(
     "Team count does not match with the minimum requirement of this level",
     {
      appearance: "warning",
     }
    );
   } else if (
    values?.finals?.find((item) => item === "finals") &&
    totalTeamCount < 3
   ) {
    addToast(
     "Team count does not match with the minimum requirement of this level",
     {
      appearance: "warning",
     }
    );
   } 
   else if (
    values?.numberOfPools < 1
   ) {
    addToast(
     "Minimum 1 pool is required",
     {
      appearance: "warning",
     }
    );
   }
   else if (
    values?.numberOfTeamsPerPool < 2
   ) {
    addToast(
     "Minimum 2 Team is required in a pool",
     {
      appearance: "warning",
     }
    );
   } 
  //  else if (
  //   values?.finals?.find((item) => item === "finals") &&
  //   !values.reserveDays
  //  ) {
  //   addToast("Please select reserve Days", { appearance: "warning" });
   //} 
   else {
    

    const obj = {
     name: info?.step1?.competitionName,
     start_date: info?.step1?.startDate,
     end_date: info?.step1?.endDate,

     competition_settings: [
      {
       key: "competition_type",
       value: info?.step1?.competitionType,
      },
      authLevel === "csa"
       ? {
          key: "csa-levels",
          value: info?.step1?.level,
         }
       : {
          key: "member-levels",
          value: info?.step1?.level,
         },
      info?.step1?.level === "club"
       ? {
          key: "competition_league_club",
          value: info?.step1?.league,
         } 
       : info?.step1?.level === "primary-school" ||
         info?.step1?.level === "high-school"
       ? {
          key: "competition_league_school",
          value: info?.step1?.league,
         }
       : {
          key: "competition_league_other",
          value: info?.step1?.league,
         },
      {
       key: "season_number",
       value: info?.step1?.seasonNumber,
      },
      {
       key: "age-group",
       value: info?.step1?.ageGroup,
      },
      {
       key: "gender",
       value: info?.step1?.gender,
      },
      {
       key: "analysis_options",
       values: values.analysisOptions,
      },

      {
       key: "finals",
       values: values?.finals || [],
      },
      values?.finals?.find((item) => item === "finals")
       ? {
          key: "reserve_day",
          values: values.reserveDays,
         }
       : {
          key: "reserve_day",
          values: [],
         },
      {
       key: "scoring_type",
       values: values?.scoringType,
      },
      {
       key: "match_format",
       value: values?.matchFormat,
      },
      {
       key: "number_of_pools",
       value: values?.numberOfPools ? values?.numberOfPools.toString() : "",
      },
      {
       key: "number_of_teams_per_Pool",
       value: values?.numberOfTeamsPerPool
        ? values?.numberOfTeamsPerPool.toString()
        : "",
      },
      {
       key: "number_of_matches_vs_each_team_in_pool",
       value: values?.numberOfMatchesVsEachTeamInPool
        ? values?.numberOfMatchesVsEachTeamInPool.toString()
        : "",
      },
      {
       key: "overs_per_innings",
       value: values?.oversPerInnings ? values?.oversPerInnings.toString() : "",
      },
      {
       key: "innings_per_team",
       value: values?.inningsPerTeam ? values?.inningsPerTeam.toString() : "0",
      },
      {
       key: "ball_colour",
       value: values?.recommendedBall,
      },
      {
       key: "differently_abled",
       value: info?.step1?.differentlyAbled,
      },
      {
       key: "sponsor_logo",
       value: info?.fileSPONL ? info?.fileSPONL : "",
      },
      {
       key: "competition_logo",
       value: info?.fileCMSL ? info?.fileCMSL : "",
      },
     ],
    };
    //  callAPI(obj);
    dispatch(
     setCMSData({
      ...CMSData,
      tab1Step1: CMSData?.tab1Step1,
      tab1Step2: values,
      tab1: obj,
      tab2Step1: CMSData?.tab2Step1,
      tab2Step2: CMSData?.tab2Step2,
      tab2Step3: CMSData?.tab2Step3,
      tab2: CMSData?.tab2,
      tab3Step1: CMSData?.tab3Step1,
      tab3Step2: CMSData?.tab3Step2,
      tab3: CMSData?.tab3,
      selMembers: CMSData?.selMembers,
     })
    );
    setActiveKey("2");
    dispatch(setCurrentTabState(2));
    setStep(1);
   }
  },
 });
 useEffect(() => {
  if (response) {
   addToast(
    isEdit ? "Updated Successfully" : "Successfully Created",
    { appearance: "success" },
    () => navigate("/cms-list")
   );
   resetForm();
  } else if (error) {
   addToast(error?.message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [response, error]);
 const {
  errors,
  touched,
  handleSubmit,
  values,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  resetForm,
 } = formik;
 const checkValidity = () => {
  const { matchFormat, numberOfPools, numberOfTeamsPerPool, oversPerInnings } =
   values;
  const isVAlid =
   matchFormat &&
   numberOfPools &&
   numberOfTeamsPerPool &&
   oversPerInnings &&
   Object.keys(errors).length === 0;
  return isVAlid ? false : true;
  
 };
 
 const getKeyValue = (arr) =>
  arr?.map((item) => ({ label: item.name, value: item.value }));

  function getNumbersArray(count) {
    let arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(i.toString());
    }
    return arr;
  }

 return (
  <div className="mt-3 pt-3 px-3">
   <CsaStepper steps={steps} activeKey={1} />

   <Row
    gutter={{
     xs: 8,
     sm: 16,
     md: 24,
     lg: 32,
    }}
    className="mt-3 pt-3"
    align="Start"
   >
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Match Format"
      placeholder="Select Match Format"
      name="matchFormat"
      value={values.matchFormat}
      menuArr={getKeyValue(info?.defaultSettings?.matchFormat)}
      optionType
     // mode={"multiple"}
      onChange={(e) => {
       setFieldValue("matchFormat", e);
      }}
      required
      defaultValue={values.matchFormat}
      onBlur={handleBlur}
      touched={touched.matchFormat}
      error={errors.matchFormat || null}
     />
    </Col>
    {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <SearchDropDown
         label="Match Format"
         placeholder="Select Match Format"
         name="matchFormat"
        value={values.matchFormat}
        mode
        options={getKeyValue(info?.defaultSettings?.matchFormat)}
        onChange={(e) => {
         setFieldValue("matchFormat", e);
        }}
      //  onClear={onClear}
        required
        defaultValue={values.matchFormat}
        onBlur={handleBlur}
        touched={touched.matchFormat}
        error={errors.matchFormat || null}
        // loading={searching}
       />
      </Col> */}

    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Number of Pools"
      placeholder="Select Number of Pools"
      name="numberOfPools"
      value={values.numberOfPools}
      menuArr={getNumbersArray(20)}
      onChange={(e) => {
       setFieldValue("numberOfPools", e);
      }}
      required
      defaultValue={values.numberOfPools}
      onBlur={handleBlur}
      touched={touched.numberOfPools}
      error={errors.numberOfPools || null}
     />
    </Col>

    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Number of Teams per Pool"
      placeholder="Select Number of Teams per Pool"
      name="numberOfTeamsPerPool"
      required
      menuArr={getNumbersArray(60)}
      value={values.numberOfTeamsPerPool}
      onChange={(e) => {
       setFieldValue("numberOfTeamsPerPool", e);
      }}
      defaultValue={values.numberOfTeamsPerPool}
      onBlur={handleBlur}
      touched={touched.numberOfTeamsPerPool}
      error={errors.numberOfTeamsPerPool || null}
     />
    </Col>

    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Number of Matches vs Each Team in Pool"
      placeholder="Select Number of Matches vs Each Team in Pool"
      name="numberOfMatchesVsEachTeamInPool"
      menuArr={getNumbersArray(100)}
      required
      value={values.numberOfMatchesVsEachTeamInPool}
      onChange={(e) => {
       setFieldValue("numberOfMatchesVsEachTeamInPool", e);
      }}
      defaultValue={values.numberOfMatchesVsEachTeamInPool}
      onBlur={() => setFieldTouched("numberOfMatchesVsEachTeamInPool", true)}
      touched={touched.numberOfMatchesVsEachTeamInPool}
      error={errors.numberOfMatchesVsEachTeamInPool || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Innings per Team"
      placeholder="Select Innings per Team"
      name="inningsPerTeam"
      menuArr={["1", "2"]}
      value={values.inningsPerTeam}
      onChange={(e) => {
       setFieldValue("inningsPerTeam", e);
      }}
      onBlur={handleBlur}
      touched={touched.inningsPerTeam}
      error={errors.inningsPerTeam || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Overs per Innings"
      placeholder="Select Overs per Innings"
      required
      name="oversPerInnings"
      value={values.oversPerInnings}
      menuArr={["Unlimited",...getNumbersArray(300)]}
      onChange={(e) => {
       setFieldValue("oversPerInnings", e);
      }}
      onBlur={() => setFieldTouched("oversPerInnings", true)}
      touched={touched.oversPerInnings}
      error={errors.oversPerInnings || null}
     />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaDropDown
      label="Recommended Ball Type/Colour"
      placeholder="Select Recommended Ball Type/Colour"
      required
      name="recommendedBall"
      value={values.recommendedBall}
      optionType
      menuArr={getKeyValue(info?.defaultSettings?.ballColour)}
      onChange={(e) => {
       setFieldValue("recommendedBall", e);
      }}
      onBlur={() => setFieldTouched("recommendedBall", true)}
      touched={touched.recommendedBall}
      error={errors.recommendedBall || null}
     />
    </Col>
    {/* <Col span={24}>
     <CsaCheckBox
      label="Scoring Type"
      name="scoringType"
      required
      options={getKeyValue(info?.defaultSettings?.scoringType)}
      value={values.scoringType}
      onChange={(checkedValue) => {
       setFieldValue("scoringType", checkedValue);
      }}
      onBlur={handleBlur}
      touched={touched.scoringType}
      error={errors.scoringType || null}
     />
    </Col> */}
    <Col span={24}>
     <CsaCheckBox
      label="Analysis Options"
      name="analysisOptions"
      options={getKeyValue(info?.defaultSettings?.analysisOpt)}
      singleSelect
      value={values.analysisOptions}
      onChange={(checkedValue) => {
       setFieldValue("analysisOptions", checkedValue);
      }}
      onBlur={handleBlur}
      touched={touched.analysisOptions}
      error={errors.analysisOptions || null}
     />
    </Col>
    <Col span={24}>
     <CsaCheckBox
      label="Finals"
      name="finals"
      options={getKeyValue(info?.defaultSettings?.finals)}
      value={values.finals}
      onChange={(checkedValue) => {
       setFieldValue("finals", checkedValue);
      }}
      onBlur={handleBlur}
      touched={touched.finals}
      error={errors.finals || null}
     />
    </Col>
    {/* {values?.finals?.find((item) => item === "finals") ? (
     <Col span={24}>
      <CsaCheckBox
       label="Reserve Days"
       name="reserveDays"
       options={getKeyValue(info?.defaultSettings?.reserveDays)}
       value={values.reserveDays}
       onChange={(checkedValue) => {
        setFieldValue("reserveDays", checkedValue);
       }}
       onBlur={handleBlur}
       touched={touched.reserveDays}
       error={errors.reserveDays || null}
      />
     </Col>
    ) : (
     <></>
    )} */}
    <div className="mt-4  d-flex w-100 justify-content-end">
     <CsaButton
      label="Back"
      textColor="#007237"
      onClick={() => {
       setInfo({ step1: info?.step1, step2: values });
       setStep(1);
      }}
      btnColor="#FFCB03"
     />
     <CsaButton
      label="Submit"
      textColor="#FFF"
      onClick={() => handleSubmit()}
      btnColor="#007237"
      disabled={checkValidity()}
      loading={loading}
     />
    </div>
   </Row>
  </div>
 );
}

export default CompetitionFormat;
