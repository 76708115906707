import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, AutoComplete, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import useAxios from "utils/axios";
import MemberView from "../../Common/MemberRegisterForm/MemberView";
import { serviceUrl } from "services/serviceUrl";
import GoogleplaceSearchInput from "Common/GooglePlaceSearch";

const MembersSchema = Yup.object().shape({
  province: Yup.string().required("Select Province"),
  membersName: Yup.string().required("Select Member Name"),
  // webAddress: Yup.string()
  // .matches(
  //   /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
  //   "Enter correct Address!"
  // )
  // .required("Enter Web Address"),
  address: Yup.string().required("Enter Address"),
});

function MemberRegisterForm({ styles, setStep, setInfo, info, setLocal }) {
  const location = useLocation();
  const authRole = localStorage.getItem("selRole");
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const steps = ["General Information", "Administrator"];
  const { id, type } = location?.state || {};
  const [fileId, setFileId] = useState(null);
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const {
    callAPI: fetchDetail,
    response: success,
    error: err,
    loading: fetching,
  } = useAxios({
    url: serviceUrl.getMemberDetailByID + `${id}`,
    method: "get",
  });
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getMemberMasterData,
    method: "get",
  });
  useEffect(() => {
    callAPI();
  }, []);
  const data =
    isEdit || isView
      ? {
          nationality: success?.[0]?.nationality,
          iDNumber:
            success?.[0]?.foreign_user === "False"
              ? success?.[0]?.id_number
              : "", //need to change based on api
          passportNumber: success?.[0]?.passport_number,
          firstName: success?.[0]?.first_name,
          lastName: success?.[0]?.last_name,
          emailAddress: success?.[0]?.email_address,
          contactNumber: success?.[0]?.contact_number,
          dateofBirth: success?.[0]?.date_of_birth,
          race: success?.[0]?.race,
          gender: success?.[0]?.gender,
          differentlyAbled: success?.[0]?.differently_abled,
          province: success?.[0]?.province,
          address: success?.[0]?.address,
          latitude: success?.[0]?.latitude || "",
          longitude: success?.[0]?.longitude || "",
          membersName: success?.[0]?.field_members_names,
          webAddress: success?.[0]?.web_url,
          status: success?.[0]?.status === "Active" ? "Active" : "Disable",
          uid: success?.[0]?.uid,
          member_id: success?.[0]?.member_id,
        }
      : info?.step1;

  useEffect(() => {
    if (id) {
      fetchDetail();
    }
  }, [id]);

  useEffect(() => {
    if (err) {
    }
  }, [err]);

  const handleClear = () => {
    resetForm();
    navigate(-1);
  };
  const onSearch = (val) => {
    if (!val) return;
    let filtered = masterData.memberList.filter(
      (obj) =>
        obj.key !== 0 &&
        obj.value.toString().toLowerCase().includes(val.toLowerCase())
    );
    setOptions(filtered);
  };
  const onSelect = (value) => {};
  useEffect(() => {
    setLocal(info?.isLocal || success && success?.[0]?.foreign_user === "True" ? false : true);
  }, [info,success]);

  const getMemberNameById = (id) => {
    let memberName = response?.find((item) => item?.member_id == id)
      ?.member_name;
    return memberName;
  };

  const formik = useFormik({
    initialValues: {
      province: data ? data?.province : "",
      address: data ? data?.address : "",
      latitude: data ? data?.latitude : "",
      longitude: data ? data?.longitude : "",
      //  membersName: data
      //   ? isEdit
      //     ? data?.membersName
      //     : getMemberNameById(data?.membersName)
      //   : "",
      membersName: data ? data?.membersName : "",
      webAddress: data ? data?.webAddress : "",
    },
    validationSchema: MembersSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      //  let memberId = response?.find(
      //   (item) => item?.member_name == values.membersName
      //  )?.member_id;
      //  values.membersName = memberId;
      setInfo({
        step1: values,
        step2: info?.step2,
        file: fileId,
        isLocal:info?.isLocal,
        editData: isEdit || isView ? data : {},
      });
      setStep(2);
    },
  });

  console.log(info?.isLocal,"local")

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const checkValidity = () => {
    const { province, membersName, address } = values;

    const isVAlid =
      province && membersName && address && Object.keys(errors).length === 0;

    return isVAlid ? false : true;
  };

  const updateLocation = (e, latLng) => {
    formik.setFieldValue("latitude", latLng.lat);
    formik.setFieldValue("longitude", latLng.lng);
    formik.setFieldValue("address", e);
  };

  return isView ? (
    <div>
      <Row>
        {fetching ? (
          <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <MemberView viewData={data} />
        )}
      </Row>
    </div>
  ) : (
    <div>
      <CsaStepper steps={steps} activeKey={0} />
      {fetching ? (
        <Spin />
      ) : (
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="mt-3 pt-3"
          align="Start"
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaDropDown
              label="Province"
              placeholder="Select Province"
              required
              menuArr={masterData.provinceList}
              onChange={(e) => {
                setFieldValue("province", e);
              }}
              name="province"
              value={values.province}
              onBlur={() => setFieldTouched("province", true)}
              touched={touched.province}
              error={errors.province || null}
            />
          </Col>

          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <p className={styles.name}>
       {"Members Name"}
       {<span className={styles.required}>*</span>}
      </p>
      <AutoComplete
       style={{
        width: "100%",
       }}
       allowClear
       options={response?.map((p) => ({
        key: p?.member_id,
        value: p.member_name,
        ...p,
       }))}
       name="membersName"
       required
       onChange={(e) => {
        setFieldValue("membersName", e);
       }}
       placeholder="Enter Members Name"
       value={values.membersName}
       onSelect={(val, option) => onSelect(val, option.label)}
       onSearch={onSearch}
       onBlur={(e) => {
        handleBlur(e);
        const input = e.target.value;
        if (input) {
         let filtered = masterData.memberList?.find(
          (val) => val?.value?.toLowerCase() === input?.toLowerCase()
         );
         if (filtered) {
          setFieldValue("membersName", filtered?.value);
         } else {
          setFieldValue("membersName", "");
         }
        }
       }}
       error={errors.membersName || null}
       disabled={
        authRole === "CSA ADMINISTRATOR" || authRole === "CSA ADMIN ASSISTANT"
         ? null
         : isEdit
       }
      ></AutoComplete>
      {errors.membersName && (
       <pre className="text-danger">{errors.membersName}</pre>
      )}
     </Col> */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Members Name"
              placeholder="Enter Members Name"
              type="text"
              name="membersName"
              onChange={handleChange}
              value={values.membersName}
              required
              onBlur={handleBlur}
              error={errors.membersName || null}
              touched={touched?.membersName}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Web Address"
              placeholder="Enter Web Address"
              type="text"
              name="webAddress"
              onChange={handleChange}
              value={values.webAddress}
              onBlur={handleBlur}
              touched={touched?.webAddress}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {/* <CsaTextInput
              label="Address"
              placeholder="Enter Address"
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address}
              required
              onBlur={handleBlur}
              error={errors.address || null}
              touched={touched?.address}
            /> */}

            <GoogleplaceSearchInput
              label="Address"
              placeholder="Enter Address"
              value={values.address}
              latitude={values.latitude}
              longitude={values.longitude}
              AddLocation={(e, latLng) => updateLocation(e, latLng)}
              error={errors.address || null}
              touched={touched?.address}
              required
            />
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Latitude"
              placeholder="Enter Latitude"
              type="text"
              name="latitude"
              onChange={handleChange}
              value={values.latitude}
              onBlur={handleBlur}
              touched={touched.latitude}
              error={errors.latitude || null}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CsaTextInput
              label="Longitude"
              placeholder="Enter Longitude"
              type="text"
              name="longitude"
              onChange={handleChange}
              value={values.longitude}
              onBlur={handleBlur}
              touched={touched.longitude}
              error={errors.longitude || null}
            />
          </Col>
          <div className="mt-4 d-flex w-100 justify-content-end">
            <Space>
              <CsaButton
                label="Cancel"
                textColor="#007237"
                onClick={() => handleClear()}
                btnColor="#FFCB03"
              />
              <CsaButton
                label="Continue"
                textColor="#FFF"
                onClick={() => handleSubmit()}
                btnColor="#007237"
                disabled={checkValidity()}
              />
            </Space>
          </div>
        </Row>
      )}
    </div>
  );
}

export default MemberRegisterForm;
