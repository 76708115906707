import CsaButton from "Common/CsaButton";
import CsaTextInput from "Common/CsaTextInput";
import { Modal, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
import * as Yup from "yup";

const streamingValidationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter a Valid Username"),
  pwd: Yup.string().required("Please Enter a valid Password"),
});

const ProviderLoginPopup = ({
  showStreamingPopup,
  setShowStreamingPopup,
  data,
  matchName,
  setStreamingData,
  k,
}) => {
  const { addToast } = useToasts();

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.redeemToken,
    method: "post",
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      pwd: "",
    },
    validationSchema: streamingValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqObj = {
        email: values.username,
        password: values.pwd,
        matchName: matchName,
        matchId: data,
        redeemCount: 1,
      };
      callAPI(reqObj);
    },
  });

  console.log(data, k, "sss");

  useEffect(() => {
    if (response?.error) {
      addToast(response.error, { appearance: "info" });
      if (response?.redeem?.id) {
        setShowStreamingPopup({
          url: true,
          login: false,
        });
        setStreamingData(response);
      }
    } else if (response?.redeem?.id) {
      setShowStreamingPopup({
        url: true,
        login: false,
      });
      setStreamingData(response);
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      addToast(error.message, { appearance: "error" });
    }
  }, [error]);

  const {
    errors,
    setFieldTouched,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleReset,
    handleSubmit,
    handleBlur,
  } = formik;
  const mapInputData = [
    {
      label: "Username",
      type: "text",
      value: values.username,
      name: "username",
      placeholder: "Enter Username",
    },
    {
      label: "Password",
      type: "text",
      value: values.pwd,
      name: "pwd",
      inputType: "password",
      placeholder: "Enter Password",
    },
  ];

  const checkValidity = () => {
    const { username, pwd } = values;
    const isVAlid = username && pwd && Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  const handlePopup = (v) => {
    setShowStreamingPopup({
      ...showStreamingPopup,
      login: false,
    });
  };
  console.log(showStreamingPopup?.data, "matchName");

  const streamUrl = "https://dev-ui-streaming.d1qrmgoaba8px4.amplifyapp.com/";
  return (
    <Modal
      key={k}
      title="Welcome to CSA Streaming Services!"
      centered
      visible={showStreamingPopup.login}
      className="modalStyle"
      closable={false}
      footer={[
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CsaButton
            label="Cancel"
            textColor="#007237"
            onClick={() =>
              setShowStreamingPopup({
                ...showStreamingPopup,
                login: false,
              })
            }
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Login"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="green"
            disabled={checkValidity()}
            loading={loading}
          />
        </div>,
      ]}
      //   onOk={() => setModal2Open(false)}
      onCancel={() => handlePopup({ ...showStreamingPopup, login: false })}
    >
      <p>Stream your match in 3 easy steps:</p>
      <p></p>
      <Space direction="vertical">
        <Text>
          <Text strong>1:</Text> Register as a Streaming Service,{" "}
          <a
            style={{ color: "blue", textDecoration: "none" }}
            onMouseOver={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
            href={streamUrl + "register-streaming-service"}
            target="_self"
          >
            click here
          </a>{" "}
          to register
        </Text>
        <Text>
          <Text strong>2:</Text> Load tokens to your account. One token will be
          used per match. Have you got tokens? If not, please{" "}
          <a
            style={{ color: "blue", textDecoration: "none" }}
            onMouseOver={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
            //  onClick={() => setShowStreamingPopup({ login: false, url: true })}
            href={streamUrl + "login"}
          >
            click here{" "}
          </a>
          before you move on to step 3
        </Text>
        <Text>
          <Text strong>3:</Text> Enter your <Text strong>Username</Text> and{" "}
          <Text strong>Password</Text> to recieve your StreamKey URL and Overlay
          URL.
        </Text>
      </Space>
      <div
        style={{
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        <Text style={{ fontStyle: "italic" }}>Match: {matchName}</Text>{" "}
      </div>
      {mapInputData.map(
        ({ label, name, value, type, inputType, placeholder }) => {
          return (
            <CsaTextInput
              label={label}
              placeholder={placeholder}
              type={inputType || "text"}
              name={name}
              //   maxLength={max}
              onChange={handleChange}
              value={value}
              isNumber={inputType === "number"}
              required
              onBlur={handleBlur}
              touched={touched[name]}
              error={errors[name] || null}
            />
          );
        }
      )}
      <div
        style={{
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        <Text style={{ fontStyle: "italic" }}>
          <Text strong>Note: </Text> A token will be used from your account.
        </Text>{" "}
      </div>
    </Modal>
  );
};

export default ProviderLoginPopup;
