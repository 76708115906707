import React, { useEffect, useState } from "react";
import { Table } from "antd";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";

export default function TournamentFieldingStatsTable({ ...props }) {
  const { addToast } = useToasts();

  const { matchId } = props;
  
  const {
    callAPI: fetchStats,
    response: resStats,
    error: errStats,
    loading: loadStats,
  } = useAxios({
    url: serviceUrl.getTournamentStats,
    method: "get",
  });

  useEffect(() => {
    fetchStats(`${matchId}/fielding`, true);
  }, []);
  useEffect(() => {
    if (errStats) {
      addToast(errStats?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [errStats]);

  const res = resStats?.data || [];
  // 
  //  const resData = res?.map((i) => ({
  //   position:i?.position,
  //   name:i?.name+" "+i?.surname,
  //   team:i?.team,
  //   games:i?.games,
  //   stumpings:i?.stumpings,
  //   run_outs:i?.run_outs,
  //   catches:i?.catches,
  //  }))

  let Pos = 1;

  //const sortedByCatches = res?.sort((a,b) => b.catches - a.catches)

  const sort = res.sort(function (a, b) {
    let rn1 = a.catches;
    let rn2 = b.catches;

    let sr1 = a.run_outs;
    let sr2 = b.run_outs;

    let b1 = a.stumpings;
    let b2 = b.stumpings;

    if (rn1 > rn2) return -1;
    if (rn1 < rn2) return 1;

    if (sr1 > sr2) return -1;
    if (sr1 < sr2) return 1;

    if (b1 > b2) return -1;
    if (b1 < b2) return 1;

    return 0;
  });

  const resData = sort?.map((i) => ({
    position: Pos++,
    name: i?.name + " " + i?.surname,
    team: i?.team,
    games: i?.games,
    stumpings: i?.stumpings,
    run_outs: i?.run_outs,
    catches: i?.catches,
    direct_hits: i?.direct_hits,
  }));
  const columns = [
    {
      title: "Pos",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Player",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Team Name",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Matches",
      dataIndex: "games",
      key: "games",
    },
    {
      title: "Catches",
      dataIndex: "catches",
      key: "catches",
    },
    // {
    //   title: "Drop Catches:",
    //   dataIndex: "droped",
    //   key: "droped",
    // },
    {
      title: "Run Outs",
      dataIndex: "run_outs",
      key: "run_outs",
    },
    {
      title: "Direct Hits",
      dataIndex: "direct_hits",
      key: "direct_hits",
    },
    {
      title: "Stumpings",
      dataIndex: "stumpings",
      key: "stumpings",
    },
  ];

  return (
    <>
      <Table
      className="center-aligned"
        //   className="table-striped-rows"
        loading={loadStats}
        columns={columns}
        dataSource={resData}
        pagination={false}
        scroll={{
          x: 800,
        }}
      />
    </>
  );
}
