import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./ClubSchools.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";

const ClubSchoolsTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getClubDetails + userLevel + "?",
    method: "get",
  });
  useEffect(() => {
    callAPI(`member_id=${memberId}`, true);
  }, []);

  const columns = [
    {
      title: "Club Name",
      dataIndex: "name",
      key: "clubName",
    },
    {
      title: "Administrator",
      dataIndex: "administrator",
      key: "administrator",
    },
    {
      title: "Senior Teams",
      dataIndex: "seniorTeams",
      key: "seniorTeams",
    },
    {
      title: "Junior Teams",
      dataIndex: "juniorTeams",
      key: "juniorTeams",
    },
    { title: "Woman Teams", dataIndex: "womanTeams", key: "womanTeams" },
    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBER CLUB COORDINATOR"
      ? {
          title: "Action",
          key: "action",
          render: (item) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-club", {
                  state: { id: item?.id, isView: true },
                })
              }
              handleEdit={() =>
                navigate("/edit-club", {
                  state: { id: item?.id, isEdit: true },
                })
              }
            />
          ),
          //  align: "right",
        }
      : {},
  ];
  const data = response?.rows
    // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
    ?.map((item) => ({
      key: item?.id,
      name: item?.field_club_name?.replace(/&#039;/g, "'"),
      juniorTeams: item?.junior_count
        ? item?.junior_count[0]?.count
        : "0" || "0",
      administrator:
        item?.field_first_name?.toString().replace(/&#039;/g, "'") +
        " " +
        item?.field_last_name?.toString().replace(/&#039;/g, "'"),
      womanTeams: item?.women_count ? item?.women_count[0]?.count : "0" || "0",
      seniorTeams: item?.senior_count
        ? item?.senior_count[0]?.title
        : "0" || "0",
      status1: item?.field_status === "Active" ? "Activated" : "Disabled",
      ...item,
    }));

  const onSelect = (value) => {};

  const onChange = (page) => {
    callAPI(`keyword=&member_id=${memberId}&page=${page - 1}`, true);
  };
  const handleSearch = (e) => {
    callAPI(`keyword=${e}&member_id=${memberId}`, true);
  };
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Club", value: "/club-list" },
  ];
  return (
    <Wrapper>
      <div className={styles.ClubSchoolsWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Club</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                // options={options}
                onSelect={(val, option) => onSelect(val, option.label)}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className=" d-flex w-100 justify-content-end">
                {authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT" ||
                authRole === "MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBERS ADMIN ASSISTANT" ||
                authRole === "MEMBER CLUB COORDINATOR" ? (
                  <CsaButton
                    label="Create"
                    textColor="#FFF"
                    onClick={() => navigate("/register-club")}
                    btnColor="#007237"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2">
            <Table
              className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 700,
              }}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={response?.pager?.total_items}
                size={"small"}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ClubSchoolsTable;
