import React, { useState } from "react";
import { useLocation } from "react-router-dom";
//Components
import PrimarySchoolRegisterForm from "../../Common/PrimarySchoolRegisterForm";
import PrimarySchoolRegisterForm2 from "../../Common/PrimarySchoolRegisterForm/PrimarySchoolRegisterForm2";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./PrimarySchools.module.scss";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function PrimarySchools() {
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
  };
  const location = useLocation();
  const { isView = false, isEdit = false } = location?.state || {};
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Primary Schools", value: "/primary-school-list" },
    { key: "Primary Schools", value: "/register-primary-school" },
  ];

  return (
    <Wrapper>
      <div className={styles.PrimarySchoolsMWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Primary School
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>
            {step === 1 && <PrimarySchoolRegisterForm {...props} />}
            {step === 2 && <PrimarySchoolRegisterForm2 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default PrimarySchools;
