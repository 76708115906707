import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { serviceUrl } from "services/serviceUrl";
import PrimaryView from "../../Common/PrimarySchoolRegisterForm/PrimaryView";
import useAxios from "utils/axios";
import GoogleplaceSearchInput from "Common/GooglePlaceSearch";

const PrimarySchoolSchema = Yup.object().shape({
  municipality: Yup.string().required("Select Municipality"),
  natEMIS: Yup.string().required("Enter NatEMIS"),
  dbeSchoolName: Yup.string().required("Enter DBE School Name"),
  phasePed: Yup.string().required("Enter Phase-PED"),
  sector: Yup.string().required("Select sector"),
  settlementType: Yup.string().required("Select Settlement Type"),
  schoolType: Yup.string().required("Select School Type"),
  kfcMiniCricket: Yup.string().required("Select KFC Mini Cricket"),
  schoolStatus: Yup.string().required("Select school status"),
  webAddress: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
    "Enter correct Address!"
  ),
});

function PrimarySchoolRegisterForm({
  styles,
  setStep,
  setInfo,
  info,
  img,
  setImg,
  setLocal,
}) {
  const memberName = localStorage.getItem("csa_member_name");
  const provinceName = JSON.parse(localStorage.getItem("csa_province"));
  const location = useLocation();
  const { isView = false, id, isEdit = false } = location?.state || {};
  const steps = ["General Information", "Administrator"];
  const { addToast } = useToasts();
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getPrimarySchoolDetailByID + `${id}`,
    method: "get",
  });
  const data = isEdit
    ? {
      municipality: response?.[0]?.field_municipality || "",
      natEMIS: response?.[0]?.field_natemis || "",
      dbeSchoolName: response?.[0]?.field_dbe_school_name || "",
      phasePed: response?.[0]?.field_phase_ped || "",
      commonlyKnownAs: response?.[0]?.field_commonly_known_as || "",
      sector: response?.[0]?.field_sector || "",
      settlementType: response?.[0]?.field_settlement_type || "",
      schoolType: response?.[0]?.field_school_type || "",
      webAddress: response?.[0]?.field_web_address || "",
      address: response?.[0]?.field_address || "",
      latitude: response?.[0]?.field_latitude || "",
      longitude: response?.[0]?.field_longitude || "",
      kfcMiniCricket: response?.[0]?.field_kfc_mini_cricket || "",
      schoolStatus:
        response?.[0]?.field_school_status === "Active"
          ? "Active"
          : "Disable" || "",
      uid: response?.[0]?.uid,
    }
    : info?.step1 || {};
  useEffect(() => {
    setLocal(response && response?.[0]?.foreign_user === "True" ? false : true);
  }, [response]);
  const formik = useFormik({
    initialValues: {
      municipality: data ? data?.municipality : "",
      natEMIS: data ? data?.natEMIS : "",
      dbeSchoolName: data ? data?.dbeSchoolName?.replace(/&#039;/g, "'") : "",
      phasePed: data ? data?.phasePed : "",
      commonlyKnownAs: data ? data?.commonlyKnownAs?.replace(/&#039;/g, "'") : "",
      sector: data ? data?.sector : "",
      settlementType: data ? data?.settlementType : "",
      schoolType: data ? data?.schoolType : "",
      webAddress: data ? data?.webAddress : "",
      address: data ? data?.address : "",
      latitude: data ? data?.latitude : "",
      longitude: data ? data?.longitude : "",
      kfcMiniCricket: data ? data?.kfcMiniCricket : "",
      schoolStatus: data ? data?.schoolStatus : "",
    },
    validationSchema: PrimarySchoolSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      
      setInfo({
        step1: values,
        step2: info?.step2,
        editData: response?.[0] || null,
      });
      setStep(2);
    },
  });

  

  useEffect(() => {
    if (isEdit || isView) {
      callAPI();
    }
  }, [isEdit, isView]);
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const navigate = useNavigate();

  const handleClear = () => {
    resetForm();
    navigate(-1);
  };
  const checkValidity = () => {
    const {
      municipality,
      natEMIS,
      dbeSchoolName,
      phasePed,
      sector,
      settlementType,
      schoolType,
      kfcMiniCricket,
      schoolStatus,
    } = values;
    const isVAlid =
      municipality &&
      natEMIS &&
      dbeSchoolName &&
      phasePed &&
      sector &&
      settlementType &&
      schoolType &&
      kfcMiniCricket &&
      schoolStatus &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  const updateLocation = (e, latLng) => {
    
    formik.setFieldValue('latitude', latLng.lat)
    formik.setFieldValue('longitude', latLng.lng)
    formik.setFieldValue('address', e)
  }
  
  return (
    <>
      {loading ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      ) : isView ? (
        <PrimaryView viewData={response?.[0]} />
      ) : (
        <>
          <CsaStepper steps={steps} activeKey={0} />
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3"
            align="Start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Municipality"
                placeholder="Select Municipality"
                required
                menuArr={
                  masterData.optionsMunicipality?.find(
                    (item) => item?.key === provinceName
                  )?.value
                }
                onChange={(e) => {
                  setFieldValue("municipality", e);
                }}
                name="municipality"
                value={values.municipality}
                onBlur={() => setFieldTouched("municipality", true)}
                touched={touched.municipality}
                error={errors.municipality || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="NatEMIS"
                placeholder="Enter NatEMIS"
                type="text"
                name="natEMIS"
                onChange={handleChange}
                value={values.natEMIS}
                required
                onBlur={handleBlur}
                touched={touched.natEMIS}
                error={errors.natEMIS || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="DBE School Name"
                placeholder="Enter DBE School Name"
                type="text"
                name="dbeSchoolName"
                onChange={handleChange}
                value={values.dbeSchoolName}
                required
                onBlur={handleBlur}
                touched={touched.dbeSchoolName}
                error={errors.dbeSchoolName || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Commonly Known As"
                placeholder="Enter Commonly Known As"
                type="text"
                name="commonlyKnownAs"
                onChange={handleChange}
                value={values.commonlyKnownAs}
                onBlur={handleBlur}
                touched={touched.commonlyKnownAs}
                error={errors.commonlyKnownAs || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Phase-PED"
                placeholder="Enter Phase-PED"
                required
                menuArr={masterData.pHASE_PED}
                onChange={(e) => {
                  setFieldValue("phasePed", e);
                }}
                name="phasePed"
                value={values.phasePed}
                onBlur={() => setFieldTouched("phasePed", true)}
                touched={touched.phasePed}
                error={errors.phasePed || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Sector"
                placeholder="Select Sector"
                required
                menuArr={masterData.sectorList}
                onChange={(e) => {
                  setFieldValue("sector", e);
                }}
                name="sector"
                value={values.sector}
                onBlur={() => setFieldTouched("sector", true)}
                touched={touched.sector}
                error={errors.sector || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Settlement Type"
                placeholder="Select Settlement Type"
                required
                menuArr={masterData.settlementTypeList}
                onChange={(e) => {
                  setFieldValue("settlementType", e);
                }}
                name="settlementType"
                value={values.settlementType}
                onBlur={() => setFieldTouched("settlementType", true)}
                touched={touched.settlementType}
                error={errors.settlementType || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="School Type"
                placeholder="Select School Type"
                required
                menuArr={masterData.schoolTypeList}
                onChange={(e) => {
                  setFieldValue("schoolType", e);
                }}
                name="schoolType"
                value={values.schoolType}
                onBlur={() => setFieldTouched("schoolType", true)}
                touched={touched.schoolType}
                error={errors.schoolType || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Web Address"
                placeholder="Enter Web Address"
                type="text"
                name="webAddress"
                onChange={handleChange}
                value={values.webAddress}
                onBlur={handleBlur}
                touched={touched.webAddress}
                error={errors.webAddress || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* <CsaTextInput
        label="Address"
        placeholder="Enter Address"
        type="text"
        name="address"
        onChange={handleChange}
        value={values.address}
        onBlur={handleBlur}
        touched={touched.address}
        error={errors.address || null}
       /> */}

              <GoogleplaceSearchInput
                label="Address"
                placeholder="Enter Address"
                value={values.address}
                latitude={values.latitude}
                longitude={values.longitude}
                AddLocation={(e, latLng) => updateLocation(e, latLng)}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Latitude"
                placeholder="Enter Latitude"
                type="text"
                name="latitude"
                onChange={handleChange}
                value={values.latitude}
                onBlur={handleBlur}
                touched={touched.latitude}
                error={errors.latitude || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Longitude"
                placeholder="Enter Longitude"
                type="text"
                name="longitude"
                onChange={handleChange}
                value={values.longitude}
                onBlur={handleBlur}
                touched={touched.longitude}
                error={errors.longitude || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="KFC Mini Cricket"
                placeholder="Select KFC Mini Cricket"
                required
                menuArr={masterData.kfcMiniCricketList}
                onChange={(e) => {
                  setFieldValue("kfcMiniCricket", e);
                }}
                name="kfcMiniCricket"
                value={values.kfcMiniCricket}
                onBlur={() => setFieldTouched("kfcMiniCricket", true)}
                touched={touched.kfcMiniCricket}
                error={errors.kfcMiniCricket || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="School Status"
                placeholder="Select School Status"
                required
                menuArr={masterData.statusList}
                onChange={(e) => {
                  setFieldValue("schoolStatus", e);
                }}
                name="schoolStatus"
                value={values.schoolStatus}
                onBlur={() => setFieldTouched("schoolStatus", true)}
                touched={touched.schoolStatus}
                error={errors.schoolStatus || null}
              />
            </Col>
            <div className="mt-4 d-flex w-100 justify-content-end">
              <Space>
                <CsaButton
                  label="Cancel"
                  textColor="#007237"
                  onClick={() => handleClear()}
                  btnColor="#FFCB03"
                />
                <CsaButton
                  label="Continue"
                  textColor="#FFF"
                  onClick={() => handleSubmit()}
                  btnColor="#007237"
                  disabled={checkValidity()}
                />
              </Space>
            </div>
          </Row>
        </>
      )}
    </>
  );
}

export default PrimarySchoolRegisterForm;
