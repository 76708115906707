import styles from "./CsaButton.module.scss";
import React from "react";
import { Button } from "antd";

function CsaButton({
 type = "",
 label = "Button text",
 onClick,
 textColor,
 disabled = false,
 btnColor,
 size = "large",
 ...props
}) {
 return (
  <div className={styles.CsaButtonWrapper}>
   <Button
    className={styles.button}
    type={type}
    size={size}
    style={{ background: disabled ? '#ccc' : btnColor }}
    onClick={onClick}
    disabled={disabled}
    {...props}
   >
    <span style={{ color: textColor }}>{label}</span>
   </Button>
  </div>
 );
}

export default CsaButton;
