import React, { useEffect, useState } from "react";
//Components
import StaffMembersRegisterForm from "../../Common/StaffMembersRegisterForm";
import StaffMembersRegisterForm2 from "../../Common/StaffMembersRegisterForm/StaffMembersRegisterForm2";

import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./StaffMembers.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function StaffMembers() {
  const [img, setImg] = useState([]);
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
    img,
    setImg,
  };
  const location = useLocation();
  const id = location?.state?.id;
  const type = location?.state?.type;
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Staff & Match Officials", value: "/staff-list" },
    {
      key: isEdit ? `Staff Members` : "Staff Members",
      value: "/register-staff",
    },
  ];

  return (
    <Wrapper>
      <div className={styles.StaffMembersWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <>
            <div className={styles.labelWrapper}>
              <p className={styles.heading}>
                {isEdit ? "Edit" : isView ? "" : "Create"} Staff Member
              </p>
              {isView && <CsaBackNavigateButton />}
            </div>
            <div>
              {step === 1 && <StaffMembersRegisterForm {...props} />}
              {step === 2 && <StaffMembersRegisterForm2 {...props} />}
            </div>
          </>
        </div>
      </div>
    </Wrapper>
  );
}

export default StaffMembers;
