import styles from "./CsaRadioButton.module.scss";
import React from "react";
import { Radio } from "antd";

function CsaRadioButton({
 options,
 label,
 onChange,
 name,
 onBlur,
 error,
 value,
 required = false,
}) {
 return (
  <div className={styles.CsaRadioButtonWrapper}>
   <p className={styles.name}>
    {label}
    {required && <span className={styles.required}>*</span>}
   </p>
   <Radio.Group
    options={options}
    className="w-100"
    onChange={onChange}
    value={value}
    name={name}
    onBlur={onBlur}
   />
   {error && <pre className="text-danger">{error}</pre>}
  </div>
 );
}

export default CsaRadioButton;
