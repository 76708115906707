import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDatePicker from "Common/CsaDatePicker";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "utils/axios";
// const roles = localStorage.getItem("CSAPermissions")?.split(",");

const MatchRefereeSchema = Yup.object().shape({
  inceptionDate: Yup.string().required("Select Inception Date"),
  highestLevelOfficiated: Yup.string().required(
    "Select Highest level officiated "
  ),
  iCCPanel: Yup.string().required("Select ICC panel "),
  status: Yup.string().required("Select Status"),
});

function MatchRefereeRegisterForm2({
  styles,
  setStep,
  setInfo,
  info,
  img,
  setImg,
}) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const steps = ["Personal Information", "Experience"];
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editReferee : serviceUrl.setReferee,
    method: isEdit ? "put" : "post",
  });

  let level =
    authLevel === "member"
      ? "MEMBER"
      : authLevel === "csa"
      ? "CSA"
      : "SUB-MEMBER";
  const setLevel = (arr) => {
    if (info?.userExisting === true) {
      arr.push(level);
    }
    return arr;
  };
  const data = isEdit
    ? {
        inceptionDate: info?.editData?.inceptionDate,
        highestLevelOfficiated: info?.editData?.highestLevelOfficiated,
        iCCPanel: info?.editData?.iCCPanel,
        status: info?.editData?.status,
      }
    : info?.step2 || {};
  const formik = useFormik({
    initialValues: {
      inceptionDate: data ? data?.inceptionDate : "",
      highestLevelOfficiated: data ? data?.highestLevelOfficiated : "",
      premierLeague: data ? data?.premierLeague || "No" : "No",
      iCCPanel: data ? data?.iCCPanel || "No" : "No",
      status: data ? data?.status : "",
    },
    validationSchema: MatchRefereeSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({ step1: info?.step1, step2: values });
      const obj = {
        email_address: info?.step1?.emailAddress,
        nationality: info?.step1?.nationality,
        id_number: info?.step1?.iDNumber?.toString() || "",
        passport_number: info?.step1?.passportNumber?.toString() || "",
        contact_number: info?.step1?.contactNumber?.toString() || "",
        first_name: info?.step1?.firstName,
        last_name: info?.step1?.lastName,
        dob: info?.step1?.dateofBirth,
        race: info?.step1?.race,
        gender: info?.step1?.gender,
        differently_abled: info?.step1?.differentlyAbled,
        inception_date: values.inceptionDate,
        highest_level: values.highestLevelOfficiated,
        icc_panel: values.iCCPanel === "Yes" ? true : false,
        status: values?.status === "Active" ? true : false || false,
        fid: info?.file,
        user_level:
          info?.userExisting === true
            ? setLevel(info?.existingUserData?.user_level)
            : isEdit
            ? info?.editData?.user_level
            : level,
        roles:
          info?.userExisting === true
            ? ["referees"]
            : isEdit
            ? null
            : ["referees"],
        member_id: level === "CSA" ? "" : memberId,
      };

      callAPI(
        id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
      );
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/match-referee-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = formik;
  const checkValidity = () => {
    const {
      inceptionDate,

      highestLevelOfficiated,
      iCCPanel,
      status,
    } = values;
    const isVAlid =
      inceptionDate &&
      highestLevelOfficiated &&
      iCCPanel &&
      status &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDatePicker
            label="Inception Date"
            placeholder="Select Inception Date"
            name="inceptionDate"
            setFieldValue={setFieldValue}
            required
            value={values.inceptionDate}
            onBlur={handleBlur}
            touched={touched.inceptionDate}
            error={errors.inceptionDate || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Highest Level Officiated"
            placeholder="Select Highest Level Officiated"
            name="highestLevelOfficiated"
            menuArr={masterData.highestLevelOfficiatedList}
            required
            value={values.highestLevelOfficiated}
            onChange={(e) => {
              setFieldValue("highestLevelOfficiated", e);
            }}
            onBlur={() => setFieldTouched("highestLevelOfficiated", true)}
            touched={touched.highestLevelOfficiated}
            error={errors.highestLevelOfficiated || null}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="ICC Panel"
            placeholder="Select ICC Panel"
            name="iCCPanel"
            required
            menuArr={["Yes", "No"]}
            value={values.iCCPanel}
            onChange={(e) => {
              setFieldValue("iCCPanel", e);
            }}
            defaultValue={values.iCCPanel || "No"}
            onBlur={handleBlur}
            touched={touched.iCCPanel}
            error={errors.iCCPanel || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Status"
            placeholder="Select Status"
            required
            name="status"
            value={values.status}
            menuArr={masterData.statusList}
            onChange={(e) => {
              setFieldValue("status", e);
            }}
            onBlur={() => setFieldTouched("status", true)}
            touched={touched.status}
            error={errors.status || null}
          />
        </Col>
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({ step1: info?.step1, step2: values,file:info.file });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Submit"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={checkValidity()}
            loading={loading}
          />
        </div>
      </Row>
    </div>
  );
}

export default MatchRefereeRegisterForm2;
