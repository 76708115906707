import React, { useEffect, useState } from "react";
//Components
import PlayersRegisterForm from "../../Common/PlayersRegisterForm";
import PlayersRegisterForm2 from "../../Common/PlayersRegisterForm/PlayersRegisterForm2";
import PlayersRegisterForm3 from "../../Common/PlayersRegisterForm/PlayersRegisterForm3";
import PlayersRegisterFormSub from "../../Common/PlayersRegisterForm-SubMember";
import PlayersRegisterFormSub2 from "../../Common/PlayersRegisterForm-SubMember/PlayersRegisterForm2";
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./Players.module.scss";
import { useLocation } from "react-router-dom";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function Players() {
  const userLevel = localStorage.getItem("csa_level");
  const [img, setImg] = useState([]);
  const [step, setStep] = useState(1); // basedon role need to change steps
  const [info, setInfo] = useState({ step1: {}, step2: {}, step3: {} });
  const [isLocal, setLocal] = useState(true);
  const location = useLocation();
  const id = location?.state?.id;
  const type = location?.state?.type;
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Players", value: "/player-list" },
    { key: "Player", value: "/register-player" },
  ];
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
    img,
    setImg,
  };

  return (
    <Wrapper>
      <div className={styles.PlayersWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Player
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          {userLevel !== "sub-member" ? (
            <div>
              {step === 1 && <PlayersRegisterForm {...props} />}
              {step === 2 && <PlayersRegisterForm2 {...props} />}
              {step === 3 && <PlayersRegisterForm3 {...props} />}
            </div>
          ) : (
            <div>
              {step === 1 && <PlayersRegisterFormSub {...props} />}
              {step === 2 && <PlayersRegisterFormSub2 {...props} />}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default Players;
