import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import NetView from "../../Common/NetRegisterForm/NetView";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";

//Components
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaRadioButton from "Common/CsaRadioButton";
import * as masterData from "../../utils/commonMasterData";
import { useSelector } from "react-redux";

const NetSchema = Yup.object().shape({
  pitchType: Yup.string(),
  netNumber: Yup.string().required("Enter Net Number"),
  status: Yup.string().required("Select Status"),
});

function NetRegisterForm({ styles, setStep, setInfo, info }) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const sub_member_name = localStorage.getItem("sub_member_name");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const location = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { isView = false, id, isEdit = false } = location?.state || {};
  const {
    callAPI: fetch,
    response: fetchResponse,
    error: err,
    loading: fetchLoading,
  } = useAxios({
    url: serviceUrl.getNetDetailByID + `${id}`,
    method: "get",
  });
  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editNet : serviceUrl.setNet,
    method: isEdit ? "put" : "post",
  });
  const data = isEdit
    ? {
        netNumber: fetchResponse?.[0]?.net_number,
        pitchType: fetchResponse?.[0]?.field_pitch_type,
        coversAvailable: fetchResponse?.[0]?.field_covers_available,
        bowlMachineAvailable: fetchResponse?.[0]?.field_bowl_machine_available,
        floodlightsAdequateForDayNightGames:
          fetchResponse?.[0]?.field_floodlights,
        status:
          fetchResponse?.[0]?.field_status === "Yes" ? "Active" : "Disable",
        netType: fetchResponse?.[0]?.field_net_type,
      }
    : info?.step2 || {};
  const formik = useFormik({
    initialValues: {
      pitchType: data ? data?.pitchType : "",
      netNumber: data ? data?.netNumber : "",
      bowlMachineAvailable: data ? data?.bowlMachineAvailable : "",
      floodlightsAdequateForDayNightGames: data
        ? data?.floodlightsAdequateForDayNightGames
        : "",
      coversAvailable: data ? data?.coversAvailable : "",
      status: data ? data?.status : "",
      netType: data ? data?.netType : "",
    },
    validationSchema: NetSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({ step1: values });
      const obj = {
        net_number: values.netNumber,
        bowl_machine_available:
          values.bowlMachineAvailable === "Yes" ? true : false,
        covers_available: values.coversAvailable === "Yes" ? true : false,
        floodlights:
          values.floodlightsAdequateForDayNightGames === "Yes" ? true : false,
        pitch_type: values.pitchType,
        net_type: values.netType,
        status: values?.status === "Active" ? true : false || false,
        user_level:
          authLevel === "member"
            ? "MEMBER"
            : authLevel === "csa"
            ? "CSA"
            : "SUB-MEMBER",
        member_id: authLevel === "csa" ? "" : memberId,
        uid: uid,
        ...(authLevel === "sub-member" && {
          sub_member_id: sub_member_id,
          sub_member_name: sub_member_name,
        }),
      };

      callAPI(
        id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
      );
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/net-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);
  useEffect(() => {
    if (isEdit || isView) {
      fetch();
    }
  }, [isEdit, isView]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  const checkValidity = () => {
    const { netNumber, status } = values;
    const isVAlid = netNumber && status;
    return isVAlid ? false : true;
  };

  const handleClear = () => {
    resetForm();
    navigate(-1);
  };

  return (
    <>
      {fetchLoading ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      ) : isView ? (
        <NetView viewData={fetchResponse?.[0]} />
      ) : (
        <>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3"
            align="Start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Net Number"
                placeholder="Enter Net Number"
                type="text"
                name="netNumber"
                onChange={handleChange}
                value={values.netNumber}
                required
                onBlur={handleBlur}
                touched={touched.netNumber}
                error={errors.netNumber || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Pitch Type"
                placeholder="Select Pitch Type"
                menuArr={masterData.pitchTypeList}
                onChange={(e) => {
                  setFieldValue("pitchType", e);
                }}
                name="pitchType"
                value={values.pitchType}
                defaultValue={values.pitchType}
                onBlur={handleBlur}
                touched={touched.pitchType}
                error={errors.pitchType || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Floodlights Adequate for Day/Night Practice"
                placeholder="Select Floodlights Adequate for Day/Night Practice"
                name="floodlightsAdequateForDayNightGames"
                value={values.floodlightsAdequateForDayNightGames}
                menuArr={["Yes", "No"]}
                onChange={(e) => {
                  setFieldValue("floodlightsAdequateForDayNightGames", e);
                }}
                onBlur={handleBlur}
                touched={touched.floodlightsAdequateForDayNightGames}
                error={errors.floodlightsAdequateForDayNightGames || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Covers Available"
                placeholder="Select Covers Available"
                name="coversAvailable"
                value={values.coversAvailable}
                menuArr={["Yes", "No"]}
                onChange={(e) => {
                  setFieldValue("coversAvailable", e);
                }}
                onBlur={handleBlur}
                touched={touched.coversAvailable}
                error={errors.coversAvailable || null}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Bowl Machine Available"
                placeholder="Select Bowl Machine Available"
                name="bowlMachineAvailable"
                value={values.bowlMachineAvailable}
                menuArr={["Yes", "No"]}
                onChange={(e) => {
                  setFieldValue("bowlMachineAvailable", e);
                }}
                onBlur={handleBlur}
                touched={touched.bowlMachineAvailable}
                error={errors.bowlMachineAvailable || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Status"
                placeholder="Select Status"
                required
                name="status"
                value={values.status}
                menuArr={masterData.statusList}
                onChange={(e) => {
                  setFieldValue("status", e);
                }}
                onBlur={() => setFieldTouched("status", true)}
                touched={touched.status}
                error={errors.status || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaRadioButton
                label=""
                name="netType"
                options={["Indoor", "Outdoor"]}
                onChange={(e) => {
                  setFieldValue("netType", e.target.value);
                }}
                value={values.netType}
                onBlur={handleBlur}
                touched={touched.netType}
                error={errors.netType || null}
              />
            </Col>
            <div className="mt-4 d-flex w-100 justify-content-end">
              {" "}
              <Space>
                <CsaButton
                  label="Cancel"
                  textColor="#007237"
                  onClick={() => handleClear()}
                  btnColor="#FFCB03"
                />
                <CsaButton
                  label="Submit"
                  textColor="#FFF"
                  onClick={() => handleSubmit()}
                  btnColor="#007237"
                  size="large"
                  disabled={checkValidity()}
                  loading={loading}
                />
              </Space>
            </div>
          </Row>
        </>
      )}
    </>
  );
}

export default NetRegisterForm;
