import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";
import { useSelector } from "react-redux";
//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./StaffMembers.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { fixSpecialChar } from "utils/helpers";
const StaffMemberTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Role & Access",
      dataIndex: "role",
      key: "role",
      width: "40%",
    },
    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR"
      ? {
          title: "Action",
          key: "action",
          render: (data) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-staff", { state: { ...data, type: "VIEW" } })
              }
              handleEdit={() =>
                navigate("/edit-staff", { state: { ...data, type: "EDIT" } })
              }
            />
          ),
          // align: "center",
        }
      : {},
  ];

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getStaffDetails,
    method: "get",
  });

  const data = response?.rows
    // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
    ?.map((item) => ({
      ...item,
      key: item?.id,
      firstname: fixSpecialChar(item?.first_name),
      lastname: fixSpecialChar(item?.last_name),
      role: item?.roles,
      designation: fixSpecialChar(item?.designation),
      status1: item?.status === "Active" ? "Activated" : "Disabled",
    }));

  useEffect(() => {
    userLevel === "member"
      ? callAPI(`level=${userLevel.toUpperCase()}&member_id=${memberId}`, true)
      : userLevel === "sub-member"
      ? callAPI(
          `level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI(`level=${userLevel.toUpperCase()}`, true);
  }, []);

  useEffect(() => {
    if (error) {
      console.log("error", error);
    }
    if (response) {
      console.log("response", response);
    }
  }, [error, response]);
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Staff Members", value: "/staff-list" },
  ];
  const handleSearch = (e) => {
    userLevel === "member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}&uid=${uid}`,
          true
        )
      : callAPI(`keyword=${e}&level=${userLevel.toUpperCase()}`, true);
  };

  const onChange = (page) => {
    userLevel === "member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&member_id=${memberId}&page=${
            page - 1
          }`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=""&level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}&page=${
            page - 1
          }`,
          true
        )
      : callAPI(
          `keyword=""&level=${userLevel.toUpperCase()}&page=${page - 1}`,
          true
        );
  };
  return (
    <Wrapper>
      <div className={styles.StaffMembersWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Staff Members</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                options={options}
                // onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className=" d-flex w-100 justify-content-end">
                {authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT" ||
                authRole === "MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBERS ADMIN ASSISTANT" ||
                authRole === "SUB MEMBERS ADMINISTRATOR" ||
                authRole === "SUB-MEMBERS ADMINISTRATOR" ? (
                  <CsaButton
                    label="Create"
                    textColor="#FFF"
                    onClick={() => navigate("/register-staff")}
                    btnColor="#007237"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2">
            <Table
              className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 650,
              }}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={response?.pager?.total_items}
                size={"small"}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default StaffMemberTable;
