import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./Scorers.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { findHightestLevel } from "utils/helpers";

const ScorersTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Scorers", value: "/scorer-list" },
  ];
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "Premier League",
      dataIndex: "premierLeague",
      key: "premierLeague",
    },

    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS SCORERS MANAGER" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR"
      ? {
          title: "Action",
          key: "action",
          render: (data) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-scorer", { state: { ...data, type: "VIEW" } })
              }
              handleEdit={() =>
                navigate("/edit-scorer", { state: { ...data, type: "EDIT" } })
              }
            />
          ),
          //align: "left",
        }
      : {},
  ];
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getScorerDetails,
    method: "get",
  });

  const data = response?.rows
    // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
    ?.map((item) => ({
      key: item?.id,
      firstname: item?.first_name?.replace(/&#039;/g, "'"),
      lastname: item?.last_name?.replace(/&#039;/g, "'"),
      grade: findHightestLevel(
        item.grade_a,
        item.grade_b,
        item.grade_c,
        item.grade_d,
        "fromScorer"
      ),
      premierLeague: item?.premier_league,
      status1: item?.status === "Active" ? "Activated" : "Disabled",
      ...item,
    }));

  useEffect(() => {
    userLevel === "member"
      ? callAPI(`level=${userLevel.toUpperCase()}&member_id=${memberId}`, true)
      : userLevel === "sub-member"
      ? callAPI(
          `level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI(`level=${userLevel.toUpperCase()}`, true);
  }, []);

  useEffect(() => {
    if (error) {
      
    }
    if (response) {
      
    }
  }, [error, response]);
  const handleSearch = (e) => {
    userLevel === "member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI(`keyword=${e}&level=${userLevel.toUpperCase()}`, true);
  };

  const onChange = (page) => {
    userLevel === "member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&member_id=${memberId}&page=${
            page - 1
          }`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=""&level=${userLevel.toUpperCase()}&uid=${uid}&page=${
            page - 1
          }&member_id=${memberId}`,
          true
        )
      : callAPI(
          `keyword=""&level=${userLevel.toUpperCase()}&page=${page - 1}`,
          true
        );
  };

  return (
    <Wrapper>
      <div className={styles.ScorersWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Scorers</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                options={options}
                // onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className=" d-flex w-100 justify-content-end">
                {authRole === "MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBERS ADMIN ASSISTANT" ||
                authRole === "MEMBERS SCORERS MANAGER" ||
                authRole === "SUB MEMBERS ADMINISTRATOR" ||
                authRole === "SUB-MEMBERS ADMINISTRATOR" ? (
                  <CsaButton
                    label="Create"
                    textColor="#FFF"
                    onClick={() => navigate("/register-scorer")}
                    btnColor="#007237"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2">
            <Table
            className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 700,
              }}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={response?.pager?.total_items}
                size={"small"}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ScorersTable;
