import Wrapper from "Layout/Wrapper";
import React from "react";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import { Col, Row, Select, Spin, Table } from "antd";
//import { memberList } from 'utils/commonMasterData';
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from "react";
import useAxios from "utils/axios";
import fileDownload from "js-file-download";
import axios from "axios";
import { useSelector } from "react-redux";
import * as masterData from "../../utils/commonMasterData";
import { LoadingOutlined } from "@ant-design/icons";
import { convertToHttps } from "utils/helpers";
import moment from "moment";

export default function Reports() {
  const defaultReport = localStorage.getItem("report_type");
  const [tournamentName, setTournamentName] = useState(null);
  const [reportstype, setReportsType] = useState(
    defaultReport || "match_report"
  );
  const [isLoading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const { addToast } = useToasts();

  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const membre_name = localStorage.getItem("csa_member_name");
  const sub_member_name = localStorage.getItem("sub_member_name");
  const sub_member_id = localStorage.getItem("sub_member_id");
  console.log(sub_member_id, uid, selectedType, "sub");

  const {
    callAPI: getMemberList,
    response: resMembers,
    error: errMembers,
    loading: loadMembers,
  } = useAxios({
    url: serviceUrl.getMemberMasterData,
    method: "get",
  });

  // const {
  //   callAPI: fetchIn_progress,
  //   response: resIn_progress,
  //   error: errIn_progress,
  //   loading: loadIn_progress,
  // } = useAxios({
  //   url: serviceUrl.getCMSList,
  //   method: "get",
  // });

  const {
    callAPI: getQoo,
    response: resQoo,
    error: errQoo,
    loading: loadQoo,
  } = useAxios({
    url: serviceUrl.getQoo,
    method: "get",
  });

  const {
    callAPI: getAllReports,
    response: resReports,
    error: errReports,
    loading: loadReports,
  } = useAxios({
    url: serviceUrl.getAllReports,
    method: "get",
  });

  const {
    callAPI: postReport,
    response: resPostReport,
    error: errPostReport,
    loading: loadPostReport,
  } = useAxios({
    url: serviceUrl.postReport,
    method: "post",
  });

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getCMSList,
    method: "get",
  });

  const tableData =
    resReports?.data?.map((i, index) => ({
      no: index + 1,
      date: moment(i?.created_at).format("DD-MM-YYYY"),
      ...i,
    })) || [];

  const hasPending = tableData?.some((item) => item.status === "Pending");

  useEffect(() => {
    localStorage.setItem("report_type", reportstype);
    if (reportstype === "quality_index") {
      getMemberList();
      getAllReports(uid, true);
    }

    return () => {
      localStorage.removeItem("report_type");
    };
  }, [reportstype]);

  useEffect(() => {
    let reportsInterval;

    if (hasPending) {
      reportsInterval = setInterval(() => {
        getAllReports(uid, true);
      }, 5000);
    }

    return () => clearInterval(reportsInterval);
  }, [hasPending]);

  // const handleDownload = (url) => {
  //   const anchor = document.createElement("a");
  //   anchor.href = convertToHttps(url);
  //   anchor.download = "test";
  //   anchor.click();
  // };

  useEffect(() => {
    if (resPostReport?.message) {
      addToast(resPostReport?.message, { appearance: "success" });
      getAllReports(uid, true);
    } else if (errPostReport?.message) {
      addToast(errPostReport?.message, { appearance: "error" });
    }
  }, [resPostReport, errPostReport]);

  const memberRes = resMembers?.map((p) => ({
    label: p?.member_name,
    value: p?.member_id?.toString(),
  }));

  const time =
    new Date().toLocaleDateString() + "/" + new Date().toLocaleTimeString();

  const downloadMatchreport = async (url, name) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "filename";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error as needed
    }
  };

  const handleExport = () => {
    if (reportstype === "match_report") {
      const url =
        serviceUrl.ExportReport +
        `${tournamentName?.value}/export/overs?type=default&format=excel`;
      const filename = time;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set the download attribute
      link.style.display = "none"; // Ensure it's hidden
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (reportstype === "qoo") {
      const url =
        serviceUrl.getQoo +
        `${tournamentName?.value}/export/overs?type=qoo&format=excel`;
      const filename = time;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set the download attribute
      link.style.display = "none"; // Ensure it's hidden
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // axios
      //   .get(
      //     serviceUrl.getQoo +
      //       `${tournamentName?.value}/export/overs?type=qoo&format=excel`,
      //     { responseType: "arraybuffer" }
      //   )
      //   .then(setLoading(true))
      //   .then((response) => {
      //     const blob = new Blob([response.data], {
      //       type:
      //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     });

      //     setLoading(false);
      //     fileDownload(
      //       blob,
      //       `Qualtiy Of Opportunity Report_${tournamentName?.children}_date=${time}.xlsx`
      //     ); // npm " js-file-download" responsible for downloading the file for the user
      //   })
      //   .catch((error) => {
      //     if (error) {
      //       setLoading(false);
      //       addToast(error.message || "No Reports found", {
      //         appearance: "error",
      //       });
      //     }
      //   });
    } else {
      const reqObj = selectedType
        ? {
            report_type: selectedType,
            member_id: Number(selectedMember?.value),
            member_name: selectedMember?.label,
            user_id: Number(uid),
          }
        : {
            member_id: Number(selectedMember?.value),
            member_name: selectedMember?.label,
            user_id: Number(uid),
          };
      postReport(reqObj);
    }
  };

  useEffect(() => {
    callAPI(
      `?page=1&per_page=1000&include_relation=false&status=completed&user_type=scorer`,
      true
    );
    // fetchIn_progress(
    //   `?page=1&per_page=1000&include_relation=false&status=in_progress&user_type=scorer`,
    //   true
    // );
  }, []);

  useEffect(() => {
    if (error) {
      addToast("Something went wrong", {
        appearance: "error",
      });
    }
  }, [error]);
  const resTournaments = response?.data || [];
  // const resInprogress = resIn_progress?.data || [];

  const combinedData = resTournaments?.map((item) => ({
    value: item?.id?.toString(),
    label: item?.name?.toString(),
  }));
  // const inProgrssData = resInprogress?.map((item) => ({
  //   value: item?.id?.toString(),
  //   label: item?.name?.toString(),
  // }));
  // const combinedData = [...resData, ...inProgrssData];

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Reports", value: "/reports" },
  ];

  const reports = [
    { label: "Match Report", value: "match_report" },
    { label: "Quality Index", value: "quality_index" },
    { label: "Quality Of Opportunity", value: "qoo" },
  ];

  const handleDownload = async (url, member, type) => {
    try {
      const response = await fetch(convertToHttps(url));
      const blob = await response.blob();
      fileDownload(
        blob,
        `Quality Index Report_${member}_${type}_date=${time}.xlsx`
      );
    } catch (error) {
      addToast("Error downloading file:", { appearance: "error" });
    }
  };

  const columns = [
    {
      title: "No:",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Report Type",
      dataIndex: "report_type",
      key: "report_type",
    },
    {
      title: "Member Name",
      dataIndex: "member_name",
      key: "member_name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, item) => (
        <>
          {item.status === "Pending" ? (
            <LoadingOutlined
              style={{
                fontSize: "28px",
                color: "#007237",
              }}
            />
          ) : (
            <CsaButton
              label={"Download"}
              textColor="#FFF"
              btnColor="#007237"
              onClick={() =>
                handleDownload(item.file, item.member_name, item.report_type)
              }
              // disabled={
              //   reportstype === "match_report" || reportstype === "qoo"
              //     ? !tournamentName
              //     : !selectedMember || !selectedType
              // }
            >
              Download{" "}
            </CsaButton>
          )}
        </>
        // <EllipsisOutlined
        //   style={{ fontSize: "30px", cursor: "pointer", color: "gray" }}
        //   onClick={() => navigate(`company-details`)}
        // />
      ),
    },
  ];

  return (
    <Wrapper>
      <div
        style={{
          color: "#007237",
          fontSize: "20px",
          paddingTop: "20px",
          paddingLeft: "20px",
        }}
      >
        <CsaBreadcrumb pathArr={pathArr} />
        <div style={{ width: "100%" }}>
          <p style={{ color: "#007237", fontSize: "25px" }}>Export</p>
        </div>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 30,
          }}
        >
          {loading ? (
            <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Report Type"
                    placeholder="Select Report Type"
                    name="reporttype"
                    optionType
                    onClear={(e) => setReportsType("")}
                    onChange={(e) => setReportsType(e)}
                    menuArr={reports}
                    defaultValue="match_report"
                  />
                </div>
              </Col>
              {(reportstype !== "quality_index" && (
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                  <div className="m-2 ">
                    <CsaDropDown
                      label="Tournament Name"
                      placeholder="Select Tournament Name"
                      name="tournamenttype"
                      optionType
                      onClear={(e) => setTournamentName("")}
                      onChange={(e, k) => setTournamentName(k)}
                      menuArr={combinedData}
                    />
                  </div>
                </Col>
              )) ||
                (reportstype === "quality_index" && (
                  <>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                      <div className="m-2 ">
                        <CsaDropDown
                          label="Member Name"
                          placeholder="Select Member Name"
                          name="memberName"
                          optionType
                          onClear={(e) => setSelectedMember("")}
                          onChange={(e) =>
                            setSelectedMember(
                              memberRes?.find((i) => i.value === e)
                            )
                          }
                          menuArr={memberRes}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                      <div className="m-2 ">
                        <CsaDropDown
                          label="Sub-Member Type"
                          placeholder="Select Sub-Member Type"
                          name="subMember"
                          optionType
                          menuArr={masterData.submemberList?.filter(
                            (i) => i.value !== "ancillary_member"
                          )}
                          onSelect={(e, v) => setSelectedType(v.children)}
                          // onChange={(e) => {
                          //   setSelectedType(e);
                          // }}
                          // value={values.subMember}
                          // onBlur={handleBlur}
                          // touched={touched.subMember}
                          // error={errors.subMember || null}
                        />
                      </div>
                    </Col>
                  </>
                ))}

              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <div style={{ marginLeft: "15px", marginTop: "25px" }}>
                  <CsaButton
                    label="Export"
                    textColor="#FFF"
                    btnColor="#007237"
                    loading={isLoading || loadPostReport}
                    disabled={
                      reportstype === "match_report" || reportstype === "qoo"
                        ? !tournamentName
                        : !selectedMember
                    }
                    onClick={handleExport}
                  />
                </div>
              </Col>
            </>
          )}
        </Row>
        {reportstype === "quality_index" && (
          <div className="m-3">
            <Table
              className="center-aligned"
              //   className="table-striped-rows"
              loading={loadReports && !hasPending}
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{
                x: 800,
              }}
            />
          </div>
        )}
      </div>
      <a
        href="https://api-dev.csacms.xyz/api/v1/stats/match/12/export/overs?type=default&format=excel"
        download="abcd"
        target="_blank"
        rel="noopener noreferrer"
        hidden
        className="matchReport_button"
      >
        Click to download
      </a>
    </Wrapper>
  );
}
