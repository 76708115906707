import styles from "./CsaDatePicker.module.scss";
import { DatePicker } from "antd";
import React from "react";
import moment from "moment";

function CsaDatePicker({
 label,
 // onChange,
 name,
 onBlur,
 error,
 required = false,
 value,
 touched,
 disableFuture = true,
 disabled,
 setFieldValue,
 minDate = null,
}) {
 const dateFormat = "DD/MM/YYYY";
 const disabledDate = (current) => {
  return current && current > moment().endOf("day");
 };
 const onChange = (date) => {
  if (date) {
   setFieldValue(name, moment(date).format("DD/MM/YYYY"));
  }
 };

 return (
  <div className={styles.CsaDatePickerWrapper}>
   <p className={styles.name}>
    {label}
    {required && <span className={styles.required}>*</span>}
   </p>
   <DatePicker
    className="w-100"
    onChange={onChange}
    name={name}
    allowClear={false}
    onBlur={onBlur}
    format={dateFormat}
    value={
     value ? (value !== "Invalid date" ? moment(value, dateFormat) : "") : ""
    }
    // disabledDate={(current) => {
    //  return current && current > moment().endOf("day");
    // }}
    // disabledDate={(data) => {
    //   let customDate = moment(minDate).format("DD/MM/YYYY");
    //   let current = moment(data).format("DD/MM/YYYY");
    //   
    //   
    //   return minDate
    //     ? data < moment(customDate)
    //     : disableFuture
    //     ? disabledDate(data)
    //     : "";
    // }}
    disabledDate={(current) =>
     minDate
      ? current.isBefore(moment(minDate, "DD/MM/YYYY"))
      : disableFuture
      ? disabledDate(current)
      : ""
    }
    disabled={disabled}
   />
   {error && touched && <pre className="text-danger">{error}</pre>}
  </div>
 );
}

export default CsaDatePicker;
