import React, { useEffect, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useLocation, useNavigate } from "react-router-dom";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";

// const roles = localStorage.getItem("CSAPermissions")?.split(",");

const PlayersSchema = Yup.object().shape({
  //  battingSkill: Yup.string().required("Select Level1"),
  //  battingHand: Yup.string().required("Select Level2"),
  //  bowlingSkill: Yup.string().required("Select Level3"),
  //  bowlingHand: Yup.string().required("Select Level4"),
});

function PlayersRegisterForm2({ styles, setStep, setInfo, info, img, setImg }) {
  const roles = ["member", "sub-member"];
  const steps = ["General Information", "Playing Style", "Contract"];
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = location?.state || {};
  const isEdit = type === "EDIT";
  const data = isEdit
    ? {
        battingSkill: info?.step2?.battingSkill || info?.editData?.battingSkill || "",
        battingHand: info?.step2?.battingHand || info?.editData?.battingHand || "",
        bowlingSkill:info?.step2?.bowlingSkill || info?.editData?.bowlingSkill || "",
        bowlingHand:info?.step2?.bowlingHand || info?.editData?.bowlingHand || "",
      }
    : info?.userExisting
    ? {
        battingHand:info?.step2?.battingHand || info?.existingUserData?.field_batting_hand || "",
        battingSkill: info?.step2?.battingSkill || info?.existingUserData?.field_batting_skill || "",
        bowlingSkill:info?.step2?.bowlingSkill || info?.existingUserData?.field_bowl_skill || "",
        bowlingHand:info?.step2?.bowlingHand || info?.existingUserData?.field_bowling_hand || "",
      }
    : info?.step2 || {};
  const formik = useFormik({
    initialValues: {
      battingSkill: data ? data?.battingSkill : "",

      battingHand: data ? data?.battingHand : "",

      bowlingSkill: data ? data?.bowlingSkill : "",

      bowlingHand: data ? data?.bowlingHand : "",
      consent: isEdit ? true :data? data?.consent : "",
    },
    validationSchema: PlayersSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: info?.step1,
        step2: values,
        step3: info?.step3,
        editData: isEdit ? info.editData : null,
        file: info.file,
        existingUserData: info?.userExisting ? info?.existingUserData : {},
        userExisting: info?.userExisting ? info?.userExisting : false,
        userExistingRole: info?.userExisting
          ? info?.existingUserData?.[0]?.roles_target_id.split(",")
          : null,
      });
      setStep(3);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    setFieldValue,
  } = formik;

  console.log(values,data,'vaals')

  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Batting Skill"
            placeholder="Select Batting Skill"
            name="battingSkill"
            value={values.battingSkill}
            menuArr={masterData.battingSkillPlayer}
            onChange={(e) => {
              setFieldValue("battingSkill", e);
            }}
            onBlur={handleBlur}
            touched={touched.battingSkill}
            error={errors.battingSkill || null}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Batting Hand"
            placeholder="Select Batting Hand"
            name="battingHand"
            value={values.battingHand}
            menuArr={masterData.battingHandPlayer}
            onChange={(e) => {
              setFieldValue("battingHand", e);
            }}
            onBlur={handleBlur}
            touched={touched.battingHand}
            error={errors.battingHand || null}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Bowling Skill"
            placeholder="Select Bowling Skill"
            name="bowlingSkill"
            value={values.bowlingSkill}
            menuArr={masterData.bowlingSkillPlayer}
            onChange={(e) => {
              setFieldValue("bowlingSkill", e);
            }}
            onBlur={handleBlur}
            touched={touched.bowlingSkill}
            error={errors.bowlingSkill || null}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Bowling Arm"
            placeholder="Select Bowling Arm"
            name="bowlingHand"
            value={values.bowlingHand}
            menuArr={masterData.bowlingHandPlayer}
            onChange={(e) => {
              setFieldValue("bowlingHand", e);
            }}
            onBlur={handleBlur}
            touched={touched.bowlingHand}
            error={errors.bowlingHand || null}
          />
        </Col>
        <div style={{ marginLeft: "15px" }}>
          <p
            style={{
              gap: 5,
              color: "#000000",
              fontSize: "15px",
              // backgroundColor: "green",
              margin: 0,
            }}
          >
            Consent <span style={{ color: "red" }}> *</span>
          </p>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Checkbox
              name="consent"
              defaultChecked={values?.consent}
              value={values.consent}
              onChange={(e) => setFieldValue("consent", e.target.checked)}
            />
            <p
              style={{
                color: "#000000",
                fontSize: "15px",
              }}
            >
              I hereby confirm that I have received concent from the player that
              I am adding (Or a guardian if under 18). See
              <a href="3"> Terms & Conditions</a>
            </p>
          </div>
        </div>

        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({
                step1: info?.step1,
                step2: values,
                step3: info?.step3,
                file: info.file,
              });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            disabled={!values.consent}
            label="Next"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
          />
        </div>
      </Row>
    </div>
  );
}

export default PlayersRegisterForm2;
