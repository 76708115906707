import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Space, Spin } from "antd";
import Logo from "../../images/CSA_Green_Logo.jpg";
import { NavConfig } from "Common/SideNav/NavConfig";
import CSAHeader from "Common/CsaHeader";
import CSAFooter from "Common/CsaFooter";
import styles from "./index.module.scss";
import CsaImageUpload from "Common/CsaImageUpload";
import { imgUrlToFile } from "utils/helpers";
import axios from "axios";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector, useDispatch } from "react-redux";
import { setUserImgFid, setUserImgUrl } from "redux/slice/commonSlice";
import { useToasts } from "react-toast-notifications";
const { Header, Content, Sider } = Layout;

export default function Wrapper({ children, hide = false }) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const isAuth = useSelector((state) => state.auth.login);
  const level = useSelector((state) => state.auth.csa_level);
  const role = useSelector((state) => state.auth.csa_role);
  const profileId = useSelector((state) => state.auth.user_img_fid) || null;
  const profileUrl = useSelector((state) => state.auth.user_img_url) || null;
  const uid = useSelector((state) => state.auth.login_as_uid);
  const [img, setImg] = useState([]);
  const [fileId, setFileId] = useState(null);
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [showSider, setSiderShow] = useState(true);
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.ImgeUploadCurrentUser,
    method: "put",
  });
  const {
    callAPI: deleteImage,
    response: resDeleteImage,
    error: errDeleteImage,
    loading: deletingImage,
  } = useAxios({
    url: serviceUrl.deletePofileImage,
    method: "delete",
  });

  const showDrawer = () => {
    setVisible(!visible);
  };

  console.log("V4 🤖");

  useEffect(() => {
    if (profileId && profileUrl && img?.length === 0) {
      imgUrlToFile(profileUrl, profileId, setImg);
      setFileId(profileId);
    }
  }, [profileId, profileUrl]);

  useEffect(() => {
    if (response) {
      addToast(response?.Message || "Success", { appearance: "success" });
      //
      //  localStorage.setItem("csa_fid", fileId);
      //  localStorage.setItem("csa_profile", response?.image_url);
      dispatch(setUserImgFid(fileId));
      dispatch(setUserImgUrl(response?.image_url));
    }
  }, [response]);
  useEffect(() => {
    if (resDeleteImage) {
      setFileId(null);
      dispatch(setUserImgFid(null));
      dispatch(setUserImgUrl(null));
    }
  }, [resDeleteImage]);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const reader = new FileReader();
    reader.onload = function (e) {
      const blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      const fileName = file.name;
      const headers = {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + localStorage.getItem("csa_token"),
        "Content-Disposition": `file;filename="${fileName}"`,
      };
      axios
        .post(serviceUrl.imgUpload, blob, {
          headers: headers,
        })
        .then((res) => {
          setFileId(res?.data?.fid?.[0]?.value);
          onSuccess(file);
          const obj = {
            fid: res?.data?.fid?.[0]?.value,
            uid: uid,
          };
          callAPI(obj);
        })
        .catch((err) => {
          onError({ event: err });
        });
    };
    reader.readAsArrayBuffer(file);

    return true;
  };
  const props = {
    styleH: true,
    name: "file",
    fileList: img,
    customRequest: uploadImage,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setImg(info?.fileList);
    },
    onRemove() {
      // const obj = {
      //   fid: fileId,
      //   uid: uid,
      // };
      // callAPI(obj);
      deleteImage(uid, true);
    },
  };
  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (_openKeys) => {
    setOpenKeys(_openKeys.slice(-1));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location]);

  let menuConfig = NavConfig();
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header className={styles.header}>
        <CSAHeader
          showDrawer={showDrawer}
          setSiderShow={setSiderShow}
          showSider={showSider}
          visible={visible}
        />
      </Header>
      <Layout className="site-layout">
        {location.pathname !== "/login" &&
        location.pathname !== "/recovery" &&
        location.pathname !== "/" &&
        isAuth ? (
          <Sider
            style={{ backgroundColor: "#ffcb05" }}
            className="d-none d-lg-block d-xl-block"
            collapsed={!showSider}
            width={220}
          >
            {/* {showSider && <h3 className="text-center mb-0">CSA</h3>} */}
            <div className="w-100 d-flex align-items-center justify-content-center ">
              <div
                className={showSider ? styles.imgWrap : styles.imgWrapCollapsed}
              >
                {loading || deletingImage ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display:
                        "flex" /* Enables flexbox for centered alignment */,
                      justifyContent:
                        "center" /* Centers the content horizontally */,
                      alignItems: "center" /* Centers the content vertically */,
                    }}
                  >
                    <Spin size="large" />
                  </div>
                ) : (
                  <CsaImageUpload {...props} />
                )}
              </div>
            </div>
            <Menu
              mode="inline"
              className={styles.sideNavBg}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
            >
              {menuConfig?.map((item, i) =>
                item?.children ? (
                  <Menu.SubMenu
                    key={`main${i}`}
                    title={
                      <Space>
                        {item.icon && (
                          <img
                            src={item.icon}
                            alt="staff"
                            width="16"
                            height="16"
                          />
                        )}
                        <p className={styles.menuName}>
                          {showSider && item.name}
                        </p>
                      </Space>
                    }
                    style={{
                      textDecoration: "none",
                      color: "#FFF",
                      borderBottom: "1px solid #ffcb05",
                    }}
                  >
                    {item?.children?.map((sub, index) => (
                      <Menu.Item
                        key={`sub${index}`}
                        style={{ borderTop: ".5px solid #fff" }}
                      >
                        <NavLink
                          style={{
                            textDecoration: "none",
                            color: "#fff",
                          }}
                          to={sub.route}
                        >
                          <Space>
                            {sub.icon && (
                              <img
                                src={sub.icon}
                                alt="staff"
                                width="12"
                                height="12"
                              />
                            )}
                            {sub.name}
                          </Space>
                        </NavLink>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={`main1${i}`}
                    style={
                      menuConfig?.length - 1 !== i
                        ? { borderBottom: "1px solid #ffcb05" }
                        : {}
                    }
                  >
                    <NavLink
                      to={item.route}
                      style={{ textDecoration: "none", color: "#FFF" }}
                    >
                      <Space>
                        {item.icon && (
                          <img
                            src={item.icon}
                            alt="staff"
                            width="12"
                            height="12"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        )}
                        {showSider && item.name}
                      </Space>
                    </NavLink>
                  </Menu.Item>
                )
              )}
            </Menu>
          </Sider>
        ) : (
          <></>
        )}
        <Content style={{ background: "#FFF" }}>
          <div
            style={{
              minHeight: 360,
            }}
          >
            <main>
              <div className={hide ? styles.wrapperActive : styles.wrapper}>
                {children}
              </div>
            </main>
          </div>
        </Content>
      </Layout>
      <Layout>
        <footer className={styles.Footer}>
          <CSAFooter />
        </footer>
      </Layout>
    </Layout>
  );
}
