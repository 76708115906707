import React, { useState, useEffect } from "react";
import styles from "./PlayerInformation.module.scss";
import CsaButton from "Common/CsaButton";
import player from "../../images/img_avatar.png";
import useAxios from "./../../utils/axios";
import { serviceUrl } from "services/serviceUrl";
import fileDownload from "js-file-download";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

import {
  Card,
  Row,
  Col,
  AutoComplete,
  Input,
  Spin,
  Table,
  Pagination,
  Tabs,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setIsBack } from "redux/slice/commonSlice";
import { useDispatch } from "react-redux";

export default function PlayerInformation({ ...props }) {
  const [activeKey, setActiveKey] = useState("1");
  const { onchangecomponent, mId } = props;
  const { TabPane } = Tabs;
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const onKeyChange = (key) => {
    setActiveKey(key);
  };

  const {
    callAPI: fetchPlayerStats,
    response: resPlayerStats,
    error: errPlayer,
    loading: fetchingPlayerStats,
  } = useAxios({
    url: serviceUrl.getStats + `player/${id}`,
    method: "get",
  });

  const {
    callAPI: fetchPlayerDetails,
    response: resPlayerDetails,
    error: errPlayerDetails,
    loading: fetchingPlayerDetauls,
  } = useAxios({
    url: serviceUrl.getPlayerDetailByID + `${id}`,
    method: "get",
  });

  useEffect(() => {
    fetchPlayerStats();
    fetchPlayerDetails();
  }, []);

  useEffect(() => {
    if (errPlayer) {
      addToast(errPlayer?.message || "Something went wrong", {
        appearance: "warning",
      });
    }
    if (errPlayerDetails) {
      addToast(errPlayerDetails?.message || "Something went wrong", {
        appearance: "warning",
      });
    }
  }, [errPlayer, errPlayerDetails]);

  const bowlingStats = resPlayerStats?.data?.bowling?.map((i) => ({
    average: i?.average.toFixed(1),
    best_bowling: i?.bb,
    economy: i?.economy ? i.economy.toFixed(1) : 0,
    five_wickets: i?.five_wickets,
    inns: i?.inns,
    level: i?.level,
    maidens: i?.maidens,
    matches: i?.matches,
    no_balls: i?.no_balls,
    overs: i?.overs?.toFixed(1),
    runs_conceded: i?.runs_conceded,
    strike_rate: i?.strike_rate ? i.strike_rate.toFixed(1) : 0,
    ten_wickets: i?.ten_wickets,
    wickets: i?.wickets,
    wides: i?.wides,
  }));
  const fieldingStats = resPlayerStats?.data?.fielding;

  const battingStats = resPlayerStats?.data?.batting?.map((i) => ({
    average: i?.average.toFixed(1),
    balls: i?.balls,
    ducks: i?.ducks,
    fifties: i?.fifties,
    fours: i?.fours,
    highest_score: i?.highest_score,
    hundreds: i?.hundreds,
    inns: i?.inns,
    level: i?.level,
    matches: i?.matches,
    not_outs: i?.not_outs,
    runs: i?.runs,
    sixes: i?.sixes,
    strike_rate: i?.strike_rate ? i.strike_rate.toFixed(1) : 0,
  }));

  const handleDownload = () =>
    axios
      .get(serviceUrl.getStats + `player/${id}/export?format=excel`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        fileDownload(blob, `file.xlsx`); // npm " js-file-download" responsible for downloading the file for the user
      })
      .catch((error) => {
        if (error) {
          addToast("No data found for this player", {
            appearance: "error",
          });
        }
      });

  const columnsBatting = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: "15%",
    },
    {
      title: "Matches",
      dataIndex: "matches",
      key: "matches",
    },
    {
      title: "Inns",
      dataIndex: "inns",
      key: "inns",
    },
    {
      title: "NO",
      dataIndex: "not_outs",
      key: "not_outs",
    },
    {
      title: "Runs",
      dataIndex: "runs",
      key: "no",
    },
    {
      title: "Balls",
      dataIndex: "balls",
      key: "balls",
    },
    {
      title: "4s",
      dataIndex: "fours",
      key: "fours",
    },
    {
      title: "6s",
      dataIndex: "sixes",
      key: "sixes",
    },
    {
      title: "HS",
      dataIndex: "highest_score",
      key: "highest_score",
    },
    {
      title: "50s",
      dataIndex: "fifties",
      key: "fifties",
    },
    {
      title: "100s",
      dataIndex: "hundreds",
      key: "hundreds",
    },
    {
      title: "Avg",
      dataIndex: "average",
      key: "average",
    },
    {
      title: "SR",
      dataIndex: "strike_rate",
      key: "strike_rate",
    },
  ];
  const columnsBowling = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: "15%",
    },
    {
      title: "Matches",
      dataIndex: "matches",
      key: "matches",
    },
    {
      title: "Inns",
      dataIndex: "inns",
      key: "inns",
    },
    {
      title: "Overs",
      dataIndex: "overs",
      key: "overs",
    },
    {
      title: "Maidens",
      dataIndex: "maidens",
      key: "maidens",
    },
    {
      title: "Runs",
      dataIndex: "runs_conceded",
      key: "runs_conceded",
    },
    {
      title: "Wickets",
      dataIndex: "wickets",
      key: "wickets",
    },
    {
      title: "BB",
      dataIndex: "best_bowling",
      key: "best_bowling",
    },
    {
      title: "Ave",
      dataIndex: "average",
      key: "average",
    },
    {
      title: "Econ",
      dataIndex: "economy",
      key: "econ",
    },
    {
      title: "SR",
      dataIndex: "strike_rate",
      key: "strike_rate",
    },
    {
      title: "5W",
      dataIndex: "five_wickets",
      key: "five_wickets",
    },
    {
      title: "Wides",
      dataIndex: "wides",
      key: "wides",
    },
    {
      title: "NB",
      dataIndex: "no_balls",
      key: "no_balls",
    },
  ];
  const columnsFielding = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: "15%",
    },
    {
      title: "Matches",
      dataIndex: "matches",
      key: "matches",
    },
    {
      title: "Catches",
      dataIndex: "catches",
      key: "catches",
    },
    {
      title: "Drop Catches",
      dataIndex: "dropcatches",
      key: "dropcatches",
    },
    {
      title: "Run-Outs",
      dataIndex: "stumpings",
      key: "stumpings",
    },
    {
      title: "Direct Hits",
      dataIndex: "direct_hits",
      key: "direct_hits",
    },
    {
      title: "Runs Saved",
      dataIndex: "runs_saved",
      key: "runs_saved",
    },
    {
      title: "Runs Conceded",
      dataIndex: "runs_given",
      key: "runs_given",
    },
  ];

  const handleGoBack = () => {
    dispatch(setIsBack(true));
    navigate(-1, {
      state: {
        isBack: true,
      },
    });
    // onchangecomponent({ page: "", isBack: true });
  };
  // window.onpopstate = () => {
  //   navigate(-1, {
  //     state: {
  //       isBack: true,
  //     },
  //   });
  // };
  window.onpopstate = () => {
    dispatch(setIsBack(true));
  };

  return (
    <>
      <div className={styles.buttonWrapper}>
        <CsaButton
          label="Back"
          textColor="#FFF"
          // onClick={() => handleNavigate(-1)}
          btnColor="#007237"
          onClick={() => handleGoBack()}
        />
      </div>
      <div className={styles.PlayerInfoWrapper}>
        <div className={styles.PlayerCardWrapper}>
          {/* <div className={styles.PlayerImage}>
            <div>
              <img src={resPlayerDetails?.[0]?.profile ? resPlayerDetails?.[0]?.profile : player} alt="" />
            </div>
          </div> */}
          <div>
            <img
              className={styles.playerImg}
              src={
                resPlayerDetails?.[0]?.profile
                  ? resPlayerDetails?.[0]?.profile
                  : player
              }
              alt=""
            />
          </div>
          <div className={styles.playertextContainer}>
            <h5 style={{ color: "#007237" }}>
              {resPlayerDetails?.[0]?.first_name?.replace(/&#039;/g, "'")}
              &nbsp;
              {resPlayerDetails?.[0]?.last_name?.replace(/&#039;/g, "'")}
            </h5>
            <div className={styles.PlayerInfoSection}>
              <div className={styles.PlayerInfoText}>
                <p>Age: {resPlayerDetails?.[0]?.age || " "}</p>
                <p>School Club: {resPlayerDetails?.[0]?.club || " "}</p>
                <p>
                  Batting Skill:{" "}
                  {resPlayerDetails?.[0]?.field_batting_skill || ""}
                </p>
                <p>
                  Batting Hand:{" "}
                  {resPlayerDetails?.[0]?.field_batting_hand || ""}
                </p>
                <p>
                  Bowling Skill: {resPlayerDetails?.[0]?.field_bowl_skill || ""}
                </p>
                <p>
                  Batting Hand:{" "}
                  {resPlayerDetails?.[0]?.field_bowling_hand || ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-2 d-flex w-100 justify-content-end align-items-end">
        <CsaButton
          label="Export"
          textColor="#FFF"
          btnColor="#007237"
          onClick={handleDownload}
        />
      </div>
      <div>
        <Tabs
          type="card"
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={onKeyChange}
        >
          {" "}
          <TabPane tab="Batting " key="1">
            <div>
              <Table
                //  className="center-aligned"
                //   className="table-striped-rows"
                // loading={loading}
                columns={columnsBatting}
                dataSource={battingStats}
                pagination={false}
                scroll={{
                  x: 800,
                }}
              />
              <div className=" d-flex w-100 justify-content-end mt-5">
                <Pagination
                  // onChange={onChange}
                  total={10}
                  size={"small"}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Bowling" key="2">
            <div>
              <Table
                // className="center-aligned"
                //   className="table-striped-rows"
                // loading={loading}
                columns={columnsBowling}
                dataSource={bowlingStats}
                pagination={false}
                scroll={{
                  x: 800,
                }}
              />
              <div className=" d-flex w-100 justify-content-end mt-5">
                <Pagination
                  // onChange={onChange}
                  total={10}
                  size={"small"}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Fielding" key="3">
            <div>
              <Table
                className="center-aligned"
                //   className="table-striped-rows"
                // loading={loading}
                columns={columnsFielding}
                dataSource={fieldingStats}
                pagination={false}
                scroll={{
                  x: 900,
                }}
              />
              <div className=" d-flex w-100 justify-content-end mt-5">
                <Pagination
                  // onChange={onChange}
                  total={10}
                  size={"small"}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
