import CsaButton from "Common/CsaButton";
import CsaTextInput from "Common/CsaTextInput";
import { Modal, Space, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as Yup from "yup";
import styles from "./Summary.module.scss";
import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import { useToasts } from "react-toast-notifications";

const StreamingServiceModal = ({
  showStreamingPopup,
  setShowStreamingPopup,
  streamingData,
}) => {
  const { Text, Link } = Typography;
  const { addToast } = useToasts();
  const currentDomain = window.location.origin;
  const formik = useFormik({
    initialValues: {
      overlay_url:
        currentDomain + "/match/streaming/" + streamingData?.redeem?.id || "",
      stream_key: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  console.log(streamingData, "ssdd");
  const {
    errors,
    setFieldTouched,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleReset,
    handleSubmit,
    handleBlur,
  } = formik;

  const checkValidity = () => {
    const { overlay_url, stream_key } = values;
    const isVAlid =
      overlay_url && stream_key && Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  const mapInputData = [
    {
      label: "Overlay URL:",
      type: "text",
      value: values.overlay_url,
      name: "overlay_url",
      placeholder: "Enter Overlay URL",
    },
    {
      label: "Stream Key URL:",
      type: "text",
      value: values.stream_key,
      name: "stream_key",
      placeholder: "Enter Stream Key URL",
      disabled: true,
    },
  ];

  const handlePopup = (v) => {
    setShowStreamingPopup({
      ...showStreamingPopup,
      url: false,
    });
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    addToast("URL copied to clipboard");
  };

  return (
    <Modal
      title={"Welcome " + streamingData?.provider?.streamingService || ""}
      centered
      visible={showStreamingPopup.url}
      className="modalStyle"
      closable={false}
      footer={[
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CsaButton
            label="Close"
            textColor="#007237"
            onClick={handlePopup}
            btnColor="#FFCB03"
          />
        </div>,
      ]}
      //   onOk={() => setModal2Open(false)}
      onCancel={handlePopup}
    >
      <Text>
        You have <Text strong>{streamingData?.provider?.tokens} tokens</Text>
        available. To purchase more tokens, please contact a CSA, Member or
        School/Club administrator.
      </Text>
      <Space direction="vertical"></Space>
      <div
        style={{
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        <Text style={{ fontStyle: "italic" }}>
          Match: {streamingData?.redeem?.matchName}
        </Text>{" "}
      </div>
      {mapInputData.map(
        ({ label, name, value, type, inputType, placeholder, disabled }) => {
          return (
            <div className={styles.streamUrlWrapper}>
              <CsaTextInput
                key={name}
                label={label}
                type={"text"}
                name={name}
                //   maxLength={max}
                // onChange={handleChange}
                value={value}
                disabled={disabled}
              />
              {value && (
                <CopyOutlined
                  style={{
                    background: "white",
                    paddingLeft: "5px",
                    cursor: "pointer",
                    color: "#007237",
                  }}
                  className={styles.copyButton}
                  onClick={() => handleCopy(value)}
                />
              )}
            </div>
          );
        }
      )}
    </Modal>
  );
};

export default StreamingServiceModal;
