import { Card, Col, Row, Avatar, Image, Table } from "antd";
import React from "react";

export default function ScorerView(props) {
  const { viewData } = props;
  const columns = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      align: "center",
    },
    {
      title: "Date Obtained:",
      dataIndex: "dateObtained",
      key: "dateObtained",
      align: "center",
    },
    {
      title: "Certificate :",
      key: "certificate",
      render: (data) =>
        data?.certificate === "Nil" ? (
          "Nil"
        ) : (
          <a href={data?.certificate} target="_blank">
            certificate
          </a>
        ),
      align: "center",
    },
  ];
  const data = [
    // {
    //  key: "1",
    //  title: "Grade A",
    //  grade: viewData?.gradeA,
    //  dateObtained: viewData?.gradeA === "Yes" ? viewData?.dateObtainedgA : "Nil",
    //  certificate: viewData?.certificateUploadgA
    //   ? viewData?.certificateUploadgA
    //   : "Nil",
    // },
    {
      key: "2",
      title: "Grade B",
      grade: viewData?.gradeB,
      dateObtained:
        viewData?.gradeB === "Yes" ? viewData?.dateObtainedgB : "Nil",
      certificate: viewData?.certificateUploadgB
        ? viewData?.certificateUploadgB
        : "Nil",
    },
    {
      key: "3",
      title: "Grade C",
      grade: viewData?.gradeC,
      dateObtained:
        viewData?.gradeC === "Yes" ? viewData?.dateObtainedgC : "Nil",
      certificate: viewData?.certificateUploadgC
        ? viewData?.certificateUploadgC
        : "Nil",
    },
    {
      key: "4",
      title: "Grade D",
      grade: viewData?.gradeD,
      dateObtained:
        viewData?.gradeD === "Yes" ? viewData?.dateObtainedgD : "Nil",
      certificate: viewData?.certificateUploadgD
        ? viewData?.certificateUploadgD
        : "Nil",
    },
  ];
  return (
    <Card
      style={{
        width: "100%",
        backgroundColor: "#f1f1f1",
        justifyContent: "center",
        borderRadius: 15,
        borderWidth: 0.5,
        borderColor: "#f3f3f3",
      }}
    >
      <Row>
        {viewData && (
          <>
            <Col span={24} className="w-100 mb-3 d-flex justify-content-center">
              {viewData?.profile ? (
                <Avatar
                  size={150}
                  src={
                    <Image
                      src={viewData?.profile}
                      style={{
                        width: 150,
                        height: 150,
                      }}
                    />
                  }
                />
              ) : (
                <Avatar size={150}>CSA </Avatar>
              )}
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Nationality</h6>
              <p>{viewData?.nationality}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">ID Number</h6>
              <p>{viewData?.iDNumber || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Passport No.</h6>
              <p>{viewData?.passportNumber || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Email Address</h6>
              <p>{viewData?.emailAddress || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">First Name</h6>
              <p>{viewData?.firstName?.replace(/&#039;/g, "'") || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Last Name</h6>
              <p>{viewData?.lastName?.replace(/&#039;/g, "'") || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Contact No.</h6>
              <p>{viewData?.contactNumber || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Date of Birth</h6>
              <p>{viewData?.dateofBirth || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Race</h6>
              <p>{viewData?.race || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Gender</h6>
              <p>{viewData?.gender || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Differently Abled</h6>
              <p>{viewData?.differentlyAbled || "Nil"}</p>
            </Col>
            {/* <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Grade A</h6>
       <p>{viewData?.gradeA || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Date Obtained</h6>
       <p>{viewData?.dateObtainedgA || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Certificate Upload</h6>
       {viewData?.certificateUploadgA ? (
        <a href={viewData?.certificateUploadgA} target="_blank">
         Certificate
        </a>
       ) : (
        "Nil"
       )}
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Grade B</h6>
       <p>{viewData?.gradeB || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Date Obtained</h6>
       <p>{viewData?.dateObtainedgB || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Certificate Upload</h6>
       {viewData?.certificateUploadgB ? (
        <a href={viewData?.certificateUploadgB} target="_blank">
         Certificate
        </a>
       ) : (
        "Nil"
       )}
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Grade C</h6>
       <p>{viewData?.gradeC || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Date Obtained</h6>
       <p>{viewData?.dateObtainedgC || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Certificate Upload</h6>
       {viewData?.certificateUploadgC ? (
        <a href={viewData?.certificateUploadgC} target="_blank">
         Certificate
        </a>
       ) : (
        "Nil"
       )}
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Grade D</h6>
       <p>{viewData?.gradeD || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Date Obtained</h6>
       <p>{viewData?.dateObtainedgD || "Nil"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Certificate Upload</h6>
       {viewData?.certificateUploadgD ? (
        <a href={viewData?.certificateUploadgD} target="_blank">
         Certificate
        </a>
       ) : (
        "Nil"
       )}
      </Col> */}
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Premier League </h6>
              <p>{viewData?.premierLeague || "Nil"}</p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Status</h6>
              <p>
                {viewData?.status === "Active"
                  ? "Activated"
                  : "Disabled" || "Nil"}
              </p>
            </Col>
          </>
        )}
      </Row>{" "}
      <h6 className="mb-1">Grade:</h6>
      <div className="mt-2 pt-2">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </Card>
  );
}
