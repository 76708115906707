import styles from "./SearchDropDown.module.scss";
import React from "react";
import { Select } from "antd";
const { Option } = Select;

function SearchDropDown({
 placeholder = "Search to select",
 label = "",
 onBlur,
 required = false,
 onSearch = () => {},
 options = [],
 loading = false,
 error,
 touched,
 onChange,
 onClear,
 value,
 mode = false,
}) {
 return (
  <div className={styles.CsaDropDownWrapper}>
   <p className={styles.name}>
    {label}
    {required && <span className={styles.required}>*</span>}
   </p>
   <Select
    loading={loading}
    mode={mode && "multiple"}
    showSearch
    value={value}
    className="w-100"
    placeholder={placeholder}
    onSearch={onSearch}
    onBlur={onBlur}
    onChange={onChange}
    onClear={onClear}
    optionFilterProp="children"
    filterOption={(input, option) => option.children.includes(input)}
    filterSort={(optionA, optionB) =>
     optionA.children
      .toLowerCase()
      .localeCompare(optionB.children.toLowerCase())
    }
   >
    {options?.map((opt) => (
     <Option value={opt?.key} key={opt?.value}>
      {opt?.label}
     </Option>
    ))}
   </Select>

   {error && touched && <pre className="text-danger">{error}</pre>}
  </div>
 );
}

export default SearchDropDown;
