import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Venues.module.scss";
import CsaButton from "Common/CsaButton";
import { Tabs } from "antd";
import { useToasts } from "react-toast-notifications";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "./../../utils/axios";

import {
  Card,
  Row,
  Col,
  AutoComplete,
  Input,
  Spin,
  Table,
  Pagination,
} from "antd";

const columns = [
  {
    title: "Venue Name:",
    dataIndex: "field_name",
    key: "field_name",
  },
  // {
  //   title: "Province:",
  //   dataIndex: "field_province",
  //   key: "field_province",
  // },
  // {
  //   title: "Municipality:",
  //   dataIndex: "field_municipality",
  //   key: "field_municipality",
  // },
  {
    title: "Member:",
    dataIndex: "member_name",
    key: "member_name",
  },
  {
    title: "Sub-Member:",
    dataIndex: "subMember",
    key: "subMember",
  },
  {
    title: "Address:",
    dataIndex: "field_address",
    key: "field_address",
  },
  {
    title: "Capacity:",
    dataIndex: "spectators_capacity",
    key: "spectators_capacity",
  },
  {
    title: "Status:",
    dataIndex: "status",
    key: "status",
  },
];

//   }]

const Venues = ({ ...props }) => {
  const { TabPane } = Tabs;
  const { addToast } = useToasts();
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();

  const { matchId } = props;

  const {
    callAPI: fetchVenues,
    response: responseVenues,
    error: errVenues,
    loading: fetchingVenues,
  } = useAxios({
    url: serviceUrl.getCompetitionById + `${matchId}?include_relation=true`,
    method: "get",
  });
  useEffect(() => {
    fetchVenues();
  }, []);
  useEffect(() => {
    if (errVenues) {
      addToast(errVenues?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [addToast, errVenues]);

  const matchArr = responseVenues?.data?.competition_settings || [];

  const list = matchArr?.filter((dat) => dat?.key === "venue_list");

  const data = list?.map((data, key) => ({
    ...data?.data,
    subMember: data?.data?.sub_member
      ? JSON.parse(data?.data?.sub_member)[0]?.sub_member
      : "N/A ",
    key: key,
    status: data?.data.status ? "Active" : "Disabled",
    field_address: data?.data.field_address || "N/A",
  }));

  console.log(data, list, "ddd");

  return (
    <>
      <div>
        <Table
          className="center-aligned"
          //   className="table-striped-rows"
          loading={fetchingVenues}
          columns={columns}
          dataSource={data}
          pagination={true}
          scroll={{
            x: 800,
          }}
        />
        <div className=" d-flex w-100 justify-content-end mt-5">
          {/* <Pagination onChange={onChange} total={10} size={"small"} /> */}
        </div>
      </div>
    </>
  );
};

export default Venues;
