/* eslint-disable no-unused-expressions */
import styles from "./CsaCheckBox.module.scss";
import React, { useEffect, useState } from "react";
import { Checkbox, Row, Col } from "antd";
function CsaCheckBox({
 options,
 label,
 onChange,
 name,
 onBlur,
 error,
 value = null,
 touched,
 required = false,
 disabled,
 colSize = 8,
}) {
 return (
  <div className={styles.CsaCheckBoxWrapper}>
   <p className={styles.name}>
    {label}
    {required && <span className={styles.required}>*</span>}
   </p>
   <Checkbox.Group
    style={{
     width: "100%",
    }}
    // options={options}
    onChange={onChange}
    name={name}
    value={value}
    onBlur={onBlur}
    disabled={disabled}
   >
    <Row>
     {options?.map((item) => {
      return (
       <Col
        xs={24}
        sm={24}
        md={colSize}
        lg={colSize}
        xl={colSize}
        key={item.value}
       >
        <Checkbox value={item.value}>{item.label}</Checkbox>
       </Col>
      );
     })}
    </Row>
   </Checkbox.Group>
   {error && touched && <pre className="text-danger">{error}</pre>}
  </div>
 );
}

export default CsaCheckBox;
