import styles from "./Recovery.module.scss";
import React from "react";
import { Col, Row, Divider } from "antd";
//Components
import CsaRecoverPasswordForm from "../../Common/CsaRecoverPasswordForm";

import Logo from "../../images/CSA_Green_Logo.jpg";
import Wrapper from "Layout/Wrapper";
function RecoveryPwd() {
 return (
  <Wrapper hide={true}>
   <div className={styles.RecoveryWrapper}>
    <Row>
     <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.grid}>
      <div className="w-100 d-xl-flex d-lg-flex align-items-center justify-content-center mb-3 d-none">
       <div className={styles.imgWrap}>
        <img src={Logo} alt="icon" />
       </div>
      </div>
     </Col>

     <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.grid}>
      <Row>
       <CsaRecoverPasswordForm />
      </Row>
     </Col>
    </Row>
   </div>
  </Wrapper>
 );
}

export default RecoveryPwd;
