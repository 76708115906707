import React from "react";
import styles from "./MatchSummaryOverlay.module.scss";
import { Table } from "antd";
import tossImage from "../../images/toss.png";
import { comnCellStyles, getShortenedName } from "utils/helpers";

const columns = [
  {
    title: "",
    dataIndex: "batter",
    key: "batter",
    align: "left",
    width: "35%",
    render(text, record) {
      return {
        props: {
          style: { ...comnCellStyles },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "runs",
    dataIndex: "runs",
    key: "runs",
    align: "right",
    render(text, record) {
      return {
        props: {
          style: { ...comnCellStyles },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "",
    dataIndex: "bowler",
    key: "bowler",
    align: "left",
    width: "35%",
    render(text, record) {
      return {
        props: {
          style: {
            color: "yellow",
            ...comnCellStyles,
          },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "wkt",
    dataIndex: "wkt",
    key: "wkt",
    align: "right",
    render(text, record) {
      return {
        props: {
          style: { ...comnCellStyles },
        },
        children: <div>{text}</div>,
      };
    },
  },
];

const TeamNameHeader = ({ data, innsNum, toss, testMatchData }) => {
  const teamSummary = data?.teamSummary;
  const innsLength = data?.numOfInns;
  const { hasMultipleInns, matchStatus } = testMatchData;

  const rowCount =
    (innsLength >= 4 && 1) || (innsLength === 3 && 2) || (innsLength < 3 && 4);
  //   const battingSummary = data?.summaryBatting
  //     ?.sort((a, b) => b?.runs > a?.runs)
  //     ?.slice(0, 4);
  //   const bowlingSummary = data?.summaryBowling
  //     ?.sort((a, b) => b?.wickets > a?.wickets)
  //     ?.slice(0, 4);

  const battingSummary = data?.summaryBatting
    .sort(function (a, b) {
      let runsA = Number(a.runs);
      let runsB = Number(b.runs);
      let srA = Number(a.strikeRate);
      let srB = Number(b.strikeRate);
      let playerA = a.batsman?.name;
      let playerB = b.batsman?.name;
      return runsB > runsA
        ? 1
        : runsB < runsA
        ? -1
        : srB > srA
        ? 1
        : srB < srA
        ? -1
        : playerA.localeCompare(playerB);
    })
    ?.slice(0, rowCount);

  const bowlingSummary = data?.summaryBowling
    ?.filter((x) => x.overs !== "0.0")
    .sort(function (a, b) {
      let wicketsA = Number(a.wickets);
      let wicketsB = Number(b.wickets);
      let ecA = Number(a.economyRate);
      let ecB = Number(b.economyRate);
      let ovA = Number(a.balls);
      let ovB = Number(b.balls);
      let playerA = a.bowler?.name;
      let playerB = b.bowler?.name;
      return wicketsB > wicketsA
        ? 1
        : wicketsB < wicketsA
        ? -1
        : ecB < ecA
        ? 1
        : ecB > ecA
        ? -1
        : ovB < ovA
        ? 1
        : ovB > ovA
        ? -1
        : playerA.localeCompare(playerB);
    })
    ?.slice(0, rowCount);
  console.log(bowlingSummary, "toss");

  const batAndBowlArr = battingSummary.map((batting, index) => ({
    batter: getShortenedName(batting?.batsman?.name, 18),
    runs:
      batting?.runs +
        (batting?.wicketType === "Not Out" ? "* " : " ") +
        "(" +
        batting?.balls +
        ")" || "",
    bowler: getShortenedName(bowlingSummary[index]?.bowler?.name, 18),
    wkt: bowlingSummary[index]
      ? bowlingSummary[index]?.wickets +
        "-" +
        bowlingSummary[index]?.runs +
        " (" +
        (bowlingSummary[index]?.overs || "") +
        ")"
      : "",
    key: index,
  }));

  function setFontsizeByChar(v) {
    let x;
    if (v <= 23) {
      x = 2;
    } else {
      const numberOfDecrements = Math.floor((v - 20) / 3);
      x = 2 - numberOfDecrements * 0.1;
    }
    return `${x}vw`;
  }

  return (
    <>
      <div className={styles.teamNameWrapper}>
        <span className={styles.teamName}>
          <h2
            style={{
              fontSize: setFontsizeByChar(data?.battingTeamName?.length),
            }}
          >
            {data?.battingTeamName?.toUpperCase()}
          </h2>

          {data?.innsNum && <p>{data?.innsNum}</p>}
          {data?.battingTeamId === toss && (
            <img className={styles.tossImg} src={tossImage} alt="toss" />
          )}
        </span>

        <span>
          <h2>
            {teamSummary?.score}/{teamSummary?.wickets}
          </h2>
          <p>
            OVERS {teamSummary.overs}
            {!hasMultipleInns && "/"}
            {!hasMultipleInns && data?.targetOvers}
          </p>
        </span>
      </div>
      <div className={styles.tableWrapper}>
        <Table
          showHeader={false}
          className="overlay-table"
          columns={columns}
          dataSource={batAndBowlArr}
          pagination={false}
          size={"small"}
        />
      </div>
    </>
  );
};

export default TeamNameHeader;
