import React from "react";
import { Table } from "antd";
import { useOutletContext } from "react-router-dom";

function PlayerStatsTable() {
  const { playerData } = useOutletContext();
  
  const { loadingPlayerSearch, columns, isback, previousData, statsData } =
    playerData;
  return (
    <>
      <Table
      className="center-aligned"
       // className="table-striped-rows"
        loading={loadingPlayerSearch}
        columns={columns}
        dataSource={isback ? previousData.data : statsData}
        pagination={false}
        scroll={{
          x: 600,
        }}
      />
    </>
  );
}

export default PlayerStatsTable;
