import React from "react";
import "./App.less";
import Routes from "Routes";
import { ToastProvider } from "react-toast-notifications";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import { persistStore } from "redux-persist";

import { PersistGate } from "redux-persist/integration/react";

const IntialLoader = () => <h1>Loading...</h1>;
let persistor = persistStore(store);
const App = () => (
  <Provider store={store}>
    <PersistGate loading={<IntialLoader />} persistor={persistor}>
      <ToastProvider autoDismiss={true}>
        <Routes />
      </ToastProvider>
    </PersistGate>
  </Provider>
);

export default App;
