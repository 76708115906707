import styles from "./CsaHeader.module.scss";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import Logo from "../../images/logo.png";
// import Profile from "../../images/Profile.svg";
import { Button, Col, Row, Space, Menu, Dropdown, Drawer, Spin } from "antd";
import {
  MenuOutlined,
  UserOutlined,
  BellOutlined,
  DownOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { NavConfig } from "Common/SideNav/NavConfig";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { isLogin, setCSALevel, setCSARole } from "redux/slice/commonSlice";

function CSAHeader({ showDrawer, setSiderShow, showSider, visible }) {
  const userLevel = localStorage.getItem("csa_level");
  const level = useSelector((state) => state.auth.csa_level);
  const role = useSelector((state) => state.auth.csa_role);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.login);

  //  const isAuth = localStorage.getItem("csa_token");
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.logout + `${localStorage.getItem("csa_logout_token")}`,
    method: "post",
  });

  const handleLogIn = async () => {
    await localStorage.clear();
    navigate("/login");
  };
  const handleLogOut = async () => {
    dispatch(isLogin(false));
    dispatch(setCSALevel(null));
    dispatch(setCSARole(null));
    await callAPI();
    await localStorage.clear();
    navigate("/login");
  };
  const { addToast } = useToasts();
  // useEffect(() => {
  //   if (response) {
  //     localStorage.clear();
  //     navigate("/login");
  //   } else if (error) {
  //     addToast(error?.message || "Something went wrong", {
  //       appearance: "error",
  //     });
  //   }
  // }, [response, error]);

  const userf = isAuth
    ? JSON.parse(localStorage.getItem("csa_current_user_f"))
    : {};
  const userl = isAuth
    ? JSON.parse(localStorage.getItem("csa_current_user_l"))
    : {};
  let userName = `${userf || ""} ${userl || ""}`;
  const menu = (
    <Menu
      style={{
        backgroundImage: "linear-gradient(90deg, #004f27, #00793a)",
        color: "#FFF",
        width: "100%",
        borderBottom: "1px solid #ffcb05",
      }}
    >
      <Menu.Item
        key={1}
        style={{
          backgroundImage: "linear-gradient(90deg, #004f27, #00793a)",
          color: "#FFF",
          borderBottom: "1px solid #ffcb05",
        }}
      >
        <NavLink
          style={{ textDecoration: "none", color: "#fff" }}
          to={"/user-profile"}
        >
          My Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item
        key={2}
        style={{
          backgroundImage: "linear-gradient(90deg, #004f27, #00793a)",
          color: "#FFF",
          borderBottom: "1px solid #ffcb05",
        }}
      >
        <NavLink
          style={{ textDecoration: "none", color: "#fff" }}
          to={"/update-password"}
        >
          Change Password
        </NavLink>
      </Menu.Item>
      <Menu.Item
        key={3}
        style={{
          backgroundImage: "linear-gradient(90deg, #004f27, #00793a)",
          color: "#FFF",
        }}
      >
        <p
          className="mb-0"
          style={{ textDecoration: "none", color: "#fff" }}
          onClick={handleLogOut}
        >
          Logout
        </p>
      </Menu.Item>
    </Menu>
  );
  return (
    <Row className={styles.CsaHeaderWrapper} justify="space-between">
      <Col className={styles.green} xs={0} sm={0} md={0} lg={16} xl={16}>
        <Space>
          {location.pathname !== "/login" &&
            location.pathname !== "/recovery" &&
            location.pathname !== "/" &&
            isAuth && (
              <Button
                type="primary"
                icon={<MenuOutlined />}
                onClick={() => setSiderShow(!showSider)}
                style={{
                  backgroundImage: "linear-gradient(90deg, #004f27, #00793a)",
                }}
                size="large"
              />
            )}
          <img
            src={Logo}
            alt="csa-logo"
            onClick={() => navigate("/home")}
            className="mx-5"
          />
        </Space>
      </Col>
      <Col className={styles.yellow} xs={0} sm={0} md={0} lg={8} xl={8}>
        <Row align="middle" justify="end">
          <Col>
            <div className="d-flex align-items-center">
              <Link to="#">
                {location.pathname !== "/login" &&
                location.pathname !== "/recovery" &&
                location.pathname !== "/" &&
                location.pathname !== "/score" &&
                isAuth ? (
                  <>
                    <Space>
                      <BellOutlined />
                      <UserOutlined />
                      <Dropdown overlay={menu}>
                        <Space>
                          {userName}
                          <DownOutlined style={{ fontSize: "10px" }} />
                        </Space>
                      </Dropdown>
                    </Space>
                  </>
                ) : location.pathname === "/" ||
                  (location.pathname === "/score" && !isAuth) ? (
                  <Button
                    type="primary"
                    shape="round"
                    onClick={handleLogIn}
                    icon={
                      <LoginOutlined
                        style={{
                          backgroundColor: "transparent",
                          color: "#004f27",
                          fontWeight: "bold",
                          borderColor: "transparent",
                        }}
                      />
                    }
                    size="large"
                    style={{
                      backgroundColor: "transparent",
                      color: "#004f27",
                      fontWeight: "bold",
                      borderColor: "transparent",
                      border: 0,
                    }}
                  >
                    LOGIN
                  </Button>
                ) : (
                  <></>
                )}
              </Link>
            </div>
          </Col>
        </Row>
      </Col>
      <Col className={styles.greenMob} xs={24} sm={24} md={24} lg={0} xl={0}>
        <Row align="middle" justify="space-between">
          <Col>
            <Space>
              {location.pathname !== "/login" &&
              location.pathname !== "/recovery" &&
              location.pathname !== "/score" &&
              isAuth ? (
                <>
                  <Button
                    type="primary"
                    icon={<MenuOutlined />}
                    onClick={showDrawer}
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg, #004f27, #00793a)",
                    }}
                  />
                  <Drawer
                    title={
                      userLevel?.toUpperCase() === "SUB-MEMBER"
                        ? "Member Pathway Structure"
                        : userLevel?.toUpperCase()
                    }
                    placement="left"
                    visible={visible}
                    onClose={showDrawer}
                    width={"75%"}
                  >
                    <Menu
                      defaultSelectedKeys={["1"]}
                      mode="inline"
                      className={styles.sideNavBg}
                    >
                      {NavConfig(level, role)?.map((item, i) =>
                        item?.children ? (
                          <Menu.SubMenu
                            key={i}
                            title={
                              <Space>
                                {item.icon && (
                                  <img
                                    src={item.icon}
                                    alt="staff"
                                    width="12"
                                    height="12"
                                  />
                                )}
                                <p className={styles.menuName}>{item.name}</p>
                              </Space>
                            }
                            style={{
                              textDecoration: "none",
                              color: "#FFF",
                              borderBottom: "1px solid #ffcb05",
                            }}
                          >
                            {item?.children?.map((sub, index) => (
                              <Menu.Item
                                key={index}
                                style={{ borderTop: ".5px solid #fff" }}
                              >
                                <NavLink
                                  style={{
                                    textDecoration: "none",
                                    color: "#fff",
                                  }}
                                  to={sub.route}
                                >
                                  <Space>
                                    {sub.icon && (
                                      <img
                                        src={sub.icon}
                                        alt="staff"
                                        width="12"
                                        height="12"
                                      />
                                    )}
                                    {sub.name}
                                  </Space>
                                </NavLink>
                              </Menu.Item>
                            ))}
                          </Menu.SubMenu>
                        ) : (
                          <Menu.Item
                            key={i}
                            style={
                              NavConfig(level, role)?.length - 1 !== i
                                ? { borderBottom: "1px solid #ffcb05" }
                                : {}
                            }
                          >
                            <NavLink
                              to={item.route}
                              style={{ textDecoration: "none", color: "#FFF" }}
                            >
                              <Space>
                                {item.icon && (
                                  <img
                                    src={item.icon}
                                    alt="staff"
                                    width="12"
                                    height="12"
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  />
                                )}
                                {item.name}
                              </Space>
                            </NavLink>
                          </Menu.Item>
                        )
                      )}
                    </Menu>
                  </Drawer>
                </>
              ) : (
                <Space> </Space>
              )}
              <img src={Logo} alt="csa-logo" onClick={() => navigate("/")} />
            </Space>
          </Col>
          {loading ? (
            <Spin size="large" />
          ) : (
            <Col>
              {location.pathname !== "/login" &&
              location.pathname !== "/recovery" &&
              location.pathname !== "/" &&
              location.pathname !== "/score" &&
              isAuth ? (
                <>
                  <Space>
                    <Button
                      type="primary"
                      icon={<BellOutlined />}
                      style={{
                        backgroundImage:
                          "linear-gradient(90deg, #004f27, #00793a)",
                      }}
                    />
                    <Button
                      type="primary"
                      icon={<UserOutlined />}
                      style={{
                        backgroundImage:
                          "linear-gradient(90deg, #004f27, #00793a)",
                      }}
                    />
                    <Dropdown overlay={menu}>
                      <Space style={{ color: "#FFF" }}>
                        {userName}
                        <DownOutlined style={{ fontSize: "10px" }} />
                      </Space>
                    </Dropdown>
                  </Space>
                </>
              ) : location.pathname === "/" ||
                (location.pathname === "/score" && !isAuth) ? (
                <Button
                  type="primary"
                  shape="round"
                  onClick={handleLogIn}
                  icon={
                    <LoginOutlined
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                      }}
                    />
                  }
                  size="large"
                  style={{
                    backgroundColor: "transparent",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    borderColor: "transparent",
                    border: 0,
                  }}
                >
                  LOGIN
                </Button>
              ) : (
                <></>
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default CSAHeader;
