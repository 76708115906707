import React, { useState, useEffect } from "react";
//Components
import CoachRegisterForm from "../../Common/CoachRegisterForm";
import CoachRegisterForm2 from "../../Common/CoachRegisterForm/CoachRegisterForm2";
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./Coaches.module.scss";
import { useLocation } from "react-router-dom";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function Coaches() {
  const [img, setImg] = useState([]);
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({ step1: {}, step2: {} });
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
    img,
    setImg,
  };
  const location = useLocation();

  const id = location?.state?.id;
  const type = location?.state?.type;
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Coaches", value: "/coach-list" },
    { key: "Coach", value: "/register-coach" },
  ];

  return (
    <Wrapper>
      <div className={styles.CoachesWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isEdit ? "Edit" : isView ? "" : "Create"} Coach
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>
            {step === 1 && <CoachRegisterForm {...props} />}
            {step === 2 && <CoachRegisterForm2 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Coaches;
