import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";
//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./Players.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { fixSpecialChar } from "utils/helpers";

const PlayersTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Players", value: "/player-list" },
  ];
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    userLevel !== "sub-member"
      ? {
          title: "Contracted",
          dataIndex: "contracted",
          key: "contracted",
        }
      : {},
    userLevel !== "sub-member"
      ? {
          title: "Contract End Date",
          dataIndex: "contractEndDate",
          key: "contractEndDate",
        }
      : {},
    userLevel === "sub-member"
      ? {
          title: "Race",
          dataIndex: "race",
          key: "race",
        }
      : {},
    userLevel === "sub-member"
      ? {
          title: "Differently abled",
          dataIndex: "differentlyAbled",
          key: "differentlyAbled",
        }
      : {},
    // {roles === "subMember" && }
    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR"
      ? {
          title: "Action",
          key: "action",
          render: (data) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-player", { state: { ...data, type: "VIEW" } })
              }
              handleEdit={() =>
                navigate("/edit-player", { state: { ...data, type: "EDIT" } })
              }
            />
          ),
          align: "right",
        }
      : {},
  ];
  const { callAPI, response, error, loading } = useAxios({
    url:
      userLevel?.toLowerCase() === "sub-member"
        ? serviceUrl.getPlayerDetailsSubMember
        : userLevel?.toLowerCase() === "csa"
        ? serviceUrl.getCsaPlayerDetails
        : serviceUrl.getPlayerDetails,
    method: "get",
  });

  const data = response?.rows
    // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
    ?.map((item) => ({
      key: item?.id,
      firstname: fixSpecialChar(item?.first_name),
      lastname: fixSpecialChar(item?.last_name),
      gender: item?.field_gender,
      contracted: item?.field_contracted,
      contractEndDate: item?.field_contract_end_date
        ? item?.field_contract_end_date
        : "N/A",
      race: item?.field_race,
      differentlyAbled: item?.differently_abled,
      status1: item?.status === "Assigned" ? "Activated" : "Disabled",
      ...item,
    }));
  useEffect(() => {
    userLevel === "member"
      ? callAPI(`level=${userLevel.toUpperCase()}&member_id=${memberId}`, true)
      : userLevel === "sub-member"
      ? callAPI(`keyword=&sub_member_id=${sub_member_id}`, true)
      : callAPI();
  }, []);

  useEffect(() => {
    if (error) {
      console.log("error", error);
    }
    if (response) {
      console.log("response", response);
    }
  }, [error, response]);
  const handleSearch = (e) => {
    userLevel === "member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(`keyword=${e}&sub_member_id=${sub_member_id}`, true)
      : callAPI(`keyword=${e}`, true);
  };

  const onChange = (page, pageSize) => {
    userLevel === "member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&member_id=${memberId}&page=${
            page - 1
          }&items_per_page=${pageSize}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=&sub_member_id=${sub_member_id}&page=${
            page - 1
          }&items_per_page=${pageSize}`,
          true
        )
      : callAPI(`keyword=&page=${page - 1}&items_per_page=${pageSize}`, true);
  };
  return (
    <Wrapper>
      <div className={styles.PlayersWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Players</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                options={options}
                // onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className=" d-flex w-100 justify-content-end">
                {authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT" ||
                authRole === "MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBERS ADMIN ASSISTANT" ||
                authRole === "SUB MEMBERS ADMINISTRATOR" ||
                authRole === "SUB-MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ? (
                  <CsaButton
                    label="Create"
                    textColor="#FFF"
                    onClick={() => navigate("/register-player")}
                    btnColor="#007237"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2">
            <Table
              className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{
                x: 750,
              }}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={response?.pager?.total_items}
                size={"small"}
                current={response?.pager?.current_page + 1}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PlayersTable;
