import styles from "./ExpandTable.module.scss";
import React, { useState } from "react";
import { Table } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTable2Open, setTableOpen } from "redux/slice/commonSlice";

const ExpandTable = ({
  dataSource,
  columns,
  className,
  scroll,
  tableId,
  openedTable,
  openedTable2,
  ...props
}) => {
  const dispatch = useDispatch();
  const [hideBody, setHideBody] = useState(false);

  const openTable = useSelector((state) => state.auth.tableOpen);

  const openOrCloseAll = () => {
    setHideBody((current) => !current);

    openedTable(tableId + hideBody);
  };

  return (
    <>
      <div className={`${styles.ExpandTableWrapper} ${className}`}>
        <div
          className="expand-table-common-icon"
          style={{ position: "absolute", left: 15, top: 10, zIndex: 1 }}
        >
          <div
            onClick={openOrCloseAll} //openOrCloseAll click header button event
            style={{
              display: hideBody ? "none" : "inline-block",
            }}
            className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          />
          <div
            onClick={openOrCloseAll}
            style={{
              display: hideBody ? "inline-block" : "none",
            }}
            className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          />
        </div>
        <Table
          className={`expand-table ${className}`}
          dataSource={!hideBody ? dataSource : []}
          pagination={false}
          columns={columns}
          scroll={scroll}
        />
      </div>
    </>
  );
};

export default ExpandTable;
