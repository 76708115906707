import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Col, Row } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
//Components
import CsaTextInput from "Common/CsaTextInput";
import CsaButton from "Common/CsaButton";
import styles from "./CsaLoginForm.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";

const RecoveryPasswordSchema = Yup.object().shape({
 username: Yup.string()
  .email("Invalid Email Address")
  .required("Enter Email Address"),
});

function RecoveryPasswordForm() {
 const { addToast } = useToasts();
 const navigate = useNavigate();
 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.resetPasswordByMail,
  method: "post",
 });
 const formik = useFormik({
  initialValues: {
   username: "",
  },
  validationSchema: RecoveryPasswordSchema,
  onSubmit: (values) => {
   const obj = {
    mail: values.username,
   };
   callAPI(obj);
  },
 });
 useEffect(() => {
  if (response) {
   addToast(
    response?.message || "Check registered email for login instructions ",
    { appearance: "success" },
    () => navigate("/login")
   );
   resetForm();
  } else if (error) {
   addToast(error?.message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [response, error]);
 const {
  handleChange,
  errors,
  values,
  handleBlur,
  handleSubmit,
  touched,
  resetForm,
 } = formik;
 const checkValidity = () => {
  const { username } = values;
  const isVAlid =
   username &&
   Object.keys(errors).length === 0 &&
   Object.keys(errors).length === 0;
  return isVAlid ? false : true;
 };
 return (
  <div className={styles.CsaLoginFormWrapper}>
   <Row justify="center" align="middle">
    <Col span={24}>
     <h4 className="text-center text-white">Recover Password</h4>
    </Col>
   </Row>
   <Row justify="start" align="middle" className="mt-3">
    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
     <CsaTextInput
      label="Email Address"
      placeholder="Enter Email Address"
      name="username"
      type="text"
      labelColor
      onChange={handleChange}
      value={values.username}
      required
      onBlur={handleBlur}
      touched={touched.username}
      error={errors.username || null}
     />
    </Col>

    <div className="w-100 d-flex align-items-center justify-content-center mt-2 mb-2">
     <CsaButton
      label="Submit"
      textColor="#004f27"
      htmlType="submit"
      onClick={() => handleSubmit()}
      disabled={checkValidity()}
      loading={loading}
     />
    </div>
   </Row>
  </div>
 );
}

export default RecoveryPasswordForm;
