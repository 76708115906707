import { Card, Col, Row, Avatar, Image, Tag } from "antd";
import React from "react";

export default function ClubView(props) {
 const { viewData } = props;
 return (
  <Card
   style={{
    width: "100%",
    backgroundColor: "#f1f1f1",
    justifyContent: "center",
    borderRadius: 15,
    borderWidth: 0.5,
    borderColor: "#f3f3f3",
   }}
  >
   <Row>
    {viewData && (
     <>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Municipality</h6>
       <p>{viewData?.field_municipality || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Club Name</h6>
       <p>{viewData?.field_club_name?.replace(/&#039;/g, "'") || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Commonly Known As</h6>
       <p>{viewData?.field_commonly_known_as?.replace(/&#039;/g, "'") || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Settlement Type</h6>
       <p>{viewData?.field_settlement_type || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Web Address</h6>
       <p>{viewData?.field_web_address || "N/A"}</p>
      </Col>{" "}
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Address</h6>
       <p>{viewData?.field_address || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Latitude</h6>
       <p>{viewData?.field_latitude || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Longitude</h6>
       <p>{viewData?.field_longitude || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Club Status</h6>
       <p>
        {viewData?.field_club_status === "Active"
         ? "Active"
         : "Disable" || "N/A"}
       </p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Nationality</h6>
       <p>{viewData?.nationality}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">ID Number</h6>
       <p>
        {viewData.foreign_user === "False" ? viewData?.id_number : "" || "N/A"}
       </p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Passport No.</h6>
       <p>{viewData?.passport_number || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Email Address</h6>
       <p>{viewData?.field_email_address || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">First Name</h6>
       <p>{viewData?.first_name?.replace(/&#039;/g, "'") || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Last Name</h6>
       <p>{viewData?.last_name?.replace(/&#039;/g, "'") || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Contact No.</h6>
       <p>{viewData?.field_contact_number || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Date of Birth</h6>
       <p>{viewData?.date_of_birth || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Race</h6>
       <p>{viewData?.race || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Gender</h6>
       <p>{viewData?.gender || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Differently Abled</h6>
       <p>{viewData?.differently_abled || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Status</h6>
       <p>
        {viewData?.status === "Active" ? "Activated" : "Disabled" || "N/A"}
       </p>
      </Col>
     </>
    )}
   </Row>
  </Card>
 );
}
