import Wrapper from "Layout/Wrapper";
import React from "react";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import { Col, Row, Select, Spin } from "antd";
//import { memberList } from 'utils/commonMasterData';
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from "react";
import useAxios from "utils/axios";
import * as masterData from "../../utils/commonMasterData";

import { useFormik } from "formik";
import * as Yup from "yup";
import CsaChart from "./BarChart";

const SubMembersSchema = Yup.object().shape({
  membersName: Yup.string().required("Select Member Name"),
  subMemberType: Yup.string().required("Select Sub Member Type"),
  subMemberName: Yup.string().required("Select Sub Member Name"),
});

export default function Graphs() {
  const [tournamentName, setTournamentName] = useState();
  const [reportstype, setReportsType] = useState();
  const [graphData, setGraphData] = useState([]);
  const [graphTeamData, setGraphTeamData] = useState([]);
  const [raceData, setRaceData] = useState([]);
  const [subMemberGraph, setSubMemberGraph] = useState([]);
  const userLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const subMemberType = localStorage.getItem("sub_member_type");
  const subMemberId = localStorage.getItem("sub_member_id");
  const csaYellow = "#ede303";
  const csaGreen = "#007237";
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getMemberMasterData,
    method: "get",
  });

  const {
    callAPI: getGraphs,
    response: resGraphs,
    error: errGraphs,
    loading: loadGraphs,
  } = useAxios({
    url: serviceUrl.getGraphs,
    method: "get",
  });

  const {
    callAPI: FetchAPISubMember,
    response: fetchResponseSubMember,
    error: fetchErrSubMember,
    loading: fetchLoadSubMember,
  } = useAxios({
    url: serviceUrl.getCMSSubMemberList,
    method: "get",
    query: true,
  });

  useEffect(() => {
    callAPI();
  }, []);

  const subMemberNameData = fetchResponseSubMember?.rows || [];

  const namesData = response?.map((p) => ({
    label: p.member_name,
    value: p.member_id.toString(),
    ...p,
  }));

  const { Option } = Select;
  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Graphs", value: "/graphs" },
  ];

  const memberList = [
    { value: "584", label: "League 1" },
    { value: "556", label: "Border League" },
  ];

  const ReportTypes = [];

  const reports = ReportTypes?.map((i) => ({
    value: i?.value?.toString(),
    label: i?.label?.toString(),
  }));

  const tData = memberList?.map((i) => ({
    value: i?.value,
    label: i?.label,
  }));

  const formik = useFormik({
    initialValues: {
      membersName:
        userLevel.toLowerCase() === "member" ||
        userLevel.toLowerCase() === "sub-member"
          ? memberId
          : "",
      subMemberType:
        userLevel.toLowerCase() === "sub-member" ? subMemberType : "",
      subMemberName:
        userLevel.toLowerCase() === "sub-member" ? subMemberId : "",
    },
    validationSchema: SubMembersSchema,
  });

  const onSelect = (name, key, opt) => {
    // console.log(opt, "opt");
  };

  const {
    errors,
    setFieldTouched,
    touched,
    values,
    setFieldValue,
    handleBlur,
  } = formik;
  console.log(values.membersName, "opt");
  // fetch school hub club etc...
  useEffect(() => {
    if (values?.membersName && values.subMemberType) {
      FetchAPISubMember(
        `?type=${values.subMemberType}&member_id=${values?.membersName}`,
        true
      );
    }
  }, [values?.membersName, values.subMemberType]);

  const handleSubmit = () => {
    //  getGraphs(`both/${values.subMemberName}`, true);
    if (userLevel?.toLowerCase() === "member") {
      if (values?.membersName && values.subMemberName) {
        getGraphs(`both/${values.membersName}/${values.subMemberName}`, true);
      }
      if (!values.subMemberName) {
        getGraphs(`member_only/${values.membersName}`, true);
      }
    } else if (userLevel?.toLowerCase() === "sub-member") {
      getGraphs(`both/${values.membersName}/${values.subMemberName}`, true);
    } else {
      if (values?.membersName && values.subMemberName) {
        getGraphs(`both/${values.membersName}/${values.subMemberName}`, true);
      }
      if (!values.subMemberName) {
        getGraphs(`member_only/${values.membersName}`, true);
      }
    }
  };

  useEffect(() => {
    handleSubmit();
    // if (userLevel.toLowerCase() === "sub-member"  ) {
    //   handleSubmit();
    // }
  }, []);

  const teamCounts = resGraphs?.teamBreakdown || [];
  const subMemberCounts = resGraphs?.memberReport || {};

  console.log(teamCounts, "vaals");
  useEffect(() => {
    if (resGraphs) {
      const genderGraphData = Object.entries(teamCounts).map(
        ([key, value]) => ({
          name: key,
          value: value,
          fill: key.includes("Female") ? csaGreen : csaYellow,
        })
      );

      setGraphTeamData([
        {
          name: "Count",
          male: resGraphs?.genderCount?.find((i) => i._id === "Male")?.count,
          female: resGraphs?.genderCount?.find((i) => i._id === "Female")
            ?.count,
        },
      ]);
      setGraphData(genderGraphData);

      const transformedData2 = resGraphs?.racePercentage
        ?.filter((d) => d._id !== "")
        .map((i) => ({
          name: i._id,
          percentage: Number(i.percentage?.toFixed(2)),
        }));
      setRaceData(transformedData2);

      const sMemberData = Object.entries(subMemberCounts)
        .filter(([key, value]) => {
          return (
            key.includes("primary_schools") ||
            key.includes("clubs") ||
            key.includes("high_schools") ||
            key.includes("ancillary_member")
          );
        })
        .map(([key, value]) => ({
          name:
            (key?.includes("primary_schools") && "Primary Schools") ||
            (key?.includes("clubs") && "Clubs") ||
            (key?.includes("high_schools") && "High Schools") ||
            (key?.includes("ancillary_member") && "Ancillary Member"),
          value: value,
          fill: csaGreen,
        }));
      setSubMemberGraph(sMemberData);
      // const sMemberData = Object.entries(subMemberCounts).map(
      //   ([key, value]) => ({
      //     name:
      //       (key?.includes("primary_schools") && "Primary Schools") ||
      //       (key?.includes("clubs") && "Clubs") ||
      //       (key?.includes("high_schools") && "High Schools") ||
      //       (key?.includes("ancillary_member") && "Ancillary Member"),
      //     value: value,
      //     fill: csaYellow,
      //   })
      // );
      console.log(sMemberData, "ssss");
    }
  }, [resGraphs]);

  console.log(values.subMemberName, "smm");

  return (
    <Wrapper>
      <div
        style={{
          color: "#007237",
          fontSize: "20px",
          paddingTop: "20px",
          paddingLeft: "20px",
        }}
      >
        <CsaBreadcrumb pathArr={pathArr} />
        <div style={{ width: "100%" }}>
          <p style={{ color: "#007237", fontSize: "25px" }}>Graphs</p>
        </div>
      </div>
      <Row
        style={{
          width: "100%",
          padding: "20px",
        }}
        justify="start"
      >
        {userLevel?.toLowerCase() !== "sub-member" && loading ? (
          <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {userLevel.toLowerCase() === "csa" && (
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                <div className="m-2">
                  <CsaDropDown
                    label="Member"
                    value={values.membersName}
                    name="membersName"
                    optionType
                    onClear={(e) => setReportsType("")}
                    onChange={(e) => {
                      setFieldValue("membersName", e);
                    }}
                    onSelect={(key, opt) => onSelect("membersName", key, opt)}
                    menuArr={namesData}
                  />
                </div>
              </Col>
            )}
            {userLevel.toLowerCase() !== "sub-member" && (
              <>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                  <div className="m-2">
                    <CsaDropDown
                      label="Member Pathway Structure Type"
                      name="subMemberType"
                      value={values.subMemberType}
                      optionType
                      onChange={(e) => {
                        setFieldValue("subMemberType", e);
                      }}
                      menuArr={masterData.submemberList}
                      disabled={!values.membersName}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                  <div className="m-2">
                    <CsaDropDown
                      loading={fetchLoadSubMember}
                      label="School, Club, Hub etc:"
                      error={
                        values.subMemberType && !values.subMemberName
                          ? "Please Select Sub Member"
                          : null
                      }
                      touched={true}
                      required={values.subMemberType}
                      name="subMemberName"
                      value={values.subMemberName}
                      disabled={!values.subMemberType}
                      optionType
                      onChange={(e) => setFieldValue("subMemberName", e)}
                      menuArr={
                        subMemberNameData && subMemberNameData?.length > 0
                          ? subMemberNameData
                              ?.map((p) => ({
                                key: p?.id,
                                value: p?.id?.toString(),
                                label:
                                  values?.subMemberType === "clubs_schools"
                                    ? p?.club_school_name
                                    : values?.subMemberType === "high_schools"
                                    ? p?.high_school_name
                                    : values?.subMemberType ===
                                      "primary_schools"
                                    ? p?.primary_school_name
                                    : p?.ancillary_member_name,
                                ...p,
                              }))
                              ?.sort((a, b) =>
                                a?.label?.trim().localeCompare(b?.label?.trim())
                              )
                          : []
                      }
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <CsaButton
                      label="Show"
                      textColor="#FFF"
                      btnColor="#007237"
                      loading={loadGraphs}
                      disabled={values.subMemberType && !values.subMemberName}
                      onClick={() => handleSubmit()}
                      type="submit"
                    />
                  </div>
                </Col>
              </>
            )}
          </>
        )}
      </Row>

      <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 20 }}>
        {loadGraphs ? (
          <div className="w-100 p-5 d-flex align-items-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          resGraphs && (
            <div
              style={{
                borderTopColor: "gray",
                borderTop: "solid 1px",
                width: "100%",
                padding: "25px",
                marginTop: "25px",
              }}
            >
              <h1 style={{ fontSize: "20px", marginTop: "25px" }}>
                Number of Players :
              </h1>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span>Female : </span>
                <div
                  style={{
                    backgroundColor: csaGreen,
                    width: "30px",
                    height: "15px",
                  }}
                ></div>
                <span>Male : </span>
                <div
                  style={{
                    backgroundColor: csaYellow,
                    width: "30px",
                    height: "15px",
                  }}
                ></div>
              </Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  //   background: "green",
                }}
              >
                <CsaChart
                  dataKeys={[
                    { label: "male", color: csaYellow },
                    { label: "female", color: csaGreen },
                  ]}
                  data={graphTeamData}
                />
              </div>
              <h1 style={{ fontSize: "20px", marginTop: "25px" }}>
                Number of Teams :
              </h1>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span>Female : </span>
                <div
                  style={{
                    backgroundColor: csaGreen,
                    width: "30px",
                    height: "15px",
                  }}
                ></div>
                <span>Male : </span>
                <div
                  style={{
                    backgroundColor: csaYellow,
                    width: "30px",
                    height: "15px",
                  }}
                ></div>
              </Row>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  //   background: "green",
                }}
              >
                <CsaChart
                  width={880}
                  dataKeys={[{ label: "value" }]}
                  data={graphData}
                />
              </div>
              <h1 style={{ fontSize: "20px", marginTop: "25px" }}>
                Race Percentage :
              </h1>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  //   background: "green",
                }}
              >
                <CsaChart
                  maxRange={100}
                  dataKeys={[{ label: "percentage", color: csaYellow }]}
                  data={raceData}
                />
              </div>
              {!values.subMemberName && (
                <>
                  <h1 style={{ fontSize: "20px", marginTop: "25px" }}>
                    Member Pathway Structure :
                  </h1>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      //   background: "green",
                    }}
                  >
                    <CsaChart
                      maxRange={100}
                      dataKeys={[{ label: "value" }]}
                      data={subMemberGraph}
                    />
                  </div>
                </>
              )}
            </div>
          )
        )}
      </Col>
    </Wrapper>
  );
}
