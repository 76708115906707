import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";

//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import { fixSpecialChar } from "utils/helpers";

const TeamSchema = Yup.object().shape({
  teamCoach: Yup.string().required("Select team coach"),
});

function TeamRegisterForm2({ styles, setStep, setInfo, info, img, setImg }) {
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getCoachDetails,
    method: "get",
  });
  useEffect(() => {
    userLevel === "member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(`keyword=&level=${userLevel.toUpperCase()}&uid=${uid}`, true)
      : callAPI(`keyword=&level=${userLevel.toUpperCase()}`, true);
  }, []);
  const steps = ["Team Info", "Management Team", "Squad"];
  const { addToast } = useToasts();

  const data = info?.step2
    ? info?.step2
    : (info?.editData && {
        teamCoach: info?.editData?.team_coach[0]?.id || "",
        teamManager: info?.editData?.field_team_manager || "",
        battingCoach: info?.editData?.batting_coach[0]?.id || "",
        bowlingCoach: info?.editData?.bowling_coach[0]?.id || "",
        fieldingCoach: info?.editData?.fielding_coach[0]?.id || "",
        fitnessCoach: info?.editData?.fitness_coach[0]?.id || "",
        teamDoctor: info?.editData?.field_team_doctor || "",
        analyst: info?.editData?.field_analyst || "",
        physio: info?.editData?.field_physio || "",
      }) ||
      {};
  const getIdByName = (name) => {
    const resp = response?.rows?.find(
      (item) => `${item?.first_name} ${item?.last_name}` === name
    );

    return resp?.id;
  };
  const getNameById = (id) => {
    const resp = response?.rows?.find((item) => item.id === id);
    return resp ? resp?.first_name + " " + resp?.last_name : "";
  };

  const formik = useFormik({
    initialValues: {
      teamCoach: data ? getNameById(data?.teamCoach) : "",
      teamManager: data ? data?.teamManager : "",
      battingCoach: data ? getNameById(data?.battingCoach) : "",
      bowlingCoach: data ? getNameById(data?.bowlingCoach) : "",
      fieldingCoach: data ? getNameById(data?.fieldingCoach) : "",
      fitnessCoach: data ? getNameById(data?.fitnessCoach) : "",
      teamDoctor: data ? data?.teamDoctor : "",
      analyst: data ? data?.analyst : "",
      physio: data ? data?.physio : "",
    },
    validationSchema: TeamSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: info?.step1,
        step2: {
          teamCoach: getIdByName(values?.teamCoach),
          teamManager: values?.teamManager,
          battingCoach: getIdByName(values?.battingCoach),
          bowlingCoach: getIdByName(values?.bowlingCoach),
          fieldingCoach: getIdByName(values?.fieldingCoach),
          fitnessCoach: getIdByName(values?.fitnessCoach),
          teamDoctor: values?.teamDoctor,
          analyst: values?.analyst,
          physio: values?.physio,
        },
        step3: info?.step3,
        editData: info?.editData || null,
        file: info?.file,
        players: info?.players,
      });
      setStep(3);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const [optionsArr, setOptionsArr] = useState([]);

  useEffect(() => {
    if (response) {
      const res = response?.rows
        ?.filter((x) => x?.status === "Active")
        ?.map(
          (item) =>
            `${fixSpecialChar(item?.first_name)} ${fixSpecialChar(
              item?.last_name
            )}`
        );

      if (optionsArr?.length === 0) {
        setOptionsArr(res);
      }
      //  let arr = [
      //   values.teamCoach,
      //   values.battingCoach,
      //   values.bowlingCoach,
      //   values.fieldingCoach,
      //   values.fitnessCoach,
      //  ];
      //
      //  let body = res?.filter((item) => !arr?.includes(item));
      //  setOptionsArr(body);
    }
  }, [response, values]);
  return (
    <div>
      <CsaStepper steps={steps} activeKey={1} />

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        {loading ? (
          <Col
            span={12}
            className="w-100 justify-content-center align-items-center"
          >
            <Spin size="large" />
          </Col>
        ) : (
          <>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Team Coach"
                placeholder="Select Team Coach"
                name="teamCoach"
                value={fixSpecialChar(values.teamCoach)}
                menuArr={optionsArr}
                onChange={(e) => {
                  setFieldValue("teamCoach", e);
                }}
                required
                onBlur={() => setFieldTouched("teamCoach", true)}
                touched={touched.teamCoach}
                error={errors.teamCoach || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Team Manager"
                placeholder="Enter Team Manager"
                type="text"
                name="teamManager"
                onChange={handleChange}
                value={values.teamManager}
                onBlur={handleBlur}
                touched={touched.teamManager}
                error={errors.teamManager || null}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Batting Coach"
                placeholder="Select Batting Coach"
                name="battingCoach"
                value={fixSpecialChar(values.battingCoach)}
                menuArr={optionsArr}
                onChange={(e) => {
                  setFieldValue("battingCoach", e);
                }}
                onBlur={handleBlur}
                touched={touched.battingCoach}
                error={errors.battingCoach || null}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Bowling Coach"
                placeholder="Select Bowling Coach"
                name="bowlingCoach"
                value={fixSpecialChar(values.bowlingCoach)}
                menuArr={optionsArr}
                onChange={(e) => {
                  setFieldValue("bowlingCoach", e);
                }}
                onBlur={handleBlur}
                touched={touched.bowlingCoach}
                error={errors.bowlingCoach || null}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Fielding Coach"
                placeholder="Select Fielding Coach"
                name="fieldingCoach"
                value={fixSpecialChar(values.fieldingCoach)}
                menuArr={optionsArr}
                onChange={(e) => {
                  setFieldValue("fieldingCoach", e);
                }}
                onBlur={handleBlur}
                touched={touched.fieldingCoach}
                error={errors.fieldingCoach || null}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Fitness Coach"
                placeholder="Select Fitness Coach"
                name="fitnessCoach"
                menuArr={optionsArr}
                value={fixSpecialChar(values.fitnessCoach)}
                onChange={(e) => {
                  setFieldValue("fitnessCoach", e);
                }}
                onBlur={handleBlur}
                touched={touched.fitnessCoach}
                error={errors.fitnessCoach || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Team Doctor"
                placeholder="Enter Team Doctor"
                name="teamDoctor"
                value={values.teamDoctor}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.teamDoctor}
                error={errors.teamDoctor || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Physio"
                placeholder="Enter Physio"
                name="physio"
                value={values.physio}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.physio}
                error={errors.physio || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Analyst"
                placeholder="Enter Analyst"
                name="analyst"
                value={values.analyst}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.analyst}
                error={errors.analyst || null}
              />
            </Col>
          </>
        )}
        <div className="mt-4 d-flex w-100 justify-content-end">
          <CsaButton
            label="Back"
            textColor="#007237"
            onClick={() => {
              setInfo({
                step1: info?.step1,
                step2: {
                  teamCoach: getIdByName(values?.teamCoach),
                  teamManager: values?.teamManager,
                  battingCoach: getIdByName(values?.battingCoach),
                  bowlingCoach: getIdByName(values?.bowlingCoach),
                  fieldingCoach: getIdByName(values?.fieldingCoach),
                  fitnessCoach: getIdByName(values?.fitnessCoach),
                  teamDoctor: values?.teamDoctor,
                  analyst: values?.analyst,
                  physio: values?.physio,
                },
                step3: info?.step3,
                file: info.file,
              });
              setStep(1);
            }}
            btnColor="#FFCB03"
          />
          <CsaButton
            label="Next"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            disabled={!values.teamCoach}
          />
        </div>
      </Row>
    </div>
  );
}

export default TeamRegisterForm2;
