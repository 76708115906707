import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./MatchReferees.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";

const MatchRefereesTable = () => {
 const authRole = localStorage.getItem("selRole");
 const userLevel = localStorage.getItem("csa_level");
 const navigate = useNavigate();
 const [options, setOptions] = useState([]);

 const pathArr = [
  { key: "Home", value: "/home" },
  { key: "Match Referees", value: "/match-referee-list" },
 ];
 const columns = [
  {
   title: "First Name",
   dataIndex: "firstname",
   key: "firstname",
  },
  {
   title: "Last Name",
   dataIndex: "lastname",
   key: "lastname",
  },

  {
   title: "Highest level Officiated",
   dataIndex: "highestLevelOfficiated",
   key: "highestLevelOfficiated",
  },
  {
   title: "ICC Panel",
   dataIndex: "iccPanel",
   key: "iccPanel",
  },
  {
   title: "Status",
   dataIndex: "status1",
   key: "status1",
  },
  authRole === "CSA ADMINISTRATOR" ||
  authRole === "CSA ADMIN ASSISTANT" ||
  authRole === "CSA MATCH REFEREE MANAGER"
   ? {
      title: "Action",
      key: "action",
      render: (data) => (
       <CsaMoreMenu
        handleView={() =>
         navigate("/view-match-referee", { state: { ...data, type: "VIEW" } })
        }
        handleEdit={() =>
         navigate("/edit-match-referee", { state: { ...data, type: "EDIT" } })
        }
       />
      ),
      align: "right",
     }
   : {},
 ];
 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.getRefereeDetails,
  method: "get",
 });

 const data = response?.rows
  // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
  ?.map((item) => ({
   key: item?.id,
   firstname: item?.first_name,
   lastname: item?.last_name,
   iccPanel: item?.icc_panel,
   highestLevelOfficiated: item?.highest_level,
   status1: item?.status === "Active" ? "Activated" : "Disabled",
   ...item,
  }));

 useEffect(() => {
  callAPI(`level=${userLevel.toUpperCase()}`, true);
 }, []);

 useEffect(() => {
  if (error) {
   
  }
  if (response) {
   
  }
 }, [error, response]);
 const handleSearch = (e) => {
  callAPI(`keyword=${e}&level=${userLevel.toUpperCase()}`, true);
 };

 const onChange = (page) => {
  callAPI(`keyword=""&level=${userLevel.toUpperCase()}&page=${page - 1}`, true);
 };

 return (
  <Wrapper>
   <div className={styles.MatchRefereesWrapper}>
    <CsaBreadcrumb pathArr={pathArr} />
    <div className={styles.contents}>
     <p className={styles.heading}>Match Referees</p>
     <Row
      gutter={{
       xs: 8,
       sm: 16,
       md: 24,
       lg: 32,
      }}
      className="mt-1 pt-1"
      align="start"
     >
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <AutoComplete
        style={{
         width: "100%",
         marginBottom: 10,
        }}
        options={options}
        // onSelect={onSelect}
        onSearch={handleSearch}
       >
        <Input.Search
         size="large"
         placeholder="Search..."
         allowClear
         enterButton
        />
       </AutoComplete>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <div className=" d-flex w-100 justify-content-end">
        {authRole === "CSA ADMINISTRATOR" ||
        authRole === "CSA ADMIN ASSISTANT" ||
        authRole === "CSA MATCH REFEREE MANAGER" ? (
         <CsaButton
          label="Create"
          textColor="#FFF"
          onClick={() => navigate("/register-match-referee")}
          btnColor="#007237"
         />
        ) : null}
       </div>
      </Col>
     </Row>
     <div className="mt-2 pt-2">
      <Table
      className="center-aligned"
       loading={loading}
       columns={columns}
       dataSource={data}
       pagination={false}
      />
      <div className=" d-flex w-100 justify-content-end mt-5">
       <Pagination
        onChange={onChange}
        total={response?.pager?.total_items}
        size={"small"}
       />
      </div>
     </div>
    </div>
   </div>
  </Wrapper>
 );
};

export default MatchRefereesTable;
