import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./Members.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";

const MembersTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Members", value: "/member-list" },
  ];
  const columns = [
    {
      title: "Members Name",
      dataIndex: "membersName",
      key: "membersName",
    },
    {
      title: "Administrator",
      dataIndex: "administrator",
      key: "administrator",
    },
    {
      title: "Teams",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Players",
      dataIndex: "players",
      key: "players",
    },
    { title: "Fields", dataIndex: "fields", key: "fields" },
    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "CSA ADMINISTRATOR" || authRole === "CSA ADMIN ASSISTANT"
      ? {
          title: "Action",
          key: "action",
          render: (data) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-member", { state: { ...data, type: "VIEW" } })
              }
              handleEdit={() =>
                navigate("/edit-member", { state: { ...data, type: "EDIT" } })
              }
            />
          ),
          align: "right",
        }
      : {},
  ];
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getMemberDetails,
    method: "get",
  });

  const data = response?.rows?.map((item) => {
    return {
      key: item?.id,
      membersName: item?.field_members_names,
      administrator: item?.first_name + " " + item?.last_name,
      team: item?.teams_count ? item?.teams_count[0]?.count : "0",
      players: item?.players_count ? item?.players_count[0]?.count : "0",
      fields: item?.fields_count ? item?.fields_count[0]?.count : "0",
      status1: item?.status === "Active" ? "Activated" : "Disabled",
      ...item,
    };
  });

  useEffect(() => {
    userLevel === "member"
      ? callAPI(`level=${userLevel.toUpperCase()}&member_id=${memberId}`, true)
      : userLevel === "sub-member"
      ? callAPI(
          `level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI(`level=${userLevel.toUpperCase()}`, true);
  }, []);

  useEffect(() => {
    if (error) {
      
    }
    if (response) {
      
    }
  }, [error, response]);
  const handleSearch = (e) => {
    userLevel === "member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI(`keyword=${e}&level=${userLevel.toUpperCase()}`, true);
  };

  const onChange = (page, pageSize) => {
    userLevel === "member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&member_id=${memberId}&page=${
            page - 1
          }&items_per_page=${pageSize}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&uid=${uid}&page=${
            page - 1
          }&items_per_page=${pageSize}&member_id=${memberId}`,
          true
        )
      : callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&page=${
            page - 1
          }&items_per_page=${pageSize}`,
          true
        );
  };

  return (
    <Wrapper>
      <div className={styles.MembersWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>Members</p>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-1 pt-1"
            align="start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AutoComplete
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
                options={options}
                // onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input.Search
                  size="large"
                  placeholder="Search..."
                  allowClear
                  enterButton
                />
              </AutoComplete>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className=" d-flex w-100 justify-content-end">
                {authRole === "CSA ADMINISTRATOR" ||
                authRole === "CSA ADMIN ASSISTANT" ? (
                  <CsaButton
                    label="Create"
                    textColor="#FFF"
                    onClick={() => navigate("/register-member")}
                    btnColor="#007237"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="mt-2 pt-2">
            <Table
            className="center-aligned"
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
            <div className=" d-flex w-100 justify-content-end mt-5">
              <Pagination
                onChange={onChange}
                total={response?.pager?.total_items}
                size={"small"}
                current={response?.pager?.current_page + 1}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MembersTable;
