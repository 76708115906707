import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./slice/commonSlice";

import { combineReducers } from "redux";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
 key: "root",
 storage,
};
const rootReducer = combineReducers({
 auth: commonReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
 reducer: persistedReducer,
 devTools: process.env.NODE_ENV !== "production",
 middleware: [thunk],
});
