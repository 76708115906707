import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import nologo from "../../images/nologoclub.png";
import styles from "./MatchSummaryOverlay.module.scss";
import TeamNameHeader from "./TeamNameHeader";
import { findLastObj, fixImage } from "utils/helpers";
import OverlayModal from "Common/OverlaysModal/OverlayModal";

function MatchSummaryOverlay({
  matchData,
  innings,
  intervalType,
  testMatchData,
}) {
  const [cuttentStatIdx, setCurrentStatIndex] = useState(0);

  const { hasMultipleInns, matchStatus } = testMatchData;

  const team1Logo = matchData?.teams?.find(
    (i) => i.teamId === innings[0]?.battingTeamId
  )?.teamLogo;
  const team2Logo = matchData?.teams?.find(
    (i) => i.teamId !== innings[0]?.battingTeamId
  )?.teamLogo;
  const winStatus = matchData?.winMessage;
  const targetRuns = findLastObj(innings)?.targetRuns;
  const isChasing = targetRuns > 0;
  const currentScore = Number(findLastObj(innings)?.teamSummary?.score);
  const runsGap = targetRuns - currentScore;
  const isTest = matchData?.numOvers === 0;
  const tossWonTeam = matchData?.toss?.teamId;

  //rotate between partnerships and rra in first inns //

  const batTeamArr = findLastObj(innings);
  const curentRR = batTeamArr?.teamSummary?.runRate || 0;
  const p = findLastObj(batTeamArr?.partnerships)?.score || 0;
  const partnership = "PARTNERSHIP - " + p + " RUNS";

  const statsArr = [
    "RRA - " + curentRR,
    partnership,
    hasMultipleInns
      ? matchStatus
      : isChasing && !hasMultipleInns
      ? "NEED " + runsGap + " MORE RUNS TO WIN"
      : undefined,
  ].filter(Boolean);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (statsArr.length) {
        setCurrentStatIndex((prevIndex) => (prevIndex + 1) % statsArr.length);
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, [statsArr]);

  const currentStat = statsArr[cuttentStatIdx];

  return (
    <OverlayModal>
      <div className={styles.logoTableContainer}>
        <img
          className={styles.teamLogo}
          src={fixImage(team1Logo)}
          alt="Team Logo 2"
        />
        <div className={styles.mainWrapper}>
          {innings?.map((i, idx) => {
            const count = innings.filter(
              (item) => item.battingTeamId === i.battingTeamId
            );

            const newIndex = count.findIndex((item) => item === i) + 1;
            const newItem = {
              ...i,
              numOfInns: innings?.length,
              innsNum: isTest
                ? (newIndex === 1 && "(1st)") ||
                  (newIndex === 2 && "(2nd)") ||
                  newIndex
                : null,
            };

            return (
              <TeamNameHeader
                toss={idx < 2 && tossWonTeam}
                key={idx}
                data={newItem}
                testMatchData={testMatchData}
              />
            );
          })}
          {innings?.length > 0 && (
            <div className={styles.extrasRow}>
              <h2>
                {winStatus
                  ? winStatus
                  : intervalType
                  ? intervalType.toUpperCase()
                  : hasMultipleInns
                  ? matchStatus
                  : isChasing && !hasMultipleInns
                  ? "NEED " + runsGap + " MORE RUNS TO WIN"
                  : currentStat || ""}
              </h2>
            </div>
          )}
        </div>
        <img
          className={styles.teamLogo}
          src={fixImage(team2Logo)}
          alt="Team Logo 2"
        />
      </div>
    </OverlayModal>
  );
}

export default MatchSummaryOverlay;
