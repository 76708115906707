/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Row, Col, Pagination, Spin } from "antd";
import { useFormik } from "formik";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import SearchDropDown from "./../SearchDropDown/index";
import { setPoolId } from "redux/slice/commonSlice";
import { fixSpecialChar } from "utils/helpers";

function CMSPool({ styles, setStep, setInfo, info, setLocal }) {
 const { addToast } = useToasts();
 const [teamOpt, setTeamOpt] = useState([]);
 const [poolData, setPoolData] = useState([]);

 const location = useLocation();
 const { id, type } = location?.state || {};
 const isEdit = type === "EDIT";
const dispatch = useDispatch();
const cmsID = useSelector((state) => state.auth.cms_id);

const poolId = useSelector((state) => state.auth.poolId)
//const poolId = null
 const {
  callAPI: callPoolApi,
  response: poolRes,
  error: poolErr,
  loading: gettingPool,
 } = useAxios({
  url: serviceUrl.getPool + `${isEdit ? id : cmsID}` + "/pools",
  method: "get",
 });
 useEffect(() => {
  if (isEdit || poolId) {
   callPoolApi();
  }
 }, [isEdit,poolId]);

 useEffect(() => {
  if (poolRes) {
   const poolDataFromApi = poolRes?.data?.map((item) => ({
    key: Number(item?.name),
    poolNum: Number(item?.name),
    poolTeamArrId: item?.teams?.map((t) => Number(t?.teamId)),
    poolTeamArr: item?.teams?.map((t) => ({
     poolKey: Number(item?.name),
     id: Number(item?.teamId),
     name: item?.teamName,
    })),
    teams: item?.teams?.map((t) => ({
     poolKey: Number(item?.name),
     key: Number(t?.teamId),
     value: Number(t?.teamId),
     teamId: Number(t?.teamId),
     label: t?.teamName,
     member: t?.member,
     submember: "Nil",
     teamName: t?.teamName,
    })),
   }));
   
   setPoolData(poolDataFromApi);
  }
 }, [poolRes]);

 
 

 


 
 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.setPool + `${cmsID}/pools`,
  method: "post",
 });

 useEffect(()=> {
 response && dispatch(setPoolId(response?.data?.[0]?.id))
  response&& addToast(
    response?.message,
    {
     appearance: "success",
    })
   response && setStep(2)
 },[callAPI])

 const {
  callAPI: fetchDetail,
  response: fetchResponse,
  error: err,
  loading: fetching,
 } = useAxios({
  url: serviceUrl.getCMSDetail + `${cmsID}`,
  method: "get",
 });
 useEffect(() => {
  fetchDetail();
 }, []);
 
 const poolCount = fetchResponse?.data?.competition_settings?.find(
  (item) => item?.key === "number_of_pools"
 )?.value;
 const numTeam = fetchResponse?.data?.competition_settings?.find(
  (item) => item?.key === "number_of_teams_per_Pool"
 )?.value;

 const getMemberNameById = (arr, id) => {
  let objectArray = [];
  let memberItem;
  if (arr?.length > 0) {
   for (let i = 0; i < arr.length; i++) {
    let memberNamePar = response?.find((item) => item?.id == arr[i])?.name;
    if (memberNamePar) {
     objectArray.push({
      value: arr[i],
      label: memberNamePar,
     });
    }
   }
  } else {
   memberItem = response?.find((item) => item?.id == id)?.name;
  }
  return id ? memberItem : objectArray;
 };
 const steps = ["Pools", "Schedule"];
 const columns = [
  {
   title: "Member",
   dataIndex: "member",
   key: "member",
   width:"33%",
  },

  {
   title: "Team Name",
   dataIndex: "teamName",
   key: "teamName",
   width:"33%",
  },

  {
   title: "Action",
   key: "action",
   width:"33%",
   render: (teamArr) => (
    <DeleteOutlined
     style={{ cursor: "pointer", color: "red" }}
     onClick={() => handleRemove(teamArr?.teamId, teamArr)}
    />
   ),
   //align: "center",
  },
 ];

 const onChange = (page) => {
  //  callAPI(`keyword=""&member_id=${memberId}&page=${page - 1}`, true);
 };
 const data = info?.step1 || {};

 const formik = useFormik({
  initialValues: {
   pool: data ? data?.pool : "",
   teamName: data ? data?.teamName : "",
  },
  enableReinitialize: true,
  onSubmit: (values) => {
   let condition = poolData?.map((item) => item?.teams?.length == numTeam)
   let checker = (arr) => arr.every((v) => v === true);
   
   if (!checker(condition) || poolData.length === 0) {
    addToast(`Please add ${numTeam} number of teams in each pool`, {
     appearance: "warning",
    });
   } else {
    setInfo({
     step1: info?.step1,
     step2: values,
    });
    const obj = {
     pools: poolData?.map((item) => ({
      name: item?.poolNum?.toString(),
      teams: item?.teams?.map((x) => ({
       teamName: x?.teamName,
       teamId: x?.teamId?.toString(),
       member:x?.member,
      })),
     })),
    };
    
    

   callAPI(obj);
    dispatch(setPoolId(response?.data?.[0]?.id))
  // response ? setStep(2) : 
   }
  },
 });
let resData = []
resData =response?.data || []
 
const poolIds = useSelector((state) => state.auth.poolId)



 const compSettings = fetchResponse?.data
  ? fetchResponse?.data?.competition_settings
  : null || [];
 const result = compSettings?.reduce(function (r, a) {
  r[a?.key] = r[a?.key] || [];
  r[a?.key].push(a);
  return r;
 }, Object.create(null));
 const settingArr = compSettings
  ? Object?.keys(result)?.map((item, i) => ({
     key: item,
     value: result[item]?.map((val) => (val?.value ? val?.value : val?.values)),
     data: result[item]?.map((val) => (val?.data ? val?.data : [])),
    }))
  : [];
 const tableTeamArr = settingArr
  ?.find((item) => item?.key === "team_list_CMS")
  ?.data.map((p) => ({
   key: p?.teamId,
   value: p?.teamId,
   label: p?.teamName,
   ...p,
  }));

 

 useEffect(() => {
  if (response?.data?.id) {
    dispatch(setPoolId(response?.data?.id))
    
   addToast(
    isEdit ? "Updated Successfully" : "Successfully created the pool ",
    {
     appearance: "success",
    }
   );
  } else if (error) {
   addToast(error?.message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [response, error]);
 const {
  errors,
  touched,
  handleSubmit,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  resetForm,
 } = formik;

 const onClear = () => {
  setFieldValue("pool");
  setFieldValue("teamName");
 };
 const handleAdd = (pool, val) => {
  //debugger;
  let reqPoolData = poolData?.find((item) => item?.key === pool);
  if (pool) {
   let numberOfTeamsperPool =
    fetchResponse?.data?.competition_settings?.find(
     (item) => item?.key === "number_of_teams_per_Pool"
    )?.value || 0;

   let currentLen = reqPoolData ? reqPoolData.length + val.length : val.length;

   if (currentLen > parseInt(numberOfTeamsperPool)) {
    addToast("Selected team length exceeded", {
     appearance: "warning",
    });
   } else {
    
    const obj = {};
    let teamArr =
     tableTeamArr?.filter((item) => val?.includes(item?.teamId)) || [];
    let reqArr = teamArr?.map((item) => ({
     poolKey: pool?.toString(),
     ...item,
    }));
    
    
    if (
     reqPoolData?.teams?.length ==
     Number(
      Number(
       fetchResponse?.data?.competition_settings?.find(
        (item) => item?.key === "number_of_teams_per_Pool"
       )?.value || 0
      )
     )
    ) {
     addToast("Pool contain Maximum Teams", {
      appearance: "warning",
     });
    } else {
     if (teamArr?.length !== 0 && pool) {
      obj.key = pool;
      obj.poolNum = pool;
      obj.teams = reqArr;
      let poolDataDub = poolData;
      let myObj = poolDataDub?.find((item) => item?.key === pool);
      
      let objIndex = poolData.findIndex((obj) => obj.key == pool);
      if (myObj) {
       poolDataDub[objIndex].teams = [
        ...poolDataDub[objIndex].teams,
        ...reqArr,
       ];
       
       //  setPoolData([...poolDataDub]);
       // setPoolData([...poolDataDub, myObj]);
       setPoolData([...poolDataDub]);
       
      } else {
       setPoolData((poolData) => [...poolData, obj]);
       
      }
      onClear();
     }
    }
   }
  } else {
   addToast("Please Select a pool", {
    appearance: "warning",
   });
  }
 };

 const handleSearch = (text) => {
  setTeamOpt(
   !text
    ? tableTeamArr
    : tableTeamArr.filter((team) => {
       return team?.label?.toLowerCase().includes(text.toLowerCase());
      })
  );
 };
 const handleRemove = (fieldArr, data) => {
  
  let deletePoolKey = data?.poolKey;
  let reqPoolData = poolData?.find((item) => item?.key == deletePoolKey);
  
  let reqTeamArr = reqPoolData?.teams?.filter(
   (item) => item?.teamId !== fieldArr
  );
  

  let poolDataDub = poolData;
  let objIndex = poolDataDub.findIndex((obj) => obj.key == deletePoolKey);
  // let reqPoolDataDub = poolDataDub?.find((item) => item?.key == deletePoolKey);
  poolDataDub[objIndex].teams = reqTeamArr;
  setPoolData([...poolDataDub]);
 };
 
//  const poolCount =  fetchResponse?.data?.competition_settings?.find(
//   (item) => item?.key === "number_of_pools"
//  )?.value 
 const poolArr =
  [
   ...Array.from(
    Array(
     Number(
      fetchResponse?.data?.competition_settings?.find(
       (item) => item?.key === "number_of_pools"
      )?.value || "0"
     ) + 1
    ).keys()
   )?.slice(1),
  ] || [];
  function getNumbersArray(count) {
    let arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(i.toString());
    }
    return arr;
  }
  
 return (
  <div className="mt-3 pt-3 px-3">
   <CsaStepper steps={steps} activeKey={0} />
   {gettingPool ? (
    <Spin />
   ) : (
    <>
     <Row
      gutter={{
       xs: 8,
       sm: 16,
       md: 24,
       lg: 32,
      }}
      className="mt-3 pt-3"
      align="Start"
     >
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <CsaDropDown
        label="Pool"
        placeholder="Select Pool"
        type="text"
        name="pool"
        onChange={(e) => {
         setFieldValue("pool", e);
        }}
        value={values.pool}
        menuArr={poolArr}
        onBlur={handleBlur}
        touched={touched.pool}
        error={errors.pool || null}
       />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
       <SearchDropDown
        label="Team Name"
        placeholder="Select Team Name"
        name="teamName"
        value={values.teamName}
        mode
        options={tableTeamArr?.filter((item) => {
         
         let found = false;
         for (let i = 0; i < poolData?.length; i++) {
          found = poolData[i]?.teams?.find((val) => val?.key === item?.teamId);
          //  poolTableData?.find((item) => val?.includes(item?.key));
          if (found) {
           break;
          }
         }
         return !found;
        })}
        onChange={(e) => {
         setFieldValue("teamName", e);
        }}
        onClear={onClear}
        required
        onSearch={handleSearch}
        onBlur={() => setFieldTouched("teamName", true)}
        touched={touched.teamName}
        error={errors.teamName || null}

        // loading={searching}
       />
      </Col>

      <Col
       xs={24}
       sm={24}
       md={24}
       lg={24}
       xl={24}
       className="d-flex justify-content-end"
      >
       {/* <CsaButton
      label="Auto Allocate"
      textColor="#FFF"
      //   onClick={() => handleSubmit()}
      btnColor="#007237"
      //   disabled={checkValidity()}
     /> */}
       <CsaButton
        label="Add"
        textColor="#FFF"
        onClick={
         //  values?.pool?.includes("Q")
         //   ? () => handleAddQuatre(values?.pool, values?.teamName):
         () => handleAdd(values?.pool, values?.teamName)
        }
        btnColor="#007237"
        disabled={!values?.pool && values?.teamName?.length === 0}
       />
      </Col>
     </Row>
     {Array.from(Array(poolArr?.length).keys())?.map((el, i) => {
      let reqData = poolData?.find((item) => item?.key == i + 1)?.teams || [];
     const finalReqData = reqData?.map((p) => ({...p,teamName: fixSpecialChar (p?.teamName)}))
      
      return (
       <div className="mt-2 pt-2" key={i}>
        <p className={styles.name}>Pool {i + 1}:</p>
        <Table
        className="center-aligned"
         //   loading={loading}
         columns={columns}
         dataSource={finalReqData}
         pagination={false}
         scroll={{
          x: 370,
        }}
         key={i}
        />
       </div>
      );
     })}

     <div className="mt-4  d-flex w-100 justify-content-end">
      <CsaButton
       label="Continue"
       textColor="#FFF"
       onClick={() => handleSubmit()}
       btnColor="#007237"
       //  loading={loading}
      />
     </div>
    </>
   )}
  </div>
 );
}

export default CMSPool;
