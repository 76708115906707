import React from "react";

const OverlayModal = ({ children }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 150,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "scroll",
        width: "100vw",
        height: "100vh",
      }}
    >
      {children}
    </div>
  );
};

export default OverlayModal;
