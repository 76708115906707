import { Card, Col, Row, Avatar, Image, Tag } from "antd";
import React from "react";

export default function NetView(props) {
 const { viewData } = props;
 return (
  <Card
   style={{
    width: "100%",
    backgroundColor: "#f1f1f1",
    justifyContent: "center",
    borderRadius: 15,
    borderWidth: 0.5,
    borderColor: "#f3f3f3",
   }}
  >
   <Row>
    {viewData && (
     <>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Net Number</h6>
       <p>{viewData?.net_number}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Pitch Type</h6>
       <p>{viewData?.field_pitch_type || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Covers Available</h6>
       <p>{viewData?.field_covers_available || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Bowl Machine Available</h6>
       <p>{viewData?.field_bowl_machine_available || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Floodlights Adequate for Day/Night Practice</h6>
       <p>{viewData?.field_floodlights || "N/A"}</p>
      </Col>
      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Indoor/Outdoor</h6>
       <p>{viewData?.field_net_type || "N/A"}</p>
      </Col>

      <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
       <h6 className="mb-1">Status</h6>
       <p>
        {viewData?.field_status === "Yes" ? "Activated" : "Disabled" || "N/A"}
       </p>
      </Col>
     </>
    )}
   </Row>
  </Card>
 );
}
