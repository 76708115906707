import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
// import { useToasts } from "react-toast-notifications";

//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { serviceUrl } from "services/serviceUrl";
import HubView from "../../Common/HubRpcRegisterForm/HubView";
import useAxios from "utils/axios";
import GoogleplaceSearchInput from "Common/GooglePlaceSearch";
const hubRpcSchema = Yup.object().shape({
  municipality: Yup.string().required("Select Municipality"),
  hubName: Yup.string().required("Enter Hub/Rpc Name"),
  hubStatus: Yup.string().required("Select Hub/Rpc status"),
  webAddress: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
    "Enter correct Address!"
  ),
});

function HubRpcRegisterForm({ styles, setStep, setInfo, info, setLocal }) {
  // const memberName = localStorage.getItem("csa_member_name");
  // const authLevel = localStorage.getItem("csa_level");
  const provinceName = JSON.parse(localStorage.getItem("csa_province"));
  const location = useLocation();
  const { isView = false, id, isEdit = false } = location?.state || {};
  const steps = ["General Information", "Administrator"];
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getHubDetailByID + `${id}`,
    method: "get",
  });

  const data = isEdit
    ? {
      municipality: response?.[0]?.field_municipality || "",
      hubName: response?.[0]?.rpc_name?.replace(/&#039;/g, "'") || "", //need to change
      commonlyKnownAs: response?.[0]?.field_commonly_known_as?.replace(/&#039;/g, "'") || "",
      settlementType: response?.[0]?.field_settlement_type || "",
      webAddress: response?.[0]?.field_web_address || "",
      sector: response?.[0]?.field_sector || "",
      address: response?.[0]?.field_address || "",
      latitude: response?.[0]?.field_latitude || "",
      longitude: response?.[0]?.field_longitude || "",
      hubStatus:
        response?.[0]?.rpc_status === "Active" ? "Active" : "Disable" || "",
      uid: response?.[0]?.uid,
    }
    : info?.step1 || {};

    

  useEffect(() => {
    setLocal(response && response?.[0]?.foreign_user === "True" ? false : true);
    // 
  }, [response]);

  const formik = useFormik({
    initialValues: {
      municipality: data ? data?.municipality : "",
      hubName: data ? data?.hubName?.replace(/&#039;/g, "'") : "",
      commonlyKnownAs: data ? data?.commonlyKnownAs?.replace(/&#039;/g, "'") : "",
      settlementType: data ? data?.settlementType : "",
      webAddress: data ? data?.webAddress : "",
      address: data ? data?.address : "",
      latitude: data ? data?.latitude : "",
      longitude: data ? data?.longitude : "",
      hubStatus: data ? data?.hubStatus : "",
      sector: data ? data.sector : "",
    },
    validationSchema: hubRpcSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: values,
        step2: info?.step2,
        editData: response?.[0] || null,
      });
      setStep(2);
    },
  });
  useEffect(() => {
    if (isEdit || isView) {
      callAPI();
    }
  }, [isEdit, isView]);

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const navigate = useNavigate();

  const handleClear = () => {
    resetForm();
    navigate(-1);
  };

  const updateLocation = (e, latLng) => {
    
    formik.setFieldValue('latitude', latLng.lat)
    formik.setFieldValue('longitude', latLng.lng)
    formik.setFieldValue('address', e)
  }
  const checkValidity = () => {
    const { municipality, hubName, hubStatus } = values;
    const isVAlid =
      municipality &&
      hubName &&
      hubStatus &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };


  return (
    <>
      {loading ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      ) : isView ? (
        <HubView View viewData={response?.[0]} />
      ) : (
        <>
          <CsaStepper steps={steps} activeKey={0} />
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3"
            align="Start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Municipality"
                placeholder="Select Municipality"
                required
                menuArr={
                  masterData.optionsMunicipality?.find(
                    (item) => item?.key === provinceName
                  )?.value
                }
                onChange={(e) => {
                  setFieldValue("municipality", e);
                }}
                name="municipality"
                value={values.municipality}
                onBlur={() => setFieldTouched("municipality", true)}
                touched={touched.municipality}
                error={errors.municipality || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Settlement Type"
                placeholder="Select Settlement Type"
                menuArr={masterData.settlementTypeList}
                onChange={(e) => {
                  setFieldValue("settlementType", e);
                }}
                name="settlementType"
                value={values.settlementType}
                onBlur={handleBlur}
                touched={touched.settlementType}
                error={errors.settlementType || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Hub/RPC Name"
                placeholder="Enter Hub/Rpc Name"
                type="text"
                name="hubName"
                onChange={handleChange}
                value={values.hubName}
                required
                onBlur={handleBlur}
                touched={touched.hubName}
                error={errors.hubName || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Sector"
                placeholder="Enter Sector"
                type="text"
                name="sector"
                onChange={handleChange}
                value={values.sector}
                required
                onBlur={handleBlur}
                touched={touched.sector}
                error={errors.sector || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Commonly Known As"
                placeholder="Enter Commonly Known As"
                type="text"
                name="commonlyKnownAs"
                onChange={handleChange}
                value={values.commonlyKnownAs}
                onBlur={handleBlur}
                touched={touched.commonlyKnownAs}
                error={errors.commonlyKnownAs || null}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Web Address"
                placeholder="Enter Web Address"
                type="text"
                name="webAddress"
                onChange={handleChange}
                value={values.webAddress}
                onBlur={handleBlur}
                touched={touched.webAddress}
                error={errors.webAddress || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* <CsaTextInput
                label="Address"
                placeholder="Enter Address"
                type="text"
                name="address"
                onChange={handleChange}
                value={values.address}
                onBlur={handleBlur}
                touched={touched.address}
                error={errors.address || null}
              /> */}
              <GoogleplaceSearchInput
                label="Address"
                placeholder="Enter Address"
                value={values.address}
                latitude={values.latitude}
                longitude={values.longitude}
                AddLocation={(e, latLng) => updateLocation(e, latLng)}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Latitude"
                placeholder="Enter Latitude"
                type="text"
                name="latitude"
                onChange={handleChange}
                value={values.latitude}
                onBlur={handleBlur}
                touched={touched.latitude}
                error={errors.latitude || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Longitude"
                placeholder="Enter Longitude"
                type="text"
                name="longitude"
                onChange={handleChange}
                value={values.longitude}
                onBlur={handleBlur}
                touched={touched.longitude}
                error={errors.longitude || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Hub/RPC Status"
                placeholder="Select Hub/Rpc Status"
                required
                menuArr={masterData.statusList}
                onChange={(e) => {
                  setFieldValue("hubStatus", e);
                }}
                name="hubStatus"
                value={values.hubStatus}
                onBlur={() => setFieldTouched("hubStatus", true)}
                touched={touched.hubStatus}
                error={errors.hubStatus || null}
              />
            </Col>
            <div className="mt-4 d-flex w-100 justify-content-end">
              <Space>
                <CsaButton
                  label="Cancel"
                  textColor="#007237"
                  onClick={() => handleClear()}
                  btnColor="#FFCB03"
                />
                <CsaButton
                  label="Continue"
                  textColor="#FFF"
                  onClick={() => handleSubmit()}
                  btnColor="#007237"
                  disabled={checkValidity()}
                />
              </Space>
            </div>
          </Row>
        </>
      )}
    </>
  );
}

export default HubRpcRegisterForm;
