import React, { useState } from "react";
//Components
import TeamRegisterForm from "../../Common/TeamRegisterForm";
import TeamRegisterForm2 from "../../Common/TeamRegisterForm/TeamRegisterForm2";
import TeamRegisterForm3 from "../../Common/TeamRegisterForm/TeamRegisterForm3";
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./Teams.module.scss";
import { useLocation } from "react-router-dom";
import CsaBackNavigateButton from "Common/CsaBackNavigateButton";

function Teams() {
  const [img, setImg] = useState([]);
  const [step, setStep] = useState(1); //TOBE changed
  const [info, setInfo] = useState({});
  const [isLocal, setLocal] = useState(true);
  const props = {
    styles,
    setStep,
    info,
    setInfo,
    isLocal,
    setLocal,
    img,
    setImg,
  };

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Teams", value: "/team-list" },
    { key: "Team", value: "/register-team" },
  ];
  const location = useLocation();
  const { isView = false, isEdit = false } = location?.state || {};
  return (
    <Wrapper>
      <div className={styles.TeamsWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <div className={styles.labelWrapper}>
            <p className={styles.heading}>
              {isView ? "Team" : isEdit ? "Update Team" : "Create Team"}
            </p>
            {isView && <CsaBackNavigateButton />}
          </div>
          <div>
            {step === 1 && <TeamRegisterForm {...props} />}
            {step === 2 && <TeamRegisterForm2 {...props} />}
            {step === 3 && <TeamRegisterForm3 {...props} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Teams;
