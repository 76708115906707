import React, { useState, useCallback, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Spin } from "antd";
import styles from "./Tournaments.module.scss";
import CsaButton from "Common/CsaButton";
import { Tabs } from "antd";
import { useToasts } from "react-toast-notifications";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "./../../utils/axios";
import TournamentsScoreCard from "Common/TournamentsScoreCard";
import team1 from "../../images/team1.svg";
import team2 from "../../images/team2.svg";
import { useDispatch, useSelector } from "react-redux";
import Gallery from "../../images/gallery.png";
import { setIsBack } from "redux/slice/commonSlice";
import nologo from "../../images/nologoclub.png";
import errImg from "../../images/bat.png";

import {
  doc,
  getDoc,
  onSnapshot,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import firebaseDb from "firebase";
import { findLastObj, fixImage, fixSpecialChar } from "utils/helpers";

// import TournamentData from "../../tournament_data.json";
const { TabPane } = Tabs;

const Tournaments = ({ ...props }) => {
  const {
    id,
    setId,
    // onchangecomponent,
    matchId,
    scoreId,
    skip,
    tName,
    banner,
  } = props;
  const [activeKey, setActiveKey] = useState("1");
  const [loadPage, setLoadPage] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [curInns, setCurInns] = useState([]);

  const { addToast } = useToasts();
  const { propsData } = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();

  const {
    // id,
    //  setId,
    onchangecomponent,
  } = propsData;

  const location = useLocation();

  const { state } = location;

  // const match_details = useSelector((state) => state.auth.match_details);
  const dispatch = useDispatch();
  //const mId = "17";
  const onKeyChange = (key) => {
    setActiveKey(key);
  };
  const handleNavigate = useCallback(
    (key) => {
      setActiveKey(key);
    },
    [setActiveKey]
  );

  const tId = matchData?.tournamentId;
  const hasMultipleInns = matchData?.numOvers === 0;

  //geting tournament banner
  const {
    callAPI: fetchBanner,
    response: resBanner,
    error: errBanner,
    loading: fetchingBanner,
  } = useAxios({
    url: serviceUrl.getCompetitionById + `${tId}?include_relation=false`,
    method: "get",
  });
  const collectionName = process.env.REACT_APP_F_MAIN_COLLECTION;
  const colRef = collection(firebaseDb, collectionName);
  const q = query(colRef, where("matchId", "==", params?.id));

  useEffect(() => {
    (async () => {
      setLoadPage(true);

      await onSnapshot(q, (snap) => {
        console.count("post");
        const matches = [];
        snap.docs.forEach((matchDoc) => {
          const matchData = { ...matchDoc.data(), id: matchDoc.id };

          // Get data from nested collection 'innings' for each match
          const inningsRef = collection(colRef, matchDoc.id, "innings");
          onSnapshot(inningsRef, (inningsSnap) => {
            const inningsData = [];
            inningsSnap.docs.forEach((inningsDoc) => {
              inningsData.push({ ...inningsDoc.data(), id: inningsDoc.id });
            });

            //  matchData.innings = inningsData;
            setCurInns(inningsData);
          });

          matches.push(matchData);
        });

        setMatchData(matches[0]);
        setLoadPage(false);
      });
    })();
  }, []);

  const bannerArr = resBanner?.data || [];
  useEffect(() => {
    tId && fetchBanner();
  }, [tId]);

  const competitionBanner = bannerArr?.competition_settings?.find(
    (elem) => elem?.key === "sponsor_logo"
  )?.value;

  const firstName = bannerArr?.competition_settings?.find(
    (elem) => elem?.key === "umpire_cms_list"
  )?.data?.firstname;

  const lastName = bannerArr?.competition_settings?.find(
    (elem) => elem?.key === "umpire_cms_list"
  )?.data?.lastname;

  let umpireName = firstName + " " + lastName;

  const inningsData = curInns;
  const homeTeamArr = inningsData?.filter(
    (team) => matchData?.homeTeamId === team.battingTeamId
  );
  const awayTeamArr = inningsData?.filter(
    (team) => matchData?.awayTeamId === team.battingTeamId
  );

  const battingTeamId = findLastObj(inningsData)?.battingTeamId;

  const currentBattTeamArr =
    battingTeamId === matchData?.homeTeamId ? homeTeamArr : awayTeamArr;

  const currentBowlingTeamArr =
    battingTeamId === matchData?.homeTeamId ? awayTeamArr : homeTeamArr;

  const battingArr = findLastObj(currentBattTeamArr);
  const battingArr1stInn =
    currentBattTeamArr?.length > 1 ? currentBattTeamArr[0] : null;

  const bowlingArr = findLastObj(currentBowlingTeamArr);
  const bowlingArr1stInn =
    currentBowlingTeamArr?.length > 1 ? currentBowlingTeamArr[0] : null;

  const team1Arr = inningsData?.filter(
    (i) => i.battingTeamId === inningsData[0].battingTeamId
  );
  const team2Arr = inningsData?.filter(
    (i) => i.battingTeamId !== inningsData[0].battingTeamId
  );

  const team1Logo = matchData?.teams?.find(
    (i) => i.teamId === findLastObj(team1Arr)?.battingTeamId
  )?.teamLogo;
  const team2Logo = matchData?.teams?.find(
    (i) => i.teamId !== findLastObj(team1Arr)?.battingTeamId
  )?.teamLogo;

  const loading = !matchData;
  window.onpopstate = () => {
    dispatch(setIsBack(true));
  };
  const handleNavigateBack = () => {
    navigate(-1);
    dispatch(setIsBack(true));
    onchangecomponent({
      mId: matchId,
      tournamentName: tName,
      banner: banner,
      isBack: true,
    });
  };

  const bowlTeamTotal =
    Number(bowlingArr1stInn?.teamSummary?.score || 0) +
    Number(bowlingArr?.teamSummary?.score || 0);
  const batTeamTotal =
    Number(battingArr?.teamSummary?.score || 0) +
    Number(battingArr1stInn?.teamSummary?.score || 0);

  const runsGap =
    batTeamTotal > bowlTeamTotal
      ? batTeamTotal - bowlTeamTotal
      : bowlTeamTotal - batTeamTotal;
  /// const trailBy = bowlTeamTotal - batTeamTotal;

  console.log(
    matchData,
    team1Arr,
    // runsGap,
    // bowlTeamTotal,
    // batTeamTotal,
    // bowlingArr1stInn?.teamSummary?.score + "b2",
    // bowlingArr?.teamSummary?.score + "b1",
    // battingArr?.teamSummary?.score,
    // battingArr1stInn?.teamSummary?.score,
    "status"
  );

  const matchStatus =
    (runsGap === 0 && "Scores level") ||
    (bowlTeamTotal > batTeamTotal && " trail by " + runsGap + " runs") ||
    (bowlTeamTotal < batTeamTotal && " lead by " + runsGap + " runs");

  console.log(inningsData, team1Arr, team2Arr, "matchData");
  return (
    <>
      <div className={styles.TournamentsHeader}>
        <p className={styles.heading}>
          {(tName && tName) || bannerArr.name || ""}
        </p>
        <CsaButton
          label="Back"
          textColor="#FFF"
          //  onClick={() => handleNavigate("1")}
          onClick={() => handleNavigateBack()}
          btnColor="#007237"
        />
      </div>

      {!matchData ? (
        <div className={styles.errContainer}>
          <div className={styles.errImgContainer}>
            <img src={errImg} className={styles.errImg} />
            <p className={styles.errText}>
              Sorry...There are no live matches at the moment.
            </p>
          </div>
        </div>
      ) : loadPage ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.TournamentsWrapper}>
            <div
              className={styles.TournamentsStatus}
              style={{
                // backgroundImage: `url(${banner && banner || competitionBanner})`,
                // backgroundSize: 'cover',
                // backgroundRepeat: 'no-repeat',
                borderRadius: 20,
                // opacity: '60%',
                // width: '100%',
                padding: 10,
              }}
            >
              <div className={styles.TournamentsWinner}>
                <div className={styles.TournamentsMatchStatus}>
                  <span>Match Status:</span>
                  <span>{matchData?.matchStatus}</span>
                </div>
                <div className={styles.TournamentsWinnerResult}>
                  <span className={styles.TournamentsWinnerName}>
                    {matchData?.matchStatus === "Ongoing" && hasMultipleInns
                      ? "Target : " +
                        fixSpecialChar(battingArr?.battingTeamName) +
                        matchStatus
                      : fixSpecialChar(matchData?.winMessage) || ""}
                  </span>
                </div>
              </div>
              <div className={styles.TournamentsDivider}>
                <div></div>
              </div>
              <div className={styles.TournamentsTeam}>
                <div className={styles.TournamentsTeamDetail}>
                  <div className={styles.TournamentsTeamScore}>
                    <div className={styles.TournamentsTeamName}>
                      {fixSpecialChar(team1Arr[0]?.battingTeamName)}
                      {/* {matchData?.homeTeamName} */}
                    </div>
                    <div className={styles.scoresWrapper}>
                      {team1Arr?.map((item, index) => {
                        return (
                          <div className={styles.TournamentsTeamScoreValue}>
                            &nbsp; {index > 0 && "-"}&nbsp;
                            {`${item?.teamSummary?.score || 0}/${
                              item?.teamSummary?.wickets || 0
                            }`}
                            {/* {item?.teamSummary?.score || 0}/
                            {item?.teamSummary?.wickets || 0} */}
                          </div>
                        );
                      })}
                    </div>
                    <div className={styles.TournamentsTeamOver}>
                      <span>
                        {findLastObj(team1Arr)?.teamSummary?.overs || 0}
                        {`${
                          hasMultipleInns && matchData.numOvers === 0
                            ? ""
                            : "/" + findLastObj(team1Arr)?.targetOvers || 0
                        } overs`}
                      </span>
                      <span>
                        CRR -{" "}
                        {Number(
                          findLastObj(team1Arr)?.teamSummary?.runRate
                        ).toFixed(2) || 0}
                        {/* {matchData?.innings?.[1]?.teamSummary?.runRate.toFixed(2) || 0} */}
                      </span>
                    </div>
                  </div>
                  <div className={styles.TournamentsTeamLogo}>
                    <div>
                      <img src={fixImage(team1Logo)} alt="" />
                    </div>
                  </div>
                </div>
                <div className={styles.TournamentsVersus}>VS</div>
                <div className={styles.TournamentsTeamDetail}>
                  <div className={styles.TournamentsTeamLogo}>
                    <div>
                      <img src={fixImage(team2Logo)} alt="" />
                    </div>
                  </div>
                  <div className={styles.TournamentsTeamScore}>
                    <div className={styles.TournamentsTeamName}>
                      {fixSpecialChar(team1Arr[0]?.bowlingTeamName)}
                      {/* {matchData?.awayTeamName} */}
                    </div>
                    <div className={styles.scoresWrapper}>
                      {team2Arr.length ? (
                        team2Arr?.map((item, index) => {
                          return (
                            <div className={styles.TournamentsTeamScoreValue}>
                              &nbsp; {index > 0 && "-"}&nbsp;
                              {`${item?.teamSummary?.score || 0}/${
                                item?.teamSummary?.wickets || 0
                              }`}
                              {/* {item?.teamSummary?.score || 0}/
                            {item?.teamSummary?.wickets || 0} */}
                            </div>
                          );
                        })
                      ) : (
                        <div className={styles.TournamentsTeamScoreValue}>
                          0/0
                        </div>
                      )}
                    </div>
                    <div className={styles.TournamentsTeamOver}>
                      <span>
                        {findLastObj(team2Arr)?.teamSummary?.overs || 0}
                        {`${
                          hasMultipleInns && matchData.numOvers === 0
                            ? ""
                            : "/" + findLastObj(team1Arr)?.targetOvers || 0
                        } overs`}
                      </span>
                      <span>
                        CRR -{" "}
                        {Number(
                          findLastObj(team2Arr)?.teamSummary?.runRate
                        ).toFixed(2) === "NaN"
                          ? 0
                          : Number(
                              findLastObj(team2Arr)?.teamSummary?.runRate
                            ).toFixed(2) || 0}
                        {/* {matchData?.innings?.[1]?.teamSummary?.runRate.toFixed(2) || 0} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.TournamentsData}>
              <Tabs
                type="card"
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={onKeyChange}
              >
                <TabPane tab="Scorecards" key="1">
                  <div style={{ marginTop: "-5px" }}>
                    <TournamentsScoreCard
                      tournamentsData={matchData}
                      inningsData={curInns}
                      otherData={{ umpire: umpireName }}
                    />
                  </div>
                </TabPane>
                {/* <TabPane tab="Charts" key="2">
                  <TournamentsScoreCard handleNavigate={handleNavigate} />
                </TabPane> */}
              </Tabs>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Tournaments;
