import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";

//Components
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import FieldView from "../../Common/FieldRegisterForm/FieldView";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import GoogleplaceSearchInput from "Common/GooglePlaceSearch";

const FieldSchema = Yup.object().shape({
  pitchType: Yup.string(),
  fieldName: Yup.string().required("Enter Field Name"),
  noOfPitches: Yup.string().matches(/^\d+$/, "Only numbers"),
  spectatorsCapacity: Yup.string().matches(/^\d+$/, "Only numbers"),
  status: Yup.string().required("Select Status"),
});

function FieldRegisterForm({ styles, setStep, setInfo, info }) {
  const authLevel = localStorage.getItem("csa_level");
  const memberId = localStorage.getItem("csa_member_id");
  const member_name = localStorage.getItem("csa_member_name");
  const uid = useSelector((state) => state.auth.login_as_uid);
  const location = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { isView = false, id, isEdit = false } = location?.state || {};
  const {
    callAPI: fetch,
    response: fetchResponse,
    error: err,
    loading: fetchLoading,
  } = useAxios({
    url: serviceUrl.getFieldDetailByID + `${id}`,
    method: "get",
  });
  const { callAPI, response, error, loading } = useAxios({
    url: isEdit ? serviceUrl.editField : serviceUrl.setField,
    method: isEdit ? "put" : "post",
  });

  const updateLocation = (e, latLng) => {
    formik.setFieldValue("latitude", latLng.lat);
    formik.setFieldValue("longitude", latLng.lng);
    formik.setFieldValue("address", e);
  };

  const data = isEdit
    ? {
        fieldName: fetchResponse?.[0]?.field_name,
        pitchType: fetchResponse?.[0]?.field_pitch_type,
        noOfPitches: fetchResponse?.[0]?.field_no_of_pitches, //need to change based on api
        squareCoversAvailable:
          fetchResponse?.[0]?.field_square_covers_available,
        spectatorsCapacity: fetchResponse?.[0]?.field_spectators_capacity,
        adequateBoundaryRope: fetchResponse?.[0]?.field_adequate_boundary_rope,
        floodlightsAdequateForDayNightGames:
          fetchResponse?.[0]?.field_floodlights,
        bomagRoller: fetchResponse?.[0]?.field_bomag_roller,
        status:
          fetchResponse?.[0]?.field_status === "Active" ? "Active" : "Disable",
      }
    : info?.step2 || {};
  const formik = useFormik({
    initialValues: {
      pitchType: data ? data?.pitchType : "",
      spectatorsCapacity: data ? data?.spectatorsCapacity : "",
      fieldName: data ? data?.fieldName : "",
      noOfPitches: data ? data?.noOfPitches : "",
      squareCoversAvailable: data ? data?.squareCoversAvailable : "",
      adequateBoundaryRope: data ? data?.adequateBoundaryRope : "",
      floodlightsAdequateForDayNightGames: data
        ? data?.floodlightsAdequateForDayNightGames
        : "",
      bomagRoller: data ? data?.bomagRoller : "",
      status: data ? data?.status : "",
      address: data ? data?.address : "",
      latitude: data ? data?.latitude : "",
      longitude: data ? data?.longitude : "",
    },
    validationSchema: FieldSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({ step1: values });
      const obj = {
        field_name: values.fieldName,
        adequate_boundary_rope:
          values.adequateBoundaryRope === "Yes" ? true : false,
        bomag_roller: values.bomagRoller === "Yes" ? true : false,
        floodlights:
          values.floodlightsAdequateForDayNightGames === "Yes" ? true : false,
        no_of_pitches: values.noOfPitches,
        square_covers_available:
          values.squareCoversAvailable === "Yes" ? true : false,
        spectators_capacity: values.spectatorsCapacity,
        pitch_type: values.pitchType,
        status: values?.status === "Active" ? true : false || false,
        address: values.address,
        latitude: values.latitude,
        longitude: values.longitude,
        member_name: member_name,
        user_level:
          authLevel === "member"
            ? "MEMBER"
            : authLevel === "csa"
            ? "CSA"
            : "SUB-MEMBER",
        member_id: authLevel === "csa" ? "" : memberId,
        uid: uid,
      };

      callAPI(
        id ? { ...obj, ...{ id: id }, ...{ uid: info?.editData?.uid } } : obj
      );
    },
  });
  useEffect(() => {
    if (response) {
      addToast(
        isEdit ? "Updated Successfully" : "Successfully Registered ",
        { appearance: "success" },
        () => navigate("/field-list")
      );
      resetForm();
    } else if (error) {
      addToast(error?.Message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);
  useEffect(() => {
    if (isEdit || isView) {
      fetch();
    }
  }, [isEdit, isView]);
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  const handleClear = () => {
    resetForm();
    navigate(-1);
  };
  const checkValidity = () => {
    const { fieldName, status } = values;
    const isVAlid = fieldName && status;
    return isVAlid ? false : true;
  };
  return (
    <>
      {fetchLoading ? (
        <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
          <Spin size="large" />
        </div>
      ) : isView ? (
        <FieldView viewData={fetchResponse?.[0]} />
      ) : (
        <>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3"
            align="Start"
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Field Name"
                placeholder="Enter Field Name"
                type="text"
                name="fieldName"
                onChange={handleChange}
                value={values.fieldName}
                required
                onBlur={handleBlur}
                touched={touched.fieldName}
                error={errors.fieldName || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Pitch Type"
                placeholder="Select Pitch Type"
                menuArr={masterData.pitchTypeList}
                onChange={(e) => {
                  setFieldValue("pitchType", e);
                }}
                name="pitchType"
                value={values.pitchType}
                defaultValue={values.pitchType}
                onBlur={handleBlur}
                touched={touched.pitchType}
                error={errors.pitchType || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="No of Pitches"
                placeholder="Enter No of Pitches"
                type="text"
                name="noOfPitches"
                onChange={handleChange}
                value={values.noOfPitches}
                onBlur={handleBlur}
                touched={touched.noOfPitches}
                error={errors.noOfPitches || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Square Covers Available"
                placeholder="Select Square Covers Available"
                name="squareCoversAvailable"
                value={values.squareCoversAvailable}
                menuArr={masterData.commonList}
                onChange={(e) => {
                  setFieldValue("squareCoversAvailable", e);
                }}
                onBlur={handleBlur}
                touched={touched.squareCoversAvailable}
                error={errors.squareCoversAvailable || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Adequate Boundary Rope"
                placeholder="Select Adequate Boundary Rope"
                name="adequateBoundaryRope"
                value={values.adequateBoundaryRope}
                menuArr={["Yes", "No"]}
                onChange={(e) => {
                  setFieldValue("adequateBoundaryRope", e);
                }}
                onBlur={handleBlur}
                touched={touched.adequateBoundaryRope}
                error={errors.adequateBoundaryRope || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Floodlights Adequate for Day/Night Matches"
                placeholder="Select Floodlights Adequate for Day/Night Matches"
                name="floodlightsAdequateForDayNightGames"
                value={values.floodlightsAdequateForDayNightGames}
                menuArr={["Yes", "No"]}
                onChange={(e) => {
                  setFieldValue("floodlightsAdequateForDayNightGames", e);
                }}
                onBlur={handleBlur}
                touched={touched.floodlightsAdequateForDayNightGames}
                error={errors.floodlightsAdequateForDayNightGames || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* <CsaTextInput
                label="Address"
                placeholder="Enter Address"
                type="text"
                name="address"
                onChange={handleChange}
                value={values.address}
                onBlur={handleBlur}
                touched={touched.address}
                error={errors.address || null}
              /> */}

              <GoogleplaceSearchInput
                label="Address"
                placeholder="Enter Address"
                value={values.address}
                latitude={values.latitude}
                longitude={values.longitude}
                AddLocation={(e, latLng) => updateLocation(e, latLng)}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Latitude"
                placeholder="Enter Latitude"
                type="text"
                name="latitude"
                onChange={handleChange}
                value={values.latitude}
                onBlur={handleBlur}
                touched={touched.latitude}
                error={errors.latitude || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Longitude"
                placeholder="Enter Longitude"
                type="text"
                name="longitude"
                onChange={handleChange}
                value={values.longitude}
                onBlur={handleBlur}
                touched={touched.longitude}
                error={errors.longitude || null}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Pitch Machine Roller"
                placeholder="Select Pitch Machine Roller"
                name="bomagRoller"
                value={values.bomagRoller}
                menuArr={["Yes", "No"]}
                onChange={(e) => {
                  setFieldValue("bomagRoller", e);
                }}
                onBlur={handleBlur}
                touched={touched.bomagRoller}
                error={errors.bomagRoller || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Spectators Capacity"
                placeholder="Enter Spectators Capacity"
                type="text"
                name="spectatorsCapacity"
                onChange={handleChange}
                value={values.spectatorsCapacity}
                onBlur={handleBlur}
                touched={touched.spectatorsCapacity}
                error={errors.spectatorsCapacity || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Status"
                placeholder="Select Status"
                required
                name="status"
                value={values.status}
                menuArr={masterData.statusList}
                onChange={(e) => {
                  setFieldValue("status", e);
                }}
                onBlur={() => setFieldTouched("status", true)}
                touched={touched.status}
                error={errors.status || null}
              />
            </Col>
            <div className="mt-4 d-flex w-100 justify-content-end">
              <Space>
                <CsaButton
                  label="Cancel"
                  textColor="#007237"
                  onClick={() => handleClear()}
                  btnColor="#FFCB03"
                />
                <CsaButton
                  label="Submit"
                  textColor="#FFF"
                  onClick={() => handleSubmit()}
                  btnColor="#007237"
                  size="large"
                  disabled={checkValidity()}
                  loading={loading}
                />
              </Space>
            </div>
          </Row>
        </>
      )}
    </>
  );
}

export default FieldRegisterForm;
