import React, { useEffect, useState } from "react";
import CsaButton from "Common/CsaButton";
import { Row, Col, Typography, Spin, Modal, Space } from "antd";
import nologo from "../../images/nologoclub.png";
import { useDispatch, useSelector } from "react-redux";
import batIcon from "../../images/crickbat.png";
import ballIcon from "../../images/Ball.svg";
import styles from "./Summary.module.scss";
import { useNavigate } from "react-router-dom";
import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import CsaTextInput from "Common/CsaTextInput";
import StreamingServiceModal from "./StreamingServiceModal";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";
import {
  capitalizeFirstLetter,
  findLastObj,
  fixSpecialChar,
} from "utils/helpers";
import ProviderLoginPopup from "./ProviderLoginPopup";
import {
  doc,
  getDoc,
  onSnapshot,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import firebaseDb from "firebase";

const streamingValidationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter a Valid Username"),
  pwd: Yup.string().required("Please Enter a valid Password"),
});

function Summary({ ...props }) {
  const [stremingData, setStreamingData] = useState({});
  const [showStreamingPopup, setShowStreamingPopup] = useState({
    login: false,
    url: false,
    key: null,
  });

  const {
    onchangecomponent,
    matchData,
    matchId,
    tName,
    banner,
    openState,
    inningsData,
    key,
  } = props;
  const navigate = useNavigate();
  const { Text, Link } = Typography;
  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues: {
      username: "",
      pwd: "",
    },
    validationSchema: streamingValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const {
    errors,
    setFieldTouched,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleReset,
    handleSubmit,
    handleBlur,
  } = formik;

  const data = matchData || [];

  //const summaryArr = matchData?.summary?.innings;
  // const team1Arr = summaryArr?.filter((i) => i.teamId === summaryArr[0].teamId);
  // const team2Arr = summaryArr?.filter((i) => i.teamId !== summaryArr[0].teamId);
  const inns = inningsData?.innings;
  const team1ArrA = inns?.filter(
    (i) => i?.battingTeamId === inns[0]?.battingTeamId
  );
  const team2ArrB = inns?.filter(
    (i) => i?.battingTeamId !== inns[0]?.battingTeamId
  );

  console.log(inns, team1ArrA, "mmm");

  const firstTeamLogo = data?.match_settings?.find(
    (e) => e?.value === team1ArrA?.[0]?.battingTeamId
  )?.data?.profile_url;

  const secondTeamLogo = data?.match_settings?.find(
    (e) => e?.value === team2ArrB?.[0]?.battingTeamId
  )?.data?.profile_url;
  // console.log(
  //   matchData,
  //   team1Arr,
  //   team2Arr,
  //   secondTeamLogo,
  //   firstTeamLogo,
  //   "matchData"
  // );
  function formatName(name) {
    const nameArray = name?.split(" ");
    const length = nameArray?.length;

    const createdName =
      (name?.length >= 17 &&
        length == 2 &&
        `${nameArray?.[0]?.charAt(0)} ${nameArray[1]}`) ||
      (name?.length < 17 &&
        length == 2 &&
        `${nameArray?.[0]} ${nameArray[1]}`) ||
      (name?.length >= 17 &&
        length == 3 &&
        `${nameArray?.[0]?.charAt(0)} ${nameArray?.[1]?.charAt(0)} ${
          nameArray[2]
        }`) ||
      (name?.length < 17 &&
        length == 3 &&
        `${nameArray?.[0]} ${nameArray?.[1]} ${nameArray[2]}`) ||
      (name?.length >= 17 &&
        length == 4 &&
        `${nameArray?.[0]?.charAt(0)} ${nameArray?.[1]?.charAt(
          0
        )} ${nameArray[2]?.charAt(0)} ${nameArray[3]}`) ||
      (name?.length < 17 &&
        length == 4 &&
        `${nameArray[0]} ${nameArray[1]} ${nameArray[2]} ${nameArray[1]} ${nameArray[3]}`) ||
      (name?.length < 17 && length == 1 && `${nameArray?.[0]}`) ||
      name;
    return createdName;
  }

  const handleNavigate = (data) => {
    navigate(`${data.id}`, {
      state: {
        //  page: "matchinfo",
        scId: data.id,
        mId: matchId,
        tournamentName: tName,
        banner: banner,
        openState: openState,
        currentData: matchData,
        isBack: false,
      },
    });
    onchangecomponent({
      //  page: "matchinfo",
      scId: data.id,
      mId: matchId,
      tournamentName: tName,
      banner: banner,
      openState: openState,
      currentData: matchData,
      isBack: false,
    });
  };

  // console.log(
  //   fixSpecialChar(team1Arr[0]?.teamName),
  //   fixSpecialChar(team2Arr[0]?.teamName),
  //   "data"
  // );

  const loadPage = false;
  return (
    <div
      key={key}
      className={
        styles.ScheduleMatchWrapper
        // : styles.ScheduleMatchWrapperyellowbg
      }
    >
      {loadPage ? (
        <Spin size="medium" />
      ) : (
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
            <Row className={styles.TeamSection1}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 19 }}
                className={styles.teamNameW}
              >
                <img
                  src={firstTeamLogo ? firstTeamLogo : nologo}
                  alt=""
                  className={styles.Teamimg}
                />
                <div className={styles.teamName}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {fixSpecialChar(team1ArrA?.[0]?.battingTeamName) || ""}
                  </Typography>
                  <div className={styles.scoresWrapper}>
                    {team1ArrA?.map((item, i) => {
                      return (
                        <Typography
                          key={i}
                          style={{
                            fontWeight: "600",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.teamSummary?.score || 0}/
                          {item?.teamSummary?.wickets || 0} -{" "}
                          {item?.teamSummary?.overs || 0}/
                          {item?.targetOvers || 0}
                        </Typography>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <div className={styles.teamDetailsWrapper}>
                <div className={styles.teamDetails}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px",
                      }}
                      src={batIcon}
                      alt=""
                    />
                    <Typography style={{ fontWeight: "bold" }}>
                      Batting
                    </Typography>
                  </div>
                  {findLastObj(team1ArrA)
                    ?.summaryBatting?.sort((a, b) => b.runs - a.runs) // Sort by runs in descending order
                    .slice(0, 3) // Take the top 3 run scorers
                    .map((i, k) => (
                      <div key={k} className={styles.BattingScore}>
                        <span>
                          <Typography
                            style={{ fontWeight: "600", whiteSpace: "pre" }}
                          >
                            {formatName(fixSpecialChar(i?.batsman?.name))}
                          </Typography>
                          <p>{i?.wicketType === "Not Out" ? " *" : ""}</p>
                        </span>
                        <Typography style={{ fontWeight: "600" }}>
                          {i?.runs + " (" + i?.balls + ")"}
                        </Typography>
                      </div>
                    ))}
                </div>

                <div className={styles.teamDetails2}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px",
                      }}
                      src={ballIcon}
                      alt=""
                    />
                    <Typography style={{ fontWeight: "bold" }}>
                      Bowling
                    </Typography>
                  </div>
                  {findLastObj(team1ArrA)
                    ?.summaryBowling?.sort((a, b) => b.wickets - a.wickets)
                    .slice(0, 3)
                    ?.filter((x) => x.overs !== "0.0")
                    ?.map((i, k) => {
                      return (
                        <div key={k} className={styles.BattingScore}>
                          <span>
                            <Typography
                              style={{ fontWeight: "600", whiteSpace: "pre" }}
                            >
                              {formatName(fixSpecialChar(i?.bowler?.name))}
                            </Typography>
                          </span>
                          <Typography style={{ fontWeight: "600" }}>
                            {i?.wickets + "/" + i?.runs + " (" + i?.overs + ")"}
                          </Typography>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
            <div className={styles.TeamSection2}>
              <div className={styles.vs}>
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "visible",
                  }}
                >
                  VS
                </Typography>
              </div>
              <div style={{ width: "100%" }}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  lg={{ span: 19 }}
                  className={styles.teamNameW}
                >
                  <img
                    src={secondTeamLogo ? secondTeamLogo : nologo}
                    alt=""
                    className={styles.Teamimg}
                  />
                  <div className={styles.teamName}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {fixSpecialChar(team2ArrB?.[0]?.battingTeamName) || ""}
                    </Typography>
                    <div className={styles.scoresWrapper}>
                      {team2ArrB?.map((item, i) => {
                        return (
                          <Typography
                            key={i}
                            style={{
                              fontWeight: "600",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item?.teamSummary?.score || 0}/
                            {item?.teamSummary?.wickets || 0} -{" "}
                            {item?.teamSummary?.overs || 0}/
                            {item?.targetOvers || 0}
                          </Typography>
                        );
                      })}
                    </div>
                  </div>
                </Col>
                <div className={styles.teamDetailsWrapper}>
                  <div className={styles.teamDetails}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "12px",
                          height: "12px",
                          marginRight: "5px",
                        }}
                        src={batIcon}
                        alt=""
                      />
                      <Typography style={{ fontWeight: "bold" }}>
                        Batting
                      </Typography>
                    </div>
                    {findLastObj(team2ArrB)
                      ?.summaryBatting?.sort((a, b) => b.runs - a.runs) // Sort by runs in descending order
                      .slice(0, 3) // Take the top 3 run scorers
                      .map((i, k) => (
                        <div key={k} className={styles.BattingScore}>
                          <span>
                            <Typography
                              style={{ fontWeight: "600", whiteSpace: "pre" }}
                            >
                              {formatName(fixSpecialChar(i?.batsman?.name))}
                            </Typography>
                            <p>{i?.wicketType === "Not Out" ? " *" : ""}</p>
                          </span>
                          <Typography style={{ fontWeight: "600" }}>
                            {i?.runs + " (" + i?.balls + ")"}
                          </Typography>
                        </div>
                      ))}
                  </div>

                  <div className={styles.teamDetails2}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "12px",
                          height: "12px",
                          marginRight: "5px",
                        }}
                        src={ballIcon}
                        alt=""
                      />
                      <Typography style={{ fontWeight: "bold" }}>
                        Bowling
                      </Typography>
                    </div>
                    {findLastObj(team2ArrB)
                      ?.summaryBowling?.sort((a, b) => b.wickets - a.wickets)
                      .slice(0, 3)
                      ?.filter((x) => x.overs !== "0.0")
                      ?.map((i, k) => {
                        return (
                          <div key={k} className={styles.BattingScore}>
                            <span>
                              <Typography
                                style={{ fontWeight: "600", whiteSpace: "pre" }}
                              >
                                {formatName(fixSpecialChar(i?.bowler?.name))}
                              </Typography>
                            </span>
                            <Typography style={{ fontWeight: "600" }}>
                              {i?.wickets +
                                "/" +
                                i?.runs +
                                " (" +
                                i?.overs +
                                ")"}
                            </Typography>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 4 }}>
            <Row
              className={styles.MatchInfoSection}
              style={{ position: "relative" }}
              //  className="d-flex w-100 justify-content-center "
            >
              <LinkOutlined
                style={{
                  position: "absolute",
                  padding: "5px",
                  backgroundColor: "#ffcb05",
                  top: "5px",
                  right: "5px",
                  borderRadius: "5px",
                  color: "#00793a",
                  cursor: "pointer",
                  zIndex: "100",
                }}
                onClick={
                  // () => callAPI(data?.id, true)
                  //  callStreamingApi
                  () =>
                    setShowStreamingPopup({
                      login: stremingData?.id ? false : true,
                      url: stremingData?.id ? true : false,
                      idx: key,
                    })
                }
              />
              {showStreamingPopup.idx === key && (
                <ProviderLoginPopup
                  showStreamingPopup={showStreamingPopup}
                  setShowStreamingPopup={(v) => setShowStreamingPopup(v)}
                  data={data?.id}
                  setStreamingData={(d) => setStreamingData(d)}
                  matchName={
                    fixSpecialChar(team1ArrA?.[0]?.battingTeamName) +
                    " Vs " +
                    fixSpecialChar(team2ArrB?.[0]?.battingTeamName)
                  }
                />
              )}

              <StreamingServiceModal
                streamingData={stremingData}
                showStreamingPopup={showStreamingPopup}
                setShowStreamingPopup={(v) => setShowStreamingPopup(v)}
              />

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 24 }}
                className={styles.MatchInfoText}
              >
                <Typography>
                  Format:&nbsp;&nbsp;
                  {data?.match_settings?.find(
                    (elem) => elem.key === "match_format"
                  )?.display_name || ""}
                </Typography>
                <Typography>
                  Time:&nbsp;&nbsp;
                  {data?.match_settings?.find(
                    (elem) => elem.key === "match_time"
                  )?.value || ""}
                </Typography>
                <Typography>
                  Venue:&nbsp;&nbsp;
                  {data?.match_settings?.find((elem) => elem.key === "venue")
                    ?.data?.field_name || ""}
                </Typography>
                <Typography>
                  Results:&nbsp;&nbsp;
                  {(data?.win_status &&
                    data.win_status?.replace(/&#039;/g, "'")) ||
                    capitalizeFirstLetter(data?.status) ||
                    ""}
                </Typography>
              </Col>
              <Col
                style={{
                  //  width: "100%",
                  alignSelf: "center",
                  justifySelf: "center",
                }}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 24 }}
                //  className="d-flex w-100 justify-content-center align-items-center"
              >
                <CsaButton
                  label="Match Info"
                  disabled={data?.status === "scheduled"}
                  textColor="#FFF"
                  btnColor="#007237"
                  onClick={() => handleNavigate(data)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Summary;
