import React, { useState } from "react";
//Components
import MyProfileForm from "../../Common/MyProfileForm";
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import styles from "./MyProfile.module.scss";

function MyProfile() {
 const [img, setImg] = useState([]);
 const [step, setStep] = useState(1);
 const [info, setInfo] = useState({ step1: {} });

 const props = {
  styles,
  setStep,
  info,
  setInfo,
  img,
  setImg,
 };

 const pathArr = [ { key: "Home", value: "/home" },
 { key: "My Profile", value: "/user-profile" },];

 return (
  <Wrapper>
   <div className={styles.MyProfileWrapper}>
    <CsaBreadcrumb pathArr={pathArr} />
    <div className={styles.contents}>
     <p className={styles.heading}>My Profile</p>

     <div className="px-5">{step === 1 && <MyProfileForm {...props} />}</div>
    </div>
   </div>
  </Wrapper>
 );
}

export default MyProfile;
