import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, AutoComplete, Input, Pagination } from "antd";

//Components
import Wrapper from "Layout/Wrapper";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import CsaButton from "Common/CsaButton";
import CsaMoreMenu from "Common/CsaMoreMenu";
import styles from "./Fields.module.scss";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import FieldsTablesubMember from "pages/FieldsTableSub-member";

const FieldsTable = () => {
  const authRole = localStorage.getItem("selRole");
  const userLevel = localStorage.getItem("csa_level");
  const uid = useSelector((state) => state.auth.login_as_uid);

  const memberId = localStorage.getItem("csa_member_id");
  const navigate = useNavigate();
  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getFieldDetails,
    method: "get",
  });
  useEffect(() => {
    userLevel === "member"
      ? callAPI(`level=${userLevel.toUpperCase()}&member_id=${memberId}`, true)
      : userLevel === "sub-member"
      ? callAPI(
          `level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI(`level=${userLevel.toUpperCase()}`, true);
  }, []);

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Facilities", value: "/field-list" },
  ];
  const columns = [
    {
      title: "Field Name",
      dataIndex: "field_name",
      key: "fieldName",
    },
    {
      title: "No of Pitches",
      dataIndex: "field_no_of_pitches",
      key: "noOfPitches",
    },
    {
      title: "Pitch Type",
      dataIndex: "field_pitch_type",
      key: "pitchType",
    },
    {
      title: "Spectators Capacity",
      dataIndex: "field_spectators_capacity",
      key: "spectatorsCapacity",
    },
    {
      title: "Status",
      dataIndex: "status1",
      key: "status1",
    },
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR"
      ? {
          title: "Action",
          key: "action",
          render: (item) => (
            <CsaMoreMenu
              handleView={() =>
                navigate("/view-field", {
                  state: { id: item?.id, isView: true },
                })
              }
              handleEdit={() =>
                navigate("/edit-field", {
                  state: { id: item?.id, isEdit: true },
                })
              }
            />
          ),
          // align: "right",
        }
      : {},
  ];
  const data = response?.rows
    // ?.filter((x) => x?.user_level === userLevel.toUpperCase())
    ?.map((item) => ({
      key: item?.id,
      status1: item?.field_status === "Active" ? "Activated" : "Disabled",
      ...item,
    }));
  const onSelect = (value) => {};

  const onChange = (page) => {
    userLevel === "member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&member_id=${memberId}&page=${
            page - 1
          }`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&uid=${uid}&page=${
            page - 1
          }&member_id=${memberId}`,
          true
        )
      : callAPI(
          `keyword=&level=${userLevel.toUpperCase()}&page=${page - 1}`,
          true
        );
  };
  const handleSearch = (e) => {
    userLevel === "member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&member_id=${memberId}`,
          true
        )
      : userLevel === "sub-member"
      ? callAPI(
          `keyword=${e}&level=${userLevel.toUpperCase()}&uid=${uid}&member_id=${memberId}`,
          true
        )
      : callAPI(`keyword=${e}&level=${userLevel.toUpperCase()}`, true);
  };
  return (
    <Wrapper>
      {userLevel === "sub-member" ? (
        <FieldsTablesubMember />
      ) : (
        <div className={styles.FieldsWrapper}>
          <CsaBreadcrumb pathArr={pathArr} />
          <div className={styles.contents}>
            <p className={styles.heading}>Fields</p>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
              className="mt-1 pt-1"
              align="start"
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <AutoComplete
                  style={{
                    width: "100%",
                    marginBottom: 10,
                  }}
                  // options={options}
                  onSelect={(val, option) => onSelect(val, option.label)}
                  onSearch={handleSearch}
                >
                  <Input.Search
                    size="large"
                    placeholder="Search..."
                    allowClear
                    enterButton
                  />
                </AutoComplete>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className=" d-flex w-100 justify-content-end">
                  {authRole === "MEMBERS ADMINISTRATOR" ||
                  authRole === "MEMBERS ADMIN ASSISTANT" ||
                  authRole === "SUB MEMBERS ADMINISTRATOR" ||
                  authRole === "SUB-MEMBERS ADMINISTRATOR" ? (
                    <CsaButton
                      label="Create"
                      textColor="#FFF"
                      onClick={() => navigate("/register-field")}
                      btnColor="#007237"
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="mt-2 pt-2">
              <Table
                className="center-aligned"
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{
                  x: 700,
                }}
              />
              <div className=" d-flex w-100 justify-content-end mt-5">
                <Pagination
                  onChange={onChange}
                  total={response?.pager?.total_items}
                  size={"small"}
                />
              </div>
            </div>
          </div>
        </div>  
      )}
    </Wrapper>
  );
};

export default FieldsTable;
