/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { Card, Col, Row, Avatar, Image, Table, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";

export default function TeamView(props) {
 const { viewData } = props;
 const columns = [
  {
   title: "Player Name:",
   key: "firstname",
   render: (item) => (
    <p>
     {item?.firstname} {item?.lastname}
    </p>
   ),
  },
  {
   title: "Batting Skill ",
   dataIndex: "battingSkill",
   key: "battingSkill",
  },
  {
   title: "Batting Hand",
   dataIndex: "battingHand",
   key: "battingHand",
  },
  {
   title: "Bowling Skill ",
   dataIndex: "bowlingSkill",
   key: "bowlingSkill",
  },
  {
   title: "Bowling Hand",
   dataIndex: "bowlingHand",
   key: "bowlingHand",
  },
 ];

 const dataTable = viewData?.players?.map((item) => ({
  key: item?.nid,
  firstname: item?.field_first_name,
  lastname: item?.field_last_name,
  battingSkill: item?.field_batting_skill ? item?.field_batting_skill : "Nil",
  battingHand: item?.field_batting_hand ? item?.field_batting_hand : "Nil",
  bowlingSkill: item?.field_bowl_skill ? item?.field_bowl_skill : "Nil",
  bowlingHand: item?.field_bowling_hand ? item?.field_bowling_hand : "Nil",
  role: item?.roles,
  ...item,
 }));
 const {
  callAPI: fetchDetail,
  response: success,
  error: err,
  loading: fetching,
 } = useAxios({
  url: serviceUrl.getPool + `${viewData?.id}` + "/pools",
  method: "get",
 });
 useEffect(() => {
  if (viewData?.id) {
   fetchDetail();
  }
 }, [viewData?.id]);
 console.group("pool", success);
 const compSettings = viewData?.competition_settings || null;
 const result = compSettings?.reduce(function (r, a) {
  r[a?.key] = r[a?.key] || [];
  r[a?.key].push(a);
  return r;
 }, Object.create(null));
 
 const settingArr = compSettings
  ? Object?.keys(result)?.map((item, i) => ({
     key: item,
     label: result[item]?.map((val) => val?.display_name),
     value: result[item]?.map((val) => val?.value),
     data: result[item]?.map((val) => (val?.data ? val?.data : [])),
    }))
  : [];

 
 function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
 }

 return (
  <div className="mx-2 px-2">
   <Card
    style={{
     width: "100%",
     backgroundColor: "#f1f1f1",
     justifyContent: "center",
     borderRadius: 15,
     borderWidth: 0.5,
     borderColor: "#f3f3f3",
     padding: 1,
    }}
   >
    <Row>
     {viewData && (
      <>
       <Col
        span={12}
        className="w-50 mb-4 d-flex flex-column justify-content-center"
       >
        {settingArr?.find((item) => item?.key === "competition_logo") ? (
         <Avatar
          size={150}
          src={
           <Image
            src={
             settingArr
              ? settingArr?.find((item) => item?.key === "competition_logo")
                 ?.value?.[0]
              : null
            }
            style={{
             width: 150,
             height: 150,
            }}
           />
          }
         />
        ) : (
         <Avatar size={150}>CSA </Avatar>
        )}
        <h6 className="mt-1 justify-content-center">Competition Logo</h6>
       </Col>
       <Col
        span={12}
        className="w-50 mb-4 d-flex flex-column justify-content-center"
       >
        {settingArr?.find((item) => item?.key === "sponsor_logo") ? (
         <Avatar
          size={150}
          src={
           <Image
            src={
             settingArr
              ? settingArr?.find((item) => item?.key === "sponsor_logo")
                 ?.value?.[0]
              : null
            }
            style={{
             width: 150,
             height: 150,
            }}
           />
          }
         />
        ) : (
         <Avatar size={150}>CSA </Avatar>
        )}
        <h6 className="mt-1 justify-content-center">Sponsor Banner</h6>
       </Col>
       <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
        <h6 className="mb-1">Competition Name</h6>
        <p>{viewData?.name}</p>
       </Col>
       <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
        <h6 className="mb-1">Start Date</h6>
        <p>{viewData?.start_date || "Nil"}</p>
       </Col>
       <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
        <h6 className="mb-1">End Date</h6>
        <p>{viewData?.end_date || "Nil"}</p>
       </Col>
       {settingArr?.map((item, i) =>
        item?.key === "competition_logo" ||
        item?.key === "sponsor_logo" ? null : item?.key ===
          "cms_host_member_list" ? (
         <Col key={i} sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
          <h6 className="mb-1">
           {item?.key
            ?.split("_")
            ?.map((p) => capitalizeFirstLetter(p))
            ?.join(" ")}
          </h6>

          <p>
           {item?.data?.map(
            (p, i) => `${p.name?.replace(/&#039;/g, "'")}${item?.data?.length - 1 === i ? " " : ", "}`
           )}
          </p>
         </Col>
        ) : item?.key === "cms_participating_member_List" ? (
         <Col key={i} sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
          <h6 className="mb-1">
           {item?.key
            ?.split("_")
            ?.map((p) => capitalizeFirstLetter(p))
            ?.join(" ")}
          </h6>

          <p>
           {item?.data?.map(
            (p, i) => `${p.name?.replace(/&#039;/g, "'")}${item?.data?.length - 1 === i ? " " : ", "}`
           )}
          </p>
         </Col>
        ) : item?.key === "venue_list" ? (
         <Col key={i} sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
          <h6 className="mb-1">
           {item?.key
            ?.split("_")
            ?.map((p) => capitalizeFirstLetter(p))
            ?.join(" ")}
          </h6>

          <p>
           {item?.data?.map(
            (p, i) => `${p.field}${item?.data?.length - 1 === i ? " " : ", "}`
           )}
          </p>
         </Col>
        ) : item?.key === "scorer_list_cms" ? (
         <Col key={i} sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
          <h6 className="mb-1">
           {item?.key
            ?.split("_")
            ?.map((p) => capitalizeFirstLetter(p))
            ?.join(" ")}
          </h6>

          <p>
           {item?.data?.map(
            (p, i) =>
             `${p.firstname?.replace(/&#039;/g, "'")} ${p.lastname?.replace(/&#039;/g, "'")}${
              item?.data?.length - 1 === i ? " " : ", "
             }`
           )}
          </p>
         </Col>
        ) : item?.key === "umpire_cms_list" ? (
         <Col key={i} sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
          <h6 className="mb-1">
           {item?.key
            ?.split("_")
            ?.map((p) => capitalizeFirstLetter(p))
            ?.join(" ")}
          </h6>

          <p>
           {item?.data?.map(
            (p, i) =>
             `${p.firstname} ${p.lastname}${
              item?.data?.length - 1 === i ? " " : ", "
             }`
           )}
          </p>
         </Col>
        ) : item?.key === "team_list_CMS" ? (
         <Col key={i} sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
          <h6 className="mb-1">
           {item?.key
            ?.split("_")
            ?.map((p) => capitalizeFirstLetter(p))
            ?.join(" ")}
          </h6>

          <p>
           {item?.data?.map(
            (p, i) =>
             `${p.teamName?.replace(/&#039;/g, "'")}${item?.data?.length - 1 === i ? " " : ", "}`
           )}
          </p>
         </Col>
        ) : (
         <Col key={i} sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
          <h6 className="mb-1">
           {item?.key
            ?.split("_")
            ?.map((p) => capitalizeFirstLetter(p))
            ?.join(" ")}
          </h6>

          <p>
           {item?.label?.map(
            (p, i) => `${p}${item?.label?.length - 1 === i ? " " : ", "}`
           )}
          </p>
         </Col>
        )
       )}
       {success?.data?.map((p1, j) => (
        <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3" key={j}>
         <h6 className="mb-1">Pool : {p1?.name}</h6>

         <p key={j}>{p1?.teams?.map(
           (p, i) => `${p?.teamName?.replace(/&#039;/g, "'")}${p1?.teams?.length - 1 === i ? " " : ", "}`)}</p>
        </Col>
       ))}

       {/* <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Gender</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "gender"
                )?.display_name || "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Differently Abled</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "differently_abled"
                )?.display_name || "Nil"}
              </p>
            </Col>

            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Match Format</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "match_format"
                )?.display_name || "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Number Of Pools</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "number_of_pools"
                )?.display_name
                  ? viewData?.competition_settings?.find(
                      (elem) => elem?.key === "number_of_pools"
                    )?.display_name
                  : "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Number Of Teams Per Pool</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "number_of_teams_per_Pool"
                )?.display_name
                  ? viewData?.competition_settings?.find(
                      (elem) => elem?.key === "number_of_teams_per_Pool"
                    )?.display_name
                  : "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Number of Matches Vs Each Team In Pool</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) =>
                    elem?.key === "number_of_matches_vs_each_team_in_pool"
                )?.display_name
                  ? viewData?.competition_settings?.find(
                      (elem) =>
                        elem?.key === "number_of_matches_vs_each_team_in_pool"
                    )?.display_name
                  : "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Overs Per Innings</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "overs_per_innings"
                )?.display_name
                  ? viewData?.competition_settings?.find(
                      (elem) => elem?.key === "overs_per_innings"
                    )?.display_name
                  : "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Innings Per Team</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "innings_per_team"
                )?.display_name
                  ? viewData?.competition_settings?.find(
                      (elem) => elem?.key === "innings_per_team"
                    )?.display_name
                  : "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Ball Color</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "ball_colour"
                )?.display_name
                  ? viewData?.competition_settings?.find(
                      (elem) => elem?.key === "ball_colour"
                    )?.display_name
                  : "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Scoring Type</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "scoring_type"
                )?.display_name || "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Analysis Options</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "analysis_options"
                )?.display_name || "Nil"}
              </p>
            </Col>
            <Col sm={24} xs={24} md={12} lg={8} xl={8} className="mb-3">
              <h6 className="mb-1">Finals</h6>
              <p>
                {viewData?.competition_settings?.find(
                  (elem) => elem?.key === "finals"
                )?.display_name || "Nil"}
              </p>
            </Col> */}
      </>
     )}
    </Row>

    {/* <h6 className="mb-1">Team Players:</h6> */}
   </Card>
  </div>
 );
}
