import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Col, Row, Space } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
//Components
import styles from "./CsaLoginForm.module.scss";
import CsaTextInput from "Common/CsaTextInput";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useDispatch, useSelector } from "react-redux";
import { isLogin, setCSALevel, setCSARole } from "redux/slice/commonSlice";

const PasswordSchema = Yup.object().shape({
 password: Yup.string()
  .matches(
   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}/,
   "Must Contain eight Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  )
  .required("Enter new password"),
 conPassword: Yup.string()
  .required("Re Enter Password")
  .oneOf([Yup.ref("password"), null], "Incorrect match with new password"),
});

function CsaRecoverPasswordForm() {
  const isAuth = useSelector ((state) => state.auth.login);
 const location = useLocation();
 const dispatch = useDispatch()
 const tempPassword = location.search.split("unique_key=")[1]
  ? location.search.split("unique_key=")[1].split("&")[0]
  : "myDefaultValue";
 const email = location.search.split("unique_key=")[1]
  ? location.search.split("id=")[1]
  : "myDefaultValue";
 
 const { addToast } = useToasts();
 const navigate = useNavigate();

 const handleClear = () => {
  resetForm();
  navigate("/");
 };
const hasTempPaswd = location.search !==''
 

 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.resetPassword,
  method: "post",
 });
 const { callAPI:logOutApi, response:resLougout, error:errLogout, loading:loadingLogout } = useAxios({
  url: serviceUrl.logout + `${localStorage.getItem("csa_logout_token")}`,
  method: "post",
 });

useEffect(()=> {
  if(hasTempPaswd && isAuth){
    dispatch(isLogin(false));
  dispatch(setCSALevel (null));
  dispatch(setCSARole (null));
  logOutApi();
  localStorage.clear();
  }
},[])

 useEffect(() => {
  if (response) {
   localStorage.clear();
   navigate("/login");
  } else if (error) {
   addToast(error?.message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [resLougout, errLogout]);

 const formik = useFormik({
  initialValues: {
   password: "",
   conPassword: "",
  },
  validationSchema: PasswordSchema,
  onSubmit: (values) => {
   const obj = {
    name: email,
    temp_pass: tempPassword,
    new_pass: values.password,
   };
   callAPI(obj);
  },
 });
 useEffect(() => {
  if (response) {
   addToast("Updated Successfully", { appearance: "success" }, () =>
    navigate("/")
   );
   resetForm();
  } else if (error) {
   addToast(error?.message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [response, error]);
 const {
  handleChange,
  errors,
  values,
  handleBlur,
  handleSubmit,
  touched,
  resetForm,
 } = formik;
 const checkValidity = () => {
  const { conPassword, password } = values;
  const isVAlid = conPassword && password && Object.keys(errors).length === 0;
  return isVAlid ? false : true;
 };



 return (
  <div className={styles.CsaLoginFormWrapper}>
   <Row justify="center" align="middle">
    <Col span={24}>
     <h4 className="text-center text-white">Set New Password</h4>
    </Col>
   </Row>
   <Row justify="start" align="middle" className="mt-3">
    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
     <CsaTextInput
      label="New Password"
      placeholder="Enter New password"
      type="password"
      name="password"
      labelColor
      onChange={handleChange}
      value={values.password}
      required
      onBlur={handleBlur}
      touched={touched.password}
      error={errors.password}
     />
    </Col>
    <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
     <CsaTextInput
      label="Confirm New Password"
      placeholder="Enter Confirm Password"
      type="password"
      name="conPassword"
      labelColor
      onChange={handleChange}
      value={values.conPassword}
      required
      onBlur={handleBlur}
      touched={touched.conPassword}
      error={errors.conPassword}
     />
    </Col>
    <div className="mt-4 d-flex w-100 justify-content-end">
     <Space>
      <CsaButton
       label="Cancel"
       textColor="#007237"
       onClick={() => handleClear()}
       btnColor="#FFCB03"
      />
      <CsaButton
       label="Submit"
       textColor="#FFF"
       onClick={() => handleSubmit()}
       btnColor="#007237"
       size="large"
       disabled={checkValidity()}
      />
     </Space>
    </div>
   </Row>
  </div>
 );
}

export default CsaRecoverPasswordForm;
