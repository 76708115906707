import styles from "./Schedule.module.scss";
import React, { useEffect, useState } from "react";
import CsaButton from "Common/CsaButton";
import ScheduleTable from "Common/ScheduleTable";
import StandingsTable from "pages/Standings";
// import Wrapper from "Layout/Wrapper";
import { Tabs, Spin, Card } from "antd";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";

import useAxios from "./../../utils/axios";
import { serviceUrl } from "services/serviceUrl";
import Venues from "pages/Venues";
import Emptyimg from "../../images/noBanner.png";
import TournamentBattingStatsTable from "pages/TournamentStats/TournamentBattingStatsTable";
import TournamentBowlingStatsTable from "pages/TournamentStats/TournamentBowlingStats";
import TournamentFieldingStatsTable from "pages/TournamentStats/TournamentFieldingStats";
import CsaCards from "Common/CsaCards";
import CompetitionLiveCards from "pages/CompetitionLiveCards";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  setActiveTabSchedule,
  setIsBack,
  setOpenState,
  setScheduleData,
} from "redux/slice/commonSlice";

function Schedule({ ...props }) {
  const isback = useSelector((state) => state.auth.isBack);
  const defaultActiveTAb = useSelector((state) => state.auth.activeTabSchedule);

  const { addToast } = useToasts();
  const [tabNum, settabNum] = useState(isback ? defaultActiveTAb : "1");
  // const { onchangecomponent, matchId, banner, tName } = props;
  const { TabPane } = Tabs;
  //
  const { propsData } = useOutletContext();
  const { onchangecomponent, matchId, banner, tName } = propsData;
  const match_details = useSelector((state) => state.auth.match_details);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const params = useParams();

  const onKeyChange = (key) => {
    dispatch(setIsBack(false));
    dispatch(setScheduleData(null));
    dispatch(setOpenState(-1));
    dispatch(setActiveTabSchedule(key));
    settabNum(key);
  };

  return (
    <>
      <div className={styles.ScheduleHeader}>
        <p className={styles.heading}>
          {(state?.tournamentName && state?.tournamentName) || ""}
        </p>
        <CsaButton
          label="Back"
          textColor="#FFF"
          btnColor="#007237"
          onClick={() => navigate(-1)}
          //  onClick={() => onchangecomponent({ page: "" })}
        />
      </div>
      <>
        <Card
          className="image_only_card"
          style={{
            width: "100%",
          }}
          cover={<img alt="csa" src={state?.banner || Emptyimg} />}
        ></Card>
        {/* <div className={styles.TournamentBanner}>
          {state?.banner ? (
            <img src={state?.banner} alt="Tournament Banner" />
          ) : (
            <div>
              <img
                className={styles.Emptyimage}
                src={Emptyimg}
                alt="Emptyimg Banner"
              />
            </div>
          )}
        </div> */}
        <div className={styles.TournamentsData}>
          <Tabs
            type="card"
            // defaultActiveKey="1"
            activeKey={tabNum}
            onChange={onKeyChange}
          >
            <TabPane tab="Schedule" key="1">
              <div style={{ marginTop: "-5px" }}>
                <ScheduleTable
                  onchangecomponent={onchangecomponent}
                  matchId={params?.id}
                  tName={tName}
                  banner={banner}
                />
              </div>
              {/* );
                })} */}
            </TabPane>
            <TabPane tab="Live Matches" key="7">
              <CompetitionLiveCards
                activeTab={tabNum}
                matchId={params?.id}
                hideTitle={true}
                onchangecomponent={onchangecomponent}
              />
            </TabPane>

            <TabPane tab="Standings" key="2">
              <StandingsTable matchId={params?.id} />
            </TabPane>
            <TabPane tab="Venues" key="3">
              <Venues matchId={params?.id} />
            </TabPane>
            <TabPane tab="Batting Stats" key="4">
              <TournamentBattingStatsTable matchId={params?.id} />
            </TabPane>
            <TabPane tab="Bowling Stats" key="5">
              <TournamentBowlingStatsTable matchId={params?.id} />
            </TabPane>
            <TabPane tab="Fielding Stats" key="6">
              <TournamentFieldingStatsTable matchId={params?.id} />
            </TabPane>
          </Tabs>
        </div>
      </>
    </>
  );
}
export default Schedule;
