import { Button, Modal, Table } from "antd";
import React, { useState } from "react";
import styles from "./BowlingOverlay.module.scss";
import nologo from "../../images/nologoclub.png";
import { comnCellStyles, fixImage, getShortenedName } from "utils/helpers";
import OverlayModal from "Common/OverlaysModal/OverlayModal";

const BowlingOverlay = ({ innings, loading, matchData }) => {
  const batTeamArr = innings;

  const batTeamName = batTeamArr?.battingTeamName;
  const bowlTeamName = batTeamArr?.bowlingTeamName;
  const currentScore = Number(batTeamArr?.teamSummary?.score);
  const currentWickets = batTeamArr?.teamSummary?.wickets;
  const currentOvers = batTeamArr?.teamSummary?.overs;

  const batTeamLogo = matchData?.teams?.find(
    (i) => i.teamId === batTeamArr?.battingTeamId
  )?.teamLogo;
  const bowlTeamLogo = matchData?.teams?.find(
    (i) => i.teamId !== batTeamArr?.battingTeamId
  )?.teamLogo;

  const teamSummary = batTeamArr?.teamSummary;

  const columns = [
    {
      title: "BOWLER",
      dataIndex: "bowler",
      key: "bowler",
      width: "35%",
      align: "left",
      render(text, record) {
        return {
          props: {
            style: {
              ...comnCellStyles,
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    { title: "OVERS", dataIndex: "overs", key: "overs" },
    { title: "MAIDENS", dataIndex: "maidens", key: "maidens" },
    { title: "RUNS", dataIndex: "runs", key: "runs" },
    { title: "WICKETS", dataIndex: "wickets", key: "wickets" },
    {
      title: "ECONOMY",
      dataIndex: "eco",
      key: "eco",
      render(text, record) {
        return {
          props: {
            style: {
              ...comnCellStyles,
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const bowlSummaryArr = batTeamArr?.summaryBowling
    ?.filter((x) => x.overs !== "0.0")
    .map((i) => ({
      bowler: getShortenedName(i?.bowler?.name?.toUpperCase(), 17),
      overs: i?.overs,
      runs: i?.runs,
      maidens: i?.maidens,
      wickets: i?.wickets,
      eco: Number(i?.economyRate)?.toFixed(2),
    }));

  function setFontsizeByChar(v, n) {
    let x;
    const M = n ? n : 15;
    if (v <= M) {
      x = 2;
    } else {
      const numberOfDecrements = Math.floor((v - M) / 1);
      x = 2 - numberOfDecrements * 0.1;
    }
    console.log(v, x, "lll");
    return `${x}vw`;
  }

  return (
    <OverlayModal>
      <div className={styles.mainWrapper}>
        {batTeamArr && (
          <div className={styles.teamNameWrapper}>
            <div>
              <h3
                style={{
                  fontSize: setFontsizeByChar(batTeamName?.length, 15),
                  width: "80%",
                }}
              >
                {batTeamName?.toUpperCase()}
              </h3>
              <h3 className={styles.scoreText}>
                {currentScore}/{currentWickets}
              </h3>
            </div>
            <span>
              <p>vs</p>{" "}
              <h3
                style={{
                  fontSize: setFontsizeByChar(bowlTeamName?.length, 15),
                  maxWidth: "90%",
                }}
              >
                {bowlTeamName?.toUpperCase()}
              </h3>
            </span>
          </div>
        )}

        <div className={styles.logoTableContainer}>
          <img
            className={styles.teamLogo}
            src={fixImage(batTeamLogo)}
            alt="Team Logo 2"
          />
          <div className={styles.tableWrapper}>
            <Table
              className="overlay-table"
              columns={columns}
              dataSource={bowlSummaryArr}
              pagination={false}
              size={"small"}
            />
            {batTeamArr && (
              <div className={styles.extrasRow}>
                <p>OVERS: {currentOvers}</p>{" "}
                <p>
                  EXTRAS: {teamSummary?.extras} ({teamSummary?.wides} WD,{" "}
                  {teamSummary?.legByes} LB, {teamSummary?.byes} B,{" "}
                  {teamSummary?.noBalls} NB)
                </p>
              </div>
            )}
          </div>
          <img
            className={styles.teamLogo}
            src={fixImage(bowlTeamLogo)}
            alt="Team Logo 2"
          />
        </div>
      </div>
    </OverlayModal>
  );
};

export default BowlingOverlay;
