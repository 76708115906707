import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList,
  Label,
  Legend,
} from "recharts";

const CsaChart = ({ data, dataKeys, maxRange, width }) => {
  console.log(data, "ddd");
  console.log("Max Number:", dataKeys, data);

  const ar1 = [
    { key1: 10, key2: 25, key3: 15 },
    { key1: 5, key2: 1, key3: 20 },
    // ... more objects
  ];

  // Use flatMap to extract all numeric values into a single array
  const allNumericValues = data?.flatMap((obj) =>
    Object.values(obj).filter((value) => typeof value === "number")
  );

  // Find the maximum numeric value using Math.max
  const maxNumericValue = Math.max(...allNumericValues);
  // const maxNumericValue = 250;

  const roundedMax =
    (maxNumericValue < 100 && 100) ||
    (maxNumericValue < 250 && 250) ||
    (maxNumericValue < 500 && 500) ||
    (maxNumericValue < 750 && 750) ||
    (maxNumericValue < 1000 && 1000) ||
    (maxNumericValue < 1500 && 1500) ||
    (maxNumericValue < 2500 && 2500) ||
    (maxNumericValue < 4000 && 4000) ||
    (maxNumericValue < 6000 && 6000) ||
    (maxNumericValue < 8000 && 8000) ||
    (maxNumericValue < 10000 && 10000);
  console.log("The highest numeric value:", roundedMax, maxNumericValue);

  // const findMaxValue = (keys, values) => {
  //   let maxValue = Number.MIN_VALUE;

  //   values.forEach((obj) => {
  //     const hasSameKeys = keys.every((key) => key in obj);
  //     if (hasSameKeys) {
  //       keys.forEach((key) => {
  //         if (obj[key] > maxValue) {
  //           maxValue = obj[key];
  //         }
  //       });
  //     }
  //   });

  //   return maxValue;
  // };

  // const maxNumber = findMaxValue(dataKeys, data);

  return (
    <BarChart
      margin={{ top: 20 }}
      width={width || 700}
      height={300}
      data={data}
    >
      {dataKeys?.map((i) => {
        return (
          <Bar barSize={30} dataKey={i?.label} fill={i?.color}>
            <LabelList fill="gray" dataKey={i?.label} position="top" />
          </Bar>
        );
      })}

      {/* <Bar barSize={30} dataKey={dataKeys?.[1]} fill="pink">
        <LabelList dataKey={dataKeys?.[1]} position="top" />
      </Bar> */}
      <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
      <XAxis dataKey="name" interval={0} />
      <YAxis domain={[0, maxRange || roundedMax]} />
    </BarChart>
  );
};

export default CsaChart;
