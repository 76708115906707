import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Col, Row, Space } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
//Components
import CsaTextInput from "Common/CsaTextInput";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";

const PasswordSchema = Yup.object().shape({
 currentPassword: Yup.string().required("Enter Current password"),
 password: Yup.string()
  .matches(
   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}/,
   "Must Contain eight Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  )
  .required("Enter new password"),
 conPassword: Yup.string()
  .required("Re Enter Password")
  .oneOf([Yup.ref("password"), null], "Incorrect match with new password"),
});

function CsaChangePasswordForm() {
 const uid = useSelector((state) => state.auth.login_as_uid);
 const { addToast } = useToasts();
 const navigate = useNavigate();

 const handleClear = () => {
  resetForm();
  navigate("/home");
 };

 const { callAPI, response, error, loading } = useAxios({
  url: serviceUrl.changePassword + `${uid}`,
  method: "patch",
 });
 const formik = useFormik({
  initialValues: {
   currentPassword: "",
   password: "",
   conPassword: "",
  },
  validationSchema: PasswordSchema,
  onSubmit: (values) => {
   const obj = {
    // name: userName,
    // temp_pass: values.currentPassword,
    // new_pass: values.password,
    pass: [
     {
      existing: values.currentPassword,
      value: values.password,
     },
    ],
   };
   callAPI(obj);
  },
 });
 useEffect(() => {
  if (response) {
   addToast("Updated Successfully", { appearance: "success" }, () =>
    navigate("/home")
   );
   resetForm();
  } else if (error) {
   addToast(error?.message || "Something went wrong", {
    appearance: "error",
   });
  }
 }, [response, error]);
 const {
  handleChange,
  errors,
  values,
  handleBlur,
  handleSubmit,
  touched,
  resetForm,
 } = formik;
 const checkValidity = () => {
  const { conPassword, currentPassword, password } = values;
  const isVAlid =
   conPassword &&
   currentPassword &&
   password &&
   Object.keys(errors).length === 0;
  return isVAlid ? false : true;
 };
 return (
  <div>
   <Row
    gutter={{
     xs: 8,
     sm: 16,
     md: 24,
     lg: 32,
    }}
    className="mt-3 pt-3"
    align="Start"
   >
    <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="Current Password"
      placeholder="Enter Current Password"
      name="currentPassword"
      type="password"
      onChange={handleChange}
      value={values.currentPassword}
      required
      onBlur={handleBlur}
      touched={touched.currentPassword}
      error={errors.currentPassword}
     />
    </Col>
    <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="New Password"
      placeholder="Enter New password"
      type="password"
      name="password"
      onChange={handleChange}
      value={values.password}
      required
      onBlur={handleBlur}
      touched={touched.password}
      error={errors.password}
     />
    </Col>
    <Col span={24} xs={24} sm={24} md={12} lg={12} xl={12}>
     <CsaTextInput
      label="Confirm New Password"
      placeholder="Enter Confirm Password"
      type="password"
      name="conPassword"
      onChange={handleChange}
      value={values.conPassword}
      required
      onBlur={handleBlur}
      touched={touched.conPassword}
      error={errors.conPassword}
     />
    </Col>
    <div className="mt-4 d-flex w-100 justify-content-end">
     <Space>
      <CsaButton
       label="Cancel"
       textColor="#007237"
       onClick={() => handleClear()}
       btnColor="#FFCB03"
      />
      <CsaButton
       label="Update"
       textColor="#FFF"
       onClick={() => handleSubmit()}
       btnColor="#007237"
       size="large"
       disabled={checkValidity()}
       loading={loading}
      />
     </Space>
    </div>
   </Row>
  </div>
 );
}

export default CsaChangePasswordForm;
