import styles from "./NotFound.module.scss";
import React from "react";
import Wrapper from "Layout/Wrapper";
import { Result, Button } from "antd";

function NotFound() {
  return (
    <Wrapper hide={true}>
      <div className={styles.NotFoundWrapper}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button type="primary">Back Home</Button>}
        />
      </div>
    </Wrapper>
  );
}

export default NotFound;
