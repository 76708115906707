import { getSortValue } from "./helpers";

export const genderList = getSortValue(["Male", "Female"]);
export const genderListTeam = getSortValue(["Male", "Female", "Mixed"]);
export const statusList = getSortValue(["Active", "Disable"]);
export const playerStatusList = getSortValue(["Assigned", "Release"]);
export const commonList = getSortValue(["Yes", "No"]);
export const kfcMiniCricketList = ["Yes", "No", "Other"];
export const raceList = getSortValue([
  "African",
  "White",
  "Indian",
  "Coloured",
]);

export const memberList = [
  { value: "Boland", label: "Boland" },
  { value: "Border", label: "Border" },
  { value: "Central Gauteng Lions", label: "Central Gauteng Lions" },
  { value: "Cricket South Africa", label: "Cricket South Africa" },
  { value: "Eastern Province", label: "Eastern Province" },
  { value: "Easterns", label: "Easterns" },
  { value: "Free State", label: "Free State" },
  { value: "Kei", label: "Kei" },
  { value: "KwaZulu-Natal Coastal", label: "KwaZulu-Natal Coastal" },
  { value: "KwaZulu-Natal Inland", label: "KwaZulu-Natal Inland" },
  { value: "Mpumalanga", label: "Mpumalanga" },
  { value: "North West", label: "North West" },
  { value: "Northern Cape", label: "Northern Cape" },
  { value: "Northerns", label: "Northerns" },
  { value: "South Western Districts", label: "South Western Districts" },
  { value: "Titans", label: "Titans" },
  { value: "Western Province", label: "Western Province" },
];
export const ageGroupArr = [
  "U4",
  "U5",
  "U6",
  "U7",
  "U8",
  "U9",
  "U10",
  "U11",
  "U12",
  "U13",
  "U14",
  "U15",
  "U16",
  "U17",
  "U18",
  "U19",
  "Senior",
  "Veterans",
];
export const csaLevel = getSortValue([
  "Cubs",
  "U19 National",
  "SA Emerging",
  "SA A",
  "Proteas",
  "International",
]);

export const memberLevel = getSortValue([
  "Regional Representative",
  "Member Representative",
  "Colts",
  "Academy",
  "Professional (Div 1 and 2",
  "Hub/RPC",
]);

export const subMemberLevel = getSortValue([
  "Mini-Cricket",
  "Primary School",
  "High School",
  "Club",
  "Ancillary Member",
]);

export const battingSkillPlayer = getSortValue([
  "Top Order",
  "WK Bat",
  "Middle Order",
  "Bat All Rounder",
  "Bowl All Rounder",
  "Lower Order",
]);
export const battingHandPlayer = getSortValue(["Right Hand", "Left Hand"]);
export const bowlingSkillPlayer = getSortValue([
  "Fast",
  "Medium Pace",
  "Orthodox",
  "Unorthodox",
  "Part-time",
  "Non-bowler",
  "WK",
]);
export const bowlingHandPlayer = getSortValue(["Right Arm", "Left Arm"]);
export const sectorList = getSortValue(["Public", "Independent"]);
export const settlementTypeList = getSortValue([
  "Farm",
  "Suburban",
  "Township (Urban)",
  "Township (Rural)",
]);
export const schoolTypeList = getSortValue([
  "Combined School",
  "High School",
  "Intermediate School",
  "Primary School",
  "School of Skills",
  "Secondary School",
  "Special Skills",
  "Specialized School",
]);
export const provinceList = getSortValue([
  "Eastern Cape",
  "Free State",
  "Gauteng",
  "KwaZulu-Natal",
  "Limpopo",
  "Mpumalanga",
  "Northern Cape",
  "North West",
  "Western Cape",
]);
export const pitchTypeList = getSortValue(["Turf", "Artificial"]);
export const highestLevelOfficiatedList = getSortValue([
  "Mini-Cricket",
  "Primary School",
  "High School",
  "Club",
  "Provincial",
  "First-Class",
  "International",
]);

export const optionsCsa = [
  { value: "csa_admin_assistant", label: "CSA Admin Assistant" },
  { value: "csa_coaches_manager", label: "CSA Coaches Manager" },
  { value: "csa_umpires_manager", label: "CSA Umpires Manager" },
  { value: "csa_match_referee_manager", label: "CSA Match Referee Manager" },
  { value: "csa_view_only", label: "CSA View Only" },
];

export const optionsMember = [
  //  { value: "members_administrator", label: "Members Administrator" },
  { value: "members_admin_assistant", label: "Members Admin Assistant" },
  { value: "members_coaches_manager", label: "Members Coaches Manager" },
  { value: "members_umpires_manager", label: "Members Umpires Manager" },
  { value: "members_scorers_manager", label: "Members Scorers Manager" },
  {
    value: "members_primary_school_coordinator",
    label: "Members Primary School Coordinator",
  },
  {
    value: "members_high_school_coordinator",
    label: "Members High School Coordinator",
  },
  { value: "member_club_coordinator", label: "Member Club Coordinator" },
  {
    value: "member_ancillary_administrator",
    label: "Member Ancillary Administrator",
  },
  { value: "members_view_only", label: "Members View Only" },
];
export const optionsSubMember = [
  { value: "sub_members_view_only", label: "Sub-Members View Only" },
];
export const pHASE_PED = getSortValue([
  "Combined School",
  "Intermediate School",
  "Primary School",
  "Secondary School",
  "Special Needs Education Centre",
  "Specialized School",
]);
export const differentlyAbled = [
  "None",
  "Cognitive/Learning",
  "Head Injuries (TBI)",
  "Hearing",
  "Invisible",
  "Mobility/Physical",
  "Psychological",
  "Spinal Cord (SCI)",
  "Vision - B1",
  "Vision - B2",
  "Vision - B3",
];
export const optionsTeamLevelSubMember = getSortValue([
  "Mini-Cricket",
  "Primary School",
  "High School",
  "Hub/RPC",
  "Club",
  "Ancillary Member ",
]);

export const optionsTeamLevelMember = getSortValue([
  "Hub/RPC",
  "Regional Representative",
  "Member Representative",
  "Colts",
  "Academy",
  "Professional (Div 1 and 2)",
]);

export const optionsTeamLevelCsa = getSortValue([
  "Cubs",
  "U19 National",
  "SA Emerging",
  "SA A",
  "Proteas",
  "International",
]);
export const nationality = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  {
    value: "Democratic Republic of the Congo",
    label: "Democratic Republic of the Congo",
  },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Holy See", label: "Holy See" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar (formerly Burma)", label: "Myanmar (formerly Burma)" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Korea", label: "North Korea" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine State", label: "Palestine State" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Korea", label: "South Korea" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States of America", label: "United States of America" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];
export const optionsMunicipality = [
  {
    key: "Eastern Cape",
    value: [
      "Alfred Nzo",
      "Buffalo City",
      "Chris Hani",
      "Joe Gqabi",
      "O.R.Tambo",
      "Amathole",
      "Nelson Mandela Bay",
      "Sarah Baartman",
    ],
  },
  {
    key: "Gauteng",
    value: [
      "City of Johannesburg",
      "Sedibeng",
      "West Rand District",
      "City of Tshwane",
      "Ekurhuleni",
    ],
  },
  {
    key: "Free State",
    value: [
      "Fezile Dabi",
      "Lejweleputswa",
      "Mangaung",
      "Thabo Mofutsanyane",
      "Xhariep",
    ],
  },

  {
    key: "KwaZulu-Natal",
    value: [
      "eThekwini",
      "iLembe",
      "Ugu",
      "Umkhanyakude",
      "King Cetshwayo (Uthungulu)",
      "Amajuba",
      "Harry Gwala (Sisonke)",
      "Umgungundlovu",
      "Umzinyathi",
      "Uthukela",
      "Zululand",
    ],
  },
  {
    key: "Limpopo",
    value: ["Capricorn", "Mopani", "Sekhukhune", "Vhembe", "Waterberg"],
  },
  { key: "Mpumalanga", value: ["Ehlanzeni", "Gert Sibande", "Nkangala"] },
  {
    key: "North West",
    value: [
      "Bojanala",
      "Dr Kenneth Kaunda",
      "Dr Ruth Segomotsi Mompati",
      "Ngaka Modiri Molema",
    ],
  },
  {
    key: "Northern Cape",
    value: [
      "Frances Baard",
      "John Taolo Gaetsewe",
      "Namakwa",
      "Pixley ka Seme",
      "Z F Mgcawu",
    ],
  },
  {
    key: "Western Cape",
    value: [
      "Cape Winelands",
      "Overberg",
      "West Coast",
      "Central Karoo",
      "Garden Route",
      "City of Cape Town",
    ],
  },
];
////////CMS MASTER//////////
export const seasonNumberList = ["2022/23", "2021/22", "2020/21", "2019/20"];
export const oversPerInningsList = [
  "Unlimited",
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  106,
  107,
  108,
  109,
  110,
  111,
  112,
  113,
  114,
  115,
  116,
  117,
  118,
  119,
  120,
  121,
  122,
  123,
  124,
  125,
  126,
  127,
  128,
  129,
  130,
  131,
  132,
  133,
  134,
  135,
  136,
  137,
  138,
  139,
  140,
  141,
  142,
  143,
  144,
  145,
  146,
  147,
  148,
  149,
  150,
  151,
  152,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  160,
  161,
  162,
  163,
  164,
  165,
  166,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  174,
  175,
  176,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,
  189,
  190,
  191,
  192,
  193,
  194,
  195,
  196,
  197,
  198,
  199,
  200,
  201,
  202,
  203,
  204,
  205,
  206,
  207,
  208,
  209,
  210,
  211,
  212,
  213,
  214,
  215,
  216,
  217,
  218,
  219,
  220,
  221,
  222,
  223,
  224,
  225,
  226,
  227,
  228,
  229,
  230,
  231,
  232,
  233,
  234,
  235,
  236,
  237,
  238,
  239,
  240,
  241,
  242,
  243,
  244,
  245,
  246,
  247,
  248,
  249,
  250,
  251,
  252,
  253,
  254,
  255,
  256,
  257,
  258,
  259,
  260,
  261,
  262,
  263,
  264,
  265,
  266,
  267,
  268,
  269,
  270,
  271,
  272,
  273,
  274,
  275,
  276,
  277,
  278,
  279,
  280,
  281,
  282,
  283,
  284,
  285,
  286,
  287,
  288,
  289,
  290,
  291,
  292,
  293,
  294,
  295,
  296,
  297,
  298,
  299,
  300,
];
export const matchFormatList = getSortValue([
  "Mini-Cricket",
  "T20",
  "One Day",
  "Unlimited Overs",
]);

export const levelList = [
  { value: "Level 1", label: "Level 1" },
  { value: "Level 2", label: "Level 2" },
  { value: "Level 3", label: "Level 3" },
  { value: "Level 4", label: "Level 4" },
];
export const gradeList = [
  //  { value: "Grade A", label: "Grade A" },
  { value: "Grade B", label: "Grade B" },
  { value: "Grade C", label: "Grade C" },
  { value: "Grade D", label: "Grade D" },
];
export const scoringType = [
  { value: "Ball by Ball", label: "Ball by Ball" },
  { value: "Summary", label: "Summary" },
];
export const analysisOptions = [
  { value: "Wagon Wheel", label: "Wagon Wheel" },
  { value: "Pitch Map", label: "Pitch Map" },
];
export const finals = [
  { value: "Quarter Finals", label: "Quarter Finals" },
  { value: "Semi Finals", label: "Semi Finals" },
  { value: "Finals", label: "Finals" },
];
export const reserveDays = [
  { value: "Quarter Finals", label: "Quarter Finals" },
  { value: "Semi Finals", label: "Semi Finals" },
  { value: "Finals", label: "Finals" },
];

export const ballColor = [
  { value: "Red", label: "Red" },
  { value: "White", label: "White" },
  { value: "Pink", label: "Pink" },
  { value: "Audible Ball", label: "Audible Ball" },
  { value: "Other", label: "Other" },
];

export const submemberList = [
  { value: "primary_schools", label: "Primary School" },
  { value: "high_schools", label: "High School" },
  { value: "clubs_schools", label: "Club" },
  { value: "ancillary_member", label: "Ancillary Member" },
];

export const equipmentList = [
  { label: "Own Equipment", value: "Own Equipment" },
  { label: "Rented Equipment", value: "Rented Equipment" },
];

export const memberAdminRoles = [
  "Members Administrator",
  "Members Admin Assistant",
  "Members Coaches Manager",
  "Members Umpires Manager",
  "Members Scorers Manager",
  "Members Primary School Coordinator",
  "Members High School Coordinator",
  "Member Club Coordinator",
  "Member Ancillary Administrator",
  "Members View Only",
  "Sub-Members Administrator",
  "Sub-Members View Only",
  "Player",
  "Member",
];
