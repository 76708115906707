import React, { useState, useEffect } from "react";
import styles from "./TeamInformation.module.scss";
import CsaButton from "Common/CsaButton";
import player from "../../images/img_avatar.png";
import CsaMoreMenu from "Common/CsaMoreMenu";
import useAxios from "./../../utils/axios";
import { serviceUrl } from "services/serviceUrl";
import fileDownload from "js-file-download";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import {
  Card,
  Row,
  Col,
  AutoComplete,
  Input,
  Spin,
  Table,
  Pagination,
  Tabs,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setIsBack } from "redux/slice/commonSlice";
import { useDispatch } from "react-redux";

export default function TeamInformationTable({ ...props }) {
  const [playersData, setPlayersData] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const { onchangecomponent, mData, mId, memberName } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const onKeyChange = (key) => {
    setActiveKey(key);
  };
  const { addToast } = useToasts();

  const columnsTeam = [
    {
      title: "Season",
      dataIndex: "season",
      key: "season",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Matches",
      dataIndex: "played",
      key: "played",
    },
    {
      title: "Won",
      dataIndex: "won",
      key: "won",
    },
    {
      title: "Lost",
      dataIndex: "lost",
      key: "lost",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
  ];

  const columnsPlayers = [
    {
      title: "First Name",
      dataIndex: "field_first_name",
      key: "field_first_name",
    },
    {
      title: "Last Name",
      dataIndex: "field_last_name",
      key: "field_last_name",
    },
    // {
    //   title: "",
    //   key: "action",
    //   render: (data) => (
    //     <CsaMoreMenu
    //     //   handleView={() =>
    //     //    navigate("/cms-view", { state: { id: data?.id, type: "VIEW" } })
    //     //   }
    //     //   handleEdit={() => handleEdit(data)}
    //     />
    //   ),
    //   align: "end",
    // },
  ];
  const { id } = useParams();

  const handleDownload = () =>
    axios
      .get(serviceUrl.getStats + `team/${id}/export?format=excel`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        fileDownload(blob, `file.xlsx`); // npm " js-file-download" responsible for downloading the file for the user
      })
      .catch((error) => {
        if (error) {
          addToast("Data not found for this team", {
            appearance: "error",
          });
        }
        console.log(error.message);
      });

  //  useEffect(() => {
  //   if (err) {
  //     addToast(err.message || "Something went wrong", {
  //       appearance: "error",
  //     });
  //   }
  // }, [addToast, err]);

  const {
    callAPI: fetchTeamStats,
    response: responseTeamStats,
    error: errCompetition,
    loading: fetchingTeamStats,
  } = useAxios({
    url: serviceUrl.getStats + `team/${id}`,
    method: "get",
  });
  useEffect(() => {
    fetchTeamStats();
  }, []);

  const {
    callAPI: fetchTeamDetails,
    response: resTeamDetails,
    error: errTeamDetails,
    loading: fetchingTeamDetauls,
  } = useAxios({
    url: serviceUrl.teamDetails + `${id}`,
    method: "get",
  });
  useEffect(() => {
    fetchTeamDetails();
  }, []);
  console.log(playersData, "playersss");
  useEffect(() => {
    setPlayersData(resTeamDetails?.[0]?.players?.rows);
  }, [resTeamDetails]);

  useEffect(() => {
    if (errTeamDetails) {
      addToast(errTeamDetails?.message || "Something went wrong", {
        appearance: "warning",
      });
    }
  }, [addToast, errTeamDetails]);

  const statsArr = responseTeamStats?.data || [];
  console.log("reeeeeeeeeesponeeeee", statsArr);

  const dataArr = Object.keys(statsArr)?.map((key, index) => ({
    id: key,
    data: statsArr[key],
  }));

  const Tabledata = dataArr[0]?.data?.competitions;

  console.log("ttttttttttttss", Tabledata);

  const tdata = Tabledata?.map((item) => {
    return {
      key: item?.id || "",
      teamname: item?.name || "",
      gamesplayed: item?.games_played || "",
      member: item?.member || "",
      won: item?.games_won || "0",
      lost: item?.games_lost || "0",
      level: resTeamDetails?.[0]?.field_team_level || "",
      played: item?.games_played || "",
      status: item?.status || "",
      players: item?.players || "",
      season: item?.competition || "",
      position: item?.position,
    };
  });
  // let  tName = ""
  //  tName = tdata[0].teamname

  // const playersData = resTeamDetails?.[0]?.players || [];
  //console.log(resExport)
  const handelGoBack = () => {
    dispatch(setIsBack(true));
    //  onchangecomponent({ page: "", statsTab: "teams", isBack: true });
    navigate(-1, {
      state: {
        data: "testt",
        isBack: true,
      },
    });
  };
  window.onpopstate = () => {
    dispatch(setIsBack(true));
  };

  // function removeFirstSpace(str) {
  //   if (str.charAt(0) === " ") {
  //     console.log(str,"removed")
  //     return str.substr(1);
  //   } else {
  //     console.log(str,"removed2")
  //     return str;

  //   }
  // }

  return (
    <>
      <div className={styles.buttonWrapper}>
        <CsaButton
          label="Back"
          textColor="#FFF"
          // onClick={() => handleNavigate(-1)}
          btnColor="#007237"
          onClick={handelGoBack}
        />
      </div>
      <div className={styles.TeamInfoWrapper}>
        <div className={styles.TeamCardWrapper}>
          {/* <div className={styles.TeamImage}>
            <div>
              <img src={resTeamDetails?.[0]?.field_profile ? resTeamDetails?.[0]?.field_profile : player} alt={""} />
            </div>
          </div> */}
          <div>
            <img
              className={styles.TeamImg}
              src={
                resTeamDetails?.[0]?.field_profile
                  ? resTeamDetails?.[0]?.field_profile
                  : player
              }
              alt={""}
            />
          </div>
          <div className={styles.TeamInfoTextWrapper}>
            <h5 style={{ color: "#007237" }}>
              {resTeamDetails?.[0]?.team_name?.replace(/&#039;/g, "'")}
            </h5>
            <div className={styles.temaInfoSection}>
              <div className={styles.TeamInfoText}>
                <p>Member: {memberName ? memberName : ""} </p>
                <p>Level: {resTeamDetails?.[0]?.field_team_level || ""} </p>
                <p>Age Group: {resTeamDetails?.[0]?.field_age_group || ""} </p>
                <p>Gender: {resTeamDetails?.[0]?.field_gender || ""}</p>
                <p>
                  Differently Abled:
                  {resTeamDetails?.[0]?.field_differently_abled || ""}
                </p>
                <p>Address: {resTeamDetails?.[0]?.field_address || ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="m-2 d-flex w-100 justify-content-end align-items-end">
          <CsaButton
            label="Export"
            textColor="#FFF"
            btnColor="#007237"
            onClick={handleDownload}
          />
        </div>
        <Tabs
          type="card"
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={onKeyChange}
        >
          {" "}
          <TabPane tab="Team " key="1">
            <div>
              <Table
                className="center-aligned"
                //   className="table-striped-rows"
                loading={fetchingTeamStats}
                columns={columnsTeam}
                dataSource={tdata}
                pagination={false}
                scroll={{
                  x: 400,
                }}
              />
              <div className=" d-flex w-100 justify-content-end mt-5">
                <Pagination
                  // onChange={onChange}
                  total={10}
                  size={"small"}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Players" key="2">
            <div>
              <Table
                className="center-aligned"
                //   className="table-striped-rows"
                // loading={loading}
                columns={columnsPlayers}
                dataSource={playersData}
                pagination={false}
                scroll={{
                  x: 400,
                }}
              />
              <div className=" d-flex w-100 justify-content-end mt-5">
                <Pagination
                  // onChange={onChange}
                  total={10}
                  size={"small"}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
