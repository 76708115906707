/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  AutoComplete,
  Input,
  Pagination,
  Checkbox,
} from "antd";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { DeleteFilled } from "@ant-design/icons";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaButton from "Common/CsaButton";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useSelector } from "react-redux";
import styles from "./fields.module.scss";
import CsaBreadcrumb from "Common/CsaBreadcrumb";
import Wrapper from "Layout/Wrapper";

function FieldsTablesubMember() {
  const [tableData, setTableData] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [isScrolling, setIsScrolling] = useState(false);
  const [optionsData, setOptionsData] = useState([]);

  const { addToast } = useToasts();
  const member_id = localStorage.getItem("csa_member_id");
  const sub_member_id = localStorage.getItem("sub_member_id");
  const sub_member_name = localStorage.getItem("sub_member_name");

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.getFieldsSubMember,
    method: "get",
  });

  const {
    callAPI: validateMainField,
    response: resValidateMainField,
    error: errValidateMainField,
    loading: loadValidateMainField,
  } = useAxios({
    url: serviceUrl.validateMainField,
    method: "get",
  });

  const {
    callAPI: deleteField,
    response: resDelete,
    error: errDelete,
    loading: loadDelete,
  } = useAxios({
    url: serviceUrl.deleteSubMemberField,
    method: "delete",
  });
  const {
    callAPI: updateMainField,
    response: resMainField,
    error: errMainField,
    loading: loadMainField,
  } = useAxios({
    url: serviceUrl.updateSubMemberMainField,
    method: "post",
  });

  const {
    callAPI: getVenues,
    response: resVenues,
    error: errVenues,
    loading: loadVenues,
  } = useAxios({
    url: serviceUrl.getSubMemberVenues,
    method: "get",
  });

  const {
    callAPI: postVenues,
    response: resPostVenues,
    error: errPostVenues,
    loading: loadPostVenues,
  } = useAxios({
    url: serviceUrl.postSubMemberField,
    method: "post",
  });

  useEffect(() => {
    callAPI(`/${member_id}?field_name=&limit=10&page=1`, true);
    getVenues(`${sub_member_id}`, true);
    validateMainField(sub_member_id, true);
  }, []);

  // manage field actions and update table  //

  useEffect(() => {
    if (resVenues?.data) {
      setTableData(resVenues?.data);
    }
  }, [resVenues]);

  useEffect(() => {
    if (resPostVenues?.message) {
      console.log(resPostVenues?.message);
      addToast(resPostVenues.message, {
        appearance: "success",
      });
      validateMainField(sub_member_id, true);
    }
  }, [resPostVenues]);

  useEffect(() => {
    if (errPostVenues?.message) {
      addToast(errPostVenues.message, {
        appearance: "error",
      });
      getVenues(`${sub_member_id}`, true);
    }
  }, [errPostVenues]);

  useEffect(() => {
    if (resMainField) {
      addToast(resMainField?.message, {
        appearance: "success",
      });
      validateMainField(sub_member_id, true);
    }
  }, [resMainField]);

  useEffect(() => {
    if (errMainField) {
      addToast(errMainField?.message, {
        appearance: "error",
      });
      getVenues(`${sub_member_id}`, true);
    }
  }, [errMainField]);

  useEffect(() => {
    if (resDelete?.message) {
      addToast(resDelete.message, {
        appearance: "error",
      });
      validateMainField(sub_member_id, true);
    }
  }, [resDelete]);

  useEffect(() => {
    if (errDelete?.message) {
      addToast(errDelete.message, {
        appearance: "error",
      });
      getVenues(`${sub_member_id}`, true);
    }
  }, [errDelete]);

  useEffect(() => {
    if (resValidateMainField) {
      getVenues(`${sub_member_id}`, true);
    }
  }, [resValidateMainField]);

  ///update main filed //
  const handleAddMainField = (e, row) => {
    console.log(row);
    if (e) {
      const obj = {
        sub_member_id: row?.sub_member_id,
        add_main_field_id: row?.id,
      };
      updateMainField(obj);
    } else {
      const obj = {
        sub_member_id: row?.sub_member_id,
        remove_main_field_id: row?.id,
      };
      updateMainField(obj);
    }
  };

  //delete filed from table //
  const handleRemove = (id) => {
    const deleteObj = {
      ids: [id],
      id: id,
      sub_member_id: sub_member_id,
      sub_member_name: sub_member_name,
    };
    deleteField(deleteObj);
  };

  const hasMainField = resValidateMainField?.main_field_selected || null;
  console.log(hasMainField, "hss");

  const columns = [
    {
      title: "Main Field",
      key: "mainField",
      render: (_, row) => (
        <Checkbox
          disabled={
            (hasMainField && !row.main_field) ||
            loadMainField ||
            loadValidateMainField
          }
          style={{ cursor: "pointer", color: "red" }}
          value={row.main_field}
          checked={row.main_field}
          onChange={(e) => handleAddMainField(e.target.checked, row)}
        />
      ),
    },
    {
      title: "Field Name ",
      dataIndex: "field_name",
      key: "field_name",
    },
    {
      title: "No Of Pitches",
      dataIndex: "no_of_pitches",
      key: "no_of_pitches",
    },
    {
      title: "Pitch Type ",
      dataIndex: "pitch_type",
      key: "pitch_type",
    },
    {
      title: "Spectators Capacity",
      dataIndex: "spectators_capacity",
      key: "spectators_capacity",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <DeleteFilled
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => handleRemove(item?.id)}
        />
      ),
      // align: "center",
    },
  ];

  const pathArr = [
    { key: "Home", value: "/home" },
    { key: "Fields", value: "/fields" },
  ];

  console.log(tableData, "tableData");

  const onSelect = (key, opt) => {
    const val = inputValue || opt.label;
    const venuesData = {
      ids: [opt.id],
      sub_member_id: Number(sub_member_id),
      sub_member_name: sub_member_name,
    };

    setInputValue(val);
    postVenues(venuesData);
  };

  useEffect(() => {
    console.log(response, "sss");
    if (response) {
      isScrolling
        ? setOptionsData([...optionsData, ...response?.data])
        : setOptionsData(response?.data);
    }
  }, [response]);

  console.log(optionsData, isScrolling, "sss");

  const handleSearch = (e) => {
    setIsScrolling(false);
    callAPI(`/${member_id}?field_name=${e}&limit=10&page=1`, true);
  };

  const handleScroll = (event) => {
    setIsScrolling(true);
    const target = event.target;
    const totalHeight = target.scrollHeight - 1;
    const scrollHeight = target.scrollTop + target.offsetHeight;
    const totalItem = response?.pagination?.total;
    const curPage = response?.pagination?.current_page;
    const totalPage = totalItem / response?.pagination?.limit;
    const roundTotal = Math.round(totalPage);
    const hasPagesLeft = curPage < roundTotal;
    const bottomOfList =
      parseInt(scrollHeight.toFixed(0)) - 1 === totalHeight ||
      parseInt(scrollHeight.toFixed(0)) - 1 === totalHeight;
    const canCallApi = hasPagesLeft && bottomOfList;
    console.log(canCallApi, hasPagesLeft, bottomOfList, roundTotal, "pages");

    if (canCallApi) {
      callAPI(
        `/${member_id}?field_name=&limit=10&page=${
          response?.pagination?.current_page + 1
        }`,
        true
      );
    }
  };

  return (
    <>
      <div className={styles.TeamsWrapper}>
        <CsaBreadcrumb pathArr={pathArr} />
        <div className={styles.contents}>
          <p className={styles.heading}>{"Fields"}</p>
        </div>
        <Col span={12}>
          <AutoComplete
            style={{
              width: "100%",
              marginBottom: 10,
            }}
            value={inputValue}
            options={optionsData?.map((i) => ({
              label: i?.field_name,
              value: i?.id,
              main_field: i?.main_field,
              ...i,
              disabled: tableData.some((item) => item.id === i?.id),
            }))}
            onSelect={(val, option) => onSelect(val, option)}
            onSearch={handleSearch}
            //   // onClear={handleChange}
            onChange={(e) => setInputValue(e)}
            onPopupScroll={handleScroll}
          >
            <Input.Search
              loading={loading}
              size="large"
              placeholder="Search and Select Field..."
              allowClear
              enterButton
              // loading={loading}
              autoComplete="off"
            />
          </AutoComplete>
        </Col>
      </div>
      <div className="mt-2 pt-2 px-2 W-50">
        <Table
          className="center-aligned"
          loading={loadVenues}
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      </div>
    </>
  );
}

export default FieldsTablesubMember;
